import memoizeOne from "memoize-one";
import {
  getIsAdvanceCoverage,
  getIsArrearsCoverage,
  getIsAdvanceOrArrearsCoverage,
} from "shared/types/SlfCoverages";
import { formatFullName } from "shared/utils/format";
import { unique } from "shared/utils/utils";
import type { BillSplitType, BillTiming } from "@prisma/client";
import type { Bill, BillPreview } from "shared/types/Bill";
import type { Policy, PolicyId } from "shared/types/Client";
import type { Document } from "shared/types/Document";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";
import type {
  BillFormValues,
  BillingPreferencesFormValues,
  PolicyBillingPreferences,
} from "shared/validation/bill";

export const getIsAutogeneratedASODentalBill = (bill: {
  billTiming?: BillTiming | null | undefined;
  slfCoverages?: SlfCoverageLongName[] | null;
}) => Boolean(bill.billTiming === "Arrears" && bill.slfCoverages?.includes("ASO Dental"));

export const getBillKeyMaker = (billSplitType: BillSplitType | null | undefined) => {
  type KeyableBillPreview =
    | Pick<
        BillPreview,
        | "groupedByLocations"
        | "splitTags"
        | "slfCoverages"
        | "contact"
        | "contactId"
        | "hasDifferentMailingAddress"
        | "location"
        | "locationId"
        | "numberOfEmployees"
      >
    | BillFormValues;

  const keyMap = new WeakMap<KeyableBillPreview, string>();

  return function makeKey(bill: KeyableBillPreview) {
    const storedKey = keyMap.get(bill);
    if (storedKey) return storedKey;

    const keyObj = {
      groupByLocationIds:
        "groupedByLocations" in bill
          ? bill.groupedByLocations?.map((l) => l.id).sort()
          : "groupByLocationIds" in bill
          ? bill.groupByLocationIds
          : undefined,
      splitTags: bill.splitTags,
      contactId: bill.contactId,
      hasDifferentMailingAddress: bill.hasDifferentMailingAddress,
      locationId: bill.locationId,
      numberOfEmployees: bill.numberOfEmployees,

      // If we are splitting by benefit, we never want to link/pair/group bills together
      // because the user is responsible for creating bills for each timing.
      // We break the linking logic by including coverages in the key for each bill
      slfCoverages: billSplitType === "BENEFIT" ? bill.slfCoverages : null,
    };
    const key = JSON.stringify(keyObj);
    keyMap.set(bill, key);
    return key;
  };
};

/**
 * Takes a list of bills, and returns an array where the elements
 * can be a single bill, or a tuple of 2 bills where the first item
 * in the tuple is an advance bill and the second is an arrears bill.
 *
 * Bills will be paired together in a tuple if they have the same value
 * for a select number of properties, and are of different bill timings.
 *
 * Paired/linked/sibling bills will be rendered together in a single card
 * in the UI. See the components below and their Storybook stories if they exist
 * to understand how they are used.
 *
 * @see {@link BillCardSingle}
 * @see {@link BillCardDouble}
 * @see {@link BillsList}
 *
 * @param bills
 * @returns
 */
export const getBillTuples = (
  billSplitType: BillSplitType | null | undefined,
  billPreviews: BillPreview[],
): Array<BillPreview | [BillPreview, BillPreview]> => {
  const billPreviewsSorted = billPreviews.slice().sort((a, b) => {
    if (a.createdAt && b.createdAt) return a.createdAt.getTime() - b.createdAt.getTime();
    if (a.createdAt && !b.createdAt) return -1;
    if (!a.createdAt && b.createdAt) return 1;
    return 0;
  });

  // If we are splitting by benefit, we never want to link/pair/group bills together
  // because the user is responsible for creating bills for each timing.
  if (billSplitType === "BENEFIT") return billPreviewsSorted;

  const result: Array<BillPreview | [BillPreview, BillPreview]> = [];

  const makeKey = getBillKeyMaker(billSplitType);

  for (const bill of billPreviewsSorted) {
    // Autogenerated ASO DEntal Arrears bills should never be tupled with other bills
    const isAutogeneratedASODentalBill = getIsAutogeneratedASODentalBill(bill);
    if (isAutogeneratedASODentalBill) {
      result.push(bill);
      continue;
    }

    const key = makeKey(bill);
    const index = result.findIndex(
      (b) => !Array.isArray(b) && b.billTiming !== bill.billTiming && key === makeKey(b),
    );
    if (index !== -1) {
      const storedBill = result[index];
      if (storedBill && !Array.isArray(storedBill)) {
        result[index] =
          storedBill.billTiming === "Advance" ? [storedBill, bill] : [bill, storedBill];
      }
    } else {
      result.push(bill);
    }
  }

  return result;
};

export const getBillingPreferencesInitialFormValues = memoizeOne(
  function _getBillingPreferencesInitialFormValues(
    slfCoverages: SlfCoverageLongName[] | null,
    policyId: PolicyId,
    policyBillingPreferences: PolicyBillingPreferences,
    bills: Bill[] | BillPreview[],
  ) {
    const advanceCoveragesInClient = slfCoverages?.filter(getIsAdvanceCoverage) ?? [];
    const arrearsCoveragesInClient = slfCoverages?.filter(getIsArrearsCoverage) ?? [];
    const advanceOrArrearsCoverages =
      slfCoverages?.filter(getIsAdvanceOrArrearsCoverage).sort() ?? [];

    const hasOnlyAdvance =
      advanceCoveragesInClient.length > 0 && arrearsCoveragesInClient.length === 0;
    const hasOnlyArrears =
      advanceCoveragesInClient.length === 0 && arrearsCoveragesInClient.length > 0;

    const initialAdvanceOrArrears = Object.fromEntries(
      advanceOrArrearsCoverages.map((cov) => {
        const billWithThisCoverage = bills.find((bill) => bill.slfCoverages?.includes(cov));
        if (billWithThisCoverage) return [cov, billWithThisCoverage.billTiming];

        if (hasOnlyAdvance) return [cov, "Advance" as const];
        if (hasOnlyArrears) return [cov, "Arrears" as const];

        return [cov, "Advance" as const];
      }),
    );

    const initialBills = getBillTuples(policyBillingPreferences.billSplitType, bills)
      .flat()
      .map((bill): BillFormValues => {
        const billFormValues = {
          id: bill.id ?? null,
          policyId: bill.policyId || policyId,

          createdAt: bill.createdAt ?? null,
          advanceOrArrears: initialAdvanceOrArrears,
          billingAdministrationType: policyBillingPreferences.billingAdministrationType,
          billingStructureType: policyBillingPreferences.billingStructureType,
          billSplitType: policyBillingPreferences.billSplitType,

          billName: bill.billName,
          billTiming: bill.billTiming,
          contactId: bill.contactId,
          hasDifferentMailingAddress: bill.hasDifferentMailingAddress,
          locationId: bill.locationId,
          numberOfEmployees: bill.numberOfEmployees,
          categorizeEmployees: bill.categorizeEmployees,
          employeeCategorizationType: bill.employeeCategorizationType,
          categorizeByLocationIds:
            bill.categoriesByLocation?.map((locs) => locs.map((loc) => loc.id)) ?? null,
          categoriesByTags: bill.categoriesByTags,

          slfCoverages:
            policyBillingPreferences.billingAdministrationType === "LIST"
              ? policyBillingPreferences.billSplitType === "BENEFIT"
                ? bill.slfCoverages?.sort() ?? null
                : unique(
                    bill.billTiming === "Advance"
                      ? bill.slfCoverages?.concat(...advanceCoveragesInClient)
                      : bill.billTiming === "Arrears"
                      ? bill.slfCoverages?.concat(...arrearsCoveragesInClient)
                      : bill.slfCoverages,
                  )?.sort() ?? null
              : slfCoverages,
          groupByLocationIds:
            policyBillingPreferences.billSplitType === "LOCATION"
              ? bill.groupedByLocations?.map((l) => l.id) ?? null
              : null,
          splitTags: policyBillingPreferences.billSplitType === "TAGS" ? bill.splitTags : null,
        };

        const isAutogeneratedASODentalBill = getIsAutogeneratedASODentalBill(bill);
        if (isAutogeneratedASODentalBill) {
          billFormValues.slfCoverages = ["ASO Dental"];
        }

        return billFormValues;
      });

    const formValues: BillingPreferencesFormValues = {
      billingAdministrationType: policyBillingPreferences.billingAdministrationType,
      billingStructureType:
        policyBillingPreferences.billingStructureType ??
        (policyBillingPreferences.billingAdministrationType === "LIST"
          ? "MULTIPLE"
          : policyBillingPreferences.billingStructureType),
      billingSummaryStatementType: policyBillingPreferences.billingSummaryStatementType,
      billPayrollCycles: policyBillingPreferences.billPayrollCycles || [],
      billPayrollCyclesOther: policyBillingPreferences.billPayrollCyclesOther,
      billPayrollCyclesExplanation: policyBillingPreferences.billPayrollCyclesExplanation,
      billSplitType: policyBillingPreferences.billSplitType,
      tpaContact: structuredClone(policyBillingPreferences.tpaContact),
      whoSubmitsClaims: policyBillingPreferences.whoSubmitsClaims,
      advanceOrArrears: initialAdvanceOrArrears,
      bills: initialBills,
    };

    if (formValues.tpaContact) {
      formValues.tpaContact.fullName = formatFullName(formValues.tpaContact);
    }

    return {
      formValues,
      advanceCoveragesInClient,
      arrearsCoveragesInClient,
      advanceOrArrearsCoverages,
    };
  },
);

export function getStatementsForPolicy(
  allBillingSummaryStatementTemplates: Document[],
  policy: Pick<Policy, "id" | "primaryPolicy">,
) {
  const billingSummaryStatementTemplates = allBillingSummaryStatementTemplates.filter((d) => {
    const belongsToThisPolicy =
      d.policyIds.length === 0
        ? policy.primaryPolicy
        : d.policyIds.some((pid) => pid === policy.id);
    return belongsToThisPolicy;
  });
  return billingSummaryStatementTemplates;
}
