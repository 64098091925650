import { viteConfig } from "client/src/utils/viteConfig";

export const isYourBenefitsExplorer = window.location.host.includes(
  viteConfig.VITE_BENEFITS_EXPLORER_BASE_URL,
);

export function initYourBenefitsExplorer() {
  if (isYourBenefitsExplorer) {
    document.title = "Your Benefits Explorer";
  }
}
