import { Modal, Typography } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";
import { SlobDropdown } from "client/src/components/SlobDropdown/SlobDropdown";
import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";
import { useRecaptcha } from "client/src/utils/re-captcha";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { BROKER } from "shared/rbac/roles";
import { Button } from "../../components/Button/Button";
import { DropdownButton } from "../../components/Button/DropdownButton";
import { ConfirmDialog } from "../../components/ConfirmDialog/ConfirmDialog";
import { ConfirmDialogDeleteUser } from "../../components/ConfirmDialog/ConfirmDialogDeleteUser";
import { genericErrorCopy2 } from "../../components/Error/ErrorMessage";
import { GoBackLink } from "../../components/Link/GoBackLink";
import { Loading } from "../../components/Loading/Loading";
import { ActionMenu } from "../../components/Menu/ActionMenu";
import { PageContent } from "../../components/PageContent/PageContent";
import { slobMessage } from "../../components/slobMessage/slobMessage";
import { InternalBrokerClientsList } from "../../domain/Client/ClientsList/InternalBrokerClientsList";
import { BrokerUpdateForm } from "../../domain/User/Form/BrokerUpdateForm";
import { triggerError } from "../../hooks/generalError";
import { useClientHubParams } from "../../hooks/useClientHubParams";
import { useDeleteBroker, useGetBroker, useResetPassword } from "../../hooks/user";

import type { Broker } from "shared/types/User";

const { Title } = Typography;

export const BrokerDetailPage = () => {
  const [editState, setEditState] = useState<Broker | null>(null);
  const { userId } = useClientHubParams(["userId"]);
  const searchParams = { userId };
  const navigate = useNavigate();

  const { data: broker, isLoading, error } = useGetBroker(userId);
  const { data: featureToggles, isLoading: isFeatureTogglesLoading } = useGetFeatureToggles();
  const { mutateAsync: deleteBroker } = useDeleteBroker();
  const { mutateAsync: resetPassword } = useResetPassword();

  const { executeRecaptcha } = useRecaptcha({ badge: "bottomleft" });

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isResetDialogVisible, setIsResetDialogVisible] = useState(false);

  if (error) {
    return triggerError(error);
  }

  if (isLoading || isFeatureTogglesLoading) {
    return <Loading />;
  }

  if (!userId || !broker) {
    return triggerError("Broker not found");
  }

  const hideModal = () => {
    setEditState(null);
  };

  const showModal = (broker: Broker) => {
    setEditState(broker);
  };

  const handleDelete = async () => {
    setIsDeleteDialogVisible(false);
    const hide = slobMessage.loading("Delete in progess", 0);
    try {
      await deleteBroker({
        params: { userId: broker.id },
      });
      void slobMessage.success("Broker deleted");
      navigate(RouteData.brokers.getPath());
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleReset = async (broker: Broker) => {
    setIsResetDialogVisible(false);
    const hide = slobMessage.loading("Sending reset email", 0);
    try {
      const recaptchaToken = await executeRecaptcha();
      await resetPassword({
        params: { email: broker.email },
        data: { recaptchaToken },
      });
      void slobMessage.success("Password email sent");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const menu = (
    <ActionMenu
      data-testid="dropdown-menu"
      items={[
        { key: "edit-broker", label: "Edit Broker" },
        { key: "delete-broker", label: "Delete Broker" },
      ]}
      onClick={({ key }) => {
        switch (key) {
          case "edit-broker":
            showModal(broker);
            break;

          case "delete-broker":
            setIsDeleteDialogVisible(true);
            break;
        }
      }}
    />
  );

  return (
    <PageContent>
      <div className="mb-12">
        <GoBackLink to={RouteData.brokers.getPath()}>&lt; Back to Brokers List</GoBackLink>
      </div>
      <Row>
        <Col span={16}>
          <Title>{broker.fullName}</Title>
        </Col>
        <Col flex="auto">
          <Row justify="end" gutter={8}>
            <Col>
              <Button onClick={() => setIsResetDialogVisible(true)} size="small">
                Reset Password
              </Button>
              <ConfirmDialog
                title="Reset password"
                isVisible={isResetDialogVisible}
                onCancel={() => setIsResetDialogVisible(false)}
                onConfirm={() => handleReset(broker)}
                confirmActionText="Yes"
                cancelActionText="No"
              >
                Are you sure you want to reset password?
              </ConfirmDialog>
            </Col>
            <Col>
              <SlobDropdown dropdownRender={() => menu}>
                <DropdownButton />
              </SlobDropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      {editState != null && (
        <Modal
          onCancel={hideModal}
          destroyOnClose={true}
          open={editState != null}
          footer={null}
          centered={true}
        >
          <BrokerUpdateForm broker={editState} handleClose={hideModal} />
        </Modal>
      )}
      <InternalBrokerClientsList extraSearchParams={searchParams} featureToggles={featureToggles} />

      <ConfirmDialogDeleteUser
        role={BROKER}
        isDeleteDialogVisible={isDeleteDialogVisible}
        setIsDeleteDialogVisible={setIsDeleteDialogVisible}
        activeUser={broker}
        handleDelete={handleDelete}
      />
    </PageContent>
  );
};
