import { faEllipsisH, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { SlobDropdown } from "client/src/components/SlobDropdown/SlobDropdown";
import { TinyBadge } from "client/src/components/TinyBadge/TinyBadge";
import { ClientErrorsDrawer } from "client/src/domain/Client/ClientErrors/ClientErrorsDrawer";
import { clientErrorLabel, clientErrorWarningTypes } from "client/src/hooks/clientError";
import clsx from "clsx";
import { compact } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { exhaustiveCheckFail } from "shared/utils/exhaustiveCheck";
import { formatFullName } from "shared/utils/format";

import { isEqual, unique } from "shared/utils/utils";
import { getIsInternalUser } from "../../../../../../shared/rbac/rbac";
import { Button } from "../../../../components/Button/Button";
import { ReactComponent as WarningInfo } from "../../../../components/Icons/WarningInfo.svg";
import { ActionMenu } from "../../../../components/Menu/ActionMenu";
import { Ribbon } from "../../../../components/Ribbon/Ribbon";
import { SlobTable } from "../../../../components/SlobTable/SlobTable";
import { ActionButtons } from "../../../../components/Table/ActionButtons";
import { Eyebrow } from "../../../../components/Typography/Typography";
import { useSlobAuth } from "../../../../hooks/auth";
import { useHubConfiguration } from "../../../../hooks/useConfig";
import { getPoliciesEffectiveDates } from "../../../../utils/sort";

import {
  CompactProgressTrackerPolicy,
  comparePolicyByPhaseScore,
  getPolicyWithHigherPhaseScore,
} from "./CompactProgressTrackerPolicy";
import { MultiCellValue } from "./MultiCellValue";
import * as styles from "./Table.module.less";
import type { SlobColumnsType, SlobTableProps } from "../../../../components/SlobTable/SlobTable";
import type { ClientError, ClientErrorType } from "@prisma/client";
import type { Dispatch, SetStateAction } from "react";
import type { Client, ImplementationType } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { User } from "shared/types/User";

type SelectedClientError = {
  errorType: ClientErrorType;
  data: ClientError[];
  client: Client;
};

type BuildMenuArgs = {
  client: Client;
  setSelectedClientErrors: Dispatch<SetStateAction<SelectedClientError | undefined>>;
  isInternalUser: boolean;
  featureToggles?: ClientFeatureToggles;
};
const buildMenu = ({ client, setSelectedClientErrors, isInternalUser }: BuildMenuArgs) => {
  const hasClientErrors = client.clientErrors?.length;
  const clientAlertErrors =
    client.clientErrors?.filter((e) => !clientErrorWarningTypes.includes(e.errorType)) || [];
  const clientAlertErrorsTypes = unique(clientAlertErrors.map((i) => i.errorType));
  const clientAlertWarnings =
    client.clientErrors?.filter((e) => clientErrorWarningTypes.includes(e.errorType)) || [];
  const clientAlertWarningTypes = unique(clientAlertWarnings.map((i) => i.errorType));

  const menu = (
    <ActionMenu
      data-testid="dropdown-action-menu"
      items={[
        ...(clientAlertErrorsTypes || []).map((error) => ({
          key: `${error}-help`,
          label: (
            <ButtonOld
              type="link-inline"
              className={styles.clientError}
              onClick={() =>
                setSelectedClientErrors({
                  errorType: error,
                  data: clientAlertErrors.filter((e) => e.errorType === error),
                  client,
                })
              }
            >
              <span>
                <FontAwesomeIcon icon={faExclamationTriangle} className={styles.dangerIcon} />
              </span>
              <span>{clientErrorLabel[error]} help</span>
            </ButtonOld>
          ),
        })),
        ...(clientAlertWarningTypes || []).map((warning) => ({
          key: `${warning}-help`,
          label: (
            <ButtonOld
              type="link-inline"
              className={styles.clientError}
              onClick={() =>
                setSelectedClientErrors({
                  errorType: warning,
                  data: clientAlertWarnings.filter((e) => e.errorType === warning),
                  client,
                })
              }
            >
              <div className="flex items-center">
                <WarningInfo width={16} height={16} className="mr-4" />
                <span>{clientErrorLabel[warning]} help</span>
              </div>
            </ButtonOld>
          ),
        })),
        {
          key: "manage-client",
          label: (
            <Link
              to={RouteData.clientDetail.getPath(client.id)}
              className={clsx(hasClientErrors && styles.withClientErrors)}
            >
              Manage Client
            </Link>
          ),
        },
      ].concat(
        isInternalUser
          ? [
              {
                key: "email-log",
                label: (
                  <Link
                    to={RouteData.emails.getPath(client.id)}
                    className={clsx(hasClientErrors && styles.withClientErrors)}
                  >
                    Email Log
                  </Link>
                ),
              },
            ]
          : [],
      )}
    />
  );
  return menu;
};

type ColumnsArgs = {
  setSelectedClientErrors: Dispatch<SetStateAction<SelectedClientError | undefined>>;
  isInternalUser: boolean;
  featureToggles?: ClientFeatureToggles;
};

const columns = ({
  setSelectedClientErrors,
  isInternalUser,
  featureToggles,
}: ColumnsArgs): SlobColumnsType<Client> => [
  {
    title: "Client",
    dataIndex: "name",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true,
    render: (clientName: string, client) => {
      const portalTypes = compact([
        client.isOnboard ? "Onboard" : null,
        client.isBenefitsExplorer ? "Benefits Explorer" : null,
      ]).join(", ");

      const ribbonTexts = compact([client.isTest && "Test", !client.isActive && "Draft"]);

      const clientAlertErrors =
        client.clientErrors?.filter((e) => !clientErrorWarningTypes.includes(e.errorType)) || [];
      const clientAlertErrorsTypes = unique(clientAlertErrors.map((i) => i.errorType));
      const clientAlertWarnings =
        client.clientErrors?.filter((e) => clientErrorWarningTypes.includes(e.errorType)) || [];
      const clientAlertWarningTypes = unique(clientAlertWarnings.map((i) => i.errorType));

      return (
        <div className={styles.flexCell}>
          <div>
            <>
              {clientAlertErrorsTypes?.map((error) => (
                <div key={error}>
                  <TinyBadge variant="red">{clientErrorLabel[error]}</TinyBadge>
                </div>
              ))}
              {clientAlertWarningTypes?.map((warning) => (
                <div key={warning}>
                  <TinyBadge variant="yellow">{clientErrorLabel[warning]}</TinyBadge>
                </div>
              ))}
            </>
            {clientName}
            <br />
            <Eyebrow>{portalTypes}</Eyebrow>
          </div>
          <Ribbon position="top-left" text={ribbonTexts} />
        </div>
      );
    },
  },
  {
    title: "Case ID",
    dataIndex: "caseId",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true,
  },
  {
    title: "Imp. Type",
    dataIndex: "implementationType",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true,
    width: 195,
    render(value: ImplementationType) {
      let implementationType;
      switch (value) {
        case "ADD_COVERAGE":
          implementationType = "ADD COVERAGE";
          break;
        case "NEW_IMPLEMENTATION":
          implementationType = "NEW ISSUE";
          break;
        default:
          exhaustiveCheckFail(value);
      }

      return <TinyBadge variant="gray">{implementationType}</TinyBadge>;
    },
  },
  {
    title: "Imp. Consultant",
    dataIndex: "slfImplementationConsultant",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true,
    render(value?: User) {
      return value ? formatFullName(value) : null;
    },
  },
  {
    title: "Onboarding Phase",
    sorter: (aClient, bClient) => {
      const aPolicy = getPolicyWithHigherPhaseScore(aClient.policies);
      const bPolicy = getPolicyWithHigherPhaseScore(bClient.policies);
      return comparePolicyByPhaseScore(aPolicy?.phaseId, bPolicy?.phaseId);
    },
    sortDirections: ["ascend", "descend", "ascend"],
    render: (client: Client) => {
      return (
        <>
          {client.policies.map((policy) => {
            return (
              <div key={policy.slfPolicyNumber}>
                {`Policy #${policy.slfPolicyNumber}`}
                <CompactProgressTrackerPolicy key={policy.id} client={client} policy={policy} />
              </div>
            );
          })}
        </>
      );
    },
  },
  {
    width: 50,
    title: "Effective Date",
    dataIndex: "firstPolicyEffective",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    className: MultiCellValue.tdClassName,
    render: (_, client: Client) => {
      const dates = getPoliciesEffectiveDates(client.policies);
      return (
        <MultiCellValue>
          {dates.map((date, i) => (
            <div key={`${date}_${i}`}>{date}</div>
          ))}
        </MultiCellValue>
      );
    },
  },
  {
    render: (client: Client) => (
      <ActionButtons>
        <Button type="secondary" size="xtra-small" to={RouteData.homeHub.getPath(client.id)}>
          View Portal
        </Button>
        <SlobDropdown
          dropdownRender={() =>
            buildMenu({
              client,
              setSelectedClientErrors,
              isInternalUser,
              featureToggles,
            })
          }
        >
          <ButtonOld
            type="tertiary"
            size="xtra-small"
            data-testid="menu-button"
            aria-label="View more"
            className={clsx(
              styles.dropdownBtnSmall,
              client.clientErrors?.length && styles.errorPin,
            )}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </ButtonOld>
        </SlobDropdown>
      </ActionButtons>
    ),
  },
];

export type ClientsTableProps = Omit<SlobTableProps<Client>, "columns">;

export const ClientsTable = ({
  isLoading,
  currentPage,
  onChange,
  totalItems,
  pageSize,
  data,
  featureToggles,
}: ClientsTableProps) => {
  const [selectedClientErrors, setSelectedClientErrors] = useState<SelectedClientError>();
  const config = useHubConfiguration();
  const { authUser } = useSlobAuth();
  const isInternalUser = getIsInternalUser(authUser);
  const slobColumns = columns({
    setSelectedClientErrors,
    isInternalUser,
    featureToggles,
  });

  const onData = useCallback(() => {
    if (data && selectedClientErrors) {
      const client = data.find((client) => client.id === selectedClientErrors?.client.id);
      if (!client?.clientErrors?.length) return;
      const clientErrors = client.clientErrors?.filter(
        (clientError) => clientError.errorType === selectedClientErrors.errorType,
      );
      if (isEqual(clientErrors, selectedClientErrors.data)) return;
      // refreshes alert if it was updated in client list
      setSelectedClientErrors({
        errorType: selectedClientErrors.errorType,
        data: clientErrors,
        client,
      });
    }
  }, [data, selectedClientErrors]);

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- disable
  useEffect(() => {
    if (data) {
      onData();
    }
  }, [data, onData]);

  return (
    <>
      <SlobTable
        columns={slobColumns}
        isLoading={isLoading}
        currentPage={currentPage}
        onChange={onChange}
        totalItems={totalItems}
        pageSize={pageSize}
        data={data}
        featureToggles={featureToggles}
      />
      <ClientErrorsDrawer
        jiraURL={config.REACT_APP_JIRA_URL}
        data={selectedClientErrors}
        onClose={() => setSelectedClientErrors(undefined)}
      />
    </>
  );
};
