import { Row, Col } from "client/src/components/Grid/Grid";
import moment from "moment";
import slugify from "slugify";
import { RouteData } from "../../../../../../shared/config/routeData";
import { Button } from "../../../../components/Button/Button";
import { Modal } from "../../../../components/Modal/Modal";
import { StackX } from "../../../../components/Spacing/Spacing";
import { Body1 } from "../../../../components/Typography/Typography";
import { Trans, useTranslation } from "../../../../i18n";
import { timezoneAdjustedMoment } from "../../../../utils/moment";
import type { PublicExplorerPage } from "../../../../../../shared/types/ExplorerPage";
import type { ReactNode } from "react";

export type ExplorerNewSiteModalCopyStructure = {
  header_not_expired: string;
  content_not_expired: ReactNode;
  header_expired: string;
  content_expired: ReactNode;
  callToAction_NewSite: string;
  callToAction_CurrentSite: string;
};

type ExplorerNewSiteModalProps = {
  visible: boolean;
  onCancel?: () => void;
  explorerPage: PublicExplorerPage | undefined;
};

export const ExplorerNewSiteModal = ({
  visible,
  onCancel,
  explorerPage,
}: ExplorerNewSiteModalProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerNewSiteModal" });

  if (!explorerPage?.newExplorerPageId) return null;

  const context =
    explorerPage.expiresAt &&
    moment().isAfter(timezoneAdjustedMoment(moment(explorerPage.expiresAt)))
      ? "expired"
      : "not_expired";

  const clientName = explorerPage.newExplorerPageClientName ?? explorerPage.clientName;

  return (
    <Modal
      open={visible}
      footer={null}
      title={
        <Trans
          t={t}
          i18nKey="header"
          values={{
            clientName,
          }}
          tOptions={{ context: context }}
        />
      }
      showCloseIcon={true}
      onCancel={onCancel}
    >
      <Trans
        t={t}
        i18nKey="content"
        values={{
          planYear:
            explorerPage.newExplorerPagePlanYear ??
            timezoneAdjustedMoment(moment(explorerPage.newExplorerPageEndDate))
              .toDate()
              .getFullYear(),
          clientName,
        }}
        tOptions={{ context: context }}
      >
        <Body1 as="p"></Body1>
      </Trans>

      <Row justify="center" align="middle" className="mt-32">
        <Col>
          <StackX dist={16} className="mt-32">
            {context !== "expired" && (
              <Button type="text-only" size="middle" onClick={() => onCancel?.()}>
                {t("callToAction_CurrentSite")}
              </Button>
            )}
            <Button
              type="primary"
              size="middle"
              {...{
                [explorerPage.customBranding ? "href" : "to"]: RouteData[
                  explorerPage.customBranding ? "benefitsExplorer" : "benefitsExplorerInternal"
                ].getPath(
                  explorerPage.newExplorerPageVanitySlug
                    ? slugify(explorerPage.newExplorerPageVanitySlug, { lower: true })
                    : explorerPage.newExplorerPageId,
                ),
              }}
            >
              {t("callToAction_NewSite")}
            </Button>
          </StackX>
        </Col>
      </Row>
    </Modal>
  );
};
