import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { Col, Row } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { BillCreationAndEditingForm } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/BillCreationAndEditingForm";
import { getPoliciesToCopyFrom } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/CopySettingsFromAnotherBillModal";
import { YourBillsCard } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/YourBillsCard";
import { MissingBillsAlert } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/MissingBillsAlert";
import {
  getBillGroupFormValuesFromBills,
  getBillsFromBillGroup,
} from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/billing";
import { useToggler } from "client/src/hooks/useToggler";
import { useEffect, useRef, useState } from "react";
import { type AdvanceOrArrearsSelection } from "shared/utils/EIF/getAdvanceAndArrearsCoveragesBreakdown";
import { getBillKeyMaker, getIsAutogeneratedASODentalBill } from "shared/utils/bill";
import { assertIsDefined, rejectNullableValues } from "shared/utils/utils";
import type { BillSplitType } from ".prisma/client";
import type { useFormik } from "formik";
import type { UserData } from "shared/rbac/rbac";
import type { Bill, BillGroup, BillPreview } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, Policy } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Location } from "shared/types/Location";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { BillFormValues, BillingPreferencesFormValues } from "shared/validation/bill";

type Props = {
  client: Client;
  policy: Policy;
  locations: Location[];
  admins: Contact[];
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  billSplitType: BillSplitType;
  bills: Bill[];
  billsInThisPolicy: Bill[];
  billPreviews: BillPreview[];
  advanceOrArrears: AdvanceOrArrearsSelection;
  formik: ReturnType<typeof useFormik<BillingPreferencesFormValues>>;
  isSubStepStarted: boolean;
  disabled: boolean;
  featureToggles: ClientFeatureToggles;
};

export function BillsCreationAndEditingCard(props: Props) {
  const {
    client,
    policy,
    locations,
    admins,
    advanceOrArrears,
    formik,
    isSubStepStarted,
    disabled,
    bills,
    billsInThisPolicy,
    billPreviews,
    changeSnapshot,
    authUser,
    billSplitType,
    featureToggles,
  } = props;

  const [viewState, setViewState] = useState<
    | { type: "list" }
    | { type: "create" }
    | {
        type: "edit";
        index1: number;
        index2: number | undefined;
        billGroup: BillGroup;
      }
  >(billPreviews.length === 0 ? { type: "create" } : { type: "list" });

  const [copyModalIsOpen, toggleCopyModalIsOpen] = useToggler();

  const policiesToCopyFrom = getPoliciesToCopyFrom(client, policy, bills);

  const { containerRef, formRef } = useManageScroll(viewState.type);

  const [index1, index2] =
    viewState.type === "edit"
      ? [viewState.index1, viewState.index2]
      : [formik.values.bills.length, formik.values.bills.length + 1];

  const billIds = billPreviews
    .filter((_billPreview, billIndex) => billIndex === index1 || billIndex === index2)
    .map((b) => b.id)
    .filter(rejectNullableValues);

  return (
    <div ref={containerRef} className="stack-y-32">
      <YourBillsCard
        billsInThisPolicy={billsInThisPolicy}
        billPreviews={billPreviews}
        client={client}
        policy={policy}
        featureToggles={featureToggles}
        changeSnapshot={changeSnapshot}
        authUser={authUser}
        billSplitType={billSplitType}
        policyBillingPreferences={formik.values}
        disabled={disabled || formik.isSubmitting || viewState.type !== "list"}
        onEdit={(index1, index2) => {
          const bill1 = billPreviews[index1];
          assertIsDefined(bill1, "bill1");
          const bill2 = index2 != null ? billPreviews[index2] : undefined;

          const billGroup: BillGroup = {
            policyId: bill1.policyId || policy.id,
            advanceOrArrears,
            billingAdministrationType: "LIST" as const,
            billingStructureType: "MULTIPLE" as const,
            billSplitType,
            billName: bill1.billName,

            ...getBillGroupFormValuesFromBills(bill1, bill2),
          };

          setViewState({ type: "edit", index1, index2, billGroup });
        }}
        onDelete={async (index1, index2) => {
          const indiciesToRemove = [index1, index2];

          findAssociatedASODentalArrearsBill(
            formik.values.bills[index1],
            index2 ? formik.values.bills[index2] : undefined,
            index1,
            index2,
            formik.values.bills,
            ({ followingIndex, followingBill, isAutogeneratedASODentalBill }) => {
              if (followingBill && isAutogeneratedASODentalBill) {
                indiciesToRemove.push(followingIndex);
              }
            },
          );

          const nextBills = formik.values.bills.filter(
            (_bill, index) => !indiciesToRemove.includes(index),
          );

          await formik.setFieldValue(`bills`, nextBills);
        }}
      />

      {viewState.type === "list" ? (
        <>
          <MissingBillsAlert
            slfCoverages={policy.slfCoverages}
            billingStructureType={"MULTIPLE"}
            billSplitType={billSplitType}
            bills={billPreviews}
          />

          <div>
            <Button
              type="text-only"
              size="middle"
              icon={<FontAwesomeIcon icon={faPlusCircle} />}
              disabled={disabled || formik.isSubmitting}
              onClick={() => setViewState({ type: "create" })}
            >
              {billPreviews.length > 0 ? "Create another bill" : "Create a bill"}
            </Button>
          </div>
        </>
      ) : (
        <div ref={formRef}>
          <HubCard>
            <Row align="middle" justify="space-between" className="mb-16">
              <Col>
                <h3 className="m-0">{viewState.type === "create" ? "Create bill" : `Edit bill`}</h3>
              </Col>

              {policiesToCopyFrom.length > 0 && (
                <Col>
                  <Button
                    type="text-only"
                    size="middle"
                    onClick={toggleCopyModalIsOpen}
                    disabled={disabled}
                  >
                    Copy settings from another bill
                  </Button>
                </Col>
              )}
            </Row>

            <BillCreationAndEditingForm
              key={billSplitType}
              client={client}
              policy={policy}
              locations={locations}
              admins={admins}
              billSplitType={billSplitType}
              billPreviews={billPreviews}
              index1={index1}
              index2={index2}
              billIds={billIds}
              advanceOrArrears={advanceOrArrears}
              changeSnapshot={changeSnapshot}
              authUser={authUser}
              disabled={disabled}
              featureToggles={featureToggles}
              initialValues={viewState.type === "edit" ? viewState.billGroup : undefined}
              isSubStepStarted={isSubStepStarted}
              onCancel={() => setViewState({ type: "list" })}
              copyModalIsOpen={copyModalIsOpen}
              toggleCopyModalIsOpen={toggleCopyModalIsOpen}
              bills={bills}
              onSubmit={async (values) => {
                const billGroup: BillGroup = {
                  ...values,
                  billingAdministrationType: values.billingAdministrationType,
                  billSplitType: values.billSplitType,
                  billingStructureType: "MULTIPLE",
                };

                const makeKey = getBillKeyMaker(billSplitType);

                const existingBillKeys = new Set(formik.values.bills.map((b) => makeKey(b)));

                const existingBill1: BillPreview | undefined = billPreviews[index1];
                const existingBill2 = index2 !== undefined ? billPreviews[index2] : undefined;

                const [bill1, bill2] = getBillsFromBillGroup({
                  billGroup,
                  policy,
                  billSplitType,
                  advanceOrArrears,
                  existingBill1,
                  existingBill2,
                });

                let billWasAddedOrEdited = false;

                const nextBills = formik.values.bills.slice();
                if (bill1 && bill2 && index2 != null) {
                  // If we're creating bills, ignore them if they already exist
                  const billsDoNotExist =
                    !existingBillKeys.has(makeKey(bill1)) && !existingBillKeys.has(makeKey(bill2));
                  if (viewState.type === "edit" || billsDoNotExist) {
                    nextBills[index1] = bill1;
                    nextBills[index2] = bill2;
                    billWasAddedOrEdited = true;
                  }
                } else {
                  // If we're creatnig bills, ignore them if they already exist
                  const billDoesNotExist = !existingBillKeys.has(makeKey(bill1));
                  if (viewState.type === "edit" || billDoesNotExist) {
                    nextBills[index1] = bill1;
                    billWasAddedOrEdited = true;
                  }
                }

                if (billWasAddedOrEdited) {
                  findAssociatedASODentalArrearsBill(
                    bill1,
                    bill2,
                    index1,
                    index2,
                    nextBills,
                    ({
                      asoDentalAdvanceBill,
                      followingIndex,
                      followingBill,
                      isAutogeneratedASODentalBill,
                    }) => {
                      const asoDentalArrearsBill = {
                        ...asoDentalAdvanceBill,
                        id: isAutogeneratedASODentalBill ? followingBill?.id ?? null : null,
                        policyId: policy.id,
                        billName: "Dental ASO Claims Reimbursement",
                        billTiming: "Arrears" as const,
                        slfCoverages: ["ASO Dental" as const],
                      };
                      if (followingBill && !isAutogeneratedASODentalBill) {
                        nextBills.splice(followingIndex, 0, asoDentalArrearsBill);
                      } else {
                        nextBills[followingIndex] = asoDentalArrearsBill;
                      }
                    },
                  );
                }

                await formik.setFieldValue(`bills`, nextBills);

                setViewState({ type: "list" });
              }}
            />
          </HubCard>
        </div>
      )}
    </div>
  );
}

function useManageScroll(viewStateType: "list" | "create" | "edit") {
  const containerRef = useRef<HTMLHeadingElement | null>(null);
  const formRef = useRef<HTMLHeadingElement | null>(null);

  useEffect(() => {
    if (viewStateType === "list") {
      if (containerRef.current) {
        window.scrollBy({
          behavior: "smooth",
          top: containerRef.current.getBoundingClientRect().top - 100,
        });
      }
    } else if (viewStateType === "create" || viewStateType === "edit") {
      if (formRef.current) {
        window.scrollBy({
          behavior: "smooth",
          top: formRef.current.getBoundingClientRect().top - 100,
        });
      }
    }
  }, [viewStateType]);

  return { containerRef, formRef };
}

function findAssociatedASODentalArrearsBill(
  bill1: BillFormValues | undefined,
  bill2: BillFormValues | undefined,
  index1: number,
  index2: number | undefined,
  listWithArrearsBill: BillFormValues[],
  onAdvanceBillFound: (args: {
    asoDentalAdvanceBill: BillFormValues;
    followingIndex: number;
    followingBill: BillFormValues | undefined;
    isAutogeneratedASODentalBill: boolean;
  }) => void,
) {
  const asoDentalAdvanceBill = [bill1, bill2].find(
    (b) => b?.billTiming === "Advance" && b.slfCoverages?.includes("ASO Dental"),
  );
  if (asoDentalAdvanceBill) {
    const followingIndex = bill2 && index2 ? index2 + 1 : index1 + 1;
    const followingBill = listWithArrearsBill[followingIndex];
    const isAutogeneratedASODentalBill = followingBill
      ? getIsAutogeneratedASODentalBill(followingBill)
      : false;

    onAdvanceBillFound({
      asoDentalAdvanceBill,
      followingIndex,
      followingBill,
      isAutogeneratedASODentalBill,
    });
  }
}
