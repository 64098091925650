import * as Sentry from "@sentry/react";
import { useRecaptcha } from "client/src/utils/re-captcha";
import { ForgotPasswordValidator } from "shared/validation/user";

import { Button } from "../../../components/Button/Button";
import { ErrorMessage, GenericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { FormInput } from "../../../components/Form/Input";
import { StackY } from "../../../components/Spacing/Spacing";
import { ResponseError } from "../../../hooks/query";
import { useSlobFormik } from "../../../hooks/useSlobFormik";

import type { ResetPasswordFunc, ResendActivationEmailFunc } from "../../../hooks/user";

type PasswordForgotFormProps = {
  onSuccess: () => void;
  submitButtonLabel: string;
  mutate: ResetPasswordFunc | ResendActivationEmailFunc;
  track: () => void;
};

export const PasswordForgotForm = ({
  onSuccess,
  submitButtonLabel,
  mutate,
  track,
}: PasswordForgotFormProps) => {
  const { executeRecaptcha } = useRecaptcha();

  const formik = useSlobFormik({
    initialValues: { email: "" },
    validationSchema: ForgotPasswordValidator,
    async onSubmit(values) {
      formik.setStatus("");

      try {
        const recaptchaToken = await executeRecaptcha();
        const data = await mutate({
          params: { email: values.email },
          data: { recaptchaToken },
        });
        if (data) {
          onSuccess();
        }
      } catch (error) {
        const errorMsg = ResponseError.getUserFacingErrorMessage(error, GenericErrorCopy2);
        formik.setStatus(errorMsg);
        Sentry.captureException(error);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <StackY dist={24}>
        <FormInput
          label="Email Address"
          name="email"
          value={formik.values.email}
          onChange={(e) => {
            formik.setStatus("");
            formik.handleChange(e);
          }}
          maxLength={64}
          onBlur={formik.handleBlur}
          touched={formik.touched.email}
          error={formik.errors.email}
          disabled={formik.isSubmitting}
        />

        {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}

        <Button
          data-testid="reset-button"
          type="primary"
          size="large"
          htmlType="submit"
          loading={formik.isSubmitting}
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          onClick={track}
        >
          {submitButtonLabel}
        </Button>
      </StackY>
    </form>
  );
};
