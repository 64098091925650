import { Button } from "client/src/components/Button/Button";
import { Card } from "client/src/components/Cards/Card";
import { FlatCard } from "client/src/components/Cards/FlatCard/FlatCard";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Loading } from "client/src/components/Loading/Loading";
import { SlobDrawer } from "client/src/components/SlobDrawer/SlobDrawer";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "client/src/components/Typography/Typography";
import { useClientFromQPS } from "client/src/hooks/client";
import { isEqual, noop } from "lodash";
import { useEffect, useState } from "react";
import { maskPhoneNumber } from "shared/validation/contact";
import type { ResponseError } from "client/src/hooks/query";
import type { SponsorContactImport } from "server/types/QPS";
import type { Client, PolicySoldCaseValueFromQPS } from "shared/types/Client";

type DrawerProps = {
  client: Client;
  onSave: () => void;
};

type ImportProps = {
  client: Client;
  onError: (error: ResponseError) => void;
};

type MultiProps = {
  multiImportValues: {
    [key: string]: {
      description?: string | null;
      importValues: PolicySoldCaseValueFromQPS<string | null>[] | undefined;
      display?: (value: string | null | Error) => string;
    };
  };
};

type PolicyMultiProps = {
  policyMulti: PolicyValues[];
};

type PolicyValues = {
  policyKey: string;
  multiValues: Record<string, MultiValueProps>;
};

type MultiValueProps = {
  description?: string | null;
  value: string | null | Error;
  display?: (value: string | null | Error) => string;
};

const hasValidMultiValues = (
  multiImportValues: Record<string, PolicySoldCaseValueFromQPS<string | null>[] | undefined>,
) => {
  if (!multiImportValues) {
    return false;
  }

  const policies = Object.values(multiImportValues).reduce((all, curr) => {
    (curr ?? []).forEach((multivalue) => all.add(multivalue.policyNumber));
    return all;
  }, new Set<number>());

  for (let i = 0; i < Object.values(multiImportValues).length; i++) {
    const importValues = Object.values(multiImportValues)[i];

    for (let j = policies.values(), policy = null; (policy = j.next().value); ) {
      const distinctPolicyImportValues = (importValues ?? []).reduce((allDistinct, item) => {
        if (item.policyNumber === policy) {
          allDistinct.add(item);
        }
        return allDistinct;
      }, new Set<PolicySoldCaseValueFromQPS<string | null>>());

      if (distinctPolicyImportValues.size > 1) {
        // entire soldcase value is invalid if there is a mix of different data for the same policy
        return false;
      }
    }
  }

  return true;
};

const Multi = ({ multiImportValues }: MultiProps) => {
  const policies = Object.values(multiImportValues).reduce((all, curr) => {
    (curr.importValues ?? []).forEach((multivalue) => all.add(multivalue.policyNumber));
    return all;
  }, new Set<number>());

  if (!multiImportValues || policies.size === 0) {
    return <p>no values for any policy.</p>;
  }

  if (policies.size === 1) {
    return (
      <StackY dist={16}>
        {Object.entries(multiImportValues).map(([key, multiValues]) => {
          const policyImportValues = (multiValues.importValues ?? []).filter((item) =>
            policies.has(item.policyNumber),
          );

          const { description, display } = multiValues;

          if (policyImportValues.length === 1) {
            const { value = null } = policyImportValues[0] || {};

            return (
              <MultiValue key={key} description={description} value={value} display={display} />
            );
          }
          if (policyImportValues.length > 1) {
            return <p key={key}>Error: multiple values within the same policy.</p>;
          }
          return null;
        })}
      </StackY>
    );
  }

  const policyMulti: PolicyValues[] = [...policies].map((policyNumber) => {
    const policyMultiValues = Object.entries(multiImportValues).reduce(
      (multiValues, [key, multiValue]) => {
        const policyImportValues = (multiValue.importValues ?? []).filter(
          (item) => item.policyNumber === policyNumber,
        );
        const { description = null, display = () => "" } = multiValue;

        if (policyImportValues.length > 1) {
          // Error: multiple values within the same policy
          const value = new Error("Multiple values within the same policy");
          multiValues[key] = { description, display, value };
        }

        if (policyImportValues.length === 1) {
          const { value = null } = policyImportValues[0] || {};
          multiValues[key] = { description, display, value };
        }
        return multiValues;
      },
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- needed to validate the reduce type
      {} as Record<string, MultiValueProps>,
    );

    return {
      policyKey: `Policy #${String(policyNumber)}`,
      multiValues: policyMultiValues,
    };
  });

  const policyMultiGrouped: PolicyValues[] = policyMulti.reduce((grouped: PolicyValues[], item) => {
    const matchIndex = grouped.findIndex((policy) => {
      return isEqual(policy.multiValues, item.multiValues);
    });

    if (matchIndex !== -1) {
      const match = grouped[matchIndex];
      if (match && !match.policyKey.includes(item.policyKey)) {
        match.policyKey = `${match.policyKey}, ${item.policyKey}`;
      }
    } else {
      grouped.push(item);
    }

    return grouped;
  }, []);

  return <PolicyMulti policyMulti={policyMultiGrouped} />;
};

const MultiValue = ({ value, display, description }: MultiValueProps) => {
  return (
    <>
      {description && <Body5 as="div">{description}</Body5>}
      <Body3 as="div">{display ? display(value) : String(value)}</Body3>
    </>
  );
};

const PolicyMulti = ({ policyMulti }: PolicyMultiProps) => {
  // @todo determine the type & value of the radio group

  let errors: Error[] = [];

  Object.values(policyMulti).find((values) => {
    const { multiValues } = values;
    errors = Object.values(multiValues).reduce((all, multiValue) => {
      if (multiValue.value instanceof Error) {
        all.push(multiValue.value);
      }
      return all;
    }, errors);
    return errors.length > 0;
  }, []);

  if (errors.length > 0) {
    return <p>Error Multiple Values in Policy</p>;
  }

  return (
    <RadioGroup
      name="policyPicker"
      fullWidth={true}
      value={undefined /* @todo save in state */}
      label=""
      direction="vertical"
      onChange={noop}
      disabled={false}
      error={false}
      options={policyMulti.map((multi) => {
        const { policyKey, multiValues } = multi;

        return {
          label: <Body2>{policyKey}</Body2>,
          content: (
            <StackY dist={16}>
              {Object.entries(multiValues).map(([key, multiValue]) => {
                const { description, display, value } = multiValue;
                return (
                  <MultiValue key={key} description={description} value={value} display={display} />
                );
              })}
            </StackY>
          ),
          value: "todo",
        };
      })}
    />
  );
};

const ContactCard = ({ contact, address }: SponsorContactImport) => {
  const { addressLine1, addressLine2, addressLine3, city, stateCode, zipCode } = address ?? {};

  const addressFormatted = `${addressLine1 ?? "-"} ${addressLine2 ? addressLine2 + " " : ""}${
    addressLine3 ? addressLine3 + " " : ""
  }${city ?? "-"}, ${stateCode ?? "-"} ${zipCode ?? "-"}`;

  // Essentially a version of client/src/domain/Contact/ContactCard wrapped in a Checkbox
  return (
    <FlatCard>
      <Checkbox
        label={
          <Body2>
            {contact.firstName} {contact.lastName}
          </Body2>
        }
        content={
          <div className="pt-16 pb-16">
            <StackY dist={16}>
              <Row gutter={[32, 18]}>
                <Col>
                  <Body5 as="div">Job Title</Body5>
                  {contact.title && <Body3>{contact.title}</Body3>}
                  {!contact.title && <Body3>-</Body3>}
                </Col>
                <Col>
                  <Body5 as="div">Email</Body5>
                  {contact.email && <Body3>{contact.email}</Body3>}
                  {!contact.email && <Body3>-</Body3>}
                </Col>
              </Row>
              <Row gutter={[32, 18]}>
                <Col>
                  <Body5 as="div">Phone</Body5>
                  {contact.phoneNumber && <Body3>{maskPhoneNumber(contact.phoneNumber)}</Body3>}
                  {!contact.phoneNumber && <Body3>-</Body3>}
                </Col>
                <Col>
                  <Body5 as="div">Location</Body5>
                  <Body3 as="div">{addressFormatted}</Body3>
                </Col>
              </Row>
            </StackY>
          </div>
        }
      />
    </FlatCard>
  );
};

const ImportClientInformationForm = ({ client, onError }: ImportProps) => {
  const { data, isLoading, isError, error } = useClientFromQPS(client.id);

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- disable
  useEffect(() => {
    if (isError) {
      onError(error);
    }
  }, [error, isError, onError]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Body3 as="p">Failed to load client information from QPS.</Body3>;
  }

  return (
    <>
      <StackY dist={24} wrap={false}>
        <div className="pt-16 pb-16">
          <Body2 as="div">Which sections do you want to import?</Body2>
          <Body3 as="div">
            Importing will overwrite any information currently in that section of the Digital Smart
            Form.
          </Body3>
        </div>
        <Button type="text-only">Select all</Button>
        {data?.benAdminPlatform && (
          <Card>
            <Checkbox
              label={<Body2>Benefits administration &amp; data feeds</Body2>}
              disabled={data?.benAdminPlatform === "" || data?.benAdminPlatform === null}
              content={
                <div className="pt-16 pb-16">
                  <Body5 as="div">Do you use a benefits administration platform?</Body5>
                  <Body3 as="div">{data?.benAdminPlatform || "-"}</Body3>
                </div>
              }
            />
          </Card>
        )}
        {data?.taxId && (
          <Card>
            <Checkbox
              label={<Body2>Company Tax ID Number</Body2>}
              content={
                <div className="pt-16 pb-16">
                  <Body3 as="div">{data?.taxId || "-"}</Body3>
                </div>
              }
            />
          </Card>
        )}
        {data?.policyNumbers && data?.policyNumbers.length > 0 && (
          <Card>
            <Checkbox
              label={<Body2>Current Sun Life coverage information</Body2>}
              content={
                <div className="pt-16 pb-16">
                  <Body3 as="div">{data?.policyNumbers.join(", ") || "-"}</Body3>
                </div>
              }
            />
          </Card>
        )}
        {data?.webAdmins && data?.webAdmins.length > 0 && (
          <Card>
            <RadioGroup<SponsorContactImport>
              name="webAdmins"
              fullWidth={true}
              value={undefined /* @todo save in state */}
              label="Plan Administrators"
              direction="vertical"
              onChange={noop}
              disabled={false}
              error={false}
              options={
                data?.webAdmins.map((webAdmin, i) => ({
                  label: "Set as Primary Plan Admin",
                  content: (
                    <ContactCard key={i} contact={webAdmin.contact} address={webAdmin.address} />
                  ),
                  value: webAdmin,
                })) || []
              }
            />
          </Card>
        )}
        {data?.waitingPeriodsServiceToCount && data?.waitingPeriodsServiceToCount.length > 0 && (
          <Card>
            <Checkbox
              label={<Body2>Additional waiting period rules</Body2>}
              disabled={
                !hasValidMultiValues({
                  waitingPeriodsServiceToCount: data?.waitingPeriodsServiceToCount,
                })
              }
              content={
                <div className="pt-16 pb-16">
                  <Multi
                    multiImportValues={{
                      waitingPeriodsServiceToCount: {
                        description:
                          "Should the employee's non-benefit eligible time served count towards their waiting period?",
                        importValues: data?.waitingPeriodsServiceToCount,
                        display: (value: unknown) => {
                          if (String(value).toLowerCase() === "true") {
                            return "Yes";
                          }
                          if (String(value).toLowerCase() === "false") {
                            return "No";
                          }
                          return "Unknown Value";
                        },
                      },
                    }}
                  />
                </div>
              }
            />
          </Card>
        )}
        {data?.rehireProvision && data?.rehireProvision.length > 0 && (
          <Card>
            <Checkbox
              label={<Body2>Rehire provision</Body2>}
              disabled={
                !hasValidMultiValues({
                  rehireProvision: data?.rehireProvision,
                })
              }
              content={
                <div className="pt-16 pb-16">
                  <Multi
                    multiImportValues={{
                      rehireProvision: {
                        importValues: data?.rehireProvision,
                        display: (value: unknown) => {
                          // value null does mean Yes
                          // (there is more data for reqtOptValueId we aren't pulling in yet)
                          if (value === null) {
                            return "Yes";
                          }
                          if (String(value).toLowerCase() === "false") {
                            return "None";
                          }
                          return "Unknown Value";
                        },
                      },
                    }}
                  />
                </div>
              }
            />
          </Card>
        )}
        {((data?.unionEmployeesCovered && data?.unionEmployeesCovered.length > 0) ||
          (data?.domesticPartnersCovered && data?.domesticPartnersCovered.length > 0)) && (
          <Card>
            <Checkbox
              label={<Body2>Union members and domestic partners</Body2>}
              disabled={
                !hasValidMultiValues({
                  unionEmployeesCovered: data?.unionEmployeesCovered,
                  domesticPartnersCovered: data?.domesticPartnersCovered,
                })
              }
              content={
                <div className="pt-16 pb-16">
                  <Multi
                    multiImportValues={{
                      unionEmployeesCovered: {
                        description: "Union Members",
                        importValues: data?.unionEmployeesCovered,
                        display: (value: unknown) => {
                          if (String(value).toLowerCase() === "true") {
                            return "Covered";
                          }
                          if (String(value).toLowerCase() === "false") {
                            return "Not Covered";
                          }
                          return "Unknown Value";
                        },
                      },
                      domesticPartnersCovered: {
                        description: "Domestic Partners",
                        importValues: data?.domesticPartnersCovered,
                        display: (value: unknown) => {
                          if (String(value).toLowerCase() === "true") {
                            return "Covered";
                          }
                          if (String(value).toLowerCase() === "false") {
                            return "Not Covered";
                          }
                          return "Unknown Value";
                        },
                      },
                    }}
                  />
                </div>
              }
            />
          </Card>
        )}
        <Card>
          <Checkbox
            label={<Body2>ERISA</Body2>}
            disabled={!data?.erisa}
            content={
              <>
                {!data ||
                  (!data?.erisa && (
                    <div className="pt-16 pb-16">
                      <Body3>This client has no ERISA info available to import.</Body3>
                    </div>
                  ))}
                {data?.erisa && (
                  <div className="pt-16 pb-16">
                    <StackY dist={16}>
                      <div>
                        <Body5 as="div">Is your company's plan subject to ERISA?</Body5>
                        <Body3>Yes</Body3>
                      </div>
                      <div>
                        <Body5 as="div">
                          Would you like us to provide custom ERISA plan details in your
                          certificate?
                        </Body5>
                        <Body3>Yes</Body3>
                      </div>
                      <div>
                        <Body5 as="div">ERISA plan administrator</Body5>
                        <Body3>Policyholder</Body3>
                      </div>
                      <div>
                        <Body5 as="div">ERISA agent for legal process</Body5>
                        <Body3>Policyholder</Body3>
                      </div>
                      <div>
                        <Body5 as="div">Company tax ID number</Body5>
                        <Body3>{data?.erisa?.einNumber || data?.taxId || "None"}</Body3>
                      </div>
                      <div>
                        <Body5 as="div">Plan year end date</Body5>
                        <Body3>{data?.erisa?.planYearEndNumber || "None"}</Body3>
                      </div>
                    </StackY>
                  </div>
                )}
              </>
            }
          />
        </Card>
      </StackY>
    </>
  );
};

export const CardImportClientInformation = ({ client, onSave }: DrawerProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isImportValid, setIsImportValid] = useState<boolean>(true);

  const onQPSError = (error: ResponseError): void => {
    if (error) {
      console.error(error);
      setIsImportValid(false);
    }
  };

  return (
    <>
      <Body3 as="p">Save time by importing some of the client information directly from QPS.</Body3>
      <Body3 as="p">
        You can choose which sections you want to import, but importing will overwrite any
        information currently in that section of the Digital Smart Form.
      </Body3>
      <SlobDrawer
        visible={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        title="Import client information"
        width={750}
        footerInner={
          <Row justify="end">
            <Col className="mt-12 stack-x-12">
              <Button type="text" size="middle" onClick={() => setIsDrawerOpen(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={!isImportValid}
                htmlType="submit"
                size="middle"
                onClick={() => {
                  setIsDrawerOpen(false);
                  onSave();
                }}
              >
                Import
              </Button>
            </Col>
          </Row>
        }
      >
        <ImportClientInformationForm client={client} onError={onQPSError} />
      </SlobDrawer>
      <Row justify="end">
        <Col className="mt-12 stack-x-12">
          <Button
            type="secondary"
            htmlType="submit"
            size="middle"
            onClick={() => setIsDrawerOpen(true)}
          >
            Import client information
          </Button>
        </Col>
      </Row>
    </>
  );
};
