import { isYourBenefitsExplorer } from "client/src/utils/benefits-explorer";
import { RouteData } from "shared/config/routeData";

import { ExplorerNavBar } from "../../components/NavBar/ExplorerNavBar";

type ExplorerNavPageProps = {
  pageId: string;
};

export const ExplorerNavPage = ({ pageId }: ExplorerNavPageProps) => {
  return (
    <ExplorerNavBar
      personalBenefitsPlanLink={
        isYourBenefitsExplorer
          ? RouteData.personalBenefitsPlan.getPath(pageId)
          : RouteData.personalBenefitsPlanInternal.getPath(pageId)
      }
      pageId={pageId}
    />
  );
};
