import { faChevronLeft, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor } from "client/src/components/Anchor/Anchor";
import { BulbMessage } from "client/src/components/BulbMessage/BulbMessage";
import { Button } from "client/src/components/Button/Button";
import { ConfirmDialog } from "client/src/components/ConfirmDialog/ConfirmDialog";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { FormInput } from "client/src/components/Form/Input";
import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { SlobRadio } from "client/src/components/Form/SlobRadio/SlobRadio";
import { SlobSelect } from "client/src/components/Form/SlobSelect";
import { TextArea } from "client/src/components/Form/TextArea";
import { Col, Row } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Tooltip } from "client/src/components/Tooltip/Tooltip";
import { Body1, Body2, Body3, Body5 } from "client/src/components/Typography/Typography";
import { EIFPrevAndNextPolicyButtons } from "client/src/domain/EIF/CompanyDetails/EIFPrevAndNextPolicyButtons";
import { EIFBottomNavButtons } from "client/src/domain/EIF/EIFBottomNavButtons";
import { AddSummaryStatementFile } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/AddSummaryStatementFile";
import { BillNoSplitForm } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/BillNoSplitForm";
import { BillsCreationAndEditingCard } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/BillsCreationAndEditingCard";
import { getPoliciesToCopyFrom } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/CopySettingsFromAnotherBillModal";
import { YourBillsCard } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/YourBillsCard";
import { BillStatementsCard } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillStatements/BillStatementsCard";
import { getIsBillEmpty } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/billing";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useBulkUpsertBill, useDeleteBillsForClient } from "client/src/hooks/bill";
import { ResponseError } from "client/src/hooks/query";
import { useGetEIFPreviousAndNextLink } from "client/src/hooks/useGetEIFPreviousAndNextLink";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getFormikErrors, useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useSlobId } from "client/src/hooks/useSlobId";
import { useToggler } from "client/src/hooks/useToggler";
import { type TrackElementClickedFunc } from "client/src/utils/analytics";
import { getIn } from "formik";
import { useRef, useState } from "react";
import { RouteData } from "shared/config/routeData";
import { getLocationStateCode, LocationStateData } from "shared/data/LocationState";
import { type Bill } from "shared/types/Bill";
import {
  alternateBillingStructureTypeLabels,
  billingAdministrationTypeLabels,
  type Client,
} from "shared/types/Client";
import { getEIFSubStepMap } from "shared/types/EIF";
import { LocationStateCodes, type Location } from "shared/types/Location";
import {
  benefitTypeToCoverage,
  coverageToBenefitType,
  getIsDBLorPFL,
  getIsHIorNJ,
} from "shared/types/SlfCoverages";
import { getAdvanceAndArrearsCoveragesBreakdown } from "shared/utils/EIF/getAdvanceAndArrearsCoveragesBreakdown";
import { getBillingPreferencesCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getBenefitTypesGroupings } from "shared/utils/benefitTypesGroupings";
import { getBillingPreferencesInitialFormValues, getStatementsForPolicy } from "shared/utils/bill";
import { getIsMultiPolicyMode } from "shared/utils/client";
import { extractFullName, listFormat } from "shared/utils/format";
import { getNextURLToNavigateToAfterEIFStepPolicyUpdate } from "shared/utils/policy";
import { assertIsDefined, rejectNullableValues } from "shared/utils/utils";
import { maskPhoneNumber } from "shared/validation/contact";
import { billingPreferencesSchema } from "shared/validation/policy";
import * as styles from "./EIFBillingPreferences.module.less";
import type {
  BillSplitType,
  BillTiming,
  BillingAdministrationType,
  BillingStructureType,
  BillingSummaryStatementType,
  WhoSubmitsClaims,
} from "@prisma/client";
import type { BillNoSplitFormRef } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/BillNoSplitForm";
import type { UpdatePolicyFunc } from "client/src/hooks/policy";
import type { ChangeEventHandler } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { BillPreview } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Policy } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Document } from "shared/types/Document";
import type { EIFPlanAdministratorsAndBillingSubStepMap, EIFStepId } from "shared/types/EIF";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { BenefitTypeEIFGroup } from "shared/utils/benefitTypesGroupings";
import type { BillingPreferencesFormValues } from "shared/validation/bill";

export const DataTestId = {
  BillingPreferences: "billing-preferences",
};

type Props = {
  client: Client;
  policy: Policy;
  bills: Bill[];
  locations: Location[];
  contacts: Contact[];
  billingSummaryStatementTemplates: Document[];
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  updatePolicy: UpdatePolicyFunc;
  trackElementClicked: TrackElementClickedFunc;
};

export function EIFBillingPreferences(props: Props) {
  const {
    client,
    policy,
    bills,
    locations,
    contacts,
    billingSummaryStatementTemplates,
    featureToggles,
    authUser,
    changeSnapshot,
    updatePolicy,
    trackElementClicked,
  } = props;

  const billsInThisPolicy = bills.filter((b) => b.policyId === policy.id);

  const billingSummaryStatementTemplatesInThisPolicy = getStatementsForPolicy(
    billingSummaryStatementTemplates,
    policy,
  );

  const eifStepId: EIFStepId = "plan-administrators-&-billing";
  const eifSubStepId = "billing-preferences" satisfies EIFPlanAdministratorsAndBillingSubStepMap;

  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();
  const navigate = useNavigateIfMounted();

  const status = getBillingPreferencesCompletionStatus({
    policy,
    bills: billsInThisPolicy,
    billingSummaryStatementTemplates: billingSummaryStatementTemplatesInThisPolicy,
  });
  const isSubStepStarted = status !== "Not Started";

  const {
    mutateAsync: deleteBillsForClientOnServer,
    isPending: isDeletingBillsForClient,
    error: deleteBillsForClientError,
  } = useDeleteBillsForClient();

  const { mutateAsync: bulkUpsertBill } = useBulkUpsertBill();

  const [copyModalIsOpen, toggleCopyModalIsOpen] = useToggler();

  const policiesToCopyFrom = getPoliciesToCopyFrom(client, policy, bills);

  const policyId = policy.id;

  const {
    formValues: initialValues,
    advanceCoveragesInClient,
    arrearsCoveragesInClient,
    advanceOrArrearsCoverages,
  } = getBillingPreferencesInitialFormValues(
    policy.slfCoverages,
    policyId,
    policy,
    billsInThisPolicy,
  );

  const hasMixedInherentTiming =
    advanceCoveragesInClient.length > 0 && arrearsCoveragesInClient.length > 0;
  const hasOnlyOptionalTiming =
    advanceCoveragesInClient.length === 0 &&
    arrearsCoveragesInClient.length === 0 &&
    advanceOrArrearsCoverages.length > 0;
  const shouldShowAdvanceOrArrearsSelection =
    advanceOrArrearsCoverages.length > 0 && (hasMixedInherentTiming || hasOnlyOptionalTiming);

  const advanceOrArrearsCoveragesGroup = getBenefitTypesGroupings(advanceOrArrearsCoverages);
  const advanceOrArrearsCoveragesGroupEntries = Array.from(
    advanceOrArrearsCoveragesGroup.entries(),
  );

  const formik = useSlobFormik({
    validationSchema: billingPreferencesSchema,
    validationContext: { policy, prefill: true },
    initialValues,
    onSubmit: async (values) => {
      const { firstName, lastName } = extractFullName(values.tpaContact?.fullName ?? "");
      const tpaContact = values.tpaContact
        ? {
            id: values.tpaContact.id,
            policyId: policy.id,
            type: "TPA" as const,

            tpaFirmName: values.tpaContact.tpaFirmName,
            firstName,
            lastName,
            email: values.tpaContact.email,
            phoneNumber: values.tpaContact.phoneNumber,
            title: values.tpaContact.title,
            address1: values.tpaContact.address1,
            address2: values.tpaContact.address2,
            city: values.tpaContact.city,
            state: values.tpaContact.state,
            zipCode: values.tpaContact.zipCode,
          }
        : null;

      await updatePolicy({
        params: { clientId: client.id, policyId: policy.id },
        query: { prefill: true },
        data: {
          tpaContact,
          billingAdministrationType: values.billingAdministrationType,
          billingStructureType: values.billingStructureType,
          billingSummaryStatementType: values.billingSummaryStatementType,
          billPayrollCycles: values.billPayrollCycles,
          billPayrollCyclesOther: values.billPayrollCyclesOther,
          billPayrollCyclesExplanation: values.billPayrollCyclesExplanation,
          billSplitType: values.billSplitType,
          whoSubmitsClaims: values.whoSubmitsClaims,
          advanceOrArrears: values.advanceOrArrears,
        },
      });

      trackElementClicked({
        module: subStepName,
        buttonLabel: "Next",
        moduleState: status,
      });

      // If they only have one bill, and that bill is empty.
      // just discard it and don't save it, that way we don't show
      // a bill card full of missing values in the Review page
      const shouldSaveBills =
        values.bills.length === 0
          ? false
          : values.bills.length === 1
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we are not sparsely populating arrays
            !getIsBillEmpty(values.bills[0]!)
          : true;

      if (shouldSaveBills) {
        await bulkUpsertBill({
          params: { clientId: client.id },
          data: {
            bills: values.bills.map((bill) => {
              return {
                ...bill,
                locationId: bill.locationId || null,
                groupByLocationIds: bill.groupByLocationIds ?? [],
              };
            }),
          },
        });
      }

      const billsToRemove = billsInThisPolicy.filter(
        (bill) => !values.bills.some((billValues) => billValues.id === bill.id),
      );
      if (billsToRemove.length) {
        await deleteBillsForClientOnServer({
          params: { clientId: client.id },
          data: {
            policyId: policy.id,
            billIds: billsToRemove.map((b) => b.id),
          },
        });
      }

      const nextUrl = getNextURLToNavigateToAfterEIFStepPolicyUpdate({
        client,
        policy,
        eifStepId,
        eifSubStepId,
        nextSubStepLink,
        isMultiPolicyMode,
      });
      if (nextUrl) {
        navigate(nextUrl);
      }
    },
  });

  const isMissingBillingSummaryStatementError = getIsMissingBillingSummaryStatementError(
    formik.values.billingSummaryStatementType,
    billingSummaryStatementTemplatesInThisPolicy,
  );

  const handleAdvanceOrArrearsChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
    const groupName = e.target.name as BenefitTypeEIFGroup;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
    const billTiming = e.target.value as BillTiming;
    const benefitTypesInGroup = advanceOrArrearsCoveragesGroup.get(groupName);

    if (benefitTypesInGroup) {
      for (const benefitType of benefitTypesInGroup) {
        const slfCoverage = benefitTypeToCoverage[benefitType];
        // Update advance or arrears object
        await formik.setFieldValue(`advanceOrArrears.${slfCoverage}`, billTiming);
      }

      const nextBills = formik.values.bills.map((bill) => {
        const billHasThisCoverage = bill.slfCoverages?.some((coverage) =>
          benefitTypesInGroup.includes(coverageToBenefitType[coverage]),
        );
        // If this bill has this coverage, remove it
        if (billHasThisCoverage) {
          bill.slfCoverages =
            bill.slfCoverages?.filter(
              (coverage) => !benefitTypesInGroup.includes(coverageToBenefitType[coverage]),
            ) ?? null;
        }
        // Otherwise, add it only if we're not splitting by BENEFIT
        else if (formik.values.billSplitType !== "BENEFIT") {
          bill.slfCoverages = (bill.slfCoverages ?? [])
            .concat(...benefitTypesInGroup.map((bt) => benefitTypeToCoverage[bt]))
            .sort();
        }
        return bill;
      });

      await formik.setFieldValue(`bills`, nextBills);
    }
  };

  const billPreviews = getBillPreviews(policy.slfCoverages, contacts, locations, formik.values);

  const checkboxesErrorId = useSlobId({ prefix: "billPayrollCycleErrorId" });
  const formId = useSlobId({ prefix: "billingPreferencesFormId" });

  const shouldConfirmBillingStructureChanges = billsInThisPolicy.length > 0;

  const [confirmChangesModal, setConfirmChangesModal] = useState<
    { visible: false } | { visible: true; pendingValues: (typeof formik)["values"] }
  >({ visible: false });

  const admins = contacts.filter(
    (contact) => contact.type === "PRIMARY_WEB_ADMIN" || contact.type === "WEB_ADMIN",
  );

  const { hasMixedTiming } = getAdvanceAndArrearsCoveragesBreakdown(
    policy,
    formik.values.advanceOrArrears,
  );

  const billNoSplitFormRef = useRef<BillNoSplitFormRef>(null);

  const prefillErrors =
    isSubStepStarted && !formik.isSubmitting
      ? getFormikErrors(formik.values, billingPreferencesSchema, { policy, prefill: false })
      : {};

  const isMultiPolicyMode = getIsMultiPolicyMode(client);

  const isDBLorPFL = getIsDBLorPFL(policy);
  const isHIorNJ = getIsHIorNJ(policy);

  return (
    <>
      <StackY
        dist={32}
        wrap={false}
        className={isMultiPolicyMode ? "pt-24 pb-56" : undefined}
        data-testid={DataTestId.BillingPreferences}
      >
        <h2>{subStepName}</h2>

        <StackY dist={16} wrap={false}>
          {isMultiPolicyMode ? (
            <Button
              to={RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId)}
              type="text-only"
              size="middle"
            >
              <FontAwesomeIcon icon={faChevronLeft} className="mr-8" />
              Back to all policies
            </Button>
          ) : (
            <p>Tell us how you would like to administer and structure the way you pay your bill.</p>
          )}

          <form id={formId} onSubmit={formik.handleSubmit}>
            <StackY dist={32} wrap={false}>
              <HubCard>
                {isMultiPolicyMode &&
                  (policy.primaryPolicy ? (
                    <h2>Policy #{policy.slfPolicyNumber} (Primary)</h2>
                  ) : (
                    <h2>Policy #{policy.slfPolicyNumber}</h2>
                  ))}

                <StackY dist={32} wrap={false}>
                  <StackY dist={8} wrap={false}>
                    <RadioGroup
                      name="billingAdministrationType"
                      value={formik.values.billingAdministrationType}
                      direction="vertical"
                      label={
                        <>
                          <Body2>How do you want to administer your bill?</Body2>
                          <div>
                            <Anchor
                              newTabIcon
                              target="_blank"
                              href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4402359261971-Understanding-billing-administration-types"
                            >
                              Learn more about billing administration types
                            </Anchor>
                          </div>
                        </>
                      }
                      options={[
                        ...(isDBLorPFL
                          ? []
                          : [
                              {
                                value: "LIST",
                                label: (
                                  <StackX dist={8} wrap={false} className="pt-4">
                                    <Body3>{billingAdministrationTypeLabels["LIST"]}</Body3>
                                    <Tooltip
                                      trigger="hover"
                                      title={
                                        <StackY dist={20}>
                                          <Body5 white>
                                            Receive an online billing statement each month and
                                            completely manage your Sun Life policies online with
                                            helpful features such as managing employees, bill
                                            payment, reporting, and more.
                                          </Body5>
                                        </StackY>
                                      }
                                      placement="bottom"
                                      large
                                    >
                                      <span>
                                        <button
                                          aria-label="info"
                                          className={`btn-reset ${styles.infoIcon}`}
                                        >
                                          <FontAwesomeIcon icon={faInfoCircle} />
                                        </button>
                                      </span>
                                    </Tooltip>
                                    <Body5>Most common</Body5>
                                  </StackX>
                                ),
                                // empty content to keep the spacing consistent
                                content: <div className="ml-32 stack-y-20"></div>,
                                contentSpacing: "tight" as const,
                              },
                            ]),
                        {
                          value: "SELF",
                          label: (
                            <StackX dist={8} wrap={false} className="pt-4">
                              <Body3>{billingAdministrationTypeLabels["SELF"]}</Body3>
                              <Tooltip
                                trigger="hover"
                                title={
                                  <StackY dist={20}>
                                    <Body5 as="div" white>
                                      Manage your employees on your own HR system. Each month you’ll
                                      report any changes to premium on a summary statement submitted
                                      with your payment. You’ll have online access to make payments
                                      and see current rates for your benefit calculations and more.
                                    </Body5>
                                  </StackY>
                                }
                                placement="bottom"
                                large
                              >
                                <span>
                                  <button
                                    aria-label="info"
                                    className={`btn-reset ${styles.infoIcon}`}
                                  >
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                  </button>
                                </span>
                              </Tooltip>
                            </StackX>
                          ),
                          content: (
                            <div className="ml-32 stack-y-20">
                              {formik.values.billingAdministrationType === "SELF" &&
                                !isDBLorPFL && (
                                  <BulbMessage>
                                    Sun Life approval is required for clients with less than 300
                                    lives. A data feed is also required to maintain eligibility for
                                    claims.
                                  </BulbMessage>
                                )}
                            </div>
                          ),
                          contentSpacing: "tight",
                        },
                        {
                          value: "TPA",
                          label: (
                            <StackX dist={8} wrap={false} className="pt-4">
                              <Body3>{billingAdministrationTypeLabels["TPA"]}</Body3>
                              <Tooltip
                                trigger="hover"
                                title={
                                  <StackY dist={20}>
                                    <Body5 as="div" white>
                                      Manage your employees via a third-party administrator (TPA).
                                      Each month the TPA will report any changes to premium on a
                                      summary statement submitted with your payment.
                                    </Body5>
                                  </StackY>
                                }
                                placement="bottom"
                                large
                              >
                                <span>
                                  <button
                                    aria-label="info"
                                    className={`btn-reset ${styles.infoIcon}`}
                                  >
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                  </button>
                                </span>
                              </Tooltip>
                            </StackX>
                          ),
                          content: (
                            <div className="ml-32 stack-y-20">
                              {formik.values.billingAdministrationType === "TPA" && (
                                <>
                                  <BulbMessage>
                                    Sun Life approval is required for clients with less than 300
                                    lives. A data feed is also required to maintain eligibility for
                                    claims.
                                  </BulbMessage>

                                  <Body2 as="div">
                                    Please tell us about your third party administrator.
                                  </Body2>

                                  <Row gutter={[24, 24]}>
                                    <Col span={24} className="stack-y-8">
                                      <FormInput
                                        name="tpaContact.tpaFirmName"
                                        label="TPA firm name"
                                        disabled={formik.isSubmitting}
                                        onChange={formik.handleChange}
                                        touched={
                                          !!formik.touched.tpaContact ||
                                          !!getIn(prefillErrors, "tpaContact.tpaFirmName")
                                        }
                                        error={
                                          getIn(formik.errors, "tpaContact.tpaFirmName") ||
                                          getIn(prefillErrors, "tpaContact.tpaFirmName")
                                        }
                                        value={formik.values.tpaContact?.tpaFirmName ?? ""}
                                        maxLength={191}
                                      />
                                      <EditedFieldMsg
                                        changeDetailInfoList={[
                                          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]
                                            ?.tpaFirmName,
                                        ]}
                                        client={client}
                                        authUser={authUser}
                                        hasPendingEdit={getHasPendingEdit({
                                          field: "tpaContact.tpaFirmName",
                                          client,
                                          formik,
                                        })}
                                      />
                                    </Col>

                                    <Col span={24} className="stack-y-8">
                                      <FormInput
                                        name="tpaContact.fullName"
                                        label="TPA contact name"
                                        disabled={formik.isSubmitting}
                                        onChange={formik.handleChange}
                                        touched={
                                          formik.touched.tpaContact ||
                                          !!getIn(prefillErrors, "tpaContact.fullName")
                                        }
                                        error={
                                          getIn(formik.errors, "tpaContact.fullName") ||
                                          getIn(prefillErrors, "tpaContact.fullName")
                                        }
                                        value={formik.values.tpaContact?.fullName ?? ""}
                                        maxLength={191}
                                      />
                                      <EditedFieldMsg
                                        changeDetailInfoList={[
                                          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]
                                            ?.firstName,
                                          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]
                                            ?.lastName,
                                        ]}
                                        client={client}
                                        authUser={authUser}
                                        hasPendingEdit={getHasPendingEdit({
                                          field: "tpaContact.fullName",
                                          client,
                                          formik,
                                        })}
                                      />
                                    </Col>

                                    <Col span={12} className="stack-y-8">
                                      <FormInput
                                        name="tpaContact.email"
                                        label="Email address"
                                        disabled={formik.isSubmitting}
                                        onChange={formik.handleChange}
                                        touched={
                                          !!formik.touched.tpaContact ||
                                          !!getIn(prefillErrors, "tpaContact.email")
                                        }
                                        error={
                                          getIn(formik.errors, "tpaContact.email") ||
                                          getIn(prefillErrors, "tpaContact.email")
                                        }
                                        value={formik.values.tpaContact?.email ?? ""}
                                        maxLength={191}
                                      />
                                      <EditedFieldMsg
                                        changeDetailInfoList={[
                                          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]
                                            ?.email,
                                        ]}
                                        client={client}
                                        authUser={authUser}
                                        hasPendingEdit={getHasPendingEdit({
                                          field: "tpaContact.email",
                                          client,
                                          formik,
                                        })}
                                      />
                                    </Col>

                                    <Col span={12} className="stack-y-8">
                                      <FormInput
                                        name="tpaContact.phoneNumber"
                                        label="Phone"
                                        disabled={formik.isSubmitting}
                                        onChange={async (event) => {
                                          const { value } = event.currentTarget;
                                          const maskedPhoneNumber = maskPhoneNumber(value);
                                          await formik.setFieldValue(
                                            "tpaContact.phoneNumber",
                                            maskedPhoneNumber || null,
                                          );
                                        }}
                                        touched={
                                          !!formik.touched.tpaContact ||
                                          !!getIn(prefillErrors, "tpaContact.phoneNumber")
                                        }
                                        error={
                                          getIn(formik.errors, "tpaContact.phoneNumber") ||
                                          getIn(prefillErrors, "tpaContact.phoneNumber")
                                        }
                                        value={formik.values.tpaContact?.phoneNumber ?? ""}
                                        maxLength={191}
                                      />
                                      <EditedFieldMsg
                                        changeDetailInfoList={[
                                          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]
                                            ?.phoneNumber,
                                        ]}
                                        client={client}
                                        authUser={authUser}
                                        hasPendingEdit={getHasPendingEdit({
                                          field: "tpaContact.phoneNumber",
                                          client,
                                          formik,
                                        })}
                                      />
                                    </Col>

                                    <Col span={12} className="stack-y-8">
                                      <FormInput
                                        name="tpaContact.title"
                                        label="Job title"
                                        disabled={formik.isSubmitting}
                                        onChange={formik.handleChange}
                                        touched={
                                          !!formik.touched.tpaContact ||
                                          !!getIn(prefillErrors, "tpaContact.title")
                                        }
                                        error={
                                          getIn(formik.errors, "tpaContact.title") ||
                                          getIn(prefillErrors, "tpaContact.title")
                                        }
                                        value={formik.values.tpaContact?.title ?? ""}
                                        maxLength={191}
                                      />
                                      <EditedFieldMsg
                                        changeDetailInfoList={[
                                          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]
                                            ?.title,
                                        ]}
                                        client={client}
                                        authUser={authUser}
                                        hasPendingEdit={getHasPendingEdit({
                                          field: "tpaContact.title",
                                          client,
                                          formik,
                                        })}
                                      />
                                    </Col>
                                    <Col span={12}></Col>

                                    <Col span={24} className="stack-y-8">
                                      <FormInput
                                        name="tpaContact.address1"
                                        label="Address 1"
                                        disabled={formik.isSubmitting}
                                        onChange={formik.handleChange}
                                        touched={
                                          !!formik.touched.tpaContact ||
                                          !!getIn(prefillErrors, "tpaContact.address1")
                                        }
                                        error={
                                          getIn(formik.errors, "tpaContact.address1") ||
                                          getIn(prefillErrors, "tpaContact.address1")
                                        }
                                        value={formik.values.tpaContact?.address1 ?? ""}
                                        maxLength={191}
                                      />
                                      <EditedFieldMsg
                                        changeDetailInfoList={[
                                          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]
                                            ?.address1,
                                        ]}
                                        client={client}
                                        authUser={authUser}
                                        hasPendingEdit={getHasPendingEdit({
                                          field: "tpaContact.address1",
                                          client,
                                          formik,
                                        })}
                                      />
                                    </Col>
                                    <Col span={24} className="stack-y-8">
                                      <FormInput
                                        name="tpaContact.address2"
                                        label="Address 2 (optional)"
                                        disabled={formik.isSubmitting}
                                        onChange={formik.handleChange}
                                        touched={
                                          !!formik.touched.tpaContact ||
                                          !!getIn(prefillErrors, "tpaContact.address2")
                                        }
                                        error={
                                          getIn(formik.errors, "tpaContact.address2") ||
                                          getIn(prefillErrors, "tpaContact.address2")
                                        }
                                        value={formik.values.tpaContact?.address2 ?? ""}
                                        maxLength={191}
                                      />
                                      <EditedFieldMsg
                                        changeDetailInfoList={[
                                          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]
                                            ?.address2,
                                        ]}
                                        client={client}
                                        authUser={authUser}
                                        hasPendingEdit={getHasPendingEdit({
                                          field: "tpaContact.address2",
                                          client,
                                          formik,
                                        })}
                                      />
                                    </Col>

                                    <Col span={11} className="stack-y-8">
                                      <FormInput
                                        name="tpaContact.city"
                                        label="City"
                                        disabled={formik.isSubmitting}
                                        onChange={formik.handleChange}
                                        touched={
                                          !!formik.touched.tpaContact ||
                                          !!getIn(prefillErrors, "tpaContact.city")
                                        }
                                        error={
                                          getIn(formik.errors, "tpaContact.city") ||
                                          getIn(prefillErrors, "tpaContact.city")
                                        }
                                        value={formik.values.tpaContact?.city ?? ""}
                                        maxLength={191}
                                      />
                                      <EditedFieldMsg
                                        changeDetailInfoList={[
                                          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]?.city,
                                        ]}
                                        client={client}
                                        authUser={authUser}
                                        hasPendingEdit={getHasPendingEdit({
                                          field: "tpaContact.city",
                                          client,
                                          formik,
                                        })}
                                      />
                                    </Col>
                                    <Col span={8} className="stack-y-8">
                                      <SlobSelect
                                        name="tpaContact.state"
                                        disabled={formik.isSubmitting}
                                        options={LocationStateCodes.map((stateCode) => ({
                                          label: LocationStateData[stateCode].displayName,
                                          value: stateCode,
                                        }))}
                                        placeholder="State"
                                        onChange={async (event) => {
                                          await formik.setFieldValue(
                                            "tpaContact.state",
                                            event.value,
                                          );
                                        }}
                                        touched={
                                          !!formik.touched.tpaContact ||
                                          !!getIn(prefillErrors, "tpaContact.state")
                                        }
                                        error={
                                          getIn(formik.errors, "tpaContact.state") ||
                                          getIn(prefillErrors, "tpaContact.state")
                                        }
                                        value={formik.values.tpaContact?.state ?? null}
                                        onSearch={async (val) => {
                                          // TB-6250: support browser autofill for state
                                          const stateCode = getLocationStateCode(val);
                                          if (stateCode) {
                                            await formik.setFieldValue(
                                              "tpaContact.state",
                                              stateCode,
                                            );
                                          }
                                        }}
                                      />
                                      <EditedFieldMsg
                                        changeDetailInfoList={[
                                          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]
                                            ?.state,
                                        ]}
                                        client={client}
                                        authUser={authUser}
                                        hasPendingEdit={getHasPendingEdit({
                                          field: "tpaContact.state",
                                          client,
                                          formik,
                                        })}
                                      />
                                    </Col>
                                    <Col span={5} className="stack-y-8">
                                      <FormInput
                                        name="tpaContact.zipCode"
                                        label="Zip"
                                        disabled={formik.isSubmitting}
                                        onChange={formik.handleChange}
                                        touched={
                                          !!formik.touched.tpaContact ||
                                          !!getIn(prefillErrors, "tpaContact.zipCode")
                                        }
                                        error={
                                          getIn(formik.errors, "tpaContact.zipCode") ||
                                          getIn(prefillErrors, "tpaContact.zipCode")
                                        }
                                        value={formik.values.tpaContact?.zipCode ?? ""}
                                        maxLength={191}
                                      />
                                      <EditedFieldMsg
                                        changeDetailInfoList={[
                                          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]
                                            ?.zipCode,
                                        ]}
                                        client={client}
                                        authUser={authUser}
                                        hasPendingEdit={getHasPendingEdit({
                                          field: "tpaContact.zipCode",
                                          client,
                                          formik,
                                        })}
                                      />
                                    </Col>
                                  </Row>

                                  <StackY dist={8} wrap={false}>
                                    <RadioGroup<WhoSubmitsClaims>
                                      name="whoSubmitsClaims"
                                      label="Who will be submitting claims to Sun Life?"
                                      direction="vertical"
                                      disabled={formik.isSubmitting}
                                      touched={
                                        formik.touched.whoSubmitsClaims ||
                                        !!prefillErrors.whoSubmitsClaims
                                      }
                                      error={
                                        formik.errors.whoSubmitsClaims ||
                                        prefillErrors.whoSubmitsClaims
                                      }
                                      value={formik.values.whoSubmitsClaims}
                                      onChange={formik.handleChange}
                                      options={[
                                        {
                                          value: "PRIMARY_PLAN_ADMINISTRATOR",
                                          label: (
                                            <StackY dist={4}>
                                              <Body3>The primary plan administrator</Body3>
                                              <Body5>
                                                This is the plan administrator at your company
                                              </Body5>
                                            </StackY>
                                          ),
                                        },
                                        {
                                          value: "THIRD_PARTY_ADMINISTRATOR",
                                          label: (
                                            <StackY dist={4}>
                                              <Body3>The third party administrator</Body3>
                                              <Body5>
                                                This is the contact at the TPA firm listed above
                                              </Body5>
                                            </StackY>
                                          ),
                                        },
                                      ]}
                                    />
                                    <EditedFieldMsg
                                      changeDetailInfoList={[
                                        changeSnapshot.Policy[policy.id]?.whoSubmitsClaims,
                                      ]}
                                      client={client}
                                      authUser={authUser}
                                      hasPendingEdit={getHasPendingEdit({
                                        field: "whoSubmitsClaims",
                                        client,
                                        formik,
                                      })}
                                    />
                                  </StackY>
                                </>
                              )}
                            </div>
                          ),
                          contentSpacing: "tight",
                        },
                      ]}
                      onChange={async (e) => {
                        const billingAdministrationType: BillingAdministrationType = e.target.value;
                        const billingStructureType =
                          billingAdministrationType === "LIST" ? ("MULTIPLE" as const) : null;
                        const newVals = {
                          ...formik.values,
                          billingAdministrationType,
                          billingStructureType,
                          billingSummaryStatementType: null,
                          billSplitType: null,
                          bills: [],
                        };
                        if (shouldConfirmBillingStructureChanges) {
                          setConfirmChangesModal({ visible: true, pendingValues: newVals });
                        } else {
                          await formik.setValues(newVals);
                        }
                      }}
                      disabled={formik.isSubmitting}
                      touched={
                        Boolean(formik.touched.billingAdministrationType) ||
                        !!prefillErrors.billingAdministrationType
                      }
                      error={
                        formik.errors.billingAdministrationType ||
                        prefillErrors.billingAdministrationType
                      }
                    />
                    {isDBLorPFL && (
                      <BulbMessage>
                        New York Disability Benefits does not allow for a list bill administration
                        option.
                      </BulbMessage>
                    )}
                    <EditedFieldMsg
                      changeDetailInfoList={[
                        changeSnapshot.Policy[policy.id]?.billingAdministrationType,
                      ]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "billingAdministrationType",
                        client,
                        formik,
                      })}
                    />
                  </StackY>

                  {(formik.values.billingAdministrationType === "SELF" ||
                    formik.values.billingAdministrationType === "TPA") && (
                    <>
                      <StackY dist={8} wrap={false}>
                        <RadioGroup<BillingStructureType>
                          name="billingStructureType"
                          value={formik.values.billingStructureType}
                          direction="vertical"
                          label={
                            <StackX dist={8} wrap={false}>
                              <Body2>How do you plan to pay your bill?</Body2>
                              <Tooltip
                                trigger="hover"
                                title={
                                  <StackY dist={20}>
                                    <Body5 white>
                                      {formik.values.billingAdministrationType === "SELF"
                                        ? `Self-bill administered clients will not receive a monthly billing statement from Sun Life. You will need to complete a summary statement to calculate and report the total number of employees, volume, and premium for the current month. This statement should be submitted with your payment.`
                                        : `TPA administered clients will not receive a monthly billing statement from Sun Life. A summary statement will need to be completed to calculate and report the total number of employees, volume, and premium for the current month. This statement should be submitted with your payment.`}

                                      {formik.values.billingAdministrationType === "SELF" &&
                                        isDBLorPFL && (
                                          <>
                                            <br />

                                            <Anchor
                                              target="_blank"
                                              href="https://p1.aprimocdn.net/sunlife/eddb6de1-afe7-4013-b639-b0bb012d5a6c/gdifl-7187month-ny-dbl-pfl-self-admin-billing-guide-2024-original-file.pdf"
                                            >
                                              Learn more about how to calculate your bill
                                            </Anchor>
                                          </>
                                        )}
                                    </Body5>
                                  </StackY>
                                }
                                placement="bottom"
                                large
                              >
                                <span>
                                  <button
                                    aria-label="info"
                                    className={`btn-reset ${styles.infoIcon}`}
                                  >
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                  </button>
                                </span>
                              </Tooltip>
                            </StackX>
                          }
                          options={[
                            {
                              value: "SINGLE",
                              label: (
                                <StackX dist={8} wrap={false}>
                                  <Body3>{alternateBillingStructureTypeLabels["SINGLE"]}</Body3>
                                  <Body5>Most common</Body5>
                                </StackX>
                              ),
                            },
                            {
                              value: "MULTIPLE",
                              label: alternateBillingStructureTypeLabels["MULTIPLE"],
                            },
                          ]}
                          onChange={async (e) => {
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
                            const billingStructureType = e.target.value as BillingStructureType;
                            const newVals = {
                              ...formik.values,
                              billingStructureType,
                              billingSummaryStatementType: null,
                              billSplitType: null,
                              bills: [],
                            };

                            if (shouldConfirmBillingStructureChanges) {
                              setConfirmChangesModal({ visible: true, pendingValues: newVals });
                            } else {
                              await formik.setValues(newVals);
                            }
                          }}
                          disabled={formik.isSubmitting}
                          touched={
                            Boolean(formik.touched.billingStructureType) ||
                            !!prefillErrors.billingStructureType
                          }
                          error={
                            formik.errors.billingStructureType || prefillErrors.billingStructureType
                          }
                        />
                        <EditedFieldMsg
                          changeDetailInfoList={[
                            changeSnapshot.Policy[policy.id]?.billingStructureType,
                          ]}
                          client={client}
                          authUser={authUser}
                          hasPendingEdit={getHasPendingEdit({
                            field: "billingStructureType",
                            client,
                            formik,
                          })}
                        />
                      </StackY>

                      <StackY dist={8} wrap={false}>
                        <RadioGroup
                          name="billingSummaryStatementType"
                          value={formik.values.billingSummaryStatementType}
                          direction="vertical"
                          label={
                            <>
                              <StackX dist={8} wrap={false}>
                                <Body2>How do you want to create your summary statement(s)?</Body2>
                                <Tooltip
                                  trigger="hover"
                                  title={
                                    <StackY dist={20}>
                                      <Body5 white>
                                        You can calculate and complete your summary statement within
                                        your Sun Life Connect account. If your benefits
                                        administration or payroll system already generates the
                                        needed information, share it with your Implementation
                                        Consultant to see if it meets our requirements. We accept
                                        the statement in Excel, PDF and Word formats.
                                      </Body5>
                                    </StackY>
                                  }
                                  placement="bottom"
                                  large
                                >
                                  <span>
                                    <button
                                      aria-label="info"
                                      className={`btn-reset ${styles.infoIcon}`}
                                    >
                                      <FontAwesomeIcon icon={faInfoCircle} />
                                    </button>
                                  </span>
                                </Tooltip>
                              </StackX>
                              <div>
                                <Anchor
                                  newTabIcon
                                  target="_blank"
                                  href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4402359261971#h_01GEF9CSJZ7GEV1N4006ZMYTP8"
                                >
                                  See a sample summary statement
                                </Anchor>
                              </div>
                            </>
                          }
                          options={[
                            {
                              value: "SUN_LIFE",
                              label: (
                                <StackX dist={8} wrap={false}>
                                  <Body3>Use Sun Life's summary statement template</Body3>
                                  <Body5>Most common</Body5>
                                </StackX>
                              ),
                            },
                            {
                              value: "CUSTOM",
                              label: "Use my own template",
                              contentSpacing: "tight",
                              content: (
                                <div className="ml-32">
                                  <Body5 as="p">
                                    You’ll need to provide your Implementation Consultant with a
                                    sample of your template for review.
                                  </Body5>

                                  {formik.values.billingSummaryStatementType === "CUSTOM" && (
                                    <AddSummaryStatementFile
                                      clientId={client.id}
                                      policyId={policy.id}
                                      disabled={formik.isSubmitting}
                                    />
                                  )}

                                  <div aria-live="assertive" className="mt-16">
                                    {isMissingBillingSummaryStatementError &&
                                      (isSubStepStarted || formik.submitCount > 0) &&
                                      !formik.isSubmitting && (
                                        <InputErrorMessage error={"Please upload a file"} />
                                      )}
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                          onChange={formik.handleChange}
                          disabled={formik.isSubmitting}
                          touched={
                            Boolean(formik.touched.billingSummaryStatementType) ||
                            !!prefillErrors.billingSummaryStatementType
                          }
                          error={
                            formik.errors.billingSummaryStatementType ||
                            prefillErrors.billingSummaryStatementType
                          }
                        />
                        <EditedFieldMsg
                          changeDetailInfoList={[
                            changeSnapshot.Policy[policy.id]?.billingSummaryStatementType,
                          ]}
                          client={client}
                          authUser={authUser}
                          hasPendingEdit={getHasPendingEdit({
                            field: "billingSummaryStatementType",
                            client,
                            formik,
                          })}
                        />
                      </StackY>
                    </>
                  )}

                  {formik.values.billingAdministrationType === "LIST" && !isHIorNJ && (
                    <>
                      <StackY dist={16} wrap={false}>
                        <div>
                          <Body2>What is your payroll cycle?</Body2>
                          <br />
                          <Body3>
                            You chose List bill as your billing preference. If you have employee
                            paid benefits we will be creating a Voluntary Deduction Report for you.
                            We use the payroll cycle to calculate your Voluntary Deduction Report.
                          </Body3>
                        </div>

                        {(
                          [
                            {
                              value: "WEEKLY",
                              label: "Weekly",
                              sublabel: "Once a week, or 52 pay periods per year",
                            },
                            {
                              value: "BIWEEKLY",
                              label: "Bi-weekly",
                              sublabel: "Every two weeks, or 26 pay periods per year",
                            },
                            {
                              value: "SEMIMONTHLY",
                              label: "Semi-monthly",
                              sublabel: "Twice a month, or 24 pay periods per year",
                            },
                            {
                              value: "MONTHLY",
                              label: "Monthly",
                              sublabel: "Once a month, or 12 pay periods per year",
                            },
                            { value: "OTHER", label: "Other", sublabel: null },
                          ] as const
                        ).map(({ value, label, sublabel }) => (
                          <Checkbox
                            key={value}
                            name="billPayrollCycles"
                            value={value}
                            label={
                              sublabel ? (
                                <>
                                  {label}
                                  <br />
                                  <Body5>{sublabel}</Body5>
                                </>
                              ) : (
                                label
                              )
                            }
                            disabled={formik.isSubmitting}
                            checked={formik.values.billPayrollCycles?.includes(value) ?? false}
                            onChange={formik.handleChange}
                            errorId={
                              (formik.touched.billPayrollCycles &&
                                formik.errors.billPayrollCycles) ||
                              prefillErrors.billPayrollCycles
                                ? checkboxesErrorId
                                : undefined
                            }
                          />
                        ))}

                        <div className="ml-32">
                          <StackY dist={8} wrap={false}>
                            <FormInput
                              name="billPayrollCyclesOther"
                              label="Please explain"
                              maxLength={1000}
                              disabled={
                                formik.isSubmitting ||
                                !formik.values.billPayrollCycles?.includes("OTHER")
                              }
                              touched={
                                !!formik.touched.billPayrollCyclesOther ||
                                !!prefillErrors.billPayrollCyclesOther
                              }
                              value={
                                formik.values.billPayrollCycles?.includes("OTHER")
                                  ? formik.values.billPayrollCyclesOther
                                  : undefined
                              }
                              onChange={formik.handleChange}
                              error={
                                formik.errors.billPayrollCyclesOther ||
                                prefillErrors.billPayrollCyclesOther
                              }
                            />
                            <EditedFieldMsg
                              changeDetailInfoList={[
                                changeSnapshot.Policy[policy.id]?.billPayrollCyclesOther,
                              ]}
                              client={client}
                              authUser={authUser}
                              hasPendingEdit={getHasPendingEdit({
                                field: "billPayrollCyclesOther",
                                client,
                                formik,
                              })}
                            />
                          </StackY>
                        </div>

                        <EditedFieldMsg
                          changeDetailInfoList={[
                            changeSnapshot.Policy[policy.id]?.billPayrollCycles,
                          ]}
                          client={client}
                          authUser={authUser}
                          hasPendingEdit={getHasPendingEdit({
                            field: "billPayrollCycles",
                            client,
                            formik,
                          })}
                        />

                        <div aria-live="assertive">
                          {((formik.touched.billPayrollCycles && formik.errors.billPayrollCycles) ||
                            prefillErrors.billPayrollCycles) && (
                            <InputErrorMessage
                              id={checkboxesErrorId}
                              error={
                                formik.errors.billPayrollCycles?.toString() ||
                                prefillErrors.billPayrollCycles
                              }
                            />
                          )}
                        </div>

                        {formik.values.billPayrollCycles &&
                          formik.values.billPayrollCycles.length > 1 && (
                            <StackY dist={8} wrap={false}>
                              <TextArea
                                name="billPayrollCyclesExplanation"
                                label="Which employees are on which payroll cycle?"
                                labelBottomText="You chose two or more payroll cycles. Please tell us what groups of employees are on each payroll cycle."
                                placeholder="Please explain"
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                value={formik.values.billPayrollCyclesExplanation}
                                touched={
                                  formik.touched.billPayrollCyclesExplanation ||
                                  !!prefillErrors.billPayrollCyclesExplanation
                                }
                                error={
                                  formik.errors.billPayrollCyclesExplanation ||
                                  prefillErrors.billPayrollCyclesExplanation
                                }
                                maxLength={1000}
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={[
                                  changeSnapshot.Policy[policy.id]?.billPayrollCyclesExplanation,
                                ]}
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "billPayrollCyclesExplanation",
                                  client,
                                  formik,
                                })}
                              />
                            </StackY>
                          )}
                      </StackY>
                    </>
                  )}
                </StackY>
              </HubCard>

              {formik.values.billingAdministrationType === "LIST" && (
                <>
                  <HubCard>
                    <Row align="middle" justify="space-between" className="mb-16">
                      <Col>
                        <h3 style={{ margin: 0 }}>Bill details</h3>
                      </Col>

                      {policiesToCopyFrom.length > 0 && formik.values.billSplitType === "NONE" && (
                        <Col>
                          <Button
                            type="text-only"
                            size="middle"
                            onClick={toggleCopyModalIsOpen}
                            disabled={formik.isSubmitting}
                          >
                            Copy settings from another bill
                          </Button>
                        </Col>
                      )}
                    </Row>

                    <p>
                      You will receive online billing statements from Sun Life each month.
                      {!isHIorNJ && (
                        <>
                          Sun Life bills for some benefits in advance and some in arrears.
                          <br />
                          <Anchor
                            href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4404442720147"
                            target="_blank"
                          >
                            Learn more about advance and arrears billing
                          </Anchor>
                        </>
                      )}
                    </p>

                    <StackY dist={32} wrap={false}>
                      <StackY dist={8} wrap={false}>
                        <RadioGroup<BillSplitType>
                          name="billSplitType"
                          value={formik.values.billSplitType}
                          direction="vertical"
                          label={
                            <>
                              <Body2>
                                {hasMixedTiming && !hasOnlyOptionalTiming
                                  ? "You have a mix of benefits billed in advance and arrears. Do you want to further separate your bill?"
                                  : "Do you want to separate your bill?"}
                              </Body2>
                              <br />
                              <Body5>
                                You can choose to receive separate bills by location, department,
                                benefit, or something else.
                              </Body5>
                            </>
                          }
                          options={[
                            {
                              label:
                                hasMixedTiming ||
                                hasOnlyOptionalTiming ||
                                // ASO Dental inherently comes with 2 bills
                                // because of the autogenerated arrears bill
                                policy.slfCoverages?.includes("ASO Dental")
                                  ? "No, send me as few bills as possible"
                                  : "No, send me a single bill",
                              value: "NONE",
                            },
                            { label: "Yes, by location", value: "LOCATION" },
                            {
                              label: "Yes, by department, division, or something else",
                              value: "TAGS",
                            },
                            ...(getBenefitTypesGroupings(client).size > 1 && !isHIorNJ
                              ? [{ label: "Yes, by benefit", value: "BENEFIT" as const }]
                              : []),
                          ]}
                          onChange={async (e) => {
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
                            const billSplitType = e.target.value as BillSplitType;
                            const newVals = {
                              ...formik.values,
                              billSplitType,
                              bills: [],
                            };
                            if (shouldConfirmBillingStructureChanges) {
                              setConfirmChangesModal({ visible: true, pendingValues: newVals });
                            } else {
                              await formik.setValues(newVals);
                            }
                          }}
                          disabled={formik.isSubmitting}
                          touched={formik.touched.billSplitType || !!prefillErrors.billSplitType}
                          error={formik.errors.billSplitType || prefillErrors.billSplitType}
                        />
                        <EditedFieldMsg
                          changeDetailInfoList={[changeSnapshot.Policy[policy.id]?.billSplitType]}
                          client={client}
                          authUser={authUser}
                          hasPendingEdit={getHasPendingEdit({
                            field: "billSplitType",
                            client,
                            formik,
                          })}
                        />
                      </StackY>

                      {shouldShowAdvanceOrArrearsSelection && (
                        <div>
                          <StackY dist={20} wrap={false}>
                            <Body2 as="div">
                              {hasOnlyOptionalTiming
                                ? "Your benefits have optional bill timing. This means you can choose whether you want them to be billed in advance or arrears."
                                : "Some of your benefits have optional bill timing. This means you can choose whether you want them to be billed in advance or arrears."}
                            </Body2>

                            <StackY dist={8} wrap={false}>
                              <table className={styles.table}>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th className="pb-8">
                                      <Body2 blueMedium as="div">
                                        Advance
                                      </Body2>
                                      <Body5 as="div">Most common</Body5>
                                    </th>
                                    <th>
                                      <Body2 blueMedium>Arrears</Body2>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {advanceOrArrearsCoveragesGroupEntries.map(
                                    ([groupName, benefitTypesInGroup]) => {
                                      const [mainBenefitType] = benefitTypesInGroup;
                                      assertIsDefined(mainBenefitType, "mainBenefitType");
                                      const slfCoverages = benefitTypesInGroup.map(
                                        (bt) => benefitTypeToCoverage[bt],
                                      );
                                      const slfCoveragesFormatted = listFormat(slfCoverages);
                                      const mainSlfCoverage =
                                        benefitTypeToCoverage[mainBenefitType];
                                      const slfCoveragebillTiming = getIn(
                                        formik.values,
                                        `advanceOrArrears.${mainSlfCoverage}`,
                                      );

                                      return (
                                        <tr key={groupName}>
                                          <td>
                                            <Body3>{slfCoveragesFormatted}</Body3>
                                          </td>
                                          <td>
                                            <SlobRadio
                                              name={groupName}
                                              value="Advance"
                                              checked={slfCoveragebillTiming === "Advance"}
                                              onChange={handleAdvanceOrArrearsChange}
                                              disabled={formik.isSubmitting}
                                              aria-label={`${slfCoveragesFormatted}: In Advance`}
                                            />
                                          </td>
                                          <td>
                                            <SlobRadio
                                              name={groupName}
                                              value="Arrears"
                                              checked={slfCoveragebillTiming === "Arrears"}
                                              onChange={handleAdvanceOrArrearsChange}
                                              disabled={formik.isSubmitting}
                                              aria-label={`${slfCoveragesFormatted}: In Arrears`}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    },
                                  )}
                                </tbody>
                              </table>

                              <EditedFieldMsg
                                changeDetailInfoList={[
                                  changeSnapshot.Policy[policy.id]?.advanceOrArrears,
                                ]}
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "advanceOrArrears",
                                  client,
                                  formik,
                                })}
                              />
                            </StackY>
                          </StackY>
                        </div>
                      )}

                      {formik.values.billSplitType === "NONE" && (
                        <BillNoSplitForm
                          ref={billNoSplitFormRef}
                          client={client}
                          policy={policy}
                          locations={locations}
                          admins={admins}
                          bills={bills}
                          copyModalIsOpen={copyModalIsOpen}
                          toggleCopyModalIsOpen={toggleCopyModalIsOpen}
                          changeSnapshot={changeSnapshot}
                          authUser={authUser}
                          billPreviews={billPreviews}
                          formik={formik}
                          billingAdministrationType="LIST"
                          billSplitType="NONE"
                          disabled={formik.isSubmitting}
                          isSubStepStarted={isSubStepStarted}
                          featureToggles={featureToggles}
                        />
                      )}
                    </StackY>
                  </HubCard>

                  {formik.values.billSplitType === "NONE" && (
                    <YourBillsCard
                      billsInThisPolicy={billsInThisPolicy}
                      billPreviews={billPreviews}
                      client={client}
                      policy={policy}
                      featureToggles={featureToggles}
                      changeSnapshot={changeSnapshot}
                      authUser={authUser}
                      billSplitType={formik.values.billSplitType}
                      policyBillingPreferences={formik.values}
                      disabled={formik.isSubmitting}
                    />
                  )}

                  {(formik.values.billSplitType === "LOCATION" ||
                    formik.values.billSplitType === "TAGS" ||
                    formik.values.billSplitType === "BENEFIT") && (
                    <BillsCreationAndEditingCard
                      client={client}
                      policy={policy}
                      locations={locations}
                      admins={admins}
                      bills={bills}
                      billsInThisPolicy={billsInThisPolicy}
                      billPreviews={billPreviews}
                      changeSnapshot={changeSnapshot}
                      authUser={authUser}
                      formik={formik}
                      isSubStepStarted={isSubStepStarted}
                      billSplitType={formik.values.billSplitType}
                      advanceOrArrears={formik.values.advanceOrArrears}
                      disabled={formik.isSubmitting}
                      featureToggles={featureToggles}
                    />
                  )}
                </>
              )}

              {(formik.values.billingAdministrationType === "SELF" ||
                formik.values.billingAdministrationType === "TPA") && (
                <BillStatementsCard
                  billNoSplitFormRef={billNoSplitFormRef}
                  client={client}
                  policy={policy}
                  locations={locations}
                  admins={admins}
                  bills={bills}
                  changeSnapshot={changeSnapshot}
                  authUser={authUser}
                  billPreviews={billPreviews}
                  formik={formik}
                  isSubStepStarted={isSubStepStarted}
                  billingAdministrationType={formik.values.billingAdministrationType}
                  featureToggles={featureToggles}
                />
              )}

              <div aria-live="assertive" className="hide:empty">
                {((formik.touched.bills && formik.errors.bills) || prefillErrors.bills) &&
                  (function (error: typeof formik.errors.bills) {
                    if (!error) return null;
                    return (
                      <ErrorMessage>
                        {typeof error === "string"
                          ? String(error)
                          : error
                              .flatMap((error, index) => {
                                if (typeof error === "string") return [error, <br key={index} />];
                                if (error.billName) {
                                  return [
                                    `Name of bill ${index + 1}: ${error.billName}`,
                                    <br key={index} />,
                                  ];
                                }
                                return null;
                              })
                              .slice(0, -1)}
                      </ErrorMessage>
                    );
                  })(formik.errors.bills || prefillErrors.bills)}
              </div>

              <div aria-live="assertive" className="hide:empty">
                {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
              </div>

              {isMultiPolicyMode ? (
                <EIFPrevAndNextPolicyButtons
                  client={client}
                  policy={policy}
                  disabled={formik.isSubmitting}
                  eifStepId={eifStepId}
                  eifSubStepId={eifSubStepId}
                  onSubmitClick={async () => {
                    await billNoSplitFormRef.current?.submitForm();
                  }}
                />
              ) : (
                <EIFBottomNavButtons
                  previousLink={previousSubStepLink}
                  previousButtonDisabled={formik.isSubmitting}
                  nextButtonDisabled={formik.isSubmitting}
                  formId={formId}
                  onSubmitClick={async () => {
                    await billNoSplitFormRef.current?.submitForm();
                  }}
                />
              )}
            </StackY>
          </form>
        </StackY>
      </StackY>

      <ConfirmDialog
        title="Change your billing selections and start over?"
        isVisible={confirmChangesModal.visible}
        isLoading={isDeletingBillsForClient}
        confirmActionText="Yes, clear my selections"
        cancelActionText="Cancel"
        onConfirm={async () => {
          if ("pendingValues" in confirmChangesModal && confirmChangesModal.pendingValues) {
            await formik.setValues(confirmChangesModal.pendingValues);
          }
          await deleteBillsForClientOnServer({
            params: {
              clientId: client.id,
            },
            data: {
              policyId: policy.id,
            },
          });

          setConfirmChangesModal({ visible: false });
        }}
        onCancel={() => {
          setConfirmChangesModal({ visible: false });
        }}
      >
        <Body1 as="p">
          Changing your selection will clear out billing details you’ve already completed. Your
          previous selections will not be saved. Are you sure you want to do this?
        </Body1>

        {deleteBillsForClientError && (
          <ErrorMessage>
            {ResponseError.getUserFacingErrorMessage(deleteBillsForClientError, GenericErrorCopy2)}
          </ErrorMessage>
        )}
      </ConfirmDialog>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}

function getBillPreviews(
  slfCoverages: SlfCoverageLongName[] | null,
  contacts: Contact[],
  locations: Location[],
  values: BillingPreferencesFormValues,
) {
  const locationsMap = new Map(locations.map((loc) => [loc.id, loc]));
  const contactsMap = new Map(contacts.map((c) => [c.id, c]));

  const billPreviews = values.bills.map((bill) => {
    const result: BillPreview = {
      id: bill.id || undefined,
      policyId: bill.policyId,
      createdAt: bill.createdAt || undefined,
      billName: bill.billName,

      billTiming: bill.billTiming,

      groupedByLocations:
        values.billSplitType === "LOCATION"
          ? bill.groupByLocationIds
            ? locations.filter((loc) => bill.groupByLocationIds?.includes(loc.id))
            : []
          : [],
      splitTags: values.billSplitType === "TAGS" ? bill.splitTags : null,
      slfCoverages: values.billingAdministrationType === "LIST" ? bill.slfCoverages : slfCoverages,

      contact: contactsMap.get(bill.contactId ?? ""),
      contactId: bill.contactId,
      hasDifferentMailingAddress: bill.hasDifferentMailingAddress,
      location: locationsMap.get(bill.locationId ?? ""),
      locationId: bill.locationId,
      numberOfEmployees: bill.numberOfEmployees,

      categorizeEmployees: bill.categorizeEmployees,
      employeeCategorizationType: bill.employeeCategorizationType,
      categoriesByLocation: getCategoriesByLocation(locationsMap, bill.categorizeByLocationIds),
      categoriesByTags: bill.categoriesByTags,
    };
    return result;
  });

  return billPreviews;
}

function getCategoriesByLocation(
  locationsMap: Map<string, Location>,
  categorizeByLocationIds: string[][] | null | undefined,
) {
  const categoriesByLocation =
    categorizeByLocationIds?.map((locationIds) => {
      const locations = locationIds
        .map((locationId) => locationsMap.get(locationId))
        .filter(rejectNullableValues);
      return locations;
    }) ?? null;
  return categoriesByLocation;
}

function getIsMissingBillingSummaryStatementError(
  billingSummaryStatementType: BillingSummaryStatementType | null | undefined,
  billingSummaryStatementTemplates: Document[],
) {
  const isMissingBillingSummaryStatementError =
    billingSummaryStatementType === "CUSTOM" && billingSummaryStatementTemplates.length === 0;
  return isMissingBillingSummaryStatementError;
}
