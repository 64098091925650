import { isYourBenefitsExplorer } from "client/src/utils/benefits-explorer";

export function initOnboard() {
  if (!isYourBenefitsExplorer) {
    document.title = "Sun Life Onboard";

    const shortcutIcon = document.createElement("link");
    shortcutIcon.rel = "shortcut icon";
    shortcutIcon.href = "../favicon.svg";
    shortcutIcon.type = "image/svg+xml";
    document.head.appendChild(shortcutIcon);

    const appleTouchIcon = document.createElement("link");
    appleTouchIcon.rel = "apple-touch-icon";
    appleTouchIcon.href = "../favicon.svg";
    document.head.appendChild(appleTouchIcon);
  }
}
