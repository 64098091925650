import { BenefitsAdministrationDataFeedsPolicySummary } from "client/src/domain/EIF/CompanyDetails/BenefitsAdministrationDataFeedsPolicySummary";
import { SubsidiaryCard } from "client/src/domain/EIF/CompanyDetails/EIFAddASubsidiary";
import { getRelevantDisabilityStatesFromPlans } from "client/src/domain/EIF/CompanyDetails/EIFSubsidiaries";
import { EIFStepBottomNavLinks } from "client/src/domain/EIF/EIFStepBottomNavLinks";
import { EIFStepHeader } from "client/src/domain/EIF/common/EIFStepHeader";
import { EIFSubStepHeader } from "client/src/domain/EIF/common/EIFSubStepHeader";
import { EIFSubStepSummaryWrapper } from "client/src/domain/EIF/common/EIFSubStepSummaryWrapper";
import { EditBanner } from "client/src/domain/EIF/common/EditBanner";
import { getChangeDetailInfoListForSubStep } from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";
import { getIsLastSubStepMap } from "client/src/domain/EIF/common/utils/getIsLastStepOrSubStepMap";
import { shouldShowDivider } from "client/src/domain/EIF/common/utils/getShouldShowHorizontalDivider";
import { getShowEditBanner } from "client/src/domain/EIF/common/utils/getShowEditBanner";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { RouteData } from "shared/config/routeData";
import { LocationStateData } from "shared/data/LocationState";
import { type EIFStepId, type EIFSubStepId, type EIFCompanyDetailsSubStep } from "shared/types/EIF";
import { getBenAdminAndDataFeedsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import {
  getIsMultiPolicyMode,
  getPrimaryPolicy,
  getShowValidationErrorsInSummary,
} from "shared/utils/client";
import { listFormat } from "shared/utils/format";
import { assertIsDefined } from "shared/utils/utils";
import {
  insuredBySunLifeValidationSchema,
  subsidiaryClientValidationSchema,
} from "shared/validation/client";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { benAdminAndDataFeedsValidationSchema } from "shared/validation/policy";
import { subsidiaryValidationSchema } from "shared/validation/subsidiary";
import { HorizontalDivider } from "../../../components/HorizontalDivider/HorizontalDivider";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body3, Body5 } from "../../../components/Typography/Typography";
import { PrintHidden } from "../../../utils/print";
import type { EIFSubStepsHaveChanges } from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, EIFSubStepViewMode, Policy } from "shared/types/Client";
import type { SignerMode } from "shared/types/OutsideSigner";
import type { Plan } from "shared/types/Plan";
import type { Subsidiary } from "shared/types/Subsidiary";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type EIFCompanyDetailsSubSectionProps = {
  client: Client;
  viewMode: EIFSubStepViewMode;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
};

export const ShowAllSubSteps = (
  plans: Plan[],
): EIFSubStepsHaveChanges<EIFCompanyDetailsSubStep> => {
  return {
    "benefits-administration-and-data-feed":
      getIsSubStepApplicable({
        eifSubStepId: "benefits-administration-and-data-feed",
        plans,
      }) ?? false,
    "tax-id": true,
    "subsidiaries-and-affiliates":
      getIsSubStepApplicable({
        eifSubStepId: "subsidiaries-and-affiliates",
        plans,
      }) ?? false,
    "current-sun-life-policies": true,
    "previous-coverage-information":
      getIsSubStepApplicable({
        eifSubStepId: "previous-coverage-information",
        plans,
      }) ?? false,
  } as const;
};

export const BenefitsAdministrationDataFeedsSummary = ({
  client,
  policy,
  authUser,
  viewMode,
  featureToggles,
  changeSnapshot,
}: EIFCompanyDetailsSubSectionProps & {
  policy: Policy;
}) => {
  const eifSubStepId = "benefits-administration-and-data-feed";

  const subStepStatus = getBenAdminAndDataFeedsCompletionStatus(policy);

  const showEditBanner = getShowEditBanner(viewMode);

  const changeDetailInfoList = getChangeDetailInfoListForSubStep({
    changeSnapshot,
    eifSubStepId,
    policies: [policy],
  });

  const isMultiPolicyMode = getIsMultiPolicyMode(client);

  const editPath = isMultiPolicyMode
    ? RouteData.eifSubStepPolicyDetail.getPath(client.id, eifStepId, eifSubStepId, policy.id)
    : RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(policy, benAdminAndDataFeedsValidationSchema, {
        prefill: false,
      })
    : {};

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          data-testid="internal-control-benefit-administration-and-data-feeds"
          navigateLink={editPath}
          changeDetailInfoList={changeDetailInfoList}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={editPath}
        subStepStatus={subStepStatus}
        showRedEditBanner={Object.keys(e).length > 0}
      />

      {isMultiPolicyMode && (
        <h5 className="mb-0">
          {policy.primaryPolicy
            ? `Policy #${policy.slfPolicyNumber} (Primary)`
            : `Policy #${policy.slfPolicyNumber}`}
        </h5>
      )}

      <BenefitsAdministrationDataFeedsPolicySummary
        client={client}
        policy={policy}
        featureToggles={featureToggles}
        viewMode={viewMode}
        changeSnapshot={changeSnapshot}
        toggleableDetails={viewMode !== "review-page"}
      />
    </div>
  );
};

export const CompanyTaxIdSummary = ({
  client,
  authUser,
  viewMode,
  changeSnapshot,
}: EIFCompanyDetailsSubSectionProps) => {
  const eifSubStepId = "tax-id";
  const subStepStatus = getEIFSubStepStatus({
    eifSubStepId,
    client,
  });

  const showEditBanner = getShowEditBanner(viewMode);

  const changeDetailInfoList = getChangeDetailInfoListForSubStep({
    changeSnapshot,
    eifSubStepId,
  });

  return (
    <div>
      <StackY dist={16}>
        {showEditBanner && (
          <EditBanner
            data-testid="internal-control-tax-id"
            navigateLink={RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId)}
            changeDetailInfoList={changeDetailInfoList}
            client={client}
            authUser={authUser}
          />
        )}

        <div>
          <EIFSubStepHeader
            eifSubStepId={eifSubStepId}
            viewMode={viewMode}
            editPath={RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId)}
            subStepStatus={subStepStatus}
            showRedEditBanner={false}
          />
          <Body3>{client.taxId}</Body3>
        </div>
      </StackY>
    </div>
  );
};

export const SubsidiariesAndAffiliatesSummary = ({
  client,
  authUser,
  viewMode,
  changeSnapshot,
  subsidiaries,
  plans,
}: EIFCompanyDetailsSubSectionProps & { subsidiaries: Subsidiary[]; plans: Plan[] }) => {
  const eifSubStepId = "subsidiaries-and-affiliates";
  const subStepStatus = getEIFSubStepStatus({
    eifSubStepId,
    client,
    subsidiaries,
    clientPlans: plans,
  });

  const showEditBanner = getShowEditBanner(viewMode);

  const changeDetailInfoList = getChangeDetailInfoListForSubStep({
    changeSnapshot,
    eifSubStepId,
    subsidiaries,
  });

  const relevantDisabilityStates = getRelevantDisabilityStatesFromPlans(plans);
  const hasRelevantDisabilityStates = relevantDisabilityStates.length > 0;
  const showStatutoryRelatedFields =
    hasRelevantDisabilityStates && client.subsidiaryEEsInNYNJOrHI === "YES";

  const subsidiaryClientValidationErrors = getShowValidationErrorsInSummary(
    viewMode,
    changeSnapshot,
  )
    ? getFormikErrors(client, subsidiaryClientValidationSchema, {
        client,
        prefill: false,
      })
    : {};

  const benefitTypes = plans.map((plan) => plan.benefitType);

  const hasSubsidiaryValidationErrors = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? // consider it an error if there are subsidiary errors or there are no subsidiaries when required
      // based on prior responses
      subsidiaries.some((sub) => {
        const subErrors = getFormikErrors(sub, subsidiaryValidationSchema, {
          prefill: false,
          benefitTypes,
          hasSubsidiary: client.hasSubsidiary ?? false,
          subsidiaryEEsInNYNJOrHI: client.subsidiaryEEsInNYNJOrHI ?? false,
        });
        return Object.keys(subErrors).length > 0;
      }) ||
      (client.hasSubsidiary === "YES" &&
        (client.subsidiaryEEsInNYNJOrHI === "YES" || !hasRelevantDisabilityStates) &&
        subsidiaries.length === 0)
    : false;

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          data-testid="internal-control-subsidiary-and-affiliates"
          navigateLink={RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId)}
          changeDetailInfoList={changeDetailInfoList}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId)}
        subStepStatus={subStepStatus}
        showRedEditBanner={
          Object.keys(subsidiaryClientValidationErrors).length > 0 || hasSubsidiaryValidationErrors
        }
      />

      <StackY dist={8}>
        {client.hasSubsidiary === "NO" && <Body3>None</Body3>}

        {client.hasSubsidiary === "YES" && (
          <>
            {client.subsidiaryEEsInNYNJOrHI === "NO" &&
              hasRelevantDisabilityStates &&
              !benefitTypes.includes("DENTAL_ASO") && (
                <>
                  <Body5 as="div">Subsidiary state(s) of employment with statutory benefits</Body5>
                  <Body3>None</Body3>
                </>
              )}

            {
              <StackY dist={16}>
                {showStatutoryRelatedFields && (
                  <div>
                    <Body5 as="div">
                      Primary company state(s) of employment with statutory benefits
                    </Body5>
                    <Body3>
                      {client.mainPolicyHolderEEsInNYNJOrHI ? (
                        client.mainPolicyHolderEEsInNYNJOrHI === "YES" ? (
                          client.mainPolicyHolderEEStates &&
                          client.mainPolicyHolderEEStates.length > 0 ? (
                            client.mainPolicyHolderEEStates
                              .map((state) => LocationStateData[state].displayName)
                              .join(", ")
                          ) : subsidiaryClientValidationErrors.mainPolicyHolderEEStates ? (
                            <Body3 redMedium>
                              {subsidiaryClientValidationErrors.mainPolicyHolderEEStates}
                            </Body3>
                          ) : (
                            "-"
                          )
                        ) : (
                          "None"
                        )
                      ) : subsidiaryClientValidationErrors.mainPolicyHolderEEsInNYNJOrHI ? (
                        <Body3 redMedium>
                          {subsidiaryClientValidationErrors.mainPolicyHolderEEsInNYNJOrHI}
                        </Body3>
                      ) : (
                        "-"
                      )}
                    </Body3>
                  </div>
                )}

                <div>
                  {subsidiaries.length === 0 &&
                    hasRelevantDisabilityStates &&
                    !benefitTypes.includes("DENTAL_ASO") &&
                    client.subsidiaryEEsInNYNJOrHI === "YES" && (
                      <>
                        <Body5 as="div">
                          Subsidiary state(s) of employment with statutory benefits
                        </Body5>
                        <Body3 redMedium>Please provide a response</Body3>
                      </>
                    )}
                </div>

                <div>
                  {subsidiaries.length === 0 && benefitTypes.includes("DENTAL_ASO") && (
                    <>
                      <Body3 redMedium>Please enter Subsidiary information</Body3>
                    </>
                  )}
                </div>

                {subsidiaries.length > 0 && (
                  <>
                    {subsidiaries.map((sub) => {
                      return (
                        <div key={sub.id}>
                          <SubsidiaryCard
                            client={client}
                            subsidiary={sub}
                            disabled={false}
                            showCta={false}
                            changeSnapshot={changeSnapshot}
                            hasDisabilityStates={relevantDisabilityStates.length > 0}
                            plans={plans}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </StackY>
            }

            {client.subsidiaryEEsInNYNJOrHI === null && hasRelevantDisabilityStates && (
              <>
                <Body5 as="div">Subsidiary state(s) of employment with statutory benefits</Body5>
                <Body3 redMedium>{subsidiaryClientValidationErrors.subsidiaryEEsInNYNJOrHI}</Body3>
              </>
            )}
          </>
        )}
      </StackY>
    </div>
  );
};

export const CurrentSunLifeCoverageInformationSummary = ({
  client,
  authUser,
  viewMode,
  changeSnapshot,
}: EIFCompanyDetailsSubSectionProps) => {
  const eifSubStepId = "current-sun-life-policies";

  const validationErrors = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(client, insuredBySunLifeValidationSchema, {
        client,
        prefill: false,
      })
    : {};

  const subStepStatus = getEIFSubStepStatus({
    eifSubStepId,
    client,
  });

  const showEditBanner = getShowEditBanner(viewMode);

  const changeDetailInfoList = getChangeDetailInfoListForSubStep({
    changeSnapshot,
    eifSubStepId,
  });

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          data-testid="internal-control-current-sun-life-policies"
          navigateLink={RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId)}
          changeDetailInfoList={changeDetailInfoList}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId)}
        subStepStatus={subStepStatus}
        showRedEditBanner={Object.keys(validationErrors).length > 0}
      />

      {client.previouslyInsuredBySunLife != null && (
        <>
          <div>
            <Body5 as="div">
              Do you currently have plans insured by Sun Life for Dental, Vision, Life, Disability,
              or Supplemental Health benefits?
            </Body5>
            <Body3 as="div">{client.previouslyInsuredBySunLife === "YES" ? "Yes" : "No"}</Body3>
            {client.previouslyInsuredBySunLife === "YES" && (
              <Body3>
                {client.previousSlfPolicies &&
                  listFormat(client.previousSlfPolicies, {
                    type: "conjunction",
                    style: "narrow",
                  })}
              </Body3>
            )}
          </div>

          {validationErrors.previouslyInsuredBySunLife && (
            <Body3 redMedium>{validationErrors.previouslyInsuredBySunLife}</Body3>
          )}

          {validationErrors.previousSlfPolicies && (
            <div>
              <Body5 as="div">Existing policy numbers</Body5>
              <Body3 redMedium>{validationErrors.previousSlfPolicies}</Body3>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const PreviousCoverageInformationSummary = ({
  client,
  authUser,
  viewMode,
  changeSnapshot,
}: EIFCompanyDetailsSubSectionProps) => {
  const eifSubStepId: EIFSubStepId = "previous-coverage-information";
  const subStepStatus = getEIFSubStepStatus({
    eifSubStepId,
    client,
  });

  const showEditBanner = getShowEditBanner(viewMode);

  const changeDetailInfoList = getChangeDetailInfoListForSubStep({
    changeSnapshot,
    eifSubStepId,
  });

  return (
    <StackY dist={16}>
      {showEditBanner && (
        <EditBanner
          data-testid="internal-control-previous-coverage-information"
          navigateLink={RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId)}
          changeDetailInfoList={changeDetailInfoList}
          client={client}
          authUser={authUser}
        />
      )}

      <div data-testid={eifSubStepId}>
        <StackY dist={16}>
          <EIFSubStepHeader
            eifSubStepId={eifSubStepId}
            viewMode={viewMode}
            editPath={RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId)}
            subStepStatus={subStepStatus}
            showRedEditBanner={false}
          />

          {subStepStatus !== "Not Started" && (
            <div>
              <Body5 as="div">
                Benefits that you currently have with other carriers but will be replacing with Sun
                Life
              </Body5>

              {Array.isArray(client.benefitsReplacedWithSlf) &&
                client.benefitsReplacedWithSlf.length > 0 &&
                client.benefitsReplacedWithSlf.map((ben) => (
                  <Body3 key={ben} as="div">
                    {ben}
                  </Body3>
                ))}

              {client.benefitsReplacedWithSlf === "none" && <Body3>None</Body3>}
            </div>
          )}
        </StackY>
      </div>
    </StackY>
  );
};

type EIFCompanyDetailsReviewProps = {
  client: Client;
  subsidiaries: Subsidiary[];
  changeSnapshot: DEIFChangeSnapshot;
  viewMode: EIFSubStepViewMode;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
  signerMode: SignerMode;
  showSubSteps: EIFSubStepsHaveChanges<EIFCompanyDetailsSubStep>;
  isLastStep: boolean;
  plans: Plan[];
};

const eifStepId: EIFStepId = "company-information";

export const EIFCompanyDetailsReview = ({
  client,
  subsidiaries,
  changeSnapshot,
  viewMode,
  authUser,
  featureToggles,
  signerMode,
  showSubSteps,
  isLastStep,
  plans,
}: EIFCompanyDetailsReviewProps) => {
  const isMultiPolicyMode = getIsMultiPolicyMode(client);

  const isLastSubStep = getIsLastSubStepMap(showSubSteps);
  const eifSubStepId = "benefits-administration-and-data-feed";

  const lastPolicy = client.policies[client.policies.length - 1];
  assertIsDefined(lastPolicy, "lastPolicy");

  return (
    <>
      {signerMode === "inside" && <EIFStepHeader viewMode={viewMode} eifStepId={eifStepId} />}

      <StackY dist={32}>
        {isMultiPolicyMode &&
          showSubSteps["benefits-administration-and-data-feed"] &&
          client.policies.map((policy) => {
            return (
              <EIFSubStepSummaryWrapper signerMode={signerMode} key={policy.id}>
                <BenefitsAdministrationDataFeedsSummary
                  authUser={authUser}
                  client={client}
                  policy={policy}
                  featureToggles={featureToggles}
                  viewMode={viewMode}
                  changeSnapshot={changeSnapshot}
                />

                {((signerMode === "outside" &&
                  shouldShowDivider(eifSubStepId, isLastSubStep, isLastStep)) ||
                  (lastPolicy.id !== policy.id && signerMode === "outside")) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
              </EIFSubStepSummaryWrapper>
            );
          })}

        <EIFSubStepSummaryWrapper signerMode={signerMode}>
          <StackY dist={32}>
            {!isMultiPolicyMode && showSubSteps["benefits-administration-and-data-feed"] && (
              <>
                <BenefitsAdministrationDataFeedsSummary
                  authUser={authUser}
                  client={client}
                  policy={getPrimaryPolicy(client)}
                  featureToggles={featureToggles}
                  viewMode={viewMode}
                  changeSnapshot={changeSnapshot}
                />

                {shouldShowDivider(
                  "benefits-administration-and-data-feed",
                  isLastSubStep,
                  isLastStep,
                ) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
              </>
            )}

            {showSubSteps["tax-id"] && (
              <>
                <CompanyTaxIdSummary
                  authUser={authUser}
                  client={client}
                  featureToggles={featureToggles}
                  viewMode={viewMode}
                  changeSnapshot={changeSnapshot}
                />

                {shouldShowDivider("tax-id", isLastSubStep, isLastStep) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
              </>
            )}

            {showSubSteps["subsidiaries-and-affiliates"] && (
              <>
                <SubsidiariesAndAffiliatesSummary
                  authUser={authUser}
                  client={client}
                  featureToggles={featureToggles}
                  viewMode={viewMode}
                  changeSnapshot={changeSnapshot}
                  subsidiaries={subsidiaries}
                  plans={plans}
                />
                {shouldShowDivider("subsidiaries-and-affiliates", isLastSubStep, isLastStep) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
              </>
            )}

            {showSubSteps["current-sun-life-policies"] && (
              <>
                <CurrentSunLifeCoverageInformationSummary
                  authUser={authUser}
                  client={client}
                  featureToggles={featureToggles}
                  viewMode={viewMode}
                  changeSnapshot={changeSnapshot}
                />

                {shouldShowDivider("current-sun-life-policies", isLastSubStep, isLastStep) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
              </>
            )}

            {showSubSteps["previous-coverage-information"] && (
              <>
                <PreviousCoverageInformationSummary
                  authUser={authUser}
                  client={client}
                  featureToggles={featureToggles}
                  viewMode={viewMode}
                  changeSnapshot={changeSnapshot}
                />

                {signerMode === "outside" &&
                  shouldShowDivider("previous-coverage-information", isLastSubStep, isLastStep) && (
                    <PrintHidden>
                      <HorizontalDivider />
                    </PrintHidden>
                  )}
              </>
            )}
          </StackY>
        </EIFSubStepSummaryWrapper>

        {viewMode !== "review-page" && <EIFStepBottomNavLinks eifStepId={eifStepId} />}
      </StackY>
    </>
  );
};
