import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";

import { assertIsDefined } from "shared/utils/utils";
import { useHubConfiguration } from "../hooks/useConfig";

export interface ReCaptchaProps {
  badge?: "bottomleft" | "bottomleft" | "inline";
  onSuccess?: (token: string) => void;
  onExpiration?: () => void;
  onError?: (err: Error) => void;
}

declare let grecaptcha: ReCAPTCHA | undefined;

let element: HTMLElement | null = null;

let instances = 0;

let rendered = false;

export const useRecaptcha = (props: ReCaptchaProps = {}) => {
  const [loaded, setLoaded] = useState(false);

  const config = useHubConfiguration();

  useEffect(() => {
    let timeoutId: number;

    (function checkReCaptchaLoaded() {
      if (!grecaptcha) {
        timeoutId = window.setTimeout(checkReCaptchaLoaded, 100);
      } else {
        setLoaded(true);
      }
    })();

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, []);

  useEffect(() => {
    if (!loaded) return;

    assertIsDefined(grecaptcha, "reCAPTCHA global undefined");

    instances++;

    grecaptcha.ready(() => {
      element ??= document.createElement("recaptcha-container");

      if (!document.body.contains(element)) {
        document.body.appendChild(element);
      }

      if (!rendered) {
        // eslint-disable-next-line testing-library/render-result-naming-convention -- false positive
        const opt_widget_id = grecaptcha.render(element, {
          sitekey: config.RECAPTCHA_SITE_KEY,
          callback: props.onSuccess,
          "expired-callback": props.onExpiration,
          "error-callback": (error: Error) => {
            Sentry.captureException(error);
            const onError = props.onError;
            onError?.(error);
          },
          size: "invisible",
          badge: props.badge,
        });
        rendered = true;

        element.setAttribute("data-opt_widget_id", opt_widget_id);
      }
    });

    return () => {
      instances--;

      if (instances === 0) {
        if (element && document.body.contains(element)) {
          document.body.removeChild(element);
        }
      }
    };
  }, [
    loaded,
    config.RECAPTCHA_SITE_KEY,
    props.onError,
    props.onExpiration,
    props.onSuccess,
    props.badge,
  ]);

  return {
    executeRecaptcha: (opt_widget_id?: string) =>
      new Promise<string>((resolve, reject) => {
        if (!rendered) {
          reject(new Error("reCAPTCHA did not render"));
          return;
        }

        if (!grecaptcha) {
          reject(new Error("reCAPTCHA global undefined"));
          return;
        }

        grecaptcha.execute(opt_widget_id);

        // We need to wait and keep checking until we get the token
        let timesChecked = 0;
        const checkForResult = () => {
          const token = grecaptcha.getResponse(opt_widget_id);
          if (token) {
            grecaptcha.reset();
            resolve(token);
            return;
          }
          timesChecked++;
          if (timesChecked > 60) {
            reject(new Error("Timed out waiting for reCAPTCHA token"));
          } else {
            setTimeout(checkForResult, 500);
          }
        };

        checkForResult();
      }),
  };
};

/**
 * Interface for Google's reCAPTCHA JavaScript API.
 *
 * Display API
 * @see {@link https://developers.google.com/recaptcha/docs/display}
 *
 * Invisible API
 * @see {@link https://developers.google.com/recaptcha/docs/invisible}
 */
interface ReCAPTCHA {
  /**
   * Programatically invoke the reCAPTCHA check. Used if the invisible reCAPTCHA is on a div
   * instead of a button.
   *
   * @param {string} opt_widget_id Optional widget ID, defaults to the first widget created if
   *     unspecified.
   */
  execute(opt_widget_id?: string): void;

  /**
   * Renders the container as a reCAPTCHA widget and returns the ID of the newly created widget.
   *
   * @param {Element|string} container The HTML element to render the reCAPTCHA widget.  Specify
   *    either the ID of the container (string) or the DOM element itself.
   * @param {Object} parameters An object containing parameters as key=value pairs, for example,
   *    {"sitekey": "your_site_key", "theme": "light"}.
   */
  render(container: Element | string, parameters: { [key: string]: unknown }): string;

  /**
   * Resets the reCAPTCHA widget.
   *
   * @param {string} opt_widget_id Optional widget ID, defaults to the first widget created if
   *     unspecified.
   */
  reset(opt_widget_id?: string): void;

  /**
   * Gets the response for the reCAPTCHA widget. Returns a null if reCaptcha is not validated.
   *
   * @param {string} opt_widget_id Optional widget ID, defaults to the first widget created if
   *     unspecified.
   */
  getResponse(opt_widget_id?: string): string;

  /**
   * ready() runs your function when the reCAPTCHA library loads. To avoid race conditions with
   * the api.js, include the api.js before your scripts that call grecaptcha, or continue to use
   * the onload callback that's defined with the v2 API.
   * @param callback
   */
  ready(callback: () => void): void;
}
