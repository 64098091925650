import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";
import { useState } from "react";
import { getClientType } from "shared/data/Client";

import { isAddCoverageClient } from "shared/utils/client";
import { getIsInternalUser } from "../../../../../shared/rbac/rbac";
import { ChildPageContactBlock } from "../../../components/ChildPageContactBlock/ChildPageContactBlock";
import { ChildPageLayout } from "../../../components/ChildPageLayout/ChildPageLayout";
import { genericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { GuidanceModal } from "../../../components/GuidanceModal/GuidanceModal";
import { KnowledgeBaseToolbar } from "../../../components/KnowledgeBase/KnowledgeBaseToolbar";
import { StackY } from "../../../components/Spacing/Spacing";
import { H1, H2, H3, Body1, Body2 } from "../../../components/Typography/Typography";
import { UnorderedList, UnorderedListItem } from "../../../components/UnorderedList/UnorderedList";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { InstallCallCard } from "../../../domain/Client/InstallCallCard";
import { InstallCallForm } from "../../../domain/Client/InstallCallForm";
import { useSlobAuth } from "../../../hooks/auth";
import { useUpdateClient } from "../../../hooks/client";
import { useTitle } from "../../../hooks/useTitle";
import { useToggler } from "../../../hooks/useToggler";
import { useTrackElementClicked } from "../../../utils/analytics";
import { hasRemainingHubTasks } from "../../Hub/Hub";
import * as styles from "./installCall.module.less";

import type { Task } from "shared/data/Tasks";
import type { Client, ClientType } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export type InstallCallProps = {
  client: Client;
  task: Task;
  featureToggles: ClientFeatureToggles;
};

type ByClientTypeProps = {
  clientType: ClientType;
  isAddCoverage?: boolean;
};

const Duration = () => (
  <div className={styles.duration}>
    <FontAwesomeIcon icon={faClock} className={styles.clockIcon} />
    <span className={clsx(styles.durationText, "eyebrow")}>Estimated 1 hour duration</span>
  </div>
);

const SidebarByClientType = ({
  clientType,
  isAddCoverage,
}: ByClientTypeProps): JSX.Element | null => {
  if (isAddCoverage) {
    return (
      <>
        <H1>Get ready for your Ongoing Service call</H1>
        <Duration />
        <Body1 as="p">
          Congratulations on onboarding your new benefits! It's time to get ready for ongoing
          service. Schedule a call with your Client Services Support Team to discuss the ongoing
          administration of your new benefits and what to expect throughout the year.
        </Body1>
      </>
    );
  } else {
    switch (clientType) {
      case "HEALTHPRO":
      case "O100":
        return (
          <>
            <H1>Get ready for your Ongoing Service call</H1>
            <Duration />
            <Body1 as="p">
              Congratulations on completing onboarding! Let's help get you prepared for ongoing
              service. In this call, you'll meet your new Sun Life Client Relationship Executive and
              their partner, your Client Advocate. Both are your guides to navigating your
              relationship with Sun Life throughout the year.
            </Body1>
          </>
        );
      case "U100MVP":
        return (
          <>
            <H1>Get ready for your Ongoing Service call</H1>
            <Duration />
            <Body1 as="p">
              Congratulations on completing onboarding! Let's help get you prepared for ongoing
              service. In this call, you'll meet your new Client Loyalty Manager. They are your
              guide to navigating your relationship with Sun Life throughout the year.
            </Body1>
          </>
        );
      case "U100":
        return (
          <>
            <H1>Get ready for your Ongoing Service call</H1>
            <Duration />
            <Body1 as="p">
              Congratulations on completing onboarding! Let's help get you prepared for ongoing
              service. In this call, you'll meet your Client Services Support Team. They are your
              guides to navigating your relationship with Sun Life throughout the year.
            </Body1>
          </>
        );
      default:
        return null;
    }
  }
};

const BodyByClientType = ({ clientType, isAddCoverage }: ByClientTypeProps): JSX.Element | null => {
  switch (clientType) {
    case "HEALTHPRO":
    case "O100":
      return (
        <div className="mt-40 mb-16">
          {isAddCoverage ? (
            <>
              <H3>Connect with your team for ongoing service</H3>
              <p className="mb-24">
                Your team for ongoing service will be your hosts on this call and are there for you
                throughout the rest of your journey with Sun Life.
              </p>
            </>
          ) : (
            <>
              <H3>Meet your team for ongoing service</H3>
              <p className="mb-24">
                Your new team for ongoing service will be your hosts on this call and are there for
                you throughout the rest of your journey with Sun Life.
              </p>
            </>
          )}
          <Body2 as="h4">Client Advocate</Body2>
          <p className="mb-24">
            Your Client Advocate is an expert in day to day plan administration and your liaison to
            our internal departments. If you need assistance navigating our system or have questions
            about our process, your Client Advocate is here to guide you.
          </p>
          <Body2 as="h4">Client Relationship Executive</Body2>
          <p>
            Your Client Relationship Executive will work with you, and your Client Advocate, to set
            you up for success. While day to day service will be managed by your Client Advocate,
            your Client Relationship Executive will be checking in and providing beneficial
            education and updates regarding your plans. Your Client Relationship Executive will help
            ensure you and your employees are having a positive experience with Sun Life.
          </p>
        </div>
      );
    case "U100MVP":
      return (
        <div className="mt-40 mb-16">
          {isAddCoverage ? (
            <H3>Connect with your Client Loyalty Manager</H3>
          ) : (
            <H3>Meet your Client Loyalty Manager</H3>
          )}
          <p>
            Your Client Loyalty Manager for ongoing service will be your host on this call and is
            there for you throughout the rest of your journey with Sun Life.
          </p>
          <Row>
            <Col span={14}>
              <p>
                Your Client Loyalty Manager will work with you to help prepare you for ongoing
                service, and will periodically check in with you to help ensure you and your
                employees are having a positive experience with Sun Life.
              </p>
            </Col>
            <Col span={10}>
              <ChildPageContactBlock email={"clm@sunlife.com"} />
            </Col>
          </Row>
        </div>
      );
    case "U100":
      return (
        <div className="mt-40 mb-16">
          {isAddCoverage ? (
            <H3>Connect with your Client Services Support Team</H3>
          ) : (
            <H3>Meet your Client Services Support Team</H3>
          )}
          <p>
            Your Client Services Support Team will host this call. They are your dedicated contact
            for ongoing service and are here for you throughout the rest of your Sun Life journey.
          </p>
          <Row>
            <Col span={14}>
              <p>
                Your Client Services Support Team are experts in day to day policy administration.
                If you need assistance navigating our system or have questions your Client Services
                Support Team are here to guide you.
              </p>
            </Col>
            <Col span={10}>
              <ChildPageContactBlock
                email={"clientservices@sunlife.com"}
                phoneNumber={"800-247-6875"}
              />
            </Col>
          </Row>
        </div>
      );
    default:
      return null;
  }
};

const contactByClientType = (clientType: ClientType): string => {
  switch (clientType) {
    case "U100MVP":
      return "Client Loyalty Manager";
    case "HEALTHPRO":
    case "O100":
      return "Client Relationship Executive";
    case "U100":
    default:
      return "Client Services Support Team";
  }
};

export function InstallCallPage({ client, task }: InstallCallProps): JSX.Element {
  useTitle("Ongoing Service Call");
  const [editing, toggleEditing] = useToggler();
  const [guidanceModalVisible, setGuidanceModalVisible] = useState(false);
  const clientType = getClientType(client);
  const { mutateAsync: updateClient, isPending, error } = useUpdateClient();
  const trackElementClicked = useTrackElementClicked(client, "Ongoing Service Call");

  const remainingTasks = hasRemainingHubTasks(client);

  const isInitialSave = client.installCallUpdatedAt === null;

  const isAddCoverage = isAddCoverageClient(client);

  const { authUser } = useSlobAuth();
  const isInternalUser = getIsInternalUser(authUser);

  const completedMessage = {
    yes: {
      heading: "You've requested your Ongoing Service Call!",
      body: `Your ${contactByClientType(
        clientType,
      )} will reach out to you by email with next steps.`,
    },
    no: {
      heading: "You've declined your Ongoing Service Call.",
      body: `You have indicated that you don't want an Ongoing Service Call. Your
      ${contactByClientType(clientType)} will be reaching out by email to make sure you
      have everything you need.`,
    },
  };

  const handleNo = async () => {
    try {
      const { isError, isSuccess } = await updateClient({
        data: {
          installCallSelection: "NO",
          installCallSchedule: null,
          installCallTopics: null,
          installCallPeople: null,
        },
        params: { clientId: client.id },
      });

      if (isError) {
        void slobMessage.error(genericErrorCopy2);
      } else if (isSuccess) {
        isInitialSave
          ? setGuidanceModalVisible(true)
          : void slobMessage.success("Ongoing Service Call Updated");
      }
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    }
  };

  return (
    <ChildPageLayout
      topNavTitle="Get ready for your Ongoing Service call"
      aside={
        <>
          <SidebarByClientType clientType={clientType} isAddCoverage={isAddCoverage} />
        </>
      }
      contents={
        <>
          {!isInternalUser && (
            <GuidanceModal
              isVisible={guidanceModalVisible}
              remainingTasks={remainingTasks}
              clientId={client.id}
              onCancel={() => setGuidanceModalVisible(false)}
              nextText={
                client.installCallSelection === "YES"
                  ? completedMessage.yes.body
                  : completedMessage.no.body
              }
              titleText={
                client.installCallSelection === "YES"
                  ? completedMessage.yes.heading
                  : completedMessage.no.heading
              }
              taskStatus={task.status}
              trackElementClicked={trackElementClicked}
            />
          )}
          <H2>What you’ll cover</H2>
          {isAddCoverage ? (
            <p>
              We will walk you through everything you need to know for the ongoing administration of
              the benefits you just added including:
            </p>
          ) : (
            <p>
              In this call, we’ll walk through everything you need to know to get started with the
              ongoing administration of your benefits, including:
            </p>
          )}
          <StackY dist={40}>
            {isAddCoverage ? (
              <UnorderedList>
                <UnorderedListItem>Evidence of Insurability (EOI) process</UnorderedListItem>
                <UnorderedListItem>
                  How to view and submit claims and see the status of claims
                </UnorderedListItem>
                <UnorderedListItem>Bill Payment Options (ePay, payment address)</UnorderedListItem>
                <UnorderedListItem>
                  Review of contract to ensure it matches your expectations
                </UnorderedListItem>
              </UnorderedList>
            ) : (
              <UnorderedList>
                <UnorderedListItem>
                  How to navigate your online account in Sun Life Connect
                </UnorderedListItem>
                <UnorderedListItem>
                  How to access your bill and set up and manage your payment options
                </UnorderedListItem>
                <UnorderedListItem>
                  An overview of the Evidence of Insurability (EOI) process
                </UnorderedListItem>
                <UnorderedListItem>
                  How to view, submit, and see the status of claims
                </UnorderedListItem>
                <UnorderedListItem>
                  A review of your contract to ensure it matches your expectations
                </UnorderedListItem>
              </UnorderedList>
            )}

            {editing ? (
              <InstallCallForm
                client={client}
                contactType={contactByClientType(clientType)}
                toggleEditing={toggleEditing}
                updateClient={updateClient}
                trackElementClicked={trackElementClicked}
                isInitialSave={isInitialSave}
                onInitialSave={setGuidanceModalVisible}
              />
            ) : (
              <InstallCallCard
                client={client}
                contactType={contactByClientType(clientType)}
                toggleEditing={toggleEditing}
                handleNo={handleNo}
                isLoading={isPending}
                trackElementClicked={trackElementClicked}
                error={error}
                completedMessage={completedMessage}
              />
            )}
            <BodyByClientType clientType={clientType} isAddCoverage={isAddCoverage} />
          </StackY>
        </>
      }
      bottomToolbar={
        <KnowledgeBaseToolbar
          title="Learn more about ongoing administration"
          topLink={{
            text: "Browse more in the Help Center",
            href: "https://onboard-help.sunlifeconnect.com/hc/en-us",
          }}
          links={[
            {
              title: "How to manage employee eligibility",
              href: "https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403323981843",
              target: "_blank",
            },
            {
              title: "How to reinstate a member",
              href: "https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403316302867",
              target: "_blank",
            },
            {
              title: "How to locate your bill",
              href: "https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4402387151635",
              target: "_blank",
            },
            {
              title: "How to make a payment online",
              href: "https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403323985043",
              target: "_blank",
            },
          ]}
        />
      }
    />
  );
}
