import { Anchor } from "client/src/components/Anchor/Anchor";
import { Body5 } from "client/src/components/Typography/Typography";
import { useTranslation } from "client/src/i18n";
import { RouteData } from "shared/config/routeData";
import type { PublicExplorerPage } from "shared/types/ExplorerPage";

interface Props {
  explorerPage: PublicExplorerPage;
}
export const PrevPageLink = ({ explorerPage }: Props) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerPage" });

  if (
    !!explorerPage.prevExplorerPageId &&
    !!explorerPage.prevExplorerPageExpiry &&
    new Date(explorerPage.prevExplorerPageExpiry) > new Date() &&
    !!explorerPage.prevExplorerPagePlanYear
  ) {
    return (
      <div className="flex justify-center mt-4">
        <Body5 as="p">
          {t("newEmployees")}:{" "}
          <Anchor
            href={RouteData.benefitsExplorer.getPath(explorerPage.prevExplorerPageId)}
            target="_blank"
          >
            {t("prevPageLink", { planYear: explorerPage.prevExplorerPagePlanYear }) ?? ""}
          </Anchor>
        </Body5>
      </div>
    );
  }
  return null;
};
