import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { Col, Row } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3, Body5 } from "client/src/components/Typography/Typography";
import { EIFSubStepSummaryWrapper } from "client/src/domain/EIF/common/EIFSubStepSummaryWrapper";
import { EditBanner } from "client/src/domain/EIF/common/EditBanner";
import { getChangeDetailInfoListForSubStep } from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";
import { getShowEditBanner } from "client/src/domain/EIF/common/utils/getShowEditBanner";
import { RouteData } from "shared/config/routeData";
import { getEIFSubStepMap } from "shared/types/EIF";
import { formatDateFullMonthWithYear } from "shared/utils/format";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import type { UserData } from "shared/rbac/rbac";
import type { ChangeDetailInfo, DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, EIFSubStepViewMode } from "shared/types/Client";
import type { EIFPlanConfigEligibilityOnlyPFMLSubStep } from "shared/types/EIF";
import type { SignerMode } from "shared/types/OutsideSigner";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export type Props = {
  client: Client;
  readonly: boolean;
  signerMode: SignerMode;
  viewMode: EIFSubStepViewMode;
  authUser: UserData;
  changeSnapshot: DEIFChangeSnapshot;
  featureToggles: ClientFeatureToggles;
};

export function EIFFLIPreferencesReview(props: Props) {
  const { client, readonly, signerMode, authUser, viewMode, changeSnapshot, featureToggles } =
    props;

  const eifSubStepId = "fli-preferences" satisfies EIFPlanConfigEligibilityOnlyPFMLSubStep;
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const stepStatus = getEIFSubStepStatus({ eifSubStepId, client, featureToggles });

  const to = RouteData.eifSubStepDetail.getPath(
    client.id,
    "plan-configuration-&-eligibility",
    eifSubStepId,
  );

  const showEditBanner = getShowEditBanner(viewMode);

  const changeDetailInfoList = getChangeDetailInfoListForSubStep({
    eifSubStepId,
    changeSnapshot,
  });

  const validChanges = changeDetailInfoList
    .filter((change): change is ChangeDetailInfo => change != null)
    .sort(
      (changeA, changeB) =>
        // sort by most recent change first
        changeB.currentValue.date.getTime() - changeA.currentValue.date.getTime(),
    );

  const latestChangeForStep = validChanges[0];

  const editedAt =
    latestChangeForStep?.status === "pending"
      ? formatDateFullMonthWithYear(latestChangeForStep.currentValue.date)
      : null;

  const editedMessage = `Edited by your implementation consultant on ${editedAt}.`;

  return (
    <EIFSubStepSummaryWrapper signerMode={signerMode}>
      {featureToggles.ONBOARD_FLI_EXCLUDED_GROUPS && (
        <StackY dist={16} wrap={false}>
          {showEditBanner && (
            <EditBanner
              data-testid={`fli-preferences-review-edit-banner`}
              changeDetailInfoList={getChangeDetailInfoListForSubStep({
                eifSubStepId,
                changeSnapshot,
              })}
              client={client}
              authUser={authUser}
              navigateLink={to}
            />
          )}
          <h5 className="m-0">{subStepName}</h5>
          {(latestChangeForStep?.status === "pending" ||
            latestChangeForStep?.status === "accepted") && (
            <AlertBanner variant="warning" w-full message={<Body3>{editedMessage}</Body3>} />
          )}
          {stepStatus === "Not Started" && (
            <Row align="middle" justify="space-between">
              <Col flex={1}>
                <h5 className="mb-0">Eligibility exclusions</h5>
              </Col>
              <Col>
                <Button to={to} size="small" type="secondary" shrink>
                  Start
                </Button>
              </Col>
            </Row>
          )}
          {client.fliCoversAllEmployees === true && (
            <>
              <h5>Eligibility exclusions</h5>
              <Body3>
                None - all benefits-eligible employees are covered by Family Leave Insurance
              </Body3>
            </>
          )}
          {client.fliCoversAllEmployees === false && Boolean(client.fliExcludedGroups?.length) && (
            <>
              <h5>Eligibility exclusions</h5>
              <Body3>{client.fliExcludedGroups?.join(", ")}</Body3>
            </>
          )}
          {stepStatus === "In Progress" && (
            <>
              <p>
                <AlertBanner
                  variant="error"
                  message={
                    <Body3>
                      Click <b>Edit</b> to complete this group.
                    </Body3>
                  }
                />
              </p>
              <h5>Eligibility exclusions</h5>
              <span className="redMedium">Please provide a response</span>
            </>
          )}

          <hr />

          <h5>Waiting periods</h5>
          <Body3>There is no waiting period for eligible employees.</Body3>

          <hr />

          <h5>Contributions</h5>
          <Body3>100% employer paid.</Body3>
        </StackY>
      )}
      {!featureToggles.ONBOARD_FLI_EXCLUDED_GROUPS && (
        <>
          <Row align="middle" className="mb-16">
            <Col flex={1}>
              <h5 className="m-0">{subStepName}</h5>
            </Col>

            {!readonly && (
              <Col>
                <Button
                  type="text-only"
                  size="small"
                  to={RouteData.eifSubStepDetail.getPath(
                    client.id,
                    "plan-configuration-&-eligibility",
                    eifSubStepId,
                  )}
                >
                  Edit
                </Button>
              </Col>
            )}
          </Row>

          <StackY dist={16} wrap={false}>
            <div>
              <Body5 as="div">Eligibility</Body5>
              <Body3>
                All benefits-eligible employees are covered by the Family Leave Insurance.
              </Body3>
            </div>

            <div>
              <Body5 as="div">Waiting periods</Body5>
              <Body3>There is no waiting period for eligible employees.</Body3>
            </div>
          </StackY>

          <hr />

          <h5>Contributions</h5>

          {client.fliPreferencesStepViewed ? (
            <Body3>100% employer paid</Body3>
          ) : (
            <Body3>Contributions not yet selected</Body3>
          )}
        </>
      )}
    </EIFSubStepSummaryWrapper>
  );
}
