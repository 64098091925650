import { ExplorerDentalPlanComparisonTable } from "client/src/domain/ExplorerPages/ClientPage/Benefit/ExplorerDentalPlanComparisonTable";
import { ExplorerMedicalPlanComparisonTable } from "client/src/domain/ExplorerPages/ClientPage/Benefit/ExplorerMedicalPlanComparisonTable";
import { ExplorerVisionPlanComparisonTable } from "client/src/domain/ExplorerPages/ClientPage/Benefit/ExplorerVisionPlanComparisonTable";
import { LegacyPlanTable } from "client/src/domain/ExplorerPages/ClientPage/Benefit/PlanTable/Legacy/LegacyPlanTable";
import { isPageNewOrValidDate } from "client/src/domain/ExplorerPages/utils";

import type { CopySwitch } from "client/src/domain/ExplorerPages/utils";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ExplorerPageBenefit, PlanType } from "shared/types/ExplorerPageBenefit";

export type ExplorerPlanComparisonTableCopyStructure = {
  [benefitType in Extract<BenefitTypeBenEx, "MEDICAL" | "DENTAL" | "VISION" | "FINANCIAL">]:
    | CopySwitch<
        PlanType | "MULTI_CHOICE",
        {
          heading: string;
          description?: string;
          tableHeaders: string[];
          planSummaryHeader?: string;
          planSummaryCell?: string;
          planMessage?: string;
          planMessageSupplemental?: {
            top: string;
            bottom: string;
          };
          frequency?: {
            yearly: string;
            biYearly: string;
          };
        } & CopySwitch<
          | PlanType
          | "NONE"
          | "DHMO_FL"
          | "DHMO_TX"
          | "HMO_HDHP"
          | "PPO_HDHP"
          | "EPO_HDHP"
          | "MEC_HDHP"
          | "POS_HDHP"
          | "OAP_HDHP"
          | "HSA_HDHP"
          | "FSA_ROLLOVER"
          | "DCA_ROLLOVER",
          string[]
        >
      >
    | {
        /**
         * The copy structure for the new custom plan comparison table.
         */
        custom: {
          tableHeaders: Record<string, string>;
          tableCells: Record<string, string | Record<string, string>>;
          tableFooters: Record<string, string>;
        };
      };
} & {
  NotApplicable: string;
  Frequency: { Yearly: string; BiYearly: string };
};

/**
 * The props for the {@link ExplorerPlanComparisonTable} component.
 */
type ExplorerPlanComparisonTableProps = {
  benefitType: BenefitTypeBenEx;
  otherBenefitTypes?: BenefitTypeBenEx[];
  plans: ExplorerPageBenefit[];
  /**
   * Date when the explorer page was created.
   */
  createdAt: Date;
  /**
   * Whether to show the legacy vision plan table.
   */
  showLegacyVisionPlanTable?: boolean;
  /**
   * Whether to show the legacy dental plan table.
   */
  showLegacyDentalPlanTable?: boolean;
  medicalPlanComparisonFeatureToggle?: boolean;
  visionPlanComparisonFeatureToggle?: boolean;
  dentalPlanComparisonFeatureToggle?: boolean;
  fsaRolloverFeatureToggle?: boolean;
  onChangeActiveBenefitType?: (benefitType: BenefitTypeBenEx) => void;
};

/**
 * The ExplorerPlanComparisonTable component displays a table comparing the plans for a given benefit type.
 */
export const ExplorerPlanComparisonTable = ({
  benefitType,
  otherBenefitTypes,
  plans,
  createdAt,
  showLegacyVisionPlanTable,
  showLegacyDentalPlanTable,
  medicalPlanComparisonFeatureToggle,
  visionPlanComparisonFeatureToggle,
  dentalPlanComparisonFeatureToggle,
  onChangeActiveBenefitType,
}: ExplorerPlanComparisonTableProps) => {
  const supplementalBenefitTypes =
    otherBenefitTypes?.filter(
      (otherBenefitType) =>
        otherBenefitType === "ACCIDENT" ||
        otherBenefitType === "CANCER" ||
        otherBenefitType === "CRITICAL_ILLNESS" ||
        otherBenefitType === "HOSPITAL_INDEMNITY",
    ) ?? [];

  const isNewPage = isPageNewOrValidDate(createdAt, benefitType);
  const useNewMedicalPlanTable =
    isNewPage && benefitType === "MEDICAL" && medicalPlanComparisonFeatureToggle;
  const useNewDentalPlanTable =
    isNewPage &&
    benefitType === "DENTAL" &&
    dentalPlanComparisonFeatureToggle &&
    !showLegacyDentalPlanTable;
  const useNewVisionPlanTable =
    isNewPage &&
    benefitType === "VISION" &&
    visionPlanComparisonFeatureToggle &&
    !showLegacyVisionPlanTable;

  // New plan comparison tables.

  if (useNewMedicalPlanTable) {
    return plans.length === 0 ? null : (
      <ExplorerMedicalPlanComparisonTable
        benefits={plans}
        supplementalBenefitTypes={supplementalBenefitTypes}
        onSupplementalBenefitTypeClick={(supplementalBenefitType) =>
          onChangeActiveBenefitType?.(supplementalBenefitType)
        }
      />
    );
  }

  if (useNewDentalPlanTable) {
    return plans.length === 0 ? null : <ExplorerDentalPlanComparisonTable benefits={plans} />;
  }

  if (useNewVisionPlanTable) {
    return plans.length === 0 ? null : <ExplorerVisionPlanComparisonTable benefits={plans} />;
  }

  // Legacy plan comparison tables.
  return <LegacyPlanTable benefitType={benefitType} plans={plans} />;
};
