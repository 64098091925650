import { Dropdown as AntdDropdown, Menu } from "antd";
import { Button } from "client/src/components/Button/Button";
import { RenameFile } from "client/src/domain/Document/RenameFile/RenameFile";
import { UploadFileTablePresentation } from "client/src/domain/Document/UploadFileTable/UploadFileTablePresentation";
import clsx from "clsx";
import { useFormik } from "formik";
import { documentCategorySorter } from "shared/utils/document";
import { rejectNullableValues } from "shared/utils/utils";
import * as Yup from "yup";

import {
  EnrollmentResourcesCategoriesNoVDR,
  categoryNameByCategoryType,
} from "../../../../../shared/types/Document";
import {
  documentBenefitTypes,
  documentBenefitTypesNames,
  isDocumentBenefitType,
} from "../../../../../shared/types/DocumentBenefitType";
import { ErrorMessage } from "../../../components/Error/ErrorMessage";
import { Checkbox } from "../../../components/Form/Checkbox";
import { MenuWithCheckboxes } from "../../../components/Form/MenuWithCheckboxes/MenuWithCheckboxes";
import { SlobSelect } from "../../../components/Form/SlobSelect";
import { TrashIcon } from "../../../components/Icons/TrashIcon";
import { Tooltip } from "../../../components/Tooltip/Tooltip";
import { Body3 } from "../../../components/Typography/Typography";
import * as styles from "../DownloadFileTable/downloadFileTable.module.less";

import type { DocumentCategory } from "../../../../../shared/types/Document";
import type { DocumentBenefitType } from "../../../../../shared/types/DocumentBenefitType";
import type { ColumnType } from "antd/lib/table";
import type { CheckboxChangeEvent } from "client/src/components/Form/Checkbox";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type FileRowData = {
  key: string | number;
  id: string | number;
  name: string;
  error?: JSX.Element | { resourceType?: string };
  errorCode?: "DUPLICATE_NAME" | "EMBEDDED_SCRIPTS";
  updatedBy: string;
  resourceType: DocumentCategory | null;
  benefitTypes: DocumentBenefitType[] | null;
};

const isRenderableError = (error: FileRowData["error"]): error is JSX.Element => {
  const isIt = Boolean(error && (typeof error === "string" || "type" in error));
  return isIt;
};

export type UploadDifferentFilesTableProps = {
  fileList: FileRowData[];
  selectedResourcesType?: DocumentCategory[];
  onClickRemove: (filename: string) => void;
  onChangeResourceType: (
    option: {
      value: DocumentCategory | "";
      label: string;
    },
    record: FileRowData,
  ) => void;
  onChangeBenefitType: (args: {
    recordId: string;
    name: DocumentBenefitType;
    checked: boolean;
  }) => void;
  getPopupContainer: (triggerNode: HTMLElement) => HTMLElement;
  disabled?: boolean;
  track: (buttonLabel: string) => void;
  renameDocument: (recordId: string, newName: string) => void;
  featureToggles: ClientFeatureToggles;
};

export const UploadDifferentFilesTable = ({
  fileList,
  selectedResourcesType = EnrollmentResourcesCategoriesNoVDR,
  onClickRemove,
  onChangeResourceType,
  onChangeBenefitType,
  getPopupContainer,
  disabled,
  renameDocument,
  track,
}: UploadDifferentFilesTableProps) => {
  const formik = useFormik<Record<string, boolean>>({
    validationSchema: Yup.lazy((obj) => {
      const spec = Object.entries(obj).reduce<Record<string, ReturnType<typeof Yup.boolean>>>(
        (prev, [key]) => {
          prev[key] = Yup.boolean();
          return prev;
        },
        {},
      );

      return Yup.object(spec);
    }),
    initialValues: {},
    onSubmit() {
      //
    },
  });

  const checkedItems = Object.values(formik.values).filter((v) => v);

  const onDelete = (fileName: string) => {
    track("Remove file");
    onClickRemove(fileName);
  };

  const errorExists = fileList.some((file) => isRenderableError(file.error));
  const hasEmbeddedScripts = fileList.some((file) => file.errorCode === "EMBEDDED_SCRIPTS");
  const selectedResourcesTypeSorted = selectedResourcesType.sort(documentCategorySorter);

  const columnHeadersMultipleCategory: (ColumnType<FileRowData> | null)[] = [
    {
      title: () => {
        return (
          <Checkbox
            variant="secondary"
            label="File Name"
            name="FileName"
            checked={checkedItems.length === fileList.length}
            onChange={() =>
              fileList.forEach((f) =>
                formik.setFieldValue(String(f.id), checkedItems.length !== fileList.length),
              )
            }
            disabled={disabled}
            indeterminate={checkedItems.length > 0 && checkedItems.length < fileList.length}
          />
        );
      },
      dataIndex: "name",
      key: "name",
      render: (text: unknown, record: FileRowData) => {
        if (record.errorCode === "DUPLICATE_NAME") {
          return (
            <RenameFile
              record={{ ...record, isNew: false, id: String(record.id) }}
              renameDocument={renameDocument}
              customContent={
                <Checkbox
                  id={String(record.id)}
                  label={record.name}
                  name={String(record.id)}
                  checked={Boolean(formik.values[String(record.id)])}
                  onChange={(e) => formik.setFieldValue(e.target.name || "", e.target.checked)}
                  disabled={disabled}
                  indeterminate={false}
                />
              }
            />
          );
        }

        return (
          <Checkbox
            id={String(record.id)}
            label={record.name}
            name={String(record.id)}
            checked={Boolean(formik.values[String(record.id)])}
            onChange={(e) => formik.setFieldValue(e.target.name || "", e.target.checked)}
            disabled={disabled}
            indeterminate={false}
          />
        );
      },
    },
    {
      title: () => {
        return checkedItems.length <= 1 ? (
          "Resource Type"
        ) : (
          <AntdDropdown
            trigger={["click"]}
            disabled={disabled}
            dropdownRender={() => (
              <Menu
                className="p-24"
                items={selectedResourcesTypeSorted.map((c) => ({
                  key: c,
                  label: <Body3>{categoryNameByCategoryType[c]}</Body3>,
                }))}
                onClick={({ keyPath }) => {
                  if (keyPath.length) {
                    const category = selectedResourcesTypeSorted.find((c) => c === keyPath[0]);
                    if (category)
                      fileList.forEach((f) => {
                        if (formik.values[String(f.id)]) {
                          onChangeResourceType(
                            {
                              value: category,
                              label: categoryNameByCategoryType[category],
                            },
                            f,
                          );
                        }
                      });
                  }
                }}
              />
            )}
          >
            <Button type="text-only">Edit Resource Type</Button>
          </AntdDropdown>
        );
      },
      dataIndex: "resourceType",
      key: "resourceType",
      width: "205px",
      className: clsx(styles.valignTop, styles.headerTopPadding),
      render: (text: unknown, record: FileRowData) => {
        const error =
          record.error &&
          typeof record.error === "object" &&
          "resourceType" in record.error &&
          record.error.resourceType;
        return (
          <SlobSelect<{ label: string; value: DocumentCategory | "" }>
            name="resourceType"
            placeholder="Resource Type"
            aria-invalid={Boolean(error)}
            disabled={disabled}
            loading={disabled}
            touched={Boolean(error)}
            showRequired={true}
            error={error || undefined}
            options={selectedResourcesTypeSorted.map((c) => ({
              value: c,
              label: categoryNameByCategoryType[c],
            }))}
            value={record.resourceType}
            onChange={(option) => onChangeResourceType?.(option, record)}
          />
        );
      },
    },
    {
      title: () => {
        return checkedItems.length <= 1 ? (
          "Benefit Type"
        ) : (
          <MenuWithCheckboxes
            getPopupContainer={getPopupContainer}
            variant="secondary"
            name="editAllBenefitTypes"
            label="Edit Benefit Type"
            disabled={disabled}
            menuItemCheckboxes={documentBenefitTypes.map((bt) => {
              const filesThatAreCheckedAndHaveCurrentBenefitType = fileList.filter(
                (f) => Boolean(formik.values[String(f.id)]) && f.benefitTypes?.includes(bt),
              );
              const checked =
                filesThatAreCheckedAndHaveCurrentBenefitType.length === checkedItems.length
                  ? true
                  : filesThatAreCheckedAndHaveCurrentBenefitType.length === 0
                  ? false
                  : "mixed";
              return {
                id: `All__${bt}`,
                label: documentBenefitTypesNames[bt],
                name: bt,
                checked,
                onChange: (e) => {
                  const name = e.target.name;
                  const checked = e.target.checked;
                  if (name && isDocumentBenefitType(name)) {
                    fileList.forEach(
                      (f) =>
                        Boolean(formik.values[String(f.id)]) &&
                        onChangeBenefitType({
                          recordId: String(f.id),
                          name,
                          checked,
                        }),
                    );
                  }
                },
              };
            })}
          />
        );
      },
      dataIndex: "benefitType",
      key: "benefitType",
      width: "205px",
      className: clsx(styles.valignTop, styles.headerTopPadding),
      render: (text: unknown, record: FileRowData) => {
        const getChecked = (type: DocumentBenefitType) => {
          return record.benefitTypes?.includes(type) ?? false;
        };

        const onChange = (e: CheckboxChangeEvent) => {
          const name = e.target.name;
          const checked = e.target.checked;
          if (name && isDocumentBenefitType(name)) {
            onChangeBenefitType?.({ recordId: String(record.id), name, checked });
          }
        };

        return (
          <MenuWithCheckboxes
            getPopupContainer={getPopupContainer}
            variant="primary"
            name="benefitTypes"
            label="Benefit Type"
            disabled={disabled}
            menuItemCheckboxes={documentBenefitTypes.map((bt) => ({
              id: `${record.id}__${bt}`,
              label: documentBenefitTypesNames[bt],
              name: bt,
              checked: getChecked(bt),
              onChange,
            }))}
          />
        );
      },
    },
    {
      title: () => {
        return checkedItems.length <= 1 ? null : (
          <Button
            type="text-only"
            disabled={disabled}
            onClick={() => {
              fileList.forEach(
                (file) => Boolean(formik.values[String(file.id)]) && onDelete(file.name),
              );
            }}
          >
            Delete
          </Button>
        );
      },
      width: "60px",
      key: "action",
      align: "right",
      className: styles.valignTop,
      onCell() {
        return { style: { verticalAlign: "middle" } };
      },
      render: (text: unknown, record: FileRowData) => (
        <div className={styles.actions}>
          <Tooltip title="Delete" placement="bottom">
            <span>
              <button
                onClick={() => onDelete(record.name)}
                title="Remove file"
                aria-label="Remove file"
                className="btn-reset"
                disabled={disabled}
              >
                <TrashIcon />
              </button>
            </span>
          </Tooltip>
        </div>
      ),
    },
    errorExists
      ? {
          title: "Error",
          key: "error",
          width: "140px",
          className: styles.valignTop,
          render: (text: unknown, record: FileRowData) => {
            if (isRenderableError(record.error)) {
              return <ErrorMessage>{record.error}</ErrorMessage>;
            }
          },
        }
      : null,
  ];

  const columnHeadersFiltered = columnHeadersMultipleCategory.filter(rejectNullableValues);

  return (
    <UploadFileTablePresentation
      dataSource={fileList}
      columns={columnHeadersFiltered}
      errorExists={errorExists}
      hasEmbeddedScripts={hasEmbeddedScripts}
    />
  );
};
