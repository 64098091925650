import { useCustomTheme } from "client/src/hooks/ExplorerPage/useCustomTheme";
import { Loading } from "../../components/Loading/Loading";
import { PageContent } from "../../components/PageContent/PageContent";
import { PersonalBenefitsFavoritePlans } from "../../domain/ExplorerPages/ClientPage/PersonalBenefitsFavoritePlans";
import { PersonalBenefitsPlanHeadline } from "../../domain/ExplorerPages/ClientPage/PersonalBenefitsPlanHeadline";
import { useGetPublicExplorerPageById } from "../../hooks/ExplorerPage/useExplorerPages";
import { triggerError } from "../../hooks/generalError";
import { useClientHubParams } from "../../hooks/useClientHubParams";

import * as styles from "./explorerPage.module.less";

export const PersonalBenefitsPlanPage = () => {
  const { explorerPageId } = useClientHubParams(["explorerPageId"]);

  const { isLoading, error, data: explorerPage } = useGetPublicExplorerPageById(explorerPageId);

  const hasCustomBranding =
    !!explorerPage?.benefitsExplorerCustomBranding && !!explorerPage.customBranding;
  const customTheme = hasCustomBranding ? explorerPage?.customTheme : undefined;
  useCustomTheme(customTheme);

  if (isLoading) {
    return <Loading />;
  }

  if (error || !explorerPage || !explorerPage.benefits) {
    return triggerError(error ?? "Failed to get explorer page data");
  }

  return (
    <div className={styles.personalPlanPage}>
      <PageContent>
        <PersonalBenefitsPlanHeadline page={explorerPage} />
        <PersonalBenefitsFavoritePlans
          plans={explorerPage.benefits}
          explorerPageID={explorerPageId}
          vasUpdateFeatureToggle={explorerPage.vasUpdateFeatureToggle}
          customBranding={hasCustomBranding}
        />
      </PageContent>
    </div>
  );
};
