import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { DownloadIcon } from "client/src/components/Icons/DownloadIcon";
import { Body1, H3 } from "client/src/components/Typography/Typography";
import { PlanSummaryLink } from "client/src/domain/ExplorerPages/ClientPage/Benefit/PlanSummaryLink";
import {
  copyKey,
  copyKeyExists,
  copyKeySwitch,
  copyKeySwitchCustom,
} from "client/src/domain/ExplorerPages/utils";
import { useGetPublicDocumentDownloadUrl } from "client/src/hooks/document";
import { useEmployeeLocation } from "client/src/hooks/useSlobLocalStorage";
import { Trans, useTranslation } from "client/src/i18n";
import { formatCurrency } from "client/src/utils/formatters";

import clsx from "clsx";
import moment from "moment";

import * as styles from "./LegacyPlanTable.module.less";
import { TableRow } from "./TableRow";
import { usePlanTableBaseKey } from "./usePlanTableBaseKey";
import { usePlanTableConfig } from "./usePlanTableConfig";
import { usePlanTablePlans } from "./usePlanTablePlans";
import type { ReactNode } from "react";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ExplorerPageBenefit } from "shared/types/ExplorerPageBenefit";

export const DataTestId = {
  PlanComparisonTable: "plan-comparison-table",
};

/**
 * The props for the {@link LegacyPlanTable} component.
 */
type LegacyPlanTableProps = {
  benefitType: BenefitTypeBenEx;
  otherBenefitTypes?: BenefitTypeBenEx[];
  plans: ExplorerPageBenefit[];
  onChangeActiveBenefitType?: (benefitType: BenefitTypeBenEx) => void;
};

/**
 * The ExplorerPlanComparisonTable component displays a table comparing the plans for a given benefit type.
 */
export const LegacyPlanTable = ({
  benefitType,
  otherBenefitTypes,
  plans,
  onChangeActiveBenefitType,
}: LegacyPlanTableProps) => {
  const { t: tGeneric } = useTranslation({ keyPrefix: "Generic" });
  const { t: tExplorerPlanComparisonTable } = useTranslation({
    keyPrefix: "ExplorerPlanComparisonTable",
  });
  const { t: tExplorerBenefitsNames } = useTranslation({ keyPrefix: "ExplorerBenefitsNames" });
  const [employeePrepaidAndChatLocation] = useEmployeeLocation();
  const screens = useBreakpoint();
  const config = usePlanTableConfig(benefitType);
  const baseKey = usePlanTableBaseKey(plans, benefitType);
  const planTypes = usePlanTablePlans(plans, benefitType, baseKey);

  const supplementalBenefitTypes =
    otherBenefitTypes?.filter(
      (otherBenefitType) =>
        otherBenefitType === "ACCIDENT" ||
        otherBenefitType === "CANCER" ||
        otherBenefitType === "CRITICAL_ILLNESS" ||
        otherBenefitType === "HOSPITAL_INDEMNITY",
    ) ?? [];

  if (config.hasNoPlanColumn)
    planTypes.push({
      planTypeKey: "NONE",
      contentFlags: null,
      planName: "",
      premium: null,
      outOfPocketCosts: null,
      linkedHDHPPlanName: null,
      financialFSAMoneyRollover: null,
      financialFSAMoneyRolloverAmount: null,
      financialFSAMoneyRolloverDate: null,
      planSummaryDocumentId: null,
      planSummaryDocument: null,
    });

  if (planTypes.length < 2 && benefitType !== "DENTAL" && benefitType !== "VISION") return null;

  const descriptionCopy = tExplorerPlanComparisonTable(copyKey(...baseKey, "description"), {
    defaultValue: null,
  });

  return (
    <>
      <H3>
        <strong>{tExplorerPlanComparisonTable(copyKey(...baseKey, "heading"))}</strong>
      </H3>
      {descriptionCopy && (
        <Row className="mb-12">
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Body1>{descriptionCopy}</Body1>
          </Col>
        </Row>
      )}
      <div
        className={styles.planTable}
        data-testid={DataTestId.PlanComparisonTable}
        style={{ margin: !screens.md ? "0 -35px" : undefined }}
      >
        <div className={styles.rows}>
          {[...Array(config.rowCount)].map((_, i) => (
            <TableRow
              key={`row-${i}`}
              isHeader={i === 0}
              valueLabel={
                <Trans
                  t={tExplorerPlanComparisonTable}
                  i18nKey={copyKey(...baseKey, "tableHeaders", i.toString())}
                />
              }
              values={planTypes.map(
                ({
                  planTypeKey,
                  contentFlags,
                  planName,
                  premium,
                  outOfPocketCosts,
                  linkedHDHPPlanName,
                  financialFSAMoneyRollover,
                  financialFSAMoneyRolloverAmount,
                  financialFSAMoneyRolloverDate,
                }) => {
                  const planTypeKeyWithLoc = copyKeySwitch(
                    tExplorerPlanComparisonTable,
                    ...baseKey,
                    copyKeySwitchCustom(
                      tExplorerPlanComparisonTable,
                      [...baseKey, `${planTypeKey}_${employeePrepaidAndChatLocation}`],
                      (planTypeKey === "FSA" || planTypeKey === "DCA") &&
                        financialFSAMoneyRollover === true
                        ? `${planTypeKey}_ROLLOVER`
                        : planTypeKey,
                    ),
                  );
                  return (
                    <Trans
                      t={tExplorerPlanComparisonTable}
                      i18nKey={copyKey(...baseKey, planTypeKeyWithLoc, i.toString())}
                      values={{
                        HSA: contentFlags?.includes("HSA compatible")
                          ? tGeneric("Yes")
                          : tGeneric("No"),
                        planName,
                        premium,
                        outOfPocketCosts,
                        linkedHDHPPlanName,
                        financialFSAMoneyRolloverAmount:
                          financialFSAMoneyRolloverAmount != null
                            ? formatCurrency(financialFSAMoneyRolloverAmount)
                            : null,
                        financialFSAMoneyRolloverDate:
                          financialFSAMoneyRolloverDate != null
                            ? moment(financialFSAMoneyRolloverDate).format("MM/DD/YYYY")
                            : null,
                      }}
                    />
                  );
                },
              )}
            />
          ))}
          {copyKeyExists(tExplorerPlanComparisonTable, ...baseKey, "planSummaryHeader") &&
            copyKeyExists(tExplorerPlanComparisonTable, ...baseKey, "planSummaryCell") && (
              <TableRow
                isHeader={false}
                valueLabel={
                  <Trans
                    t={tExplorerPlanComparisonTable}
                    i18nKey={copyKey(...baseKey, "planSummaryHeader")}
                  />
                }
                values={planTypes.map(({ planTypeKey, planName, planSummaryDocumentId }) => {
                  if (planSummaryDocumentId == null) return <></>;
                  const benefit = plans.find(
                    (plan) => plan.planType === planTypeKey && plan.planName === planName,
                  );
                  const i18nKey = copyKey(...baseKey, "planSummaryCell");
                  return benefit ? (
                    <PlanSummaryLink
                      benefit={benefit}
                      label={tExplorerPlanComparisonTable(i18nKey, { planName })}
                      showIcon={true}
                    />
                  ) : (
                    <PlanSummaryDownloadLink planSummaryDocumentId={planSummaryDocumentId}>
                      <Trans
                        t={tExplorerPlanComparisonTable}
                        i18nKey={i18nKey}
                        values={{ planName }}
                      />
                      <DownloadIcon
                        style={{
                          position: "relative",
                          top: "3px",
                          left: "3px",
                          marginLeft: "4px",
                          marginRight: "4px",
                          transform: "scale(0.875)",
                        }}
                      />
                    </PlanSummaryDownloadLink>
                  );
                })}
              />
            )}
          {copyKeyExists(tExplorerPlanComparisonTable, ...baseKey, "planMessage") && (
            <Row align="middle" justify="center" style={{ backgroundColor: "transparent" }}>
              <Col
                className={clsx(styles.tableCell, "p-24")}
                style={{ textAlign: "center", width: "100%" }}
              >
                <Trans
                  t={tExplorerPlanComparisonTable}
                  i18nKey={copyKey(...baseKey, "planMessage")}
                >
                  <H3></H3>
                  <p></p>
                  <p className="body5"></p>
                </Trans>
                {supplementalBenefitTypes.length > 0 &&
                  copyKeyExists(
                    tExplorerPlanComparisonTable,
                    ...baseKey,
                    "planMessageSupplemental",
                  ) && (
                    <div>
                      <Trans
                        t={tExplorerPlanComparisonTable}
                        i18nKey={copyKey(...baseKey, "planMessageSupplemental", "top")}
                      >
                        <H3></H3>
                        <p></p>
                        <p className="body5"></p>
                      </Trans>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                          marginBottom: "20px",
                        }}
                      >
                        {supplementalBenefitTypes.map((otherBenefitType, i) => {
                          return (
                            <>
                              {i !== 0 && <span>|</span>}
                              <Button
                                type="text-only-light"
                                role="link"
                                onClick={() => onChangeActiveBenefitType?.(otherBenefitType)}
                              >
                                <Trans
                                  t={tExplorerBenefitsNames}
                                  i18nKey={copyKey(otherBenefitType)}
                                />
                              </Button>
                            </>
                          );
                        })}
                      </div>
                      <Trans
                        t={tExplorerPlanComparisonTable}
                        i18nKey={copyKey(...baseKey, "planMessageSupplemental", "bottom")}
                      >
                        <H3></H3>
                        <p></p>
                        <p className="body5"></p>
                      </Trans>
                    </div>
                  )}
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

const PlanSummaryDownloadLink = ({
  children,
  planSummaryDocumentId,
}: {
  children?: ReactNode;
  planSummaryDocumentId: string;
}) => {
  const { data } = useGetPublicDocumentDownloadUrl(planSummaryDocumentId);

  return (
    <a href={data?.objectUrl ?? "#"} download>
      {children}
    </a>
  );
};
