import { Button } from "client/src/components/Button/Button";
import { FlatCard } from "client/src/components/Cards/FlatCard/FlatCard";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { BillExclusiveProps } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/Card/BillExclusiveProps";
import { BillSharedProps } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/Card/BillSharedProps";
import {
  getChangesForBill,
  getDefaultBillNames,
  getPrefillErrorsForBill,
} from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/billing";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { useToggler } from "client/src/hooks/useToggler";
import { wasEntityAddedInLatestICEdit } from "shared/utils/EIF/changeLog";
import { getIsAutogeneratedASODentalBill } from "shared/utils/bill";
import type { BillTiming } from "@prisma/client";
import type { UserData } from "shared/rbac/rbac";
import type { BillPreview } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, Policy } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { PolicyBillingPreferences } from "shared/validation/bill";

type Props = {
  index: number;
  client: Client;
  policy: Policy;
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  bills: Array<{ billName: string | null; billTiming?: BillTiming | null }>;
  bill: BillPreview;
  policyBillingPreferences: PolicyBillingPreferences;
  disabled?: boolean;
  expanded?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
};

export function BillCardSingle(props: Props) {
  const {
    index,
    client,
    policy,
    changeSnapshot,
    authUser,
    bills,
    bill,
    policyBillingPreferences,
    disabled,
    expanded,
    onEdit,
    onDelete,
  } = props;

  const [showDetails, toggleShowDetails] = useToggler(!!expanded);

  const changeDetailInfoList = getChangesForBill(bill, changeSnapshot);

  const defaultBillNames = getDefaultBillNames(bills);

  const billName = bill.billName || defaultBillNames[index];

  const prefillErrorsForBill = getPrefillErrorsForBill(
    client,
    policy,
    policyBillingPreferences,
    bill,
    changeSnapshot,
  );

  const isAutogeneratedASODentalBill = getIsAutogeneratedASODentalBill(bill);

  return (
    <FlatCard disabled={disabled}>
      <StackY dist={16} wrap={false}>
        <Row wrap={false}>
          <Col flex="1" className="stack-x-8">
            <span className="h5" style={{ margin: 0 }} data-testid="bill-title">
              {billName}
            </span>

            {prefillErrorsForBill.billName && (
              <Body3 redMedium> {prefillErrorsForBill.billName}</Body3>
            )}
          </Col>

          {!isAutogeneratedASODentalBill && (
            <>
              {onEdit && (
                <Col flex="50px">
                  <Button type="text-only" size="middle" onClick={onEdit} disabled={disabled}>
                    Edit
                  </Button>
                </Col>
              )}

              {onDelete && (
                <Col flex="40px">
                  <Button type="text-only" size="middle" onClick={onDelete} disabled={disabled}>
                    Delete
                  </Button>
                </Col>
              )}
            </>
          )}
        </Row>

        <BillSharedProps
          bill={bill}
          showDetails={showDetails}
          prefillErrors={expanded ? prefillErrorsForBill : {}}
          billSplitType={policyBillingPreferences.billSplitType}
        />

        <BillExclusiveProps
          bills={bills}
          bill={bill}
          showDetails={showDetails}
          prefillErrors={expanded ? prefillErrorsForBill : {}}
          billingAdministrationType={policyBillingPreferences.billingAdministrationType}
        />

        {!expanded && (
          <StackY dist={20} wrap={false}>
            <hr className="m-0" />

            <Button type="text-only" onClick={toggleShowDetails} disabled={disabled}>
              {showDetails ? "Hide details" : "Show details"}
            </Button>
          </StackY>
        )}

        <EditedFieldMsg
          changeDetailInfoList={changeDetailInfoList}
          client={client}
          authUser={authUser}
          isAdd={wasEntityAddedInLatestICEdit(bill, client)}
        />
      </StackY>
    </FlatCard>
  );
}
