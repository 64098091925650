import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { Col, Row } from "client/src/components/Grid/Grid";
import { EIFStepBottomNavLinks } from "client/src/domain/EIF/EIFStepBottomNavLinks";
import { EIFFLIPreferencesReview } from "client/src/domain/EIF/PlanConfigAndEligibility/EIFFLIPreferencesReview";
import { MultipleClassInformationBox } from "client/src/domain/EIF/PlanConfigAndEligibility/MultipleClassInformationBox";
import { EIFNonClassBenefitsContributionsReview } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFNonClassBenefitsContributionsReview";
import { EIFEntityDeletedBy } from "client/src/domain/EIF/common/EIFEntityDeletedBy";
import { useGetEIFPreviousAndNextLink } from "client/src/hooks/useGetEIFPreviousAndNextLink";
import pluralize from "pluralize";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { getIsBenAdmin, getIsBroker, getIsInternalUser } from "shared/rbac/rbac";

import { getEIFSubStepMap } from "shared/types/EIF";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { getShowValidationErrorsInSummary } from "shared/utils/client";
import { getCoveragesInClientNotYetInAnyClass } from "shared/utils/employeeClass";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { ErrorMessage, GenericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { ReactComponent as GearSolid } from "../../../components/Icons/GearSolid.svg";
import { ReactComponent as PlusCircle } from "../../../components/Icons/PlusCircle.svg";
import { InternalControl } from "../../../components/InternalControl/InternalControl";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body1, Body2, Body3, H2 } from "../../../components/Typography/Typography";
import { useDeleteClass, useDuplicateEmployeeClass } from "../../../hooks/employeeClass";
import { EIFClassBuilderItem } from "./ClassBuilder/EIFClassBuilderItem";
import { EIFClassBuilderNoActionNeeded } from "./ClassBuilder/EIFClassBuilderNoActionNeeded";
import * as styles from "./EIFPlanConfigAndEligibilityReview.module.less";

import type { TrackElementClickedFunc } from "../../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { EIFSubStepViewMode, Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type EIFPlanConfigAndEligibilityReviewProps = {
  client: Client;
  authUser: UserData;
  classes: EmployeeClass[];
  clientPlans: Plan[];
  trackElementClicked: TrackElementClickedFunc;
  viewMode: EIFSubStepViewMode;
  changeSnapshot: DEIFChangeSnapshot;
  featureToggles: ClientFeatureToggles;
  updateClient: UpdateClientFunc;
  steps: "class-builder-only" | "all";
};

export const EIFPlanConfigAndEligibilityReview = ({
  client,
  authUser,
  classes,
  clientPlans,
  viewMode,
  changeSnapshot,
  trackElementClicked,
  featureToggles,
  updateClient,
  steps,
}: EIFPlanConfigAndEligibilityReviewProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [classToDelete, setClassToDelete] = useState<string>();
  const {
    mutateAsync: deleteClassOnServer,
    isPending: isDeleting,
    isError: isErrorDeleteClass,
  } = useDeleteClass();
  const duplicateEmployeeClassQuery = useDuplicateEmployeeClass();

  const { nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const navigate = useNavigate();
  const eifSubStepId = "class-builder";

  function trackClassDeleted(_classId: string) {
    trackElementClicked({
      module: `${getEIFSubStepMap({ eifSubStepId })} - Eligible employee group`,
      buttonLabel: "Delete",
      moduleState: "deleted",
    });
  }

  const singleClassBuilderMode = client.needsEmployeeClasses === "NO";
  const isBrokerOrBA = getIsBenAdmin(authUser) || getIsBroker(authUser);
  const isSelfServicePlanConfigOff = client.allowClientSelfServicePlanConfig === "NO";

  if (isBrokerOrBA && isSelfServicePlanConfigOff && !client.allowClientToViewPlanConfig) {
    return <EIFClassBuilderNoActionNeeded clientId={client.id} />;
  }

  const readonly = Boolean(
    client.eifSignedAt !== null || (isBrokerOrBA && client.allowClientToViewPlanConfig),
  );

  const isInternalUser = getIsInternalUser(authUser);
  const hideHeader = client.eifSignedAt !== null;

  const benefitsNotAssociatedToClassYet = getCoveragesInClientNotYetInAnyClass(
    client.allPoliciesSlfCoverages,
    classes,
    clientPlans,
  );

  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  const needsClassbuilder = getIsSubStepApplicable({
    eifSubStepId: "class-builder",
    client,
    plans: clientPlans,
  });
  const needsPFMLPreferences = getIsSubStepApplicable({
    eifSubStepId: "non-class-benefits-preferences",
    client,
    featureToggles,
  });
  const needsFLIPreferences = getIsSubStepApplicable({
    eifSubStepId: "fli-preferences",
    client,
  });

  return (
    <>
      {!hideHeader && <H2>Review this section</H2>}

      <StackY dist={32}>
        {!hideHeader && (
          <Body3 as="p">
            Please review the information you entered below and make sure there are no mistakes.
          </Body3>
        )}

        {needsClassbuilder && (
          <>
            {singleClassBuilderMode && (
              <Body3 as="p">
                Note: If you need to cover any <Body2>part-time employees</Body2>, or employees that
                <Body2> live or work outside of the United States</Body2>, you will need to create
                eligible employee groups.
              </Body3>
            )}
            {singleClassBuilderMode && (
              <MultipleClassInformationBox client={client} updateClient={updateClient} />
            )}

            {benefitsNotAssociatedToClassYet.length > 0 && !suppressErrorsPostSigning && (
              <AlertBanner
                message={
                  <Body3>
                    {benefitsNotAssociatedToClassYet.length}
                    {pluralize(" benefit", benefitsNotAssociatedToClassYet.length)}
                    {benefitsNotAssociatedToClassYet.length > 1 ? " aren't" : " isn't"} yet assigned
                    to a group
                  </Body3>
                }
                variant="warning"
              />
            )}

            {isSelfServicePlanConfigOff && isInternalUser && (
              <InternalControl
                position="attached"
                bodyText="Internal User:"
                buttonIcon={<GearSolid />}
                buttonLabel="Edit Settings"
                onClick={() => navigate(RouteData.clientDetail.getPath(client.id))}
              />
            )}

            <EIFEntityDeletedBy
              changeSnapshot={changeSnapshot}
              authUser={authUser}
              client={client}
              entity="EmployeeClass"
              prefixWord="Eligibility"
              pluralizeWord="Group"
            />

            {classes.map((employeeClass, index) => (
              <EIFClassBuilderItem
                key={employeeClass.id}
                index={index + 1}
                clientId={client.id}
                employeeClass={employeeClass}
                clientPlans={clientPlans}
                viewMode={viewMode}
                changeSnapshot={changeSnapshot}
                readonly={readonly}
                singleClassBuilderMode={singleClassBuilderMode}
                toggleableDetails={true}
                expanded={false}
                featureToggles={featureToggles}
                duplicateEmployeeClassQuery={duplicateEmployeeClassQuery}
                onDelete={() => {
                  setClassToDelete(employeeClass.id);
                  setShowDeleteModal(true);
                }}
                client={client}
                authUser={authUser}
                signerMode="inside"
              />
            ))}

            {steps === "class-builder-only" && (
              <AddEligibleGroupButton
                client={client}
                isInternalUser={isInternalUser}
                singleClassBuilderMode={singleClassBuilderMode}
                classes={classes}
              />
            )}
          </>
        )}

        {steps === "all" && (
          <>
            {needsPFMLPreferences && (
              <EIFNonClassBenefitsContributionsReview
                client={client}
                clientPlans={clientPlans}
                isReviewOnly={true}
                viewMode={viewMode}
                changeSnapshot={changeSnapshot}
                authUser={authUser}
                readonly={readonly}
                signerMode="inside"
                featureToggles={featureToggles}
              />
            )}

            {needsFLIPreferences && (
              <EIFFLIPreferencesReview
                client={client}
                readonly={readonly}
                signerMode="inside"
                viewMode={viewMode}
                authUser={authUser}
                changeSnapshot={changeSnapshot}
                featureToggles={featureToggles}
              />
            )}
          </>
        )}

        {steps === "class-builder-only" && (needsPFMLPreferences || needsFLIPreferences) ? (
          <Row justify="end">
            <Col>
              <Button to={nextSubStepLink} type="primary" size="large">
                Next
              </Button>
            </Col>
          </Row>
        ) : (
          <>
            <hr />

            <EIFStepBottomNavLinks eifStepId="plan-configuration-&-eligibility" />
          </>
        )}
      </StackY>

      <ConfirmDialog
        title={"Delete class"}
        isVisible={showDeleteModal}
        isLoading={isDeleting}
        confirmActionText={"Delete"}
        cancelActionText={"Cancel"}
        onConfirm={async () => {
          if (classToDelete) {
            await deleteClassOnServer({
              params: { clientId: client.id, employeeClassId: classToDelete },
            });
            trackClassDeleted(classToDelete);
            setShowDeleteModal(false);
            setClassToDelete(undefined);
          }
        }}
        onCancel={() => {
          setClassToDelete(undefined);
          setShowDeleteModal(false);
        }}
      >
        <Body1 as="p">Are you sure you want to delete this class?</Body1>

        {isErrorDeleteClass && <ErrorMessage>{GenericErrorCopy2}</ErrorMessage>}
      </ConfirmDialog>
    </>
  );
};

type AddEligibleGroupButtonProps = {
  client: Client;
  isInternalUser: boolean;
  singleClassBuilderMode: boolean;
  classes: EmployeeClass[];
};

export const AddEligibleGroupButton = ({
  client,
  isInternalUser,
  singleClassBuilderMode,
  classes,
}: AddEligibleGroupButtonProps) => {
  const to = RouteData.eifClassBuilderCreator.getPath(
    client.id,
    "plan-configuration-&-eligibility",
    "class-builder",
  );

  const addAnotherEligibleEmployeeGroupLinkComponent = (
    <Row justify="center">
      <Col>
        <Button to={to} type="text" size="middle">
          <span className={styles.addButton}>
            <PlusCircle /> Add {classes.length ? "another" : "an"} eligible employee group
          </span>
        </Button>
      </Col>
    </Row>
  );

  const showAddEligibleGroupButton = client.eifSignedAt === null && !singleClassBuilderMode;
  if (showAddEligibleGroupButton) {
    return addAnotherEligibleEmployeeGroupLinkComponent;
  }

  if (!singleClassBuilderMode && isInternalUser && !client.deifChangesReadyForReviewAt) {
    return (
      <InternalControl
        extraContent={addAnotherEligibleEmployeeGroupLinkComponent}
        hidePrimaryButton
      />
    );
  }

  return null;
};
