import { browserTracingIntegration, init, withScope } from "@sentry/react";
import { useEffect } from "react";

import { DSN } from "shared/data/Sentry";
import { isLocalDev, isTest } from "shared/utils/config";
import type { InjectedEnv } from "../../../shared/types/Env";
import type { User, setUser } from "@sentry/react";

export function initSentry(): void {
  if (isLocalDev || isTest) {
    console.log("Sentry is disabled in local development.");
    return;
  }

  init({
    dsn: DSN,
    tunnel: "/api/sentry",
    release: "none",
    environment: "undetermined",
    integrations: [browserTracingIntegration()],
    normalizeDepth: 6,
  });
}

export function useConfigureSentryScope(config: InjectedEnv | undefined) {
  useEffect(() => {
    if (config) {
      withScope((scope) => {
        scope.addEventProcessor(function (event) {
          event.release = config.COMMIT_HASH;
          event.environment = config.ENV;
          return event;
        });
      });
    }
  }, [config]);
}

let existingSentryUser: User | null = null;

export const setSentryUser: typeof setUser = (user) => {
  const isDifferent = JSON.stringify(existingSentryUser) !== JSON.stringify(user);
  if (isDifferent) {
    existingSentryUser = user;
    withScope((scope) => {
      scope.setUser(user);
    });
  }
};
