import type { ValueOf } from "shared/types/Helper";

// @AVIT0011072 - Sun Life Onboard - Arbitrary File Upload
const AllowedExtensions = [
  "bmp",
  "csv",
  "doc",
  "docm",
  "docx",
  "dotx",
  "eml",
  "gif",
  "jpeg",
  "jpg",
  "mov",
  "mp4",
  "msg",
  "numbers",
  "ods",
  "pdf",
  "png",
  "ppt",
  "pptx",
  "rtf",
  "svg",
  "tiff",
  "txt",
  "url",
  "webp",
  "xls",
  "xlsb",
  "xlsm",
  "xlsx",
  "xltm",
  "xltx",
  "zip",
] as const;
type AllowedExtension = ValueOf<typeof AllowedExtensions>;

function isAllowedExtension(extension: string): extension is AllowedExtension {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  const isAllowedExtension = AllowedExtensions.includes(extension as AllowedExtension);
  return isAllowedExtension;
}

const AllowedExtensionsAcceptAttributeValue = AllowedExtensions.map((ae) => `.${ae}`).join(",");

// @AVIT0011072 - Sun Life Onboard - Arbitrary File Upload
const AllowedMimeTypes = [
  "application/msword",
  "application/pdf",
  "application/rtf",
  "application/vnd.apple.numbers",
  "application/vnd.ms-excel",
  "application/vnd.ms-excel.sheet.binary.macroenabled.12",
  "application/vnd.ms-excel.sheet.macroenabled.12",
  "application/vnd.ms-outlook",
  "application/vnd.ms-powerpoint",
  "application/vnd.ms-word.document.macroenabled.12",
  "application/vnd.ms-word.template.macroenabled.12",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/x-zip-compressed",
  "application/zip",
  "application/zip-compressed",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "message/rfc822",
  "text/csv",
  "text/plain",
  "text/url",
  "video/mp4",
  "video/quicktime",
] as const;
type AllowedMimeType = ValueOf<typeof AllowedMimeTypes>;

function isAllowedMimeType(mimeType: string): mimeType is AllowedMimeType {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  const isAllowedMimeType = AllowedMimeTypes.includes(mimeType as AllowedMimeType);
  return isAllowedMimeType;
}

export type UploadFileInput = {
  name: string;
  mimeType?: string;
  sizeInBytes: number;
};

export type UploadValidationCode =
  | "NAME_TOO_LONG"
  | "TOO_LARGE"
  | "UNSUPPORTED_EXTENSION"
  | "UNSUPPORTED_MIME_TYPE";

export type UploadValidationResult = {
  isValid: boolean;
  code?: UploadValidationCode;
};

export const uploadUtils = {
  validate: (input: UploadFileInput, fileSizeLimitInMB: number): UploadValidationResult => {
    const { name, mimeType, sizeInBytes } = input;

    if (name.length > 256) {
      return {
        isValid: false,
        code: "NAME_TOO_LONG",
      };
    }

    const fileSizeInMB = Math.ceil(sizeInBytes / 1024 / 1024);
    if (fileSizeInMB > fileSizeLimitInMB) {
      return {
        isValid: false,
        code: "TOO_LARGE",
      };
    }

    const splitByPeriod = name.split(".");
    const extension = splitByPeriod.length > 1 ? splitByPeriod.pop()?.toLowerCase() : undefined;
    if (extension) {
      if (isAllowedExtension(extension)) {
        return {
          isValid: true,
        };
      } else {
        return {
          isValid: false,
          code: "UNSUPPORTED_EXTENSION",
        };
      }
    } else {
      // only check mime type on files without extension
      if (mimeType && isAllowedMimeType(mimeType)) {
        return {
          isValid: true,
        };
      } else {
        return {
          isValid: false,
          code: "UNSUPPORTED_MIME_TYPE",
        };
      }
    }
  },
  AllowedExtensionsAcceptAttributeValue,
};
