import clsx from "clsx";
import moment from "moment";
import { VIEW_TYPE } from "../../../../shared/config/routeData";
import { useGetPublicExplorerPageById } from "../../hooks/ExplorerPage/useExplorerPages";

import { useGetActiveRouteData } from "../../hooks/route";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { Body4, Body5, H5 } from "../Typography/Typography";

import * as styles from "./legalFooter.module.less";
import type { PublicExplorerPage } from "../../../../shared/types/ExplorerPage";

export function LegalFooter() {
  const activeRouteData = useGetActiveRouteData();
  const { data: explorerPage } = useGetPublicExplorerPageById(
    activeRouteData?.params.explorerPageId || "",
  );
  return <LegalFooterUI activeRouteData={activeRouteData} explorerPage={explorerPage} />;
}

type LegalFooterUIProps = {
  activeRouteData?: ReturnType<typeof useGetActiveRouteData>;
  explorerPage?: PublicExplorerPage;
};
export const LegalFooterUI = (props: LegalFooterUIProps) => {
  const { activeRouteData, explorerPage } = props;
  const isBenEx = activeRouteData?.viewType === VIEW_TYPE.explorer;

  const isPrint = useMediaQuery("print");
  return (
    <div className={styles.legalFooterContainer}>
      <div className={clsx(styles.content, "p-32")}>
        <H5 as="p" yellow={!isPrint}>
          Life’s brighter under the sun
        </H5>

        <div className={styles.flex}>
          <div className={styles.text}>
            {!isBenEx && (
              <Body5 as="p" white={!isPrint}>
                In all states, except New York, insurance products are underwritten by Sun Life
                Assurance Company of Canada (Wellesley Hills, MA) ("SLOC"). In certain states,
                prepaid dental products are provided by SLOC or by prepaid dental companies
                affiliated with SLOC. In New York, insurance products and prepaid dental products
                are underwritten or provided by Sun Life and Health Insurance Company (U.S.)
                (Lansing, MI).
              </Body5>
            )}

            {explorerPage?.hideSunLifeLogo ? (
              <Body5 as="p" white={!isPrint}>
                © {moment().format("YYYY")} Sun Life Assurance Company of Canada, Wellesley Hills,
                MA 02481. All rights reserved. Visit us at{" "}
                <a href="https://www.sunlife.com/us" target="_blank" rel="noreferrer noopener">
                  <Body5 white={!isPrint}>www.sunlife.com/us</Body5>
                </a>
                .
              </Body5>
            ) : (
              <Body5 as="p" white={!isPrint}>
                © {moment().format("YYYY")} Sun Life Assurance Company of Canada, Wellesley Hills,
                MA 02481. All rights reserved. The Sun Life name and logo are registered trademarks
                of Sun Life Assurance Company of Canada. Visit us at{" "}
                <a href="https://www.sunlife.com/us" target="_blank" rel="noreferrer noopener">
                  <Body5 white={!isPrint}>www.sunlife.com/us</Body5>
                </a>
                .
              </Body5>
            )}
          </div>

          {!isPrint && (
            <div className={styles.links}>
              <a
                href="https://www.sunlife.com/sl/pslf-united-states/en/legal/?vgnLocale=en_US"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Body4 white={!isPrint}>Legal</Body4>
                <span className="sr-only">(opens in a new tab)</span>
              </a>

              <a
                href="https://www.sunlife.com/sl/pslf-united-states/en/privacy/?vgnLocale=en_US"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Body4 white={!isPrint}>Privacy</Body4>
                <span className="sr-only">(opens in a new tab)</span>
              </a>

              <a
                href="https://www.sunlife.com/sl/pslf-united-states/en/security/?vgnLocale=en_US"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Body4 white={!isPrint}>Security</Body4>
                <span className="sr-only">(opens in a new tab)</span>
              </a>

              {isBenEx && (
                <a
                  href="https://www.sunlife.com/sl/pslf-united-states/en/fraud/?vgnLocale=en_US"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Body4 white={!isPrint}>Fraud</Body4>
                  <span className="sr-only">(opens in a new tab)</span>
                </a>
              )}
            </div>
          )}
        </div>
        {isBenEx && (
          <div className={styles.flex}>
            <div className={styles.text}>
              <Body5 as="p" white={!isPrint}>
                GGWC-EE-10777
              </Body5>
            </div>
            <Body5 as="p" white={!isPrint}>
              #1478905838
            </Body5>
          </div>
        )}
      </div>
    </div>
  );
};
