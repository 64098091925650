import { DownloadOutlined } from "@ant-design/icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { ConfirmDialog } from "client/src/components/ConfirmDialog/ConfirmDialog";
import { Row } from "client/src/components/Grid/Grid";
import { HorizontalDivider } from "client/src/components/HorizontalDivider/HorizontalDivider";
import { Body1, Body3, H3, H5 } from "client/src/components/Typography/Typography";
import { ExplorerPlanContent } from "client/src/domain/ExplorerPages/ClientPage/Benefit/ExplorerPlanContent";
import {
  useDownloadPlanSummary,
  useToggleFavoritePlan,
} from "client/src/domain/ExplorerPages/ClientPage/Benefit/useExplorerPlanState";
import { copyKey, copyKeySwitch } from "client/src/domain/ExplorerPages/utils";
import { useMediaQuery } from "client/src/hooks/useMediaQuery";
import { useEmployeeLocation } from "client/src/hooks/useSlobLocalStorage";
import { useTranslation } from "client/src/i18n";
import { timezoneAdjustedMoment } from "client/src/utils/moment";
import clsx from "clsx";
import moment from "moment";
import { isSunlifeCarrier, type ExplorerPageBenefit } from "shared/types/ExplorerPageBenefit";
import * as styles from "./benefit.module.less";
import type { ExplorerPlanStateProps } from "client/src/domain/ExplorerPages/ClientPage/Benefit/useExplorerPlanState";

export const DataTestId = {
  ExplorerPlan: "explorer-plan",
  FavoriteIcon: "favorite-icon",
};

interface Props extends ExplorerPlanStateProps {
  groupedPlans: ExplorerPageBenefit[];
}

export const ExplorerPlanGrouped = ({
  groupedPlans,
  kioskMode,
  plan,
  offering,
  vasUpdateFeatureToggle,
  confirmDeselection,
  customBranding = false,
  onPlanDeselected,
  trackElementClicked,
  language,
}: Props) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerPlan" });

  const isMobile = !useBreakpoint().md;
  const isPrint = useMediaQuery("print");
  const [location] = useEmployeeLocation();

  const { benefitType, planType } = plan;

  const planTypeKey = copyKeySwitch(t, benefitType, planType);

  const carrierKey = copyKeySwitch(
    t,
    benefitType,
    planTypeKey,
    isSunlifeCarrier(plan.carrierId) ? "sunLife" : "default",
  );
  const benefitLevelKey = copyKeySwitch(t, benefitType, planTypeKey, carrierKey, "grouped");
  const baseCopyKey = [benefitType, planTypeKey, carrierKey, benefitLevelKey];

  return (
    <>
      <Card
        className={clsx(isMobile ? "px-12" : "p-40", isMobile && "py-32", styles.plan, "mt-24")}
        data-testid={DataTestId.ExplorerPlan}
      >
        <Row gutter={[24, 8]}>
          <ExplorerPlanContent
            isMobile={isMobile}
            isPrint={isPrint}
            location={location}
            offering={offering}
            plan={plan}
            vasUpdateFeatureToggle={vasUpdateFeatureToggle}
            groupedVariant
          />
        </Row>

        <Row className="mt-48 mb-20">
          <H5>{t(copyKey(...baseCopyKey, "groupedSubHeading"))}</H5>
          <Body3>{t(copyKey(...baseCopyKey, "groupedContent"))}</Body3>
        </Row>

        {groupedPlans.map((p, i) => (
          <div className="pt-16" key={p.id}>
            <GroupedPlanItem
              confirmDeselection={confirmDeselection}
              onPlanDeselected={onPlanDeselected}
              plan={p}
              trackElementClicked={trackElementClicked}
              language={language}
              isPrint={isPrint}
              isMobile={isMobile}
              kioskMode={kioskMode}
              customBranding={customBranding}
            />
            {i < groupedPlans.length - 1 && <HorizontalDivider size={1} />}
          </div>
        ))}
      </Card>
    </>
  );
};

type GroupedPlanItemProps = Pick<
  ExplorerPlanStateProps,
  | "confirmDeselection"
  | "onPlanDeselected"
  | "trackElementClicked"
  | "plan"
  | "language"
  | "expiresAt"
  | "kioskMode"
> & { isPrint: boolean; isMobile: boolean; customBranding: boolean };

const GroupedPlanItem = (props: GroupedPlanItemProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerPlan" });
  const {
    confirmDeselection,
    onPlanDeselected,
    trackElementClicked,
    plan,
    isPrint,
    isMobile,
    language,
    expiresAt,
    kioskMode,
    customBranding,
  } = props;

  const {
    confirmingDelete,
    onCancelConfirm,
    onConfirmUnfavorite,
    isFavPlan,
    showAnimation,
    toggleFavoritePlan,
  } = useToggleFavoritePlan({
    confirmDeselection,
    onPlanDeselected,
    trackElementClicked,
    plan,
    kioskMode,
  });

  const { documentURL, spanishDocumentURL, onDownloadPlanSummary } = useDownloadPlanSummary({
    plan,
    trackElementClicked,
  });

  const isPageExpiresInFuture = expiresAt
    ? moment().isBefore(timezoneAdjustedMoment(moment(expiresAt)))
    : true;

  return (
    <>
      <ConfirmDialog
        title={t("unfavoriteTitle") ?? undefined}
        isVisible={confirmingDelete}
        onCancel={onCancelConfirm}
        onConfirm={onConfirmUnfavorite}
        confirmActionText={t("unfavoriteConfirmText") ?? undefined}
        cancelActionText={t("unfavoriteCancelText") ?? undefined}
      >
        {t("unfavoriteConfirmBody")}
      </ConfirmDialog>

      <Row justify="space-between">
        <Row>
          {isPageExpiresInFuture && (
            <div className="mr-20">
              <FontAwesomeIcon
                data-testid={DataTestId.FavoriteIcon}
                icon={isFavPlan ? faHeartSolid : faHeart}
                className={clsx(
                  styles.saveIcon,
                  isFavPlan && showAnimation && styles.saveIconBump,
                  customBranding && styles.saveIconNeutral,
                )}
                onClick={() => toggleFavoritePlan(plan.id)}
              />
            </div>
          )}
          <H3>{plan.planName}</H3>
        </Row>
        {documentURL && !isPrint && (
          <Body1>
            <ButtonOld
              href={
                language === "es" && spanishDocumentURL
                  ? spanishDocumentURL.objectUrl
                  : documentURL.objectUrl
              }
              size={isMobile ? "middle" : "large"}
              type="link"
              download={
                language === "es" && spanishDocumentURL
                  ? spanishDocumentURL.filename
                  : documentURL.filename
              }
              className={clsx("px-0", styles.downloadButton)} // @todo: FP-3498: move to Link component with normal text margin
              onClick={onDownloadPlanSummary}
            >
              <DownloadOutlined /> {t("downloadPDF")}
            </ButtonOld>
          </Body1>
        )}
      </Row>
    </>
  );
};
