import type { Segment, YesNoNA, YesNo } from "server/types/Ticket";
import type { KickoffCallStatus, NIGOReason } from "shared/types/Client";
import type { ValueOf } from "shared/types/Helper";
import type { LocationStateCode } from "shared/types/Location";
import type { SlfCoverageName, slfCoverageNameToLongName } from "shared/types/SlfCoverages";

export const DOMAIN_TO_JIRA_FIELDS = {
  ticketStatus: "status",
  caseId: "customfield_26967",
  onboardIndicator: "customfield_27728",
  testIndicator: "customfield_28184",
  benefitsExplorerIndicator: "customfield_28126",
  policyEffective: "customfield_27249",
  priorCarrierBill: "customfield_27721",
  enrollmentCensusDeadline: "customfield_27718",
  clientName: "summary",
  created: "created",
  updated: "updated",
  creator: "creator",
  kickoffCallStatus: "customfield_27414",
  priorCarrierCertificationsStatus: "customfield_27720",
  infoGatheringDeadline: "customfield_26992",
  NIGOReasons: "customfield_27419",
  baRegisteredSLConnect: "customfield_27478",
  sLConnectCredentialSentDate: "customfield_26986",
  slfImplementationConsultant: "customfield_26965",
  slfCoverages: "customfield_27411",
  slfPolicyNumber: "customfield_15101",
  employeesNumber: "customfield_11800",
  mvpBrokerTier: "customfield_26998",
  eoiRequired: "customfield_26978",
  ebrEmailAddress: "customfield_27245",
  creCssEmailAddress: "customfield_27247",
  creCssName: "customfield_27027",
  frontloading: "customfield_27409",
  territory: "customfield_26994",
  sunAdvisorSPDComplete: "customfield_28108",
  approvedPFMLPlans: "customfield_28106",
  spdCompletedForPFML: "customfield_28107",
  sunAdvisorAdvicePlusPreFundingAmount: "customfield_28105",
  massachusettsPFMLCheckCutting: "customfield_28102",
  connecticutPFMLCheckCutting: "customfield_28103",
  washingtonPFMLCheckCutting: "customfield_28104",
  coloradoPFMLCheckCutting: "customfield_29101",
  oregonPFMLCheckCutting: "customfield_29100",
  issuedStateSitus: "customfield_28055",
  issuelinks: "issuelinks",
  primaryTicket: "customfield_28125",
  issueType: "issuetype",
  project: "project",
  segment: "customfield_27062",
  digitalEIF: "customfield_28427",
  completedPaperworkReceivedOutsideOnboard: "customfield_28688",
  censusTaskHidden: "customfield_28712",
  benefitsExplorerCustomBenefitOrder: "customfield_28740",
  benefitsExplorerCustomBranding: "customfield_29509",
  nonSunLifeBenefitsExplorer: "customfield_32032",
  implementationType: "customfield_27401",
  healthcareProfessionalsSegment: "customfield_29057",
  inForceSLCoverages: "customfield_29102",
  vspUploadComplete: "customfield_27563",
  gcsSetupComplete: "customfield_28228",
  contractReferralComplete: "customfield_27101",
  memberInstallationComplete: "customfield_27993",
  claimsSetupCompletionDate: "customfield_27788",
  dentalRollover: "customfield_29193",
  dentalRolloverCompleted: "customfield_29380",
  readyForAuditDate: "customfield_28633",
  deductibleMaximumReportTask: "customfield_32044",
} as const;

export type JiraField = ValueOf<typeof DOMAIN_TO_JIRA_FIELDS>;
export const JIRA_FIELDS = Object.values(DOMAIN_TO_JIRA_FIELDS);
export const brokerTiers = {
  Gold: "GOLD",
  Silver: "SILVER",
  Platinum: "PLATINUM",
} as const;
export type JiraMvpBrokerTier = keyof typeof brokerTiers;
export type JiraMvpBrokerTierValues = keyof typeof brokerTiers;
export const brokerTierValues = Object.values(brokerTiers);
export const brokerTierKeys = Object.keys(brokerTiers);
export type BrokerTierValues = (typeof brokerTiers)[JiraMvpBrokerTier];

export const priorCarrierBill = {
  None: "NONE",
  "Not applicable": "NOT_APPLICABLE",
  Pending: "PENDING",
  Completed: "COMPLETED",
} as const;

export type JiraPriorCarrierBillValues = keyof typeof priorCarrierBill;
export const priorCarrierBillValues = Object.values(priorCarrierBill);
export type priorCarrierBillValue = typeof priorCarrierBillValues;

export interface JiraIssue {
  expand: string;
  id: string;
  self: string;
  key: string;
  changelog: JiraChangeLog;
  fields: JiraIssueFields;
}

type TimestampString = string;
type DateString = string;

export const hasValidFieldIds = (keys: string[]) => keys.some((key) => key in JIRA_FIELDS);

interface JiraIssueFields {
  status: {
    name: string;
  } | null;
  created: TimestampString;
  updated: TimestampString;
  creator: Assignee;
  customfield_26967: DateString | null;
  customfield_27728: JiraValue<YesNo>;
  customfield_28184: JiraValue<YesNo>;
  customfield_27249: DateString | null;
  customfield_27721: JiraValue<JiraPriorCarrierBillValues>;
  customfield_27718: TimestampString | null;
  summary: JiraString;
  customfield_26992: DateString | null;
  customfield_27414: JiraValue<KickoffCallStatus>;
  customfield_27720: JiraValue<JiraPriorCarrierBillValues>;
  customfield_27419: JiraList<NIGOReason>;
  customfield_27478: JiraValue<YesNo>;
  customfield_26986: TimestampString | null;
  customfield_26965: Assignee | null;
  customfield_27411: JiraList<SlfCoverageName>;
  customfield_15101: JiraString;
  customfield_11800: JiraNumber;
  customfield_26998: JiraValue<JiraMvpBrokerTier> | null;
  customfield_26978: JiraValue<YesNo>;
  customfield_27245: JiraString;
  customfield_27247: JiraString;
  customfield_27027: JiraString;
  customfield_27409: JiraValue<JiraFrontLoadingValues>;
  customfield_26994: JiraValue<string>;
  customfield_28106: JiraValue<YesNoNA>;
  customfield_28107: JiraValue<YesNoNA>;
  customfield_28125: JiraValue<YesNoNA>;
  customfield_28108: JiraValue<YesNoNA>;
  customfield_28105: JiraNumber;
  customfield_28055: JiraList<LocationStateCode>;
  customfield_28102: JiraNumber;
  customfield_28103: JiraNumber;
  customfield_28104: JiraNumber;
  customfield_29101: JiraNumber;
  customfield_29100: JiraNumber;
  customfield_28126: JiraValue<YesNo>;
  issuelinks: IssueLink[];
  issuetype: IssuetypeField | null;
  project: ProjectField | null;
  customfield_27062: JiraValue<Segment>;
  customfield_28427: JiraValue<YesNo>;
  customfield_28688: JiraValue<YesNo>;
  customfield_28712: JiraValue<YesNo>;
  customfield_28740: JiraValue<YesNo>;
  customfield_29509: JiraValue<YesNo>;
  customfield_32032: JiraValue<YesNo>;
  customfield_27401: JiraValue<ImplementationTypeValuesJira>;
  customfield_29057: JiraValue<YesNo>;
  customfield_29102: JiraList<SlfCoverageName | "N/A">;
  customfield_27563: DateString | null;
  customfield_28228: DateString | null;
  customfield_27101: DateString | null;
  customfield_27993: DateString | null;
  customfield_27788: DateString | null;
  customfield_29193: JiraValue<DentalRolloverValuesJira> | null;
  customfield_29380: JiraValue<YesNoNA>;
  customfield_28633: DateString | null;
  customfield_32044: JiraValue<YesNo> | null;
}

export interface Assignee {
  self: string;
  accountId?: string;
  emailAddress?: string;
  avatarUrls: AvatarUrls;
  displayName: string;
  active: boolean;
  timeZone?: string;
  accountType?: string;
}

export interface AvatarUrls {
  "48x48": string;
  "24x24": string;
  "16x16": string;
  "32x32": string;
}

interface JiraChangeLog {
  startAt: number;
  maxResults: number;
  total: number;
  histories: Array<History>;
}

export type History = {
  id: string;
  author: Assignee;
  created: string;
  items: Array<HistoryItem>;
};

export type HistoryItem = {
  field: string;
  fieldtype: "custom" | "jira";
  fieldId: keyof JiraIssueFields;
  from: null | string;
  fromString: null | string;
  to: string;
  toString: string;
};

export interface WebhookUser {
  self: string;
  accountId: string;
  avatarUrls: AvatarUrls;
  displayName: string;
  active: boolean;
  timeZone: string;
  accountType: "atlassian";
}

export interface WebhookChangeLog {
  id?: string;
  items?: {
    field?: string;
    fieldId?: string;
    fieldtype?: string;
    from?: string;
    fromString?: string;
    to?: string;
  }[];
}

export interface WebhookRequestBody {
  changelog: WebhookChangeLog;
  webhookEvent: "jira:issue_updated";
  issue_event_type_name: "issue_updated";
  issue: JiraIssue;
  timestamp: number;
  user: WebhookUser;
}
export type JiraValue<T> = { value: T } | null;
export type JiraList<T> = { value: T }[] | null;
export type JiraName = { name: string };
export type JiraNumber = number | null;
export type JiraString = string | null;

export const dentalRolloverValuesJira = ["Preventive Rewards", "RollMax", "None"] as const;
export type DentalRolloverValuesJira = ValueOf<typeof dentalRolloverValuesJira> | null;

export const implementationTypeValuesJira = [
  "New Issue",
  "Add Coverage",
  "Add Coverage with Re-Enrollment",
] as const;
export type ImplementationTypeValuesJira = ValueOf<typeof implementationTypeValuesJira> | null;

export const jiraFrontLoadingValues = [
  "Yes - Completed",
  "Yes - Requested",
  "Yes - Rejected",
  "No",
] as const;

export type JiraFrontLoadingValues = ValueOf<typeof jiraFrontLoadingValues>;
export type SlfCoverageLongNameJira = ValueOf<typeof slfCoverageNameToLongName> | "N/A";

export interface IssueType {
  id: string;
  key: string;
  self: string;
  fields: {
    summary: string;
    status: {
      name: string;
    };
    priority: {
      self: string;
      iconUrl: string;
      name: string;
      id: string;
    };
    issuetype: IssuetypeField;
  };
}
export interface IssueLink {
  id: string;
  self: string;
  type: {
    id: string;
    name: string;
    inward: string;
    outward: string;
    self: string;
  };
  outwardIssue?: IssueType;
  inwardIssue?: IssueType;
}

export interface IssuetypeField {
  self: string;
  id: string;
  description: string;
  iconUrl: string;
  name: string;
  subtask: boolean;
  avatarId: number;
  hierarchyLevel: number;
}

export interface ProjectField {
  self: string;
  id: string;
  key: string;
  name: string;
  projectTypeKey: string;
  simplified: boolean;
  avatarUrls: AvatarUrls;
}
