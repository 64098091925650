import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import {
  getDocumentsListByBenefitType,
  getDocumentsListFromAssociatedPages,
} from "client/src/domain/ExplorerPages/utils";
import {
  useCreateExplorerPage,
  useDeleteExplorerPage,
  useGetExplorerPagesDocumentsByBenefits,
  useUpdateExplorerPage,
} from "client/src/hooks/ExplorerPage/useExplorerPages";
import { useSlobAuth } from "client/src/hooks/auth";
import { useGetDocuments } from "client/src/hooks/document";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useGetAllSlfUsers } from "client/src/hooks/user";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { RouteData } from "shared/config/routeData";
import { sortBenAdminPlatforms } from "shared/types/BenAdminPlatform";
import { BenefitTypesBenEx, isBenefitTypeBenEx } from "shared/types/BenefitTypes";
import { sortBenefitInputs } from "shared/types/ExplorerPageBenefit";
import { getPrimaryPolicy } from "shared/utils/client";
import { explorerPageInputValidation } from "shared/validation/explorerPage";

import type { ExplorerPageFormProps } from "./ExplorerPageForm";
import type { FormikTypeFromValidator } from "client/src/hooks/useSlobFormik";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ExplorerBenefitTypeMetadata, ExplorerPage } from "shared/types/ExplorerPage";
import type { Pretty, ValuesForValidationSchema } from "shared/types/Helper";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { explorerPageBenefitInputValidation } from "shared/validation/explorerPageBenefit";
import type { InferType } from "yup";

export const useExplorerPageFormState = ({
  explorerPage,
  onDelete: onDeleted,
  onCancel,
  carriers,
  benAdminPlatforms,
  initialBenefits,
  client,
  isRenewal,
  featureToggles,
  disabled = false,
  slfPolicies,
}: ExplorerPageFormProps) => {
  const updatingPage = !!explorerPage;
  const navigate = useNavigate();

  //load data
  const { data: planSummaryDocuments, isLoading: isLoadingBenefitSummaries } = useGetDocuments({
    clientId: client.id,
    categories: ["explorer-benefit-summaries"],
    language: "EN",
  });
  const { data: spanishPlanSummaryDocuments, isLoading: isLoadingSpanishBenefitSummaries } =
    useGetDocuments({
      clientId: client.id,
      categories: ["explorer-benefit-summaries"],
      language: "ES",
    });

  const benefitTypes: BenefitTypeBenEx[] = Object.values(BenefitTypesBenEx);

  const { data: explorerPageDocumentsByBenefits, isFetching: isLoadingDocumentsByBenefits } =
    useGetExplorerPagesDocumentsByBenefits({
      clientId: client.id,
      associationId: explorerPage?.associationId,
      benefitTypes,
    });

  const allEnglishDocumentsByBenefitType = getDocumentsListFromAssociatedPages(
    explorerPageDocumentsByBenefits ?? [],
    "EN",
  );
  const planSummaryDocumentsByBenefitType = getDocumentsListByBenefitType(
    allEnglishDocumentsByBenefitType,
  );

  const allSpanishDocumentsByBenefitType = getDocumentsListFromAssociatedPages(
    explorerPageDocumentsByBenefits ?? [],
    "ES",
  );
  const spanishPlanSummaryDocumentsByBenefitType = getDocumentsListByBenefitType(
    allSpanishDocumentsByBenefitType,
  );

  //mutations
  const { mutateAsync: createBenefitExplorerPage } = useCreateExplorerPage();
  const { mutateAsync: updateBenefitExplorerPage } = useUpdateExplorerPage();
  const { mutateAsync: deleteExplorerPage } = useDeleteExplorerPage();

  const internals = useGetAllSlfUsers();
  const isLoadingUsers = internals.isLoading;
  const slobUsers = internals.data ?? [];

  const { authUser } = useSlobAuth();

  //UI states
  const [showBenefitModal, setShowBenefitModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [editingBenefitIndex, setEditingBenefitIndex] = useState<number | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showSpanishHighlights, setShowSpanishHighlights] = useSpanishHighlightToggle(
    explorerPage,
    featureToggles,
  );

  const primaryPolicy = getPrimaryPolicy(client);

  const hideSunLifeLogo = client.benefitsExplorerCustomBranding
    ? true
    : explorerPage?.hideSunLifeLogo ?? false;
  // Default "Apply custom branding..." to true if Jira field is "Yes" and user is not updating a page
  const customBranding = explorerPage?.customBranding ?? client.benefitsExplorerCustomBranding;

  const initialValues: ValuesForValidationSchema<typeof explorerPageInputValidation> = useMemo(
    () => ({
      name: explorerPage ? (isRenewal ? "" : explorerPage.name) : "",
      kioskMode: explorerPage ? explorerPage.kioskMode : false,
      openEnrollmentEnd: explorerPage
        ? !isRenewal
          ? explorerPage.openEnrollmentEnd ?? null
          : client.enrollmentDateEnd ?? null
        : client.enrollmentDateEnd ?? null,
      openEnrollmentStart: explorerPage
        ? !isRenewal
          ? explorerPage.openEnrollmentStart ?? null
          : client.enrollmentDateStart ?? null
        : client.enrollmentDateStart ?? null,
      startsAt: explorerPage ? (!isRenewal ? explorerPage.startsAt ?? null : null) : null,
      expiresAt: explorerPage ? (!isRenewal ? explorerPage.expiresAt ?? null : null) : null,
      planYear: explorerPage ? (!isRenewal ? explorerPage.planYear ?? null : null) : null,
      benefits: explorerPage
        ? isRenewal
          ? explorerPage.benefits?.map((benefit) => ({
              benefitLevel: benefit.benefitLevel ?? null,
              benefitType: benefit.benefitType,
              carrierId: benefit.carrierId ?? 0,
              contentFlags: benefit.contentFlags ?? null,
              id: "",
              isNew: benefit.isNew ?? false,
              legalEntity: benefit.legalEntity ?? null,
              outOfPocketCosts: benefit.outOfPocketCosts ?? null,
              planName: benefit.planName,
              planType: benefit.planType ?? null,
              premium: benefit.premium ?? null,
              prepaidDentalNetwork: benefit.prepaidDentalNetwork ?? null,
              prepaidSitusState: benefit.prepaidSitusState ?? null,
              spanishPlanSummaryDocumentId: benefit.spanishPlanSummaryDocumentId ?? null,
              planSummaryDocumentId: benefit.planSummaryDocumentId ?? null,
              wellnessAmount: benefit.wellnessAmount ?? null,
              linkedHDHPPlanName: benefit.linkedHDHPPlanName ?? null,
              planYearDeductibleOutOfNetworkNA: benefit.planYearDeductibleOutOfNetworkNA ?? false,
              planYearMaximumOutOfNetworkNA: benefit.planYearMaximumOutOfNetworkNA ?? false,
            }))
          : explorerPage.benefits?.map((benefit) => ({
              ...benefit,
              carrierId: benefit.carrierId ?? 0,
            }))
        : initialBenefits && [...initialBenefits],
      maintenanceMode: explorerPage ? explorerPage.maintenanceMode : false,
      benefitTypeMetadata: explorerPage ? { ...explorerPage.benefitTypeMetadata } : null,
      employerState: explorerPage ? explorerPage.employerState : "",
      benAdminPlatformId: explorerPage
        ? explorerPage?.benAdminPlatformId
        : primaryPolicy.benAdminPlatformId,
      benAdminPlatformUrl: explorerPage ? explorerPage.benAdminPlatformUrl : null,
      notificationLink: explorerPage ? explorerPage.notificationLink : null,
      customCalendlyLink: explorerPage ? explorerPage.customCalendlyLink : null,
      customClientName: explorerPage ? explorerPage.customClientName : null,
      logoDocumentId: explorerPage ? explorerPage.logoDocumentId : null,
      employeeCount: explorerPage ? explorerPage.employeeCount : client.employeesNumber,
      hasEmployeesInNY: explorerPage ? explorerPage.hasEmployeesInNY : false,
      showBenefitCount: explorerPage ? explorerPage.showBenefitCount : true,
      associationId: explorerPage ? explorerPage.associationId : undefined,
      hideSunLifeLogo,
      pageOwner: explorerPage ? explorerPage.pageOwner : authUser?.id ?? "",
      policies: explorerPage ? explorerPage.policies : slfPolicies ?? [],
      medicalVideo: explorerPage
        ? explorerPage.medicalVideoDocumentId
          ? "ENGLISH_VIDEO"
          : "NO_VIDEO"
        : "NO_VIDEO",
      medicalVideoEnglishTitle: explorerPage ? explorerPage.medicalVideoEnglishTitle : null,
      medicalVideoSpanishTitle: explorerPage ? explorerPage.medicalVideoSpanishTitle : null,
      medicalVideoDocumentId: explorerPage ? explorerPage.medicalVideoDocumentId : null,
      vidyardUploadId: explorerPage ? explorerPage.vidyardUploadId : null,
      vidyardId: explorerPage ? explorerPage.vidyardId : null,
      territory: explorerPage ? explorerPage.territory : null,
      showLegacyVisionPlanTable: explorerPage ? explorerPage.showLegacyVisionPlanTable : false,
      showLegacyDentalPlanTable: explorerPage ? explorerPage.showLegacyDentalPlanTable : false,
      vanitySlug: explorerPage ? explorerPage.vanitySlug : null,
      customBranding,
      customThemeBrandColor: explorerPage ? explorerPage.customTheme?.brandColor ?? null : null,
      customThemeContrastingColor: explorerPage
        ? explorerPage.customTheme?.contrastingColor ?? null
        : null,
    }),
    [
      explorerPage,
      isRenewal,
      client.enrollmentDateEnd,
      client.enrollmentDateStart,
      client.employeesNumber,
      initialBenefits,
      primaryPolicy.benAdminPlatformId,
      hideSunLifeLogo,
      authUser?.id,
      slfPolicies,
      customBranding,
    ],
  );

  const formik = useSlobFormik({
    enableReinitialize: !!explorerPage && !isRenewal,
    initialValues,
    validationSchema: explorerPageInputValidation,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (values.benAdminPlatformId) {
        const noPlatForm =
          benAdminPlatforms.find((p) => p.id === values.benAdminPlatformId)?.name ===
          "I don’t use a platform";
        if (!noPlatForm && !values.benAdminPlatformUrl) {
          formik.setFieldError("benAdminPlatformUrl", "Error");
          return;
        }
      }

      //clear Spanish highlights is needed
      if (!showSpanishHighlights && values.benefitTypeMetadata) {
        Object.keys(values.benefitTypeMetadata).forEach((key) => {
          if (isBenefitTypeBenEx(key)) {
            const meta = values.benefitTypeMetadata?.[key];
            if (meta) meta.spanishHighlighterText = undefined;
          }
        });
      }

      setIsSaving(true);

      try {
        const { isError, isSuccess, data } =
          updatingPage && !isRenewal
            ? await updateBenefitExplorerPage({
                data: values,
                params: {
                  explorerPageId: explorerPage.id,
                  clientId: client.id,
                },
              })
            : await createBenefitExplorerPage({
                data: values,
                params: {
                  clientId: client.id,
                },
              });

        if (isError) {
          throw new Error(`Failed to ${updatingPage && !isRenewal ? "update" : "create"} page`);
        } else if (isSuccess) {
          navigate(RouteData.clientDetail.getPath(client.id), {
            state: { newExplorerPage: !updatingPage ? data : undefined },
          });
        }
      } finally {
        setIsSaving(false);
      }
    },
  });

  useHighlightOrder(formik, true);

  const availableHighlights =
    formik.values.benefits
      ?.map((bene) => bene.benefitType)
      .filter((elem, i, array) => {
        return i === array.indexOf(elem);
      })
      .sort((a, b) => {
        const aOrder = formik.values.benefitTypeMetadata?.[a]?.highlighterOrder;
        const bOrder = formik.values.benefitTypeMetadata?.[b]?.highlighterOrder;

        if (aOrder === bOrder) return 0;
        if (!aOrder) return 1;
        if (!bOrder) return -1;
        return aOrder < bOrder ? -1 : 1;
      }) ?? [];

  const useExternalFormsLink =
    benAdminPlatforms.find((p) => p.id === formik.values.benAdminPlatformId)?.name ===
    "I don’t use a platform";

  const spanishHighlightText = hasSpanishHighlightText({ ...formik.values.benefitTypeMetadata });

  //form actions
  const onDeletePageConfirm = useCallback(async () => {
    if (!explorerPage?.id || !client.id) {
      return;
    }

    setIsDeleting(true);

    const { isError, isSuccess } = await deleteExplorerPage({
      params: { clientId: client.id, explorerPageId: explorerPage.id },
    });

    setShowDeleteConfirmation(false);
    setIsDeleting(false);

    if (isError) {
      throw new Error("Failed to delete page");
    } else if (isSuccess) {
      void slobMessage.success("Successfully deleted explorer page!");
      if (onDeleted) onDeleted();
    }
  }, [explorerPage?.id, client.id, deleteExplorerPage, onDeleted]);

  const onEditBenefit = useCallback(
    (index: number) => {
      if (!formik.values.benefits || index >= formik.values.benefits.length) return;
      setEditingBenefitIndex(index);
      setShowBenefitModal(true);
    },
    [formik.values.benefits],
  );

  //benefit actions
  const onSaveBenefit = useCallback(
    async (benefit: Pretty<InferType<typeof explorerPageBenefitInputValidation>>) => {
      const newBenefitsArray = (formik.values.benefits && [...formik.values.benefits]) || [];

      if (editingBenefitIndex !== null) {
        newBenefitsArray[editingBenefitIndex] = benefit;
        setEditingBenefitIndex(null);
      } else {
        newBenefitsArray.push(benefit);
      }
      setShowBenefitModal(false);
      await formik.setValues({
        ...formik.values,
        benefits: sortBenefitInputs(newBenefitsArray, client.benefitsExplorerCustomBenefitOrder),
      });

      void formik.validateForm();

      await buildHighlightOrder(formik);
    },
    [client.benefitsExplorerCustomBenefitOrder, editingBenefitIndex, formik],
  );

  const onDeleteBenefit = useCallback(async () => {
    if (editingBenefitIndex !== null) {
      if (!formik.values.benefits) return;
      const newBenefitsArray = (formik.values.benefits && [...formik.values.benefits]) || [];
      const [deletedBene] = newBenefitsArray.splice(editingBenefitIndex, 1);

      //remove highlight if needed
      if (
        deletedBene &&
        formik.values.benefitTypeMetadata &&
        !newBenefitsArray.find((bene) => bene.benefitType === deletedBene.benefitType)
      ) {
        formik.values.benefitTypeMetadata[deletedBene.benefitType] = undefined;
      }

      void formik.setValues({ ...formik.values, benefits: newBenefitsArray });
      await buildHighlightOrder(formik);
      setEditingBenefitIndex(null);
    }
    setShowBenefitModal(false);
  }, [editingBenefitIndex, formik]);

  const onCancelBenefit = useCallback(() => {
    setEditingBenefitIndex(null);
    setShowBenefitModal(false);
  }, []);

  return {
    formik,
    data: {
      explorerPageId: isRenewal ? undefined : explorerPage?.id,
      explorerPageName: explorerPage?.name,
      explorerPageVanitySlug: explorerPage?.vanitySlug,
      client,
      benAdminPlatforms: sortBenAdminPlatforms(benAdminPlatforms),
      carriers,
      planSummaryDocuments,
      spanishPlanSummaryDocuments,
      featureToggles,
      isRenewal,
      slobUsers,
      planSummaryDocumentsByBenefitType,
      spanishPlanSummaryDocumentsByBenefitType,
      pageCreationDate: explorerPage?.createdAt,
    },
    state: {
      isLoading:
        isLoadingBenefitSummaries ||
        isLoadingSpanishBenefitSummaries ||
        isLoadingUsers ||
        isLoadingDocumentsByBenefits,
      updatingPage,
      useExternalFormsLink,
      showBenefitModal,
      isSaving,
      isDeleting,
      showSpanishHighlights,
      hasSpanishHighlightText: spanishHighlightText,
      editingBenefitIndex,
      showDeleteConfirmation,
      availableHighlights,
      disabled,
    },
    formActions: {
      onAddBenefit: () => setShowBenefitModal(true),
      onEditBenefit,
      setShowSpanishHighlights,
      onCancel: () => onCancel?.(),
      onDelete: () => setShowDeleteConfirmation(true),
      onDeletePageConfirm,
      onDeleteCancel: () => setShowDeleteConfirmation(false),
      onHighlightOrderChange: () => buildHighlightOrder(formik),
    },
    benefitActions: {
      onSaveBenefit,
      onDeleteBenefit,
      onCancelBenefit,
    },
  };
};

const useHighlightOrder = (
  formik: FormikTypeFromValidator<typeof explorerPageInputValidation>,
  enabled: boolean,
) => {
  useEffect(() => {
    if (enabled) {
      void buildHighlightOrder(formik);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- intentional, we only want this to run once
  }, [enabled]);
};

const buildHighlightOrder = async (
  formik: FormikTypeFromValidator<typeof explorerPageInputValidation>,
) => {
  formik.values.benefits
    ?.map((bene) => bene.benefitType) //get list of selected benefits
    .filter((elem, i, array) => {
      return i === array.indexOf(elem);
    })
    .map((bene) => ({
      //map existing order to benefit type
      benefitType: bene,
      index: formik.values.benefitTypeMetadata?.[bene]?.highlighterOrder,
    }))
    .sort((a, b) => {
      //order by existing order
      if (a.index === b.index) return 0;
      if (!a.index) return 1;
      if (!b.index) return -1;
      return a.index < b.index ? -1 : 1;
    })
    .map<{ benefitType: BenefitTypeBenEx; index: number }>(({ benefitType }, i) => ({
      // overwrite order to account for movement, deletions, and additions
      benefitType,
      index: i + 1,
    }))
    .forEach((map) => {
      //update metadata
      if (!formik.values.benefitTypeMetadata) formik.values.benefitTypeMetadata = {};
      formik.values.benefitTypeMetadata[map.benefitType] = {
        ...formik.values.benefitTypeMetadata[map.benefitType],
        highlighterOrder: map.index,
      };
    });

  await formik.setFieldValue("benefitTypeMetadata", formik.values.benefitTypeMetadata);
};

const useSpanishHighlightToggle = (
  explorerPage: ExplorerPage | undefined,
  featureToggles: ClientFeatureToggles | undefined,
) => {
  const state = useState(false);

  const setShowSpanishHiglights = state[1];

  useEffect(() => {
    if (explorerPage?.benefitTypeMetadata && featureToggles?.BENEFIT_EXPLORER_SPANISH) {
      setShowSpanishHiglights(hasSpanishHighlightText(explorerPage.benefitTypeMetadata));
    }
  }, [explorerPage, featureToggles?.BENEFIT_EXPLORER_SPANISH, setShowSpanishHiglights]);

  return state;
};

const hasSpanishHighlightText = (benefitTypeMetadata: ExplorerBenefitTypeMetadata) =>
  Object.keys(benefitTypeMetadata).reduce((foundSpanishHighlight, key) => {
    //should always be true
    if (isBenefitTypeBenEx(key)) {
      return foundSpanishHighlight || Boolean(benefitTypeMetadata?.[key]?.spanishHighlighterText);
    }
    return false;
  }, false);
