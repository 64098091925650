import {
  type AEPTakeEffectOptions,
  type AdministerAEPOptions,
  type AgeForDependentLifeBenefits,
  type BenefitPayableTo,
  type BillingAdministrationType,
  type BillingSummaryStatementType,
  type ChangesToBenefitsTakeEffectOptions,
  type CoverageChangesEffective,
  type CoverageChangesEffectiveAgeRules,
  type DecreaseInsuranceTakeEffectOptions,
  type ERISAPlanNumber,
  type ERISAPlanType,
  type EmployeeSpouse,
  type RehireProvision,
} from "@prisma/client";
import isString from "lodash/isString";
import { getIsStatutoryBenefitType } from "shared/types/BenefitTypes";
import {
  monthsOfTheYear,
  type MonthOfTheYear,
  type YesNoNotSure,
  type YesNo,
  rehireProvisions,
  ageForDependentLifeBenefitsOptions,
  coverageChangesEffectiveAgeRules,
  coverageChangesEffective,
  valueAddedServicesOptions,
  valueAddedServicesOptionsVT,
  billPayrollCycles,
  billingSummaryStatementTypes,
  ageRules,
} from "shared/types/Client";
import { disabilityStateCodes } from "shared/types/Location";
import {
  coveragesNotIncludedInSection125Plan,
  coverageToBenefitType,
  slfCoverageLongNameToShortName,
} from "shared/types/SlfCoverages";
import { getAnnualEnrollmentRequired } from "shared/utils/EIF/getAnnualEnrollmentRequired";
import { shouldShowValueAddedServicesLifeCoverage } from "shared/utils/EIF/getIsSubStepApplicable";
import { assertExistsInObject, assertIsDefined, sanitizeTypedArray } from "shared/utils/utils";
import { billDetailsSpec } from "shared/validation/bill";
import {
  contactInputValidationSchema,
  firstLastEmailUndefinedSchema,
} from "shared/validation/contact";
import {
  locationStateCodeValidation,
  zipCodeErrorMessage,
  zipCodeRegex,
} from "shared/validation/location";
import { monthDaySchema } from "shared/validation/monthDaySchema";
import {
  getOneOfValidation,
  nullNotAllowedTestConfig,
  yesNoNotSureNullablePrefill,
  yesNoNotSureNullableRequired,
  yesNoNullable,
  yesNoNullablePrefill,
  yesNoNullableRequired,
} from "shared/validation/validation";
import * as Yup from "yup";
import type { IssueOrAttainedAge, Tabacco } from "@prisma/client";
import type { BenAdminPlatform } from "shared/types/BenAdminPlatform";
import type {
  AgeRules,
  BillPayrollCycle,
  Client,
  ValueAddedServicesOptions,
  ValueAddedServicesOptionsVT,
} from "shared/types/Client";
import type { DisabilityStateCode } from "shared/types/Location";
import type {
  CoverageNotIncludedInSection125Plan,
  SlfCoverageLongName,
} from "shared/types/SlfCoverages";
import type { BenAdmin, Broker } from "shared/types/User";

export const dataFeedsContactValidationSchema = contactInputValidationSchema
  .pick(["id", "policyId", "firstName", "lastName", "email", "type"])
  .notRequired()
  .default(undefined)
  .when(["dataFeeds", "$prefill"], {
    is: (dataFeeds: YesNoNotSure, prefill: boolean) => dataFeeds === "YES" && !prefill,
    then: (schema) => schema.required(),
    otherwise: (_) =>
      contactInputValidationSchema
        .pick(["id", "policyId", "firstName", "lastName", "email", "type"])
        .shape({
          firstName: firstLastEmailUndefinedSchema,
          lastName: firstLastEmailUndefinedSchema,
          email: firstLastEmailUndefinedSchema,
        })
        .nullable(),
  })
  .when(["dataFeeds"], {
    is: (dataFeeds: YesNoNotSure | undefined) => dataFeeds !== "YES",
    then: () =>
      Yup.mixed()
        .nullable()
        .transform(() => null),
  });

const taxIdMatchesArguments = [
  /^\d{2}-\d{7}$/,
  "Tax ID needs to be in the format ##-#######",
] as const;

export const taxIdSchema = Yup.string().matches(...taxIdMatchesArguments);

const administerAEPOptionsSchema = Yup.mixed<AdministerAEPOptions>()
  .oneOf<AdministerAEPOptions>(
    ["CALENDAR_MONTH_PRIOR_POLICY_ANNIVERSARY", "CUSTOM"],
    "Please provide a response",
  )
  .nullable()
  .when("$prefill", {
    is: (prefill: boolean | undefined) => !!prefill,
    then: (schema) => schema,
    otherwise: (schema) =>
      schema.test({
        name: "changes-during-annual-enrollment-validation-whenDoYouAdministerYourAEP",
        message: "Please provide a response",
        test: (value, context) => {
          const client: Client = context.parent;

          if (value === undefined) {
            return true;
          }

          const annualEnrollmentRequired = getAnnualEnrollmentRequired(client);
          if (annualEnrollmentRequired || client.changesOnlyAllowedDuringAE === "YES") {
            return value === "CUSTOM" || value === "CALENDAR_MONTH_PRIOR_POLICY_ANNIVERSARY";
          }

          return true;
        },
      }),
  });

const aepTakeEffectOptionsSchema = Yup.mixed<AEPTakeEffectOptions>()
  .oneOf<AEPTakeEffectOptions>(["ANNUALLY", "OTHER"], "Please provide a response")
  .nullable()
  .when("$prefill", {
    is: (prefill: boolean | undefined) => !!prefill,
    then: (schema) => schema,
    otherwise: (schema) =>
      schema.test({
        name: "changes-during-annual-enrollment-validation-whenDoAEPTakeEffect",
        message: "Please provide a response",
        test: (value, context) => {
          const client: Client = context.parent;
          if (value === undefined) {
            return true;
          }

          const annualEnrollmentRequired = getAnnualEnrollmentRequired(client);
          if (annualEnrollmentRequired || client.changesOnlyAllowedDuringAE === "YES") {
            return value === "ANNUALLY" || value === "OTHER";
          }

          return true;
        },
      }),
  });

const decreaseInsuranceTakeEffectOptionsSchema = Yup.mixed<DecreaseInsuranceTakeEffectOptions>()
  .oneOf<DecreaseInsuranceTakeEffectOptions>(
    ["AT_ANNUAL_ENROLLMENT", "IMMEDIATELY"],
    "Please provide a response",
  )
  .nullable()
  .when("$prefill", {
    is: (prefill: boolean | undefined) => !!prefill,
    then: (schema) => schema,
    otherwise: (schema) =>
      schema.test({
        name: "changes-during-annual-enrollment-validation-whenDecreasesInsuranceTakeEffect",
        message: "Please provide a response",
        test: (value, context) => {
          const client: Client = context.parent;
          if (value === undefined) {
            return true;
          }

          const annualEnrollmentRequired = getAnnualEnrollmentRequired(client);
          if (annualEnrollmentRequired || client.changesOnlyAllowedDuringAE === "YES") {
            return value === "IMMEDIATELY" || value === "AT_ANNUAL_ENROLLMENT";
          }

          return true;
        },
      }),
  });

const changesOnlyAllowedDuringAESchema = Yup.mixed<YesNo>()
  .oneOf(["YES", "NO"])
  .nullable()
  .when("$prefill", {
    is: (prefill: boolean | undefined) => !!prefill,
    then: (schema) => schema,
    otherwise: (schema) =>
      schema.test({
        name: "changes-during-annual-enrollment-validation-changesOnlyAllowedDuringAE",
        message: "Please provide a response",
        test: (value, context) => {
          const client: Client = context.parent;

          if (value === undefined) {
            return true;
          }

          const annualEnrollmentRequired = getAnnualEnrollmentRequired(client);
          if (annualEnrollmentRequired) {
            return true;
          }

          const isValid = value === "YES" || value === "NO";
          return isValid;
        },
      }),
  });

const whenDoChangesToBenefitsTakeEffectSchema = Yup.mixed<ChangesToBenefitsTakeEffectOptions>()
  .oneOf<ChangesToBenefitsTakeEffectOptions>(
    ["IMMEDIATELY", "FOFM", "FOFM_INCLUSIVE"],
    "Please provide a response",
  )
  .nullable()
  .when("changesOnlyAllowedDuringAE", {
    is: "NO",
    then: (schema) => schema.required("Please provide a response"),
  })
  .when("$prefill", {
    is: (prefill: boolean | undefined) => !!prefill,
    then: (schema) => schema.notRequired(),
  });

const slfCoveragesTestConfig: Yup.TestConfig<boolean | undefined> = {
  name: "allPoliciesSlfCoverages required",
  message: "Missing SLF Coverages",
  exclusive: true,
  test: (_value, context) => {
    const parent: Pick<Client, "hasSection125Plan" | "allPoliciesSlfCoverages"> = context.parent;
    const { hasSection125Plan, allPoliciesSlfCoverages } = parent;
    return (
      hasSection125Plan === "NO" || hasSection125Plan == null || Boolean(allPoliciesSlfCoverages)
    );
  },
};

const section125TestFunction: Yup.TestFunction<boolean | undefined, Yup.AnyObject> = (
  _value,
  context,
) => {
  const parent: Pick<
    Client,
    | "hasSection125Plan"
    | "hasSection125Dental"
    | "hasSection125Vision"
    | "hasSection125DHMO"
    | "allPoliciesSlfCoverages"
  > = context.parent;
  const {
    hasSection125Plan,
    hasSection125Dental,
    hasSection125Vision,
    hasSection125DHMO,
    allPoliciesSlfCoverages,
  } = parent;

  const hasDentalCoverage = allPoliciesSlfCoverages?.includes("Dental") ?? false;
  const hasVisionCoverage = allPoliciesSlfCoverages?.includes("Vision") ?? false;
  const hasDHMOCoverage = allPoliciesSlfCoverages?.includes("Dental (DHMO)") ?? false;

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  const path = context.path as "hasSection125Dental" | "hasSection125Vision" | "hasSection125DHMO";
  if (path === "hasSection125Dental" && !hasDentalCoverage) {
    return true;
  }
  if (path === "hasSection125Vision" && !hasVisionCoverage) {
    return true;
  }
  if (path === "hasSection125DHMO" && !hasDHMOCoverage) {
    return true;
  }

  if (hasSection125Plan === "YES") {
    return (hasSection125Dental || hasSection125Vision || hasSection125DHMO) ?? false;
  }

  return true;
};

const erisaPlanDetailsNotAdministerOwnPlanStringRequired = Yup.string()
  .nullable()
  .when(["erisaHasPlan", "erisaPrefersPlanDetails", "erisaAdministerOwnPlan", "$prefill"], {
    is: (
      erisaHasPlan: YesNo,
      erisaPrefersPlanDetails: YesNo,
      erisaAdministerOwnPlan: YesNo,
      prefill: boolean,
    ) => {
      return (
        erisaHasPlan === "YES" &&
        erisaPrefersPlanDetails === "YES" &&
        erisaAdministerOwnPlan === "NO" &&
        !prefill
      );
    },
    then: (schema) => schema.required("Please provide a response"),
  });

const erisaPlanDetailsNotAdministerOwnPlanLocationRequired = locationStateCodeValidation
  .nullable()
  .when(["erisaHasPlan", "erisaPrefersPlanDetails", "erisaAdministerOwnPlan", "$prefill"], {
    is: (
      erisaHasPlan: YesNo,
      erisaPrefersPlanDetails: YesNo,
      erisaAdministerOwnPlan: YesNo,
      prefill: boolean,
    ) => {
      return (
        erisaHasPlan === "YES" &&
        erisaPrefersPlanDetails === "YES" &&
        erisaAdministerOwnPlan === "NO" &&
        !prefill
      );
    },
    then: (schema) => schema.required("Please provide a response"),
  });

const erisaPlanDetailsAgentNotSameAsAdminStringRequired = (isZipCode = false) =>
  Yup.string()
    .when(
      [
        "erisaPrefersPlanDetails",
        "erisaHasAgentForLegalProcess",
        "erisaAdministerOwnPlan",
        "erisaIsLegalAgentSameAsAdmin",
        "$prefill",
      ],
      {
        is: (
          erisaPrefersPlanDetails: YesNo,
          erisaHasAgentForLegalProcess: YesNo,
          erisaAdministerOwnPlan: YesNo,
          erisaIsLegalAgentSameAsAdmin: boolean,
          prefill: boolean,
        ) => {
          const result =
            erisaPrefersPlanDetails === "YES" &&
            erisaHasAgentForLegalProcess === "NO" &&
            (erisaAdministerOwnPlan === "YES" || !erisaIsLegalAgentSameAsAdmin) &&
            !prefill;
          return result;
        },
        then: (schema) => {
          if (isZipCode) {
            return schema
              .nullable()
              .matches(zipCodeRegex, zipCodeErrorMessage)
              .required("Please provide a response");
          } else {
            return schema.nullable().required("Please provide a response");
          }
        },
        otherwise: (schema) => schema.nullable(),
      },
    )
    .nullable();

const erisaPlanDetailsAgentNotSameAsAdminLocationRequired = locationStateCodeValidation
  .when(
    [
      "erisaPrefersPlanDetails",
      "erisaHasAgentForLegalProcess",
      "erisaAdministerOwnPlan",
      "erisaIsLegalAgentSameAsAdmin",
      "$prefill",
    ],
    {
      is: (
        erisaPrefersPlanDetails: YesNo,
        erisaHasAgentForLegalProcess: YesNo,
        erisaAdministerOwnPlan: YesNo,
        erisaIsLegalAgentSameAsAdmin: boolean,
        prefill: boolean,
      ) => {
        const result =
          erisaPrefersPlanDetails === "YES" &&
          erisaHasAgentForLegalProcess === "NO" &&
          (erisaAdministerOwnPlan === "YES" || !erisaIsLegalAgentSameAsAdmin) &&
          !prefill;
        return result;
      },
      then: (schema) => schema.nullable().required("Please provide a response"),
      otherwise: (schema) => schema.nullable(),
    },
  )
  .nullable();

const erisaPlanDetailsStringRequired = Yup.string()
  .nullable()
  .when(["erisaHasPlan", "erisaPrefersPlanDetails", "$prefill"], {
    is: (erisaHasPlan: YesNo, erisaPrefersPlanDetails: YesNo, prefill: boolean | undefined) =>
      erisaHasPlan === "YES" && erisaPrefersPlanDetails === "YES" && !prefill,
    then: (schema) => schema.required("Please provide a response"),
    otherwise: (schema) => schema,
  });

export const ageRuleSchema = Yup.array()
  .of(Yup.mixed<AgeRules>().oneOf(ageRules.slice()).required())
  .nullable()
  .defined()
  .strict();

export const criticalIllnessTypeSchema = Yup.mixed<IssueOrAttainedAge>()
  .oneOf<IssueOrAttainedAge>(["ISSUE", "ATTAINED"], "Please provide a response")
  .required("Please provide a response");
export const criticalIllnessTypeSchemaWithNull = Yup.mixed<IssueOrAttainedAge>()
  .oneOf<IssueOrAttainedAge>(["ISSUE", "ATTAINED"], "Please provide a response")
  .nullable();

export const criticalIllnessRateTypeSchema = Yup.mixed<Tabacco>()
  .oneOf<Tabacco>(["TOBACCO", "NON_TOBACCO"], "Please provide a response")
  .required("Please provide a response");
export const criticalIllnessRateTypeSchemaWithNull = Yup.mixed<Tabacco>()
  .oneOf<Tabacco>(["TOBACCO", "NON_TOBACCO"], "Please provide a response")
  .nullable();

const enrollmentDateSchema = Yup.date().when("upcomingEnrollment", {
  is: "YES",
  then: (schema) =>
    schema.when("dontKnowEnrollmentEventDates", {
      is: true,
      otherwise: (schema) => schema.required("Please provide a response"),
    }),
});

export const clientValidationSchema = Yup.object({
  criticalIllnessType: criticalIllnessTypeSchemaWithNull,
  criticalIllnessRateType: criticalIllnessRateTypeSchemaWithNull,

  isActive: Yup.boolean().optional(),

  // Company Profile - Enrollment Events
  upcomingEnrollment: yesNoNotSureNullableRequired,
  dontKnowEnrollmentEventDates: Yup.boolean().nullable(),
  enrollmentDateStart: enrollmentDateSchema.nullable(),
  enrollmentDateEnd: enrollmentDateSchema.nullable(),

  // Company Profile - Set Your Goals
  enrollmentSupport: Yup.boolean().nullable(),
  streamlinedTechnology: Yup.boolean().nullable(),
  valueAddedServices: Yup.boolean().nullable(),
  billing: Yup.boolean().nullable(),
  ongoingService: Yup.boolean().nullable(),
  additionalSupport: Yup.boolean().nullable(),
  otherTopicsToPayAttentionTo: Yup.boolean().nullable(),
  otherTopicsToPayAttentionToText: Yup.string().optional().nullable(),
  productVariety: Yup.boolean().nullable(),
  recommendationFromBroker: Yup.boolean().nullable(),
  qualityCoverage: Yup.boolean().nullable(),
  digitalCapabilities: Yup.boolean().nullable(),
  service: Yup.boolean().nullable(),
  cost: Yup.boolean().nullable(),
  claimsManagement: Yup.boolean().nullable(),
  mostImportantOther: Yup.boolean().nullable(),
  mostImportantOtherText: Yup.string().optional().nullable(),

  // Install Call (ongoing service call)
  installCallSelection: yesNoNullableRequired,
  installCallPeople: Yup.string().nullable(),
  installCallTopics: Yup.string().nullable(),
  installCallSchedule: Yup.string().nullable(),

  // Kickoff call
  kickoffCallSelection: yesNoNullableRequired,
  kickoffCallSchedule: Yup.string().nullable(),
  kickoffCallTopics: Yup.string().nullable(),
  kickoffCallPeople: Yup.string().nullable(),

  // Actively at work
  allEmployeesActivelyAtWork: yesNoNullableRequired,

  allBenefitsNonContributory: yesNoNullableRequired,

  eifSignedAt: Yup.date(),
  deifChangesAcceptedAt: Yup.date(),
  deifChangesDeclinedAt: Yup.date(),
  deifChangesReadyForReviewAt: Yup.date(),

  /**
   * Company Details
   */

  // Benefits administration & data feeds
  hasBenAdminPlatform: yesNoNotSureNullablePrefill,
  benAdminPlatformId: Yup.number()
    .nullable()
    .when(["hasBenAdminPlatform", "$prefill"], {
      is: (hasBenAdminPlatform: YesNoNotSure, prefill: boolean | undefined) =>
        hasBenAdminPlatform === "YES" && !prefill,
      then: (schema) => schema.required("Please provide a response"),
    }),
  benAdminPlatformOtherName: Yup.string()
    .nullable()
    .when(["benAdminPlatform", "$prefill"], {
      is: (benAdminPlatform: BenAdminPlatform | null | undefined, prefill: boolean) => {
        return benAdminPlatform?.name === "Other" && !prefill;
      },
      then: (schema) => schema.required("Please provide a response"),
    }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- .
  benAdminPlatform: Yup.mixed<any>().nullable(),
  dataFeeds: Yup.mixed<YesNoNotSure>()
    .oneOf<YesNoNotSure>(["YES", "NO", "NOT_SURE"])
    .nullable()
    .when("hasBenAdminPlatform", {
      is: (hasBenAdminPlatform: YesNoNotSure) => hasBenAdminPlatform === "YES",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  dataFeedsBenAdminContact: dataFeedsContactValidationSchema,
  dataFeedsProductionSupportContact: dataFeedsContactValidationSchema,
  dataFeedsImplementorContact: dataFeedsContactValidationSchema,

  // Tax Id
  taxId: taxIdSchema.nullable().when(["$prefill"], {
    is: (prefill: boolean | undefined) => !!prefill,
    then: (schema) => schema.transform((value: string | undefined) => (value ? value : null)),
    otherwise: (schema) => schema.test(nullNotAllowedTestConfig()),
  }),

  enrollmentNeeds: Yup.string()
    .nullable()
    .test(nullNotAllowedTestConfig())
    .min(1, "Please provide a response"),

  // Current Sun Life coverage information
  previouslyInsuredBySunLife: yesNoNullablePrefill,
  previousSlfPolicies: Yup.mixed<string[]>()
    .when(["$prefill", "previouslyInsuredBySunLife"], {
      is: (prefill: boolean | undefined, previouslyInsuredBySunLife: YesNo | undefined) => {
        return !prefill && previouslyInsuredBySunLife === "YES";
      },
      then: (schema) =>
        schema.test({
          name: "previous-slf-policies",
          message: "Please provide a response",
          test: (val) =>
            Array.isArray(val) && val.length > 0 && sanitizeTypedArray(isString, val) !== null,
        }),
      otherwise: (schema) => schema.nullable(),
    })
    .nullable(),

  // Previous coverage information
  benefitsReplacedWithSlf: Yup.mixed<SlfCoverageLongName[] | "none">()
    .nullable()
    .test({
      name: "benefits-replaced-with-sun-life-one-of",
      message: "The provided value is not valid",
      test: (val) => {
        // Required validation is in the next test
        if (val == null) {
          return true;
        }

        if (val === "none") {
          return true;
        }

        if (Array.isArray(val)) {
          const validValues = Object.keys(slfCoverageLongNameToShortName);
          const hasInvalidValue = val.some((v) => !validValues.includes(v));
          return !hasInvalidValue;
        }
      },
    })
    .test({
      name: "benefits-replaced-with-sun-life-required",
      message: "Please provide a response",
      test: (val, context) => {
        const prefill = !!context.options.context?.prefill;
        if (prefill) {
          const isValid = true;
          return isValid;
        }

        if (val === undefined) {
          const isValid = true;
          return isValid;
        }

        if (val === null || val.length === 0) {
          const isValid = false;
          return isValid;
        }

        const isValid = true;
        return isValid;
      },
    }),

  /**
   * Plan Administrators & Billing
   */

  // Plan Admin
  webAdminContact: contactInputValidationSchema
    .pick(["id", "policyId", "firstName", "lastName", "email", "type"])
    .nullable()
    .default(undefined),

  // Billing Preferences
  billingAdministrationType: billDetailsSpec.billingAdministrationType,
  billingStructureType: billDetailsSpec.billingStructureType,
  billingSummaryStatementType: Yup.mixed<BillingSummaryStatementType>()
    .oneOf(billingSummaryStatementTypes, "Please provide a response")
    .nullable()
    .when("billingAdministrationType", {
      is: (val: BillingAdministrationType | null) => val === "SELF" || val === "TPA",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  billPayrollCycles: Yup.array()
    .of(Yup.mixed<BillPayrollCycle>().oneOf(billPayrollCycles.slice()).required())
    .strict()
    .nullable()
    .when("billingAdministrationType", {
      is: "LIST",
      then: (schema) =>
        schema
          .min(1, "Please provide a response")
          .required("Please select at least one payroll cycle"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired().min(0),
    }),
  billPayrollCyclesOther: Yup.string()
    .nullable()
    .when("billPayrollCycles", {
      is: (billPayrollCycles: BillPayrollCycle[]) => {
        const isRequired = billPayrollCycles?.includes("OTHER") ?? false;
        return isRequired;
      },
      then: (schema) => schema.nullable().required("Please provide a response"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  billPayrollCyclesExplanation: Yup.string()
    .nullable()
    .when("billPayrollCycles", {
      is: (billPayrollCycles: BillPayrollCycle[]) => {
        const twoOrMore = billPayrollCycles && billPayrollCycles.length > 1;
        return twoOrMore;
      },
      then: (schema) => schema.required("Please provide a response"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),

  billSplitType: billDetailsSpec.billSplitType,

  advanceOrArrears: billDetailsSpec.advanceOrArrears,

  // Malpractice Reimbursements
  malpracticeReimbursementRider: yesNoNullablePrefill,
  malpracticeReimbursementPayableTo: Yup.mixed<BenefitPayableTo>()
    .oneOf<BenefitPayableTo>(["EMPLOYEE", "EMPLOYER"])
    .nullable()
    .when(["malpracticeReimbursementRider", "$prefill"], {
      is: (malpracticeReimbursementRider: YesNo, prefill: boolean | undefined) => {
        return malpracticeReimbursementRider === "YES" && !prefill;
      },
      then: (schema) =>
        schema
          .oneOf<BenefitPayableTo>(["EMPLOYEE", "EMPLOYER"])
          .required("Please provide a response"),
      otherwise: (schema) => schema.nullable(),
    }),

  /**
   * Plan Configuration & Eligibility
   */
  needsEmployeeClasses: yesNoNullableRequired,

  /**
   * Other Contract Details
   */

  // Confirm Policy Anniversary
  policyAnniversaryMonth: getOneOfValidation<MonthOfTheYear>(monthsOfTheYear),

  // Additional waiting period rules
  timeSpentShouldCountTowardsWaitingPeriod: yesNoNullablePrefill,
  timeSpentAsPartTimeShouldCount: Yup.boolean().nullable(),
  timeSpentAsOtherShouldCount: Yup.boolean().nullable(),
  timeSpentAsOtherShouldCountText: Yup.string()
    .nullable()
    .when(["$prefill", "timeSpentAsOtherShouldCount"], {
      is: (prefill: boolean | undefined, timeSpentAsOtherShouldCount: boolean | null | undefined) =>
        !prefill && timeSpentAsOtherShouldCount,
      then: (schema) => schema.nullable().required("Please provide a response"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  waitingPeriodShouldBeWaived: yesNoNullablePrefill,

  // Rehire provision
  reHireProvisionExists: yesNoNullablePrefill,
  rehireProvision: Yup.mixed<RehireProvision>()
    .oneOf<RehireProvision>(rehireProvisions)
    .nullable()
    .when("reHireProvisionExists", {
      is: "YES",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),

  // Union members and domestic partners
  unionMembersCovered: yesNoNullablePrefill,
  domesticPartnersCovered: yesNoNullablePrefill,

  // Changes during annual enrollment
  whenDoYouAdministerYourAEP: administerAEPOptionsSchema,
  whenDoYouAdministerYourAEPStartDate: Yup.string()
    .nullable()
    .when("whenDoYouAdministerYourAEP", {
      is: "CUSTOM",
      then: (_) => monthDaySchema,
    }),
  whenDoYouAdministerYourAEPEndDate: Yup.string()
    .nullable()
    .when("whenDoYouAdministerYourAEP", {
      is: "CUSTOM",
      then: (_) => monthDaySchema,
    }),
  whenDoAEPTakeEffect: aepTakeEffectOptionsSchema,
  whenDoAEPTakeEffectOther: Yup.string()
    .nullable()
    .when("whenDoAEPTakeEffect", {
      is: "OTHER",
      then: (schema) => schema.required("Please provide a response."),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !!prefill,
      then: (schema) => schema.notRequired(),
    }),
  whenDecreasesInsuranceTakeEffect: decreaseInsuranceTakeEffectOptionsSchema,
  changesOnlyAllowedDuringAE: changesOnlyAllowedDuringAESchema,
  whenDoChangesToBenefitsTakeEffect: whenDoChangesToBenefitsTakeEffectSchema,

  // Age rules
  ageReductionDecreaseTakeEffectWhen: getOneOfValidation<CoverageChangesEffectiveAgeRules>(
    coverageChangesEffectiveAgeRules,
  ),
  ageBandIncreaseTakeEffectWhen: getOneOfValidation<CoverageChangesEffectiveAgeRules>(
    coverageChangesEffectiveAgeRules,
  ),
  ageForDependentLifeBenefits: getOneOfValidation<AgeForDependentLifeBenefits>(
    ageForDependentLifeBenefitsOptions,
  ),
  changesForDependentLifeBenefitsUseAge: yesNoNullable,
  criticalIllnessDependentAge: getOneOfValidation<EmployeeSpouse>(["EMPLOYEE", "SPOUSE"]),
  criticalIllnessDependentTobacco: getOneOfValidation<EmployeeSpouse>(["EMPLOYEE", "SPOUSE"]),

  // Other changes to coverage
  otherCoverageChangesTakeEffectWhen:
    getOneOfValidation<CoverageChangesEffective>(coverageChangesEffective),
  firstOfTheFollowingMonthIncludesFirstDay: Yup.boolean().nullable().optional(),

  // Employee certificates
  shouldCertificatesBeSplitInAnotherWay: yesNoNullablePrefill,
  shouldCertificatesBeSplitInAnotherWayText: Yup.string()
    .nullable()
    .when("shouldCertificatesBeSplitInAnotherWay", {
      is: "YES",
      then: (schema) => schema.required("Please provide a response."),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !!prefill,
      then: (schema) => schema.notRequired(),
    }),

  // Section 125
  hasSection125Plan: yesNoNullablePrefill,
  hasSection125Dental: Yup.boolean()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => {
        return !prefill;
      },
      then: (schema) => {
        return schema.test(slfCoveragesTestConfig).test({
          name: "required",
          message: "Please provide a response",
          test: section125TestFunction,
        });
      },
    })
    .nullable(),
  hasSection125Vision: Yup.boolean()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => {
        return !prefill;
      },
      then: (schema) => {
        return schema.test(slfCoveragesTestConfig).test({
          name: "required",
          message: "Please provide a response",
          test: section125TestFunction,
        });
      },
    })
    .nullable(),
  hasSection125DHMO: Yup.boolean()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => {
        return !prefill;
      },
      then: (schema) => {
        return schema.test(slfCoveragesTestConfig).test({
          name: "required",
          message: "Please provide a response",
          test: section125TestFunction,
        });
      },
    })
    .nullable(),
  hasAdditionalCoverageIncludedIn125Plan: Yup.boolean().nullable(),
  additonalCoverageIncludedInSection125Plan: Yup.array()
    .of(Yup.mixed<CoverageNotIncludedInSection125Plan>().required())
    .when(["$prefill", "hasAdditionalCoverageIncludedIn125Plan"], {
      is: (
        prefill: boolean | undefined,
        hasAdditionalCoverageIncludedIn125Plan: boolean | null | undefined,
      ) => {
        return !prefill && hasAdditionalCoverageIncludedIn125Plan;
      },
      then: (schema) =>
        schema
          .of(
            Yup.mixed<CoverageNotIncludedInSection125Plan>()
              .oneOf(coveragesNotIncludedInSection125Plan.slice())
              .required(),
          )
          .strict()
          .nullable()
          .min(1, "Please select at least one benefit")
          .required("Please select at least one benefit"),

      otherwise: (schema) =>
        schema.of(Yup.mixed<CoverageNotIncludedInSection125Plan>().required()).nullable(),
    })
    .strict()
    .nullable(),

  // ERISA
  erisaHasPlan: yesNoNullablePrefill,
  erisaPlanType: Yup.mixed<ERISAPlanType>()
    .oneOf(["CHURCH_PLAN", "GOVERNMENT_PLAN", "OTHER"])
    .nullable()
    .when("erisaHasPlan", {
      is: (erisaHasPlan: YesNo) => erisaHasPlan === "NO",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  erisaPlanTypeOther: Yup.string()
    .when(["erisaHasPlan", "erisaPlanType", "$prefill"], {
      is: (erisaHasPlan: YesNo, erisaPlanType: ERISAPlanType, prefill: boolean) =>
        erisaHasPlan === "NO" && erisaPlanType === "OTHER" && !prefill,
      then: (schema) => schema.nullable().required("Please provide a response"),
      otherwise: (schema) => schema.nullable(),
    })
    .nullable(),
  erisaPrefersPlanDetails: Yup.mixed<YesNo>()
    .oneOf<YesNo>(["YES", "NO"], "Please provide a response")
    .when(["erisaHasPlan", "$prefill"], {
      is: (erisaHasPlan: YesNo, prefill: boolean) => erisaHasPlan === "YES" && !prefill,
      then: (schema) => schema.required("Please provide a response"),
    })
    .nullable(),
  erisaPlanAdminName: erisaPlanDetailsNotAdministerOwnPlanStringRequired,
  erisaStreetAddress: erisaPlanDetailsNotAdministerOwnPlanStringRequired,
  erisaCity: erisaPlanDetailsNotAdministerOwnPlanStringRequired,
  erisaState: erisaPlanDetailsNotAdministerOwnPlanLocationRequired,
  erisaZipCode: erisaPlanDetailsNotAdministerOwnPlanStringRequired.matches(
    zipCodeRegex,
    zipCodeErrorMessage,
  ),
  erisaPlanNumber: Yup.mixed<ERISAPlanNumber>()
    .oneOf<ERISAPlanNumber>(["PN501", "PN502", "OTHER"], "Please provide a response")
    .nullable()
    .when(["erisaHasPlan", "erisaPrefersPlanDetails", "$prefill"], {
      is: (erisaHasPlan: YesNo, erisaPrefersPlanDetails: YesNo, prefill: boolean) =>
        erisaHasPlan === "YES" && erisaPrefersPlanDetails === "YES" && !prefill,
      then: (schema) => schema.required("Please provide a response"),
    }),
  erisaPlanNumberOther: Yup.string()
    .when(["erisaHasPlan", "erisaPrefersPlanDetails", "erisaPlanNumber", "$prefill"], {
      is: (
        erisaHasPlan: YesNo,
        erisaPrefersPlanDetails: YesNo,
        erisaPlanNumber: ERISAPlanNumber,
        prefill: boolean,
      ) =>
        erisaHasPlan === "YES" &&
        erisaPrefersPlanDetails === "YES" &&
        erisaPlanNumber === "OTHER" &&
        !prefill,
      then: (schema) => schema.nullable().max(5).required("Please provide a response"),
      otherwise: (schema) => schema.nullable(),
    })
    .nullable(),
  erisaPlanYearEndDateMMDD: Yup.string()
    .nullable()
    .when(["erisaHasPlan", "erisaPrefersPlanDetails", "$prefill"], {
      is: (erisaHasPlan: YesNo, erisaPrefersPlanDetails: YesNo, prefill: boolean) => {
        return erisaHasPlan === "YES" && erisaPrefersPlanDetails === "YES" && !prefill;
      },
      then: (_schema) => monthDaySchema,
    }),
  erisaAdministerOwnPlan: Yup.mixed<YesNo>()
    .oneOf<YesNo>(["YES", "NO"], "Please provide a response")
    .nullable()
    .when(["erisaHasPlan", "erisaPrefersPlanDetails", "$prefill"], {
      is: (erisaHasPlan: YesNo, erisaPrefersPlanDetails: YesNo, prefill: boolean) => {
        const result = erisaPrefersPlanDetails === "YES" && erisaHasPlan === "YES" && !prefill;
        return result;
      },
      then: (schema) => schema.required("Please provide a response"),
    }),
  erisaHasAgentForLegalProcess: Yup.mixed<YesNo>()
    .oneOf<YesNo>(["YES", "NO"], "Please provide a response")
    .nullable()
    .when(["erisaHasPlan", "erisaPrefersPlanDetails", "$prefill"], {
      is: (erisaHasPlan: YesNo, erisaPrefersPlanDetails: YesNo, prefill: boolean) => {
        const result = erisaPrefersPlanDetails === "YES" && erisaHasPlan === "YES" && !prefill;
        return result;
      },
      then: (schema) => schema.required("Please provide a response"),
    }),
  erisaIsLegalAgentSameAsAdmin: Yup.boolean().nullable(),
  erisaLegalAgentName: erisaPlanDetailsAgentNotSameAsAdminStringRequired(),
  erisaLegalAgentStreetAddress: erisaPlanDetailsAgentNotSameAsAdminStringRequired(),
  erisaLegalAgentCity: erisaPlanDetailsAgentNotSameAsAdminStringRequired(),
  erisaLegalAgentState: erisaPlanDetailsAgentNotSameAsAdminLocationRequired,
  erisaLegalAgentZipCode: erisaPlanDetailsAgentNotSameAsAdminStringRequired(true),

  // Value Added Services
  valueAddedServicesOptions: Yup.mixed<ValueAddedServicesOptions>()
    .nullable()
    .test({
      name: "valueAddedServicesOptions",
      message: "Please provide a response",
      test: (value, context) => {
        const client: Client = context.options.context?.client;
        assertIsDefined(client, "client");

        const hasSitusStateVermont = client.policies.some(({ issuedStateSitus }) =>
          issuedStateSitus?.includes("VT"),
        );
        const showValueAddedServicesLifeCoverage = shouldShowValueAddedServicesLifeCoverage(client);

        if (!showValueAddedServicesLifeCoverage) {
          return true;
        } else {
          if (hasSitusStateVermont) {
            const isValid = getOneOfValidation<ValueAddedServicesOptionsVT>(
              valueAddedServicesOptionsVT,
            ).isValidSync(value);
            return isValid;
          }
          const isValid =
            getOneOfValidation<ValueAddedServicesOptions>(valueAddedServicesOptions).isValidSync(
              value,
            );
          return isValid;
        }
      },
    }),
  valueAddedServicesStepViewed: Yup.boolean().nullable(),
  hasSubsidiary: yesNoNullablePrefill,
  subsidiaryEEsInNYNJOrHI: Yup.mixed<YesNo>()
    .oneOf(["YES", "NO"])
    .nullable()
    .test({
      name: "subsidiaryEEsInNYNJOrHI only needed if hasSubsidiary === YES and there is Statutory",
      message: "Please provide a response",
      test: (value, context) => {
        const parent: Pick<Client, "hasSubsidiary"> = context.parent;
        assertIsDefined(context.options.context?.client, "client");
        const client: Client = context.options.context?.client;
        assertExistsInObject(client, "allPoliciesSlfCoverages");
        const coverages: SlfCoverageLongName[] = client.allPoliciesSlfCoverages ?? [];

        const prefill: boolean | undefined = context.options.context?.prefill;
        const { hasSubsidiary } = parent;

        const hasStatutoryCoverage = coverages.some((coverage) =>
          getIsStatutoryBenefitType(coverageToBenefitType[coverage]),
        );

        if (!prefill && hasSubsidiary === "YES" && !value && hasStatutoryCoverage) {
          return false;
        }

        return true;
      },
    }),
  mainPolicyHolderEEsInNYNJOrHI: Yup.mixed<YesNo>()
    .oneOf(["YES", "NO"])
    .nullable()
    .when(["$prefill", "hasSubsidiary", "subsidiaryEEsInNYNJOrHI"], {
      is: (prefill: boolean | undefined, hasSubsidiary: YesNo, subsidiaryEEsInNYNJOrHI: YesNo) =>
        !prefill && hasSubsidiary === "YES" && subsidiaryEEsInNYNJOrHI === "YES",
      then: (schema) => schema.required("Please provide a response"),
    }),
  mainPolicyHolderEEStates: Yup.array()
    .of(Yup.mixed<DisabilityStateCode>().oneOf(disabilityStateCodes.slice()).required())
    .nullable()
    .when(["$prefill", "mainPolicyHolderEEsInNYNJOrHI"], {
      is: (prefill: boolean | undefined, mainPolicyHolderEEsInNYNJOrHI: YesNo) =>
        !prefill && mainPolicyHolderEEsInNYNJOrHI === "YES",
      then: (schema) =>
        schema
          .min(1, "Please select at least one state")
          .required("Please select at least one state"),
    }),
  deductibleMaximumReportTask: Yup.boolean().nullable(),
  authorizedSignerUserId: Yup.string().nullable(),
  brokerAsAuthorizedSigner: Yup.boolean().nullable(),
});

export const authorizedSignerUserIdValidationSchema = Yup.string()
  .nullable()
  .defined()
  .test({
    name: "Must have a value unless outside signer",
    message: "Please provide a response",
    test: (value, context) => {
      const parent: { outsideSigner: typeof outsideSignerValidationSchema.fields } = context.parent;
      const outsideSigner = parent.outsideSigner;
      const outsideSignerHasValues = Object.entries(outsideSigner ?? {}).some(([_key, value]) => {
        return value !== null;
      });
      return outsideSignerHasValues || value !== null;
    },
  })
  .test({
    name: "Cannot have outside signer and authorized signer",
    message: "Cannot have outside signer and authorized signer",
    test: (value, context) => {
      const parent: { outsideSigner: typeof outsideSignerValidationSchema.fields } = context.parent;
      const outsideSigner = parent.outsideSigner;
      const outsideSignerHasValues = Object.entries(outsideSigner ?? {}).some(([_key, value]) => {
        return value !== null;
      });
      // the frontend sets authorizedSignerUserId to "OUTSIDE_SIGNER" in formik, but when sent to the backend
      // it is null
      if (outsideSignerHasValues && value === "OUTSIDE_SIGNER") {
        return true;
      }
      if (outsideSignerHasValues && value !== null) {
        return false;
      }
      return true;
    },
  })
  .test({
    name: "ben-admin-check",
    message:
      "Authorized signer must be a Broker or Outside Signer, currently a Ben Admin is selected",
    test: (value, context) => {
      const benAdmins: BenAdmin[] = context.options.context?.benAdmins;
      const brokerAsAuthorizedSigner: boolean[] =
        context.options.context?.brokerAsAuthorizedSigner ?? false;
      assertIsDefined(benAdmins, "benAdmins");
      assertExistsInObject(context?.options?.context ?? {}, "brokerAsAuthorizedSigner");
      const isBenAdmin = benAdmins.some((benAdmin) => benAdmin.id === value);
      if (isBenAdmin && brokerAsAuthorizedSigner) {
        return false;
      }

      return true;
    },
  })
  .test({
    name: "broker-check",
    message:
      "Authorized signer must be a Ben Admin or Outside Signer, currently a Broker is selected",
    test: (value, context) => {
      const brokers: Broker[] = context.options.context?.brokers;
      const brokerAsAuthorizedSigner: boolean[] =
        context.options.context?.brokerAsAuthorizedSigner ?? false;
      assertIsDefined(brokers, "brokers");
      assertExistsInObject(context?.options?.context ?? {}, "brokerAsAuthorizedSigner");
      const isBroker = brokers.some((broker) => broker.id === value);
      if (isBroker && !brokerAsAuthorizedSigner) {
        return false;
      }

      return true;
    },
  });

/**
 * Company Details
 */

export const taxIdValidationSchema = clientValidationSchema.pick(["taxId"]);

export const insuredBySunLifeValidationSchema = clientValidationSchema.pick([
  "previouslyInsuredBySunLife",
  "previousSlfPolicies",
]);

export const previousCoverageInformationSchema = clientValidationSchema.pick([
  "benefitsReplacedWithSlf",
]);

/**
 * Plan Administrators & Billing
 */

export const malpracticeReimbursementsValidationSchema = clientValidationSchema.pick([
  "malpracticeReimbursementRider",
  "malpracticeReimbursementPayableTo",
]);

/**
 * Other Contract Details
 */

export const confirmPolicyAnniversarySchema = clientValidationSchema.pick([
  "policyAnniversaryMonth",
]);

export const additionalWaitingPeriodRulesValidationSchema = clientValidationSchema.pick([
  "timeSpentShouldCountTowardsWaitingPeriod",
  "timeSpentAsPartTimeShouldCount",
  "timeSpentAsOtherShouldCount",
  "timeSpentAsOtherShouldCountText",
  "waitingPeriodShouldBeWaived",
]);

export const rehireProvisionValidationSchema = clientValidationSchema.pick([
  "reHireProvisionExists",
  "rehireProvision",
]);

export const unionMembersAndDomesticPartnersValidationSchema = clientValidationSchema.pick([
  "unionMembersCovered",
  "domesticPartnersCovered",
]);

export const changesDuringAnnualEnrollmentValidationSchema = clientValidationSchema.pick([
  "whenDoYouAdministerYourAEP",
  "whenDoYouAdministerYourAEPStartDate",
  "whenDoYouAdministerYourAEPEndDate",
  "whenDoAEPTakeEffect",
  "whenDoAEPTakeEffectOther",
  "whenDecreasesInsuranceTakeEffect",
  "changesOnlyAllowedDuringAE",
  "whenDoChangesToBenefitsTakeEffect",
]);

export const otherChangesToCoverageValidationSchema = clientValidationSchema.pick([
  "otherCoverageChangesTakeEffectWhen",
  "firstOfTheFollowingMonthIncludesFirstDay",
]);

export const employeeCertificatesValidationSchema = clientValidationSchema.pick([
  "shouldCertificatesBeSplitInAnotherWay",
  "shouldCertificatesBeSplitInAnotherWayText",
]);

export const section125ValidationSchema = clientValidationSchema.pick([
  "hasSection125Plan",
  "hasSection125Dental",
  "hasSection125Vision",
  "hasSection125DHMO",
  "hasAdditionalCoverageIncludedIn125Plan",
  "additonalCoverageIncludedInSection125Plan",
]);

export const erisaValidationSchema = clientValidationSchema
  .pick([
    "erisaHasPlan",
    "erisaPlanType",
    "erisaPlanTypeOther",
    "erisaPrefersPlanDetails",
    "erisaPlanAdminName",
    "erisaStreetAddress",
    "erisaCity",
    "erisaState",
    "erisaZipCode",
    "erisaPlanNumber",
    "erisaPlanNumberOther",
    "erisaPlanYearEndDateMMDD",
    "erisaAdministerOwnPlan",
    "erisaHasAgentForLegalProcess",
    "erisaIsLegalAgentSameAsAdmin",
    "erisaLegalAgentName",
    "erisaLegalAgentStreetAddress",
    "erisaLegalAgentCity",
    "erisaLegalAgentState",
    "erisaLegalAgentZipCode",
  ])
  .concat(
    Yup.object({
      taxId: erisaPlanDetailsStringRequired.matches(...taxIdMatchesArguments),
    }),
  );

export const valueAddedServicesSchema = clientValidationSchema.pick([
  "valueAddedServicesOptions",
  "valueAddedServicesStepViewed",
]);

export const outsideSignerValidationSchema = Yup.object({
  id: Yup.string().optional(),
  firstName: Yup.string().required("Please provide a response"),
  lastName: Yup.string().required("Please provide a response"),
  email: Yup.string()
    .transform((value: unknown) =>
      typeof value === "string" ? value.trim().toLocaleLowerCase() : value,
    )
    .emailV1("Please provide a valid email address")
    .required("Please provide a response"),
})
  .nullable()
  .when("authorizedSignerUserId", {
    is: (authorizedSignerUserId: string | null) =>
      authorizedSignerUserId === "OUTSIDE_SIGNER" || !authorizedSignerUserId,
    then: (schema) =>
      schema.transform(
        (value: { firstName: string; lastName: string; email: string } | null) =>
          value || {
            firstName: null,
            lastName: null,
            email: null,
          },
      ),
    otherwise: (schema) => schema.transform(() => null),
  });

type ClientSetYourGoalsProps = Partial<
  Pick<
    Client,
    | "enrollmentSupport"
    | "streamlinedTechnology"
    | "valueAddedServices"
    | "billing"
    | "ongoingService"
    | "additionalSupport"
    | "otherTopicsToPayAttentionTo"
    | "productVariety"
    | "recommendationFromBroker"
    | "qualityCoverage"
    | "digitalCapabilities"
    | "service"
    | "cost"
    | "claimsManagement"
    | "mostImportantOther"
  >
>;

export function getSetYourGoalsQuestionStatuses(client: ClientSetYourGoalsProps) {
  const firstQuestionProps: (keyof ClientSetYourGoalsProps)[] = [
    "enrollmentSupport",
    "streamlinedTechnology",
    "valueAddedServices",
    "billing",
    "ongoingService",
    "additionalSupport",
    "otherTopicsToPayAttentionTo",
  ];

  const secondQuestionProps: (keyof ClientSetYourGoalsProps)[] = [
    "productVariety",
    "recommendationFromBroker",
    "qualityCoverage",
    "digitalCapabilities",
    "service",
    "cost",
    "claimsManagement",
    "mostImportantOther",
  ];

  const firstQuestionAnswered = firstQuestionProps.some((p) => Boolean(client[p]));
  const secondQuestionAnswered = secondQuestionProps.some((p) => Boolean(client[p]));

  return { firstQuestionAnswered, secondQuestionAnswered };
}

export const subsidiaryClientValidationSchema = clientValidationSchema.pick([
  "hasSubsidiary",
  "subsidiaryEEsInNYNJOrHI",
  "mainPolicyHolderEEsInNYNJOrHI",
  "mainPolicyHolderEEStates",
]);
