import type { explorerPageInputValidation } from "../validation/explorerPage";
import type { BenAdminPlatform } from "./BenAdminPlatform";
import type { ClientId } from "./Client";
import type { Document } from "./Document";
import type { BenefitPlanSummaryDocument, ExplorerPageBenefit } from "./ExplorerPageBenefit";
import type { Pretty, ValueOf } from "./Helper";
import type { LocationStateCode, Territory } from "./Location";
import type { User } from "./User";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type * as Yup from "yup";

export type ExplorerPageId = string;
export type ExplorerPageVanitySlug = string;
export type benAdminPlatformId = number | null;

export type ExplorerPageInput = Pretty<Yup.InferType<typeof explorerPageInputValidation>>;
export interface ExplorerPage {
  id: ExplorerPageId;
  name: string;
  createdAt: Date;
  createdBy: string;
  createdByUser?: User | null;
  updatedAt: Date;
  updatedBy: string;
  updatedByUser?: User | null;
  deletedAt?: Date | null;
  deletedBy?: string | null;
  deletedByUser?: User | null;
  openEnrollmentStart?: Date | null;
  openEnrollmentEnd?: Date | null;
  benefits?: ExplorerPageBenefit[];
  maintenanceMode: boolean;
  startsAt?: Date | null;
  expiresAt?: Date | null;
  planYear?: string | null;
  kioskMode: boolean;
  benefitTypeMetadata?: ExplorerBenefitTypeMetadata | null;
  employerState: LocationStateCode;
  benAdminPlatformId?: number | null;
  benAdminPlatform?: BenAdminPlatform | null;
  benAdminPlatformUrl?: string | null;
  notificationLink?: string | null;
  customCalendlyLink?: string | null;
  customClientName?: string | null;
  logoDocumentId: string | null;
  logoDocument?: ExplorerPageDocument | null;
  employeeCount?: number | null;
  hasEmployeesInNY: boolean;
  showBenefitCount: boolean;
  associationId: string;
  hideSunLifeLogo: boolean;
  pageOwner: string;
  pageOwnerUser?: User | null;
  policies: string[];
  medicalVideoDocumentId: string | null;
  medicalVideoDocument?: ExplorerPageDocument | null;
  vidyardUploadId: string | null;
  vidyardId: number | null;
  medicalVideoEnglishTitle: string | null;
  medicalVideoSpanishTitle: string | null;
  territory?: Territory | null;
  showLegacyVisionPlanTable: boolean;
  showLegacyDentalPlanTable: boolean;
  vanitySlug?: string | null;
  customBranding: boolean;
  customTheme: ExplorerPageCustomTheme | null;
}
export type ExplorerPageDocument = Pick<Document, "documentKey" | "id" | "name" | "mimeType">;

export type PublicExplorerPage = ExplorerPage & {
  clientId: string;
  clientName: string | null;
  ticketId: string | null;
  isLive: boolean;
  languageFeatureToggle: boolean;
  largeClientFeatureToggle: boolean;
  medicalPlanComparisonFeatureToggle: boolean;
  visionPlanComparisonFeatureToggle: boolean;
  dentalPlanComparisonFeatureToggle: boolean;
  fsaRolloverFeatureToggle: boolean;
  newExplorerPageId: string | null;
  newExplorerPageVanitySlug: string | null;
  newExplorerPageStartDate: Date | null;
  newExplorerPageEndDate: Date | null;
  newExplorerPagePlanYear: string | null;
  newExplorerPageClientName: string | null;
  prevExplorerPageId: string | null;
  prevExplorerPageExpiry: Date | null;
  prevExplorerPagePlanYear: string | null;
  benefitsExplorerCustomBenefitOrder: boolean;
  benefitsExplorerCustomBranding: boolean;
  nonSunLifeBenefitsExplorer: boolean;
  vasUpdateFeatureToggle: boolean;
  dentalPlansCombinedFeatureToggle: boolean;
  customBrandingFeatureToggle: boolean;
  hiDisclosure: boolean;
};

export type ExplorerPageQuery = {
  limit?: number;
  offset?: number;
  search?: string; // defaults to search on "name" when no searchBy term given
  searchBy?: ExplorerPageSearchField; // the field to search by, defaults to "name"
  sortBy?: ExplorerPageSortField;
  sortDirection?: SortDirection;
  clientId?: ClientId;
};

export const ExplorerPageSearchFields = ["name", "vanitySlug"] as const;
export type ExplorerPageSearchField = ValueOf<typeof ExplorerPageSearchFields>;

export const ExplorerPageSortFields = ["createdAt", "name", "updatedAt", "startsAt"] as const;
export type ExplorerPageSortField = ValueOf<typeof ExplorerPageSortFields>;

export const SortDirections = ["asc", "desc"] as const;
export type SortDirection = ValueOf<typeof SortDirections>;

export const DemoEnrollmentStatuses = ["pre" as const, "active" as const, "post" as const];
export type DemoEnrollmentStatus = ValueOf<typeof DemoEnrollmentStatuses>;

export type ExplorerBenefitTypeMetadata = {
  [key in BenefitTypeBenEx]?: {
    highlighterText?: string | null;
    spanishHighlighterText?: string | null;
    highlighterOrder?: number;
  };
};

export const Languages = ["en", "es"] as const;
export type Language = ValueOf<typeof Languages>;

export type ExplorerPageDocumentByBenefit = {
  benefits?: Pick<ExplorerPageBenefit, "benefitType" | "planSummaryDocument">[];
};

export type PlanSummaryDocumentByBenefitType = {
  benType: BenefitTypeBenEx;
  docs: BenefitPlanSummaryDocument[];
};

export const medicalVideoUploadSection = [
  "NO_VIDEO",
  "ENGLISH_VIDEO",
  "ENGLISH_AND_SPANISH_VIDEO",
] as const;

export type MedicalVideoUploadSection = ValueOf<typeof medicalVideoUploadSection>;

export type ExplorerPageCustomTheme = {
  brandColor?: string | null;
  contrastingColor?: string | null;
};
