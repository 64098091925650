import { DownloadOutlined } from "@ant-design/icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "antd";
import { Badge } from "client/src/components/Badge/Badge";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { ConfirmDialog } from "client/src/components/ConfirmDialog/ConfirmDialog";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Body1 } from "client/src/components/Typography/Typography";
import { ExplorerPlanContent } from "client/src/domain/ExplorerPages/ClientPage/Benefit/ExplorerPlanContent";
import { useExplorerPlanState } from "client/src/domain/ExplorerPages/ClientPage/Benefit/useExplorerPlanState";
import { useTranslation } from "client/src/i18n";
import { timezoneAdjustedMoment } from "client/src/utils/moment";
import { PrintHidden } from "client/src/utils/print";
import clsx from "clsx";
import moment from "moment";

import * as styles from "./benefit.module.less";

import type { CopySwitch } from "../../utils";
import type { ExplorerPlanStateProps } from "client/src/domain/ExplorerPages/ClientPage/Benefit/useExplorerPlanState";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { BenefitLevel, PlanType } from "shared/types/ExplorerPageBenefit";

export const DataTestId = {
  ExplorerPlan: "explorer-plan",
  FavoriteIcon: "favorite-icon",
};

export type ExplorerPlanCopyStructure = {
  [benefit in BenefitTypeBenEx]: CopySwitch<
    PlanType,
    CopySwitch<
      "sunLife",
      CopySwitch<
        BenefitLevel | "grouped",
        {
          DHMO?: string;
          DHMO_TX?: string;
          DHMO_FL?: string;
          subHeading: string;
          content: string;
          content_MULTI_CHOICE?: string;
          heading?: string;
          groupedSubHeading?: string;
          groupedContent?: string;
        }
      >
    >
  > & {
    VAS?: CopySwitch<"sunLife", string>;
    VAS_1?: CopySwitch<"sunLife", string>;
    VAS_2?: CopySwitch<"sunLife", string>;
    Self_Care_Plus?: CopySwitch<"sunLife", string>;
    HDHP?: string;
    HSA_addon?: string;
  };
} & {
  downloadPDF: string;
  unfavoriteConfirmBody: string;
  unfavoriteTitle: string;
  unfavoriteConfirmText: string;
  unfavoriteCancelText: string;
  newPlanText: string;
};

export const ExplorerPlan = ({
  isMobile,
  isPrint,
  confirmingDelete,
  onCancelConfirm,
  showAnimation,
  isFavPlan,
  documentURL,
  toggleFavoritePlan,
  plan,
  onConfirmUnfavorite,
  offering,
  location,
  spanishDocumentURL,
  language,
  onDownloadPlanSummary,
  expiresAt,
  vasUpdateFeatureToggle,
  customBranding,
}: ReturnType<typeof useExplorerPlanState>) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerPlan" });

  const isPageExpiresInFuture = expiresAt
    ? moment().isBefore(timezoneAdjustedMoment(moment(expiresAt)))
    : true;

  return (
    <>
      {confirmingDelete && (
        <ConfirmDialog
          title={t("unfavoriteTitle") ?? undefined}
          isVisible={confirmingDelete}
          onCancel={onCancelConfirm}
          onConfirm={onConfirmUnfavorite}
          confirmActionText={t("unfavoriteConfirmText") ?? undefined}
          cancelActionText={t("unfavoriteCancelText") ?? undefined}
        >
          {t("unfavoriteConfirmBody")}
        </ConfirmDialog>
      )}

      <Card
        className={clsx(isMobile ? "px-12" : "p-40", isMobile && "py-32", styles.plan, "mt-24")}
        data-testid={DataTestId.ExplorerPlan}
      >
        <Row gutter={[24, 8]}>
          {isPageExpiresInFuture && (
            <Col flex="50px">
              <FontAwesomeIcon
                data-testid={DataTestId.FavoriteIcon}
                icon={isFavPlan ? faHeartSolid : faHeart}
                className={clsx(
                  styles.saveIcon,
                  isFavPlan && showAnimation && styles.saveIconBump,
                  customBranding && styles.saveIconNeutral,
                )}
                onClick={() => toggleFavoritePlan(plan.id)}
              />
            </Col>
          )}
          {plan.isNew && isMobile && (
            <Col>
              <PrintHidden>
                <Badge
                  srOnlyLabel="Information"
                  status={t("newPlanText") ?? undefined}
                  variant="info"
                />
              </PrintHidden>
            </Col>
          )}

          <ExplorerPlanContent
            isMobile={isMobile}
            isPrint={isPrint}
            location={location}
            offering={offering}
            plan={plan}
            vasUpdateFeatureToggle={vasUpdateFeatureToggle}
          />

          {documentURL && !isPrint && (
            <Col flex="1 1 150px">
              <Body1>
                <ButtonOld
                  href={
                    language === "es" && spanishDocumentURL
                      ? spanishDocumentURL.objectUrl
                      : documentURL.objectUrl
                  }
                  size={isMobile ? "middle" : "large"}
                  type="link"
                  download={
                    language === "es" && spanishDocumentURL
                      ? spanishDocumentURL.filename
                      : documentURL.filename
                  }
                  className="px-0" // @todo: FP-3498: move to Link component with normal text margin
                  onClick={onDownloadPlanSummary}
                >
                  <DownloadOutlined /> {t("downloadPDF")}
                </ButtonOld>
              </Body1>
            </Col>
          )}
        </Row>
      </Card>
    </>
  );
};

export const StatefulExplorerPlan = (args: ExplorerPlanStateProps) => {
  const props = useExplorerPlanState(args);
  return <ExplorerPlan {...props} />;
};
