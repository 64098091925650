import { Button } from "client/src/components/Button/Button";
import { Body3, H3, H5 } from "client/src/components/Typography/Typography";
import { Table } from "client/src/domain/ExplorerPages/ClientPage/Benefit/Table/Table";
import { Trans, i18n, useTranslation } from "client/src/i18n";
import { formatCurrency } from "client/src/utils/formatters";
import { type ReactNode } from "react";
import * as styles from "./ExplorerMedicalPlanComparisonTable.module.less";
import { PlanSummaryLink } from "./PlanSummaryLink";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ExplorerPageBenefit } from "shared/types/ExplorerPageBenefit";

/**
 * Props for the {@link ExplorerMedicalPlanComparisonTable} component.
 */
type ExplorerMedicalPlanComparisonTableProps = {
  /**
   * The medical plan benefits to compare.
   */
  benefits: Array<ExplorerPageBenefit>;
  /**
   * The other benefit types available.
   */
  supplementalBenefitTypes: Array<BenefitTypeBenEx>;
  /**
   * The callback to handle when a supplemental benefit type is clicked.
   */
  onSupplementalBenefitTypeClick: (benefitType: BenefitTypeBenEx) => void;
};

/**
 * A table component to compare medical plan benefits.
 */
export const ExplorerMedicalPlanComparisonTable = ({
  benefits,
  supplementalBenefitTypes,
  onSupplementalBenefitTypeClick,
}: ExplorerMedicalPlanComparisonTableProps) => {
  const rows = useRowConfig();
  const { t } = useTranslation({ keyPrefix: "ExplorerPlanComparisonTable.MEDICAL.custom" });

  return (
    <div className={styles.container}>
      <Table
        skipEmptyRows
        head={[
          {
            contents: (
              <Trans t={t} i18nKey="tableHeaders.planName">
                <H3 />
              </Trans>
            ),
          },
          ...benefits.map((benefit) => ({
            contents: <H3>{benefit.planName}</H3>,
          })),
        ]}
        body={rows.map(({ getLabel, getValue, getHeading }) => [
          {
            contents: getHeading ? getHeading(benefits) : getLabel(),
          },
          ...benefits.map((benefit) => {
            return {
              heading: getLabel(benefit),
              contents: getValue(benefit),
            };
          }),
        ])}
        footer={
          <Trans t={t} i18nKey="tableFooters.default">
            <Body3 as="p" />
            <SupplementalBenefitTypeLinks
              supplementalBenefitTypes={supplementalBenefitTypes}
              onClick={onSupplementalBenefitTypeClick}
            />
            <Body3 as="p" />
          </Trans>
        }
      />
    </div>
  );
};

/**
 * Props for the {@link SupplementalBenefitTypeLinks} component.
 */
type SupplementalBenefitTypeLinksProps = {
  supplementalBenefitTypes: Array<BenefitTypeBenEx>;
  onClick: (benefitType: BenefitTypeBenEx) => void;
};

/**
 * A component to display links to other benefit types.
 */
function SupplementalBenefitTypeLinks({
  supplementalBenefitTypes,
  onClick,
}: SupplementalBenefitTypeLinksProps) {
  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitsNames" });
  const { t: $t } = useTranslation({ keyPrefix: "ExplorerPlanComparisonTable.MEDICAL.custom" });
  return (
    supplementalBenefitTypes.length > 0 && (
      <>
        <Body3 as="p">{$t("tableFooters.supplementalBenefits")}</Body3>
        <Body3 as="p">
          {supplementalBenefitTypes.map((type, index) => (
            <>
              {index > 0 && <span className={styles.separator} />}
              <Button key={type} type="text-only-light" role="link" onClick={() => onClick(type)}>
                {t(type)}
              </Button>
            </>
          ))}
        </Body3>
      </>
    )
  );
}

/**
 * The configuration for each row in the medical plan comparison table.
 */
type RowConfig = {
  getLabel: (benefit?: ExplorerPageBenefit) => ReactNode;
  getValue: (benefit: ExplorerPageBenefit) => ReactNode;
  getHeading?: (benefits: Array<ExplorerPageBenefit>) => ReactNode;
};

function RowHeading({ tKey }: { tKey: string }) {
  const { t: $t } = useTranslation({
    keyPrefix: "ExplorerPlanComparisonTable.MEDICAL.custom.tableHeaders",
  });

  return (
    <Trans t={$t} i18nKey={tKey}>
      <H5 />
      <Body3 />
    </Trans>
  );
}

/**
 * Returns the configuration for each row in the medical plan comparison table.
 */
function useRowConfig(): Array<RowConfig> {
  const { t: tGeneric } = useTranslation({ keyPrefix: "Generic" });
  const { t: tExplorerPlanComparisonTableMedicalCustom } = useTranslation({
    keyPrefix: "ExplorerPlanComparisonTable.MEDICAL.custom",
  });

  return [
    {
      getLabel: () => <RowHeading tKey="carrier" />,
      getValue: (benefit) => benefit.carrier?.carrierName ?? "",
    },
    {
      getLabel: () => <RowHeading tKey="planType" />,
      getValue: (benefit) => benefit.planType ?? "",
    },
    getRowConfigForInOutNetwork({
      naProp: "planYearDeductibleOutOfNetworkNA",
      naLabel: "individualDeductibleOutOfNetworkNA",
      aLabel: "individualDeductible",
      inProp: "planYearDeductibleIndividualInNetwork",
      outProp: "planYearDeductibleIndividualOutOfNetwork",
    }),
    getRowConfigForInOutNetwork({
      naProp: "planYearDeductibleOutOfNetworkNA",
      naLabel: "familyDeductibleOutOfNetworkNA",
      aLabel: "familyDeductible",
      inProp: "planYearDeductibleFamilyInNetwork",
      outProp: "planYearDeductibleFamilyOutOfNetwork",
    }),
    getRowConfigForInOutNetwork({
      naProp: "planYearMaximumOutOfNetworkNA",
      naLabel: "individualOutOfPocketMaximumOutOfNetworkNA",
      aLabel: "individualOutOfPocketMaximum",
      inProp: "planYearMaximumIndividualInNetwork",
      outProp: "planYearMaximumIndividualOutOfNetwork",
    }),
    getRowConfigForInOutNetwork({
      naProp: "planYearMaximumOutOfNetworkNA",
      naLabel: "familyOutOfPocketMaximumOutOfNetworkNA",
      aLabel: "familyOutOfPocketMaximum",
      inProp: "planYearMaximumFamilyInNetwork",
      outProp: "planYearMaximumFamilyOutOfNetwork",
    }),
    {
      getLabel: () => <RowHeading tKey="hsaCompatible" />,
      getValue: (benefit) =>
        benefit.contentFlags?.includes("HSA compatible") ? tGeneric("Yes") : tGeneric("No"),
    },
    {
      getLabel: () => <RowHeading tKey="employerHsaContribution" />,
      getValue: (benefit) =>
        benefit.medicalHealthSavingsAccountEmployerContribution ? tGeneric("Yes") : tGeneric("No"),
    },
    {
      getLabel: () => <RowHeading tKey="outOfNetworkBenefits" />,
      getValue: (benefit) =>
        benefit.medicalOutOfNetworkBenefits ? tGeneric("Yes") : tGeneric("No"),
    },
    {
      getLabel: () => <RowHeading tKey="requiresPcp" />,
      getValue: (benefit) =>
        tExplorerPlanComparisonTableMedicalCustom(
          `tableCells.requiresPcp.${String(benefit.medicalPcpCoordinationRequired ?? false)}`,
        ),
    },
    {
      getLabel: () => <RowHeading tKey="specialtyCareReferral" />,
      getValue: (benefit) =>
        tExplorerPlanComparisonTableMedicalCustom(
          `tableCells.specialtyCareReferral.${String(
            benefit.medicalPcpSpecialtyCareReferralRequired ?? false,
          )}`,
        ),
    },
    {
      getLabel: () => <RowHeading tKey="linkToFullPlanSummary" />,
      getValue: (benefit) =>
        // Return `undefined` to skip the cell if there is no plan summary in the whole row.
        ((i18n.language === "en" || i18n.language.startsWith("en-")) &&
          benefit.planSummaryDocumentId) ||
        ((i18n.language === "es" || i18n.language.startsWith("es-")) &&
          benefit.spanishPlanSummaryDocumentId) ? (
          <PlanSummaryLink
            benefit={benefit}
            label={tExplorerPlanComparisonTableMedicalCustom("tableCells.downloadPlanSummary", {
              planName: benefit.planName,
            })}
            showIcon
          />
        ) : undefined,
    },
  ];
}

/**
 * Props for the {@link getRowConfigForInOutNetwork} function parameters.
 */
type RowConfigForInOutNetwork = {
  naProp: keyof ExplorerPageBenefit;
  naLabel: string;
  aLabel: string;
  inProp: keyof ExplorerPageBenefit;
  outProp: keyof ExplorerPageBenefit;
};

function getRowConfigForInOutNetwork({
  naProp,
  naLabel,
  aLabel,
  inProp,
  outProp,
}: RowConfigForInOutNetwork): RowConfig {
  return {
    getHeading: (benefits: Array<ExplorerPageBenefit>) => (
      <RowHeading tKey={benefits.every((benefit) => benefit[naProp]) ? naLabel : aLabel} />
    ),
    getLabel: (benefit) => <RowHeading tKey={benefit?.[naProp] ? naLabel : aLabel} />,
    getValue: (benefit) =>
      benefit?.[naProp]
        ? formatCurrency(benefit[inProp])
        : `${formatCurrency(benefit[inProp])} / ${formatCurrency(benefit[outProp])}`,
  };
}
