/* eslint-disable jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-static-element-interactions  -- needed throughout for focus management */

import { Dropdown } from "antd";
import { getFocusNodeList } from "rc-util/lib/Dom/focus";
import { useLayoutEffect, useRef, useState, type RefObject } from "react";
import type { DropdownProps } from "antd";

type Props = DropdownProps;

export function SlobDropdown(props: Props) {
  const {
    dropdownRender: _dropdownRender,
    onOpenChange: _onOpenChange,
    children: _children,
    ...rest
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRenderRef = useRef<HTMLDivElement>(null);
  const sentinelStartRef = useRef<HTMLDivElement>(null);
  const sentinelEndRef = useRef<HTMLDivElement>(null);

  const lastOutSideActiveElementRef = useRef<HTMLElement | null>(null);

  const [open, setOpen] = useState(false);

  useDropdownAccessibility({ dropdownRenderRef, open: rest.open !== undefined ? rest.open : open });

  const toggleAndFocusTrigger = () => {
    const triggerElement = document.body.contains(lastOutSideActiveElementRef.current)
      ? lastOutSideActiveElementRef.current
      : wrapperRef.current
      ? getFocusNodeList(wrapperRef.current)[0]
      : null;

    triggerElement?.click();
    triggerElement?.focus();
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement> | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === "Escape") {
      // Don't propagate the event so that parent
      // overlay elements don't close too (e.g. modals).
      e.stopPropagation();
      toggleAndFocusTrigger();
    }
  };

  const dropdownRender: typeof _dropdownRender = _dropdownRender
    ? (e) => {
        return (
          <>
            <div ref={sentinelStartRef} tabIndex={0} onFocus={toggleAndFocusTrigger} />
            <div ref={dropdownRenderRef} tabIndex={-1} onKeyDown={handleKeyDown}>
              {_dropdownRender(e)}
            </div>
            <div ref={sentinelEndRef} tabIndex={0} onFocus={toggleAndFocusTrigger} />
          </>
        );
      }
    : undefined;

  const onOpenChange: typeof _onOpenChange = (open) => {
    if (open) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
      lastOutSideActiveElementRef.current = document.activeElement as HTMLElement;
    } else {
      lastOutSideActiveElementRef.current = null;
    }

    setOpen(open);
    if (_onOpenChange) {
      _onOpenChange(open);
    }
  };

  // The default trigger event for a blank `trigger` prop is hover
  // Ensure that the dropdown is triggered by a click trigger to add a11y support
  const trigger = rest.trigger || ["hover", "click"];
  if (!trigger.includes("click")) {
    trigger.push("click");
  }

  return (
    <Dropdown
      {...rest}
      dropdownRender={dropdownRender}
      onOpenChange={onOpenChange}
      trigger={trigger}
    >
      <div ref={wrapperRef}>{_children}</div>
    </Dropdown>
  );
}

/**
 * Ant Design's Dropdown component is not meant to have any tabbable list of elements in its
 * overlay because it is modeled after the "menu" widget in HTML which according to the
 * spec expects its menu items to be cycled through with the arrow keys.
 *
 * Because of that assumption, it has some internal behavior baked in to close the overlay
 * if you press Tab when it is open. We obviously don't want that in this specific scenario
 * because our overlay is more complex and does have tabbable elements in it.
 *
 * This hook basically cancels out what the Ant Design's Dropdown is doing under the hood
 * and furthermore autofocuses the input element when the dropdown is opened.
 *
 * @param props
 * @returns
 */
function useDropdownAccessibility(props: {
  dropdownRenderRef: RefObject<HTMLDivElement>;
  open: boolean;
}) {
  const { dropdownRenderRef, open } = props;

  useLayoutEffect(() => {
    let inputFocused = false;

    if (open) {
      setTimeout(() => {
        const focusNodeList = dropdownRenderRef.current
          ? getFocusNodeList(dropdownRenderRef.current)
          : null;
        const firstElement = focusNodeList ? focusNodeList[0] : null;
        firstElement?.focus();
        inputFocused = true;
      }, 100);

      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Tab") {
          if (!inputFocused) {
            inputFocused = true;
            e.preventDefault();
          }

          // Prevent ant-design from handling this event
          // and closing the dropdown in the process
          e.stopImmediatePropagation();
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        inputFocused = false;
      };
    }
  }, [open, dropdownRenderRef]);
}

/* eslint-enable jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-static-element-interactions */
