import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as renameItemStyles from "client/src/components/RenameItem/RenameItem.module.less";
import { RenameFile } from "client/src/domain/Document/RenameFile/RenameFile";
import clsx from "clsx";

import { AlertBanner } from "../../../components/Banner/AlertBanner";
import { TrashIcon } from "../../../components/Icons/TrashIcon";
import { ResourcesTable } from "../../../components/SlobTable/ResourcesTable";
import { StackY } from "../../../components/Spacing/Spacing";
import { Tooltip } from "../../../components/Tooltip/Tooltip";
import { Body3 } from "../../../components/Typography/Typography";

import * as styles from "./rejectedFileTable.module.less";

import type { RcFile } from "antd/lib/upload";

export type RejectedFile = {
  key: string;
  uid: string;
  name: string;
  size: number;
  type: string;
  originFileObj: RcFile | undefined;
  reason: string;
  code: "TOO_LARGE" | "DUPLICATE_NAME" | "UNSUPPORTED" | "NAME_TOO_LONG";
  category: string;
  addedBy: string;
};

type RejectedFileTableProps = {
  rejectedFiles: RejectedFile[];
  renameDocument: (fileId: number | string, newName: string) => Promise<void> | void;
  track: (buttonLabel: string) => void;
  onClickRemoveRejected?: (filename: string) => void;
};

export function RejectedFileTable({
  rejectedFiles,
  renameDocument,
  track,
  onClickRemoveRejected,
}: RejectedFileTableProps) {
  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "rejectedName",
      render: (text: unknown, record: RejectedFile) => {
        return record.code === "DUPLICATE_NAME" || record.code === "NAME_TOO_LONG" ? (
          <RenameFile
            record={{ ...record, id: record.uid, isNew: false }}
            renameDocument={renameDocument}
          />
        ) : (
          record.name
        );
      },
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
      key: "addedBy",
    },
    {
      width: "60px",
      title: "",
      key: "action",
      render: (text: unknown, record: RejectedFile) => (
        <div className={styles.actions}>
          <Tooltip title="Delete" placement="bottom">
            <span>
              <button
                onClick={() => {
                  track("Remove file");
                  onClickRemoveRejected?.(record.name);
                }}
                title="Remove file"
                aria-label="Remove file"
                className="btn-reset"
              >
                <TrashIcon />
              </button>
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      {rejectedFiles.length > 0 && (
        <StackY dist={16}>
          <AlertBanner
            variant="error"
            message={<Body3>Fix errors to upload the following files</Body3>}
          />

          <ResourcesTable
            dataSource={rejectedFiles}
            columns={columns}
            pagination={false}
            rowClassName={clsx(
              styles.rejectedRowWrapper,
              renameItemStyles.renameButton_hoverableContainer,
            )}
            expandable={{
              expandedRowRender: (rf) => (
                <div className={styles.fileErrorWrapper}>
                  <FontAwesomeIcon icon={faExclamationTriangle} className={"redMedium"} />
                  <span className={clsx("body5", "redMedium")}>{rf.reason}</span>
                </div>
              ),
              rowExpandable: (rf) => !!rf.reason,
              defaultExpandAllRows: true,
              expandRowByClick: false,
              expandIconColumnIndex: -1, // hide the expand icon
              expandedRowClassName: () => {
                return String(styles.expandedRowWrapper);
              },
            }}
          />
        </StackY>
      )}
    </>
  );
}
