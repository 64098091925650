import { brokerTierValues, priorCarrierBillValues } from "server/types/JiraIssue";
import { ticketStatus } from "server/types/Ticket";
import { kickoffCallStatus, NIGOReasons, implementationType } from "shared/types/Client";
import { LocationStateCodes } from "shared/types/Location";
import { FrontLoadingValues, DentalRolloverValues, PhaseIdValues } from "shared/types/Policy";
import { InforceCoveragesValues } from "shared/types/SlfCoverages";
import * as Yup from "yup";
import type { KickoffCallStatus, NIGOReason } from "../types/Client";
import type { Frontloading, MvpBrokerTier, PhaseId, PriorCarrierBill } from "@prisma/client";
import type { TicketStatus } from "server/types/Ticket";
import type { ImplementationType } from "shared/types/Client";
import type { ValueOf } from "shared/types/Helper";
import type { LocationStateCode } from "shared/types/Location";
import type { InforceCoveragesValue, SlfCoverageLongName } from "shared/types/SlfCoverages";
export const ticketIdValidator = Yup.string()
  // https://community.atlassian.com/t5/Bitbucket-questions/Regex-pattern-to-match-JIRA-issue-key/qaq-p/233319
  // Matches any jira ticket (so they say...)
  // .matches(/^((?!([A-Z0-9a-z]{1,10})-?$)[A-Z]{1}[A-Z0-9]+-[1-9][0-9]*)$/g, {

  // For matching a ticket name that starts with BUILD- and has up to 7 digits
  .matches(/^BUILD-\d+$/, {
    message: "JIRA ticket must be in format BUILD-12345",
  })
  .required("JIRA ticket is required");

export const phaseIDValidator = Yup.mixed<PhaseId>()
  .oneOf(PhaseIdValues, "Invalid phase ID")
  .required();

export interface PrimaryTicket extends Yup.InferType<typeof validatePrimaryTicketSchema> {}
export const validatePrimaryTicketSchema = Yup.object({
  ticketId: ticketIdValidator,
  jiraCreatedAt: Yup.date().required(),
  jiraUpdatedAt: Yup.date().required(),
  implementationType: Yup.mixed<ImplementationType>()
    .oneOf(implementationType, "Invalid implementation type")
    .required("Implementation type is required"),
  caseId: Yup.string()
    .max(32, "must be less than 32 characters")
    .test("is-number", "must only have numbers", (value) => !isNaN(parseInt(value ?? "", 10)))
    .default(null),
  name: Yup.string().required("Name is required"),
  kickoffCallStatus: Yup.mixed<KickoffCallStatus>()
    .oneOf(kickoffCallStatus, "Invalid kickoff call status")
    .nullable()
    .default(null),
  priorCarrierCertificationsStatus: Yup.mixed<PriorCarrierBill>()
    .oneOf(priorCarrierBillValues, "Invalid prior carrier certifications status")
    .nullable()
    .default(null),
  NIGOReasons: Yup.array()
    .of(Yup.mixed<NIGOReason>().oneOf(NIGOReasons, "Invalid NIGO reason").required())
    .default([]),
  slfImplementationConsultantEmail: Yup.string()
    .email("SLF IC invalid email")
    .nullable()
    .default(null),
  mvpBrokerTier: Yup.mixed<MvpBrokerTier>()
    .oneOf(brokerTierValues, "Invalid MVP Broker Tier type")
    .nullable()
    .default(null),
  // the ebr and cre email fields are sometimes use for email like strings (i.e. email@test.com + random note)
  ebrEmailAddress: Yup.string().nullable().default(null),
  creCssEmailAddress: Yup.string().nullable().default(null),
  creCssName: Yup.string().nullable().default(null),
  territory: Yup.string().nullable().default(null),
  inForceSLCoverages: Yup.array()
    .of(
      Yup.mixed<SlfCoverageLongName>()
        .oneOf(InforceCoveragesValues, "Invalid INForce SLF Coverages")
        .required(),
    )
    .default([]),
  sLConnectCredentialSentDate: Yup.date().nullable().default(null),
  employeesNumber: Yup.number().nullable().default(null),
  baRegisteredSLConnect: Yup.boolean().required(),
  eoiRequired: Yup.boolean().required(),
  benefitsExplorerCustomBenefitOrder: Yup.boolean().required(),
  benefitsExplorerCustomBranding: Yup.boolean().required(),
  nonSunLifeBenefitsExplorer: Yup.boolean().required(),
  digitalEIF: Yup.boolean().required(),
  completedPaperworkReceivedOutsideOnboard: Yup.boolean().required(),
  censusTaskHidden: Yup.boolean().required(),
  healthcareProfessionalsSegment: Yup.boolean().required(),
  isMaxwellClient: Yup.boolean().required(),
  isOnboard: Yup.boolean().required(),
  isTest: Yup.boolean().required(),
  isBenefitsExplorer: Yup.boolean().required(),
  liveStatusDate: Yup.date().nullable().default(null),
  deductibleMaximumReportTask: Yup.boolean().required(),
}).required();

export interface PolicyTicket extends Yup.InferType<typeof validatePolicyTicketSchema> {}
export const validatePolicyTicketSchema = Yup.object({
  ticketId: ticketIdValidator,
  jiraCreatedAt: Yup.date().required(),
  jiraUpdatedAt: Yup.date().required(),
  implementationType: Yup.mixed<ImplementationType>()
    .oneOf(implementationType, "Invalid implementation type")
    .required("Implementation type is required"),
  phaseId: phaseIDValidator,
  ticketStatus: Yup.mixed<TicketStatus>().oneOf(ticketStatus, "Invalid ticket status").required(),
  primaryPolicy: Yup.boolean().required(),
  policyEffective: Yup.date().nullable().default(null),
  priorCarrierBill: Yup.mixed<PriorCarrierBill>()
    .oneOf(priorCarrierBillValues, "Invalid prior carrier bill")
    .nullable()
    .default(null),
  enrollmentCensusDeadline: Yup.date().nullable().default(null),
  infoGatheringDeadline: Yup.date().nullable().default(null),
  slfCoverages: Yup.array()
    .of(
      Yup.mixed<InforceCoveragesValue>()
        .oneOf(InforceCoveragesValues, "Invalid SLF Coverages")
        .required(),
    )
    .default([]),
  slfCoveragesReady: Yup.array()
    .of(
      Yup.mixed<InforceCoveragesValue>()
        .oneOf(InforceCoveragesValues, "Invalid SLF Coverages")
        .required(),
    )
    .default([]),

  slfPolicyNumber: Yup.array().of(Yup.string()).default([]),
  frontloading: Yup.mixed<Frontloading>()
    .oneOf(FrontLoadingValues, "Invalid Frontloading value")
    .nullable()
    .default(null),
  approvedPFMLPlans: Yup.boolean().required(),
  spdCompletedForPFML: Yup.boolean().required(),
  massachusettsPFMLCheckCutting: Yup.number().nullable().default(null),
  connecticutPFMLCheckCutting: Yup.number().nullable().default(null),
  washingtonPFMLCheckCutting: Yup.number().nullable().default(null),
  coloradoPFMLCheckCutting: Yup.number().nullable().default(null),
  oregonPFMLCheckCutting: Yup.number().nullable().default(null),
  sunAdvisorSPDComplete: Yup.boolean().required(),
  sunAdvisorAdvicePlusPreFundingAmount: Yup.number().nullable().default(null),
  issuedStateSitus: Yup.array()
    .of(
      Yup.mixed<LocationStateCode>()
        .oneOf(LocationStateCodes, "Invalid Site situs value")
        .required(),
    )
    .default([]),
  dentalRollover: Yup.mixed<ValueOf<typeof DentalRolloverValues>>()
    .oneOf(DentalRolloverValues, "Invalid dental rollover status")
    .nullable()
    .default(null),
  dentalRolloverCompleted: Yup.boolean().required(),
  liveStatusDate: Yup.date().nullable().default(null),
});
