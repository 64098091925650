import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2 } from "client/src/components/Typography/Typography";
import { getNonClassBenefitsContributionsDetailInfoList } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/getNonClassBenefitsContributionsDetailInfoList";
import { EIFEntityDeletedBy } from "client/src/domain/EIF/common/EIFEntityDeletedBy";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getIsPFMLBenefitType, getIsStatutoryBenefitType } from "shared/types/BenefitTypes";
import { getEIFSubStepMap } from "shared/types/EIF";
import {
  pfmlBenefitTypeToRelevantStateLabel,
  StatutoryBenefitTypeToCoverage,
} from "shared/types/Plan";
import { wasEntityAddedInLatestICEdit } from "shared/utils/EIF/changeLog";
import {
  getPFMLClientPlansForState,
  getRelevantPFMLBenefitTypes,
} from "shared/utils/EIF/nonClassBenefitsPreferences";
import { assertIsDefined, rejectNullableValues, unique } from "shared/utils/utils";
import type { ChangeDetailInfoList } from "client/src/domain/EIF/common/EditBanner";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EIFPlanConfigEligibilityOnlyPFMLSubStep, EIFSubStepId } from "shared/types/EIF";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type EIFChangesSummaryForPlanConfigAndEligibilityProps = {
  client: Client;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
  employeeClasses: EmployeeClass[];
  clientPlans: Plan[];
  eifSubStepId: EIFSubStepId;
  changeDetailInfoList: ChangeDetailInfoList;
  featureToggles: ClientFeatureToggles;
};

export const EIFChangesSummaryForPlanConfigAndEligibility = ({
  client,
  changeSnapshot,
  employeeClasses,
  authUser,
  clientPlans,
  eifSubStepId,
  changeDetailInfoList,
  featureToggles,
}: EIFChangesSummaryForPlanConfigAndEligibilityProps) => {
  const changesPerClass = employeeClasses
    .map((employeeClass, index) => {
      const { metadata, ...changeDetailInfoWithoutMetadata } =
        changeSnapshot.EmployeeClass[employeeClass.id] || {};
      const employeeClassChanges = Object.values(changeDetailInfoWithoutMetadata);

      const employeeClassPlanChanges = employeeClass.employeeClassPlans
        .map((employeeClassPlan) => changeSnapshot.EmployeeClassPlan[employeeClassPlan.id])
        .filter(rejectNullableValues)
        .map(({ metadata, ...rest }) => rest)
        .flatMap((item) => Object.values(item));

      const changeDetailInfoList = [...employeeClassChanges, ...employeeClassPlanChanges];

      if (changeDetailInfoList.length === 0) {
        return null;
      }

      return {
        index,
        employeeClass,
        changeDetailInfoList,
      };
    })
    .filter(rejectNullableValues);

  const pfmlClientPlans = clientPlans.filter((clientPlan) =>
    getIsPFMLBenefitType(clientPlan.benefitType),
  );

  const relevantPFMLBenefitTypes = getRelevantPFMLBenefitTypes(pfmlClientPlans);
  const uniqueSectionStates = unique(
    relevantPFMLBenefitTypes.map((relevantPFMLBenefitType) => {
      return pfmlBenefitTypeToRelevantStateLabel[relevantPFMLBenefitType];
    }),
  );

  const statutoryPlans = clientPlans.filter((clientPlan) =>
    getIsStatutoryBenefitType(clientPlan.benefitType),
  );

  const changesPerStatutoryPlan = statutoryPlans
    .map((statutoryPlan) => {
      const changeDetailInfoList = getNonClassBenefitsContributionsDetailInfoList(changeSnapshot, [
        statutoryPlan,
      ]);

      if (
        changeDetailInfoList.length === 0 ||
        !getIsStatutoryBenefitType(statutoryPlan.benefitType)
      ) {
        return null;
      }

      return {
        statutoryLabel: StatutoryBenefitTypeToCoverage[statutoryPlan.benefitType],
        statutoryPlan,
        changeDetailInfoList,
      };
    })
    .filter(rejectNullableValues);

  const changesPerState = uniqueSectionStates
    .map((state) => {
      const relevantPFMLClientPlans = getPFMLClientPlansForState(state, pfmlClientPlans);
      const changeDetailInfoList = getNonClassBenefitsContributionsDetailInfoList(
        changeSnapshot,
        relevantPFMLClientPlans,
      );

      if (changeDetailInfoList.length === 0) {
        return null;
      }

      return {
        state,
        relevantPFMLClientPlans,
        changeDetailInfoList,
      };
    })
    .filter(rejectNullableValues);

  const hasFliPreferencesChanges = Boolean(
    changeSnapshot.Client.fliExcludedGroups || changeSnapshot.Client.fliCoversAllEmployees,
  );

  if (
    changesPerClass.length === 0 &&
    changesPerStatutoryPlan.length === 0 &&
    changesPerState.length === 0 &&
    !hasFliPreferencesChanges
  ) {
    return null;
  }

  const eifFliStepId = "fli-preferences" satisfies EIFPlanConfigEligibilityOnlyPFMLSubStep;
  const fliStepName = getEIFSubStepMap({ eifSubStepId: eifFliStepId });

  return (
    <StackY dist={16} wrap={false} data-testid="class-builder">
      <EIFEntityDeletedBy
        changeSnapshot={changeSnapshot}
        authUser={authUser}
        client={client}
        entity="EmployeeClass"
        prefixWord="Eligibility"
        pluralizeWord="Group"
        changeSummaryVariant={true}
      />

      {changesPerClass.map((item) => {
        const { index, employeeClass, changeDetailInfoList } = item;

        return (
          <StackY dist={8} wrap={false} key={employeeClass.id}>
            <Body2>
              {employeeClass.groupName
                ? employeeClass.groupName
                : `Eligible employee group ${index + 1}`}
            </Body2>
            <EditedFieldMsg
              changeDetailInfoList={changeDetailInfoList}
              withAlertBanner
              client={client}
              authUser={authUser}
              isAdd={wasEntityAddedInLatestICEdit(employeeClass, client)}
            />
          </StackY>
        );
      })}

      {changesPerState.map((item) => {
        const { state, relevantPFMLClientPlans, changeDetailInfoList } = item;
        assertIsDefined(relevantPFMLClientPlans[0], "relevantPFMLClientPlans[0]");
        return (
          <StackY dist={8} wrap={false} key={state}>
            <Body2>{state}</Body2>
            <EditedFieldMsg
              changeDetailInfoList={changeDetailInfoList}
              withAlertBanner
              client={client}
              authUser={authUser}
              isAdd={wasEntityAddedInLatestICEdit(relevantPFMLClientPlans[0], client)}
            />
          </StackY>
        );
      })}

      {changesPerStatutoryPlan.map((item) => {
        const { statutoryLabel, statutoryPlan, changeDetailInfoList } = item;
        return (
          <StackY dist={8} wrap={false} key={statutoryLabel}>
            <Body2>{statutoryLabel}</Body2>
            <EditedFieldMsg
              changeDetailInfoList={changeDetailInfoList}
              withAlertBanner
              client={client}
              authUser={authUser}
              isAdd={wasEntityAddedInLatestICEdit(statutoryPlan, client)}
            />
          </StackY>
        );
      })}

      {featureToggles.ONBOARD_FLI_EXCLUDED_GROUPS &&
        hasFliPreferencesChanges &&
        eifSubStepId === "fli-preferences" && (
          <StackY dist={8} wrap={false}>
            <Body2>{fliStepName}</Body2>
            <EditedFieldMsg
              changeDetailInfoList={changeDetailInfoList}
              withAlertBanner
              client={client}
              authUser={authUser}
            />
          </StackY>
        )}
    </StackY>
  );
};
