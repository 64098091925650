import React from "react";
import ReactDOM from "react-dom/client";
import "./polyfills";

import { isLocalDev, isTest } from "shared/utils/config";
import { App } from "./App";
import { initYourBenefitsExplorer } from "./utils/benefits-explorer";
import { initDatadog } from "./utils/datadog";
import { getRootElement } from "./utils/getRootElement";
import { initHojar } from "./utils/hotjar";
import { initOnboard } from "./utils/onboard";
import { initSentry } from "./utils/sentry";

initOnboard();
initYourBenefitsExplorer();
initDatadog();
initSentry();
initHojar();

function renderApp() {
  const container = getRootElement();
  if (!container) {
    throw new Error(`Root element not found`);
  }
  const root = ReactDOM.createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

if (isLocalDev || isTest) {
  import("@axe-core/react").then(
    ({ default: axe }) => {
      axe(React, ReactDOM, 4000).then(renderApp, (err) => {
        console.log('Could not initialize "axe"');
        console.error(err);
      });
    },
    (err) => {
      console.log('Could not dynamically import "@axe-core/react"');
      console.error(err);
    },
  );
} else {
  renderApp();
}
