import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { DashedBanner } from "client/src/components/DashedBanner/DashedBanner";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3 } from "client/src/components/Typography/Typography";
import { EIFChangesSummaryForPlanConfigAndEligibility } from "client/src/domain/EIF/EIFChangesSummaryForPlanConfigAndEligibility";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getChangeDetailInfoListForStep } from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";
import { RouteData } from "shared/config/routeData";
import { eifStepIdMap, eifStepNavigation } from "shared/types/EIF";
import { getEIFSignedByFullName } from "shared/utils/EIF/getAuthorizedSignerFullName";
import type { DispatchWithoutAction } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { EIFStepId } from "shared/types/EIF";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";
import type { Subsidiary } from "shared/types/Subsidiary";
import type { ClientFeatureToggles } from "shared/types/Toggles";

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
const steps = Object.keys(eifStepNavigation) as EIFStepId[];

type Props = {
  client: Client;
  clientPlans: Plan[];
  contacts: Contact[];
  bills: Bill[];
  deletedBills: Bill[];
  changeSnapshot: DEIFChangeSnapshot;
  toggleIsVisibleRequestReview: DispatchWithoutAction;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
  employeeClasses: EmployeeClass[];
  subsidiaries: Subsidiary[];
};

export function EIFChangesSummary(props: Props) {
  const {
    client,
    clientPlans,
    bills,
    deletedBills,
    contacts,
    changeSnapshot,
    toggleIsVisibleRequestReview,
    authUser,
    featureToggles,
    employeeClasses,
    subsidiaries,
  } = props;

  const signedByFullName = getEIFSignedByFullName(client) || "-";

  return (
    <DashedBanner variant="warning">
      <Row align="middle" justify="space-between">
        <Col>
          <Body2 darkYellow>Internal User:</Body2>
        </Col>

        <Col>
          <Button
            type="secondary"
            onClick={
              client.deifChangesReadyForReviewAt == null ? toggleIsVisibleRequestReview : undefined
            }
            disabled={!!client.deifChangesReadyForReviewAt}
          >
            {client.deifChangesReadyForReviewAt ? "Review already requested" : "Send notification"}
          </Button>
        </Col>
      </Row>

      <div className="mt-20">
        <Body3 as="p">
          You’ve made changes to the client’s Company Information after{" "}
          <Body2>
            {signedByFullName}{" "}
            {client.brokerAsAuthorizedSigner ? `(Broker)` : `(Benefits Administrator)`}
          </Body2>{" "}
          submitted.
        </Body3>

        <Body3 as="p">
          Once you are done making changes, click <Body2>Send notification</Body2> to send the
          client an email asking them to review and accept the changes.
        </Body3>

        <StackY dist={16} wrap={false}>
          {steps
            .filter((step) => step !== "review-&-submit")
            .map((step) => {
              const changeListForCurrentStep = getChangeDetailInfoListForStep({
                eifStepId: step,
                changeSnapshot,
                client,
                clientPlans,
                contacts,
                bills,
                deletedBills,
                featureToggles,
                subsidiaries,
              });

              const subStepChanges = changeListForCurrentStep
                .map(([eifSubStepId, subStepName, changeDetailInfoList]) => {
                  if (changeDetailInfoList.length === 0) return null;

                  if (step === "plan-configuration-&-eligibility") {
                    return (
                      <div key={eifSubStepId}>
                        <EIFChangesSummaryForPlanConfigAndEligibility
                          client={client}
                          changeSnapshot={changeSnapshot}
                          employeeClasses={employeeClasses}
                          authUser={authUser}
                          clientPlans={clientPlans}
                          eifSubStepId={eifSubStepId}
                          changeDetailInfoList={changeDetailInfoList}
                          featureToggles={featureToggles}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <StackY dist={8} wrap={false} key={eifSubStepId} data-testid={eifSubStepId}>
                        <Body2>{subStepName}</Body2>
                        <EditedFieldMsg
                          changeDetailInfoList={changeDetailInfoList}
                          withAlertBanner
                          client={client}
                          authUser={authUser}
                        />
                      </StackY>
                    );
                  }
                })
                .filter((el) => el != null);

              if (subStepChanges.length === 0) return null;

              return (
                <StackY dist={8} wrap={false} key={step} data-testid={step}>
                  <InternalLinkButton
                    type="link-inline-bold"
                    size="middle"
                    to={RouteData.eifStepDetail.getPath(client.id, step)}
                  >
                    {eifStepIdMap[step]}

                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="ml-8"
                      style={{ fontSize: "0.875rem" }}
                    />
                  </InternalLinkButton>

                  <StackY dist={20} wrap={false}>
                    {subStepChanges}
                  </StackY>
                </StackY>
              );
            })}
        </StackY>
      </div>
    </DashedBanner>
  );
}
