import buttonStyles from "client/src/components/Button/button.module.less";
import contactUsStyles from "client/src/components/ContactUs/contactUs.module.less";
import clientPageBenefitStyles from "client/src/domain/ExplorerPages/ClientPage/Benefit/benefit.module.less";
import clientPageStyles from "client/src/domain/ExplorerPages/ClientPage/clientPage.module.less";
import { getColor } from "client/src/utils/color";
import { getCSSVariableValue } from "client/src/utils/getCSSVariableValue";
import { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { getTextColor } from "shared/utils/color";
import tinycolor from "tinycolor2";
import type { ExplorerPageCustomTheme } from "shared/types/ExplorerPage";

export function useCustomTheme(theme?: ExplorerPageCustomTheme | null) {
  const enabled = Boolean(theme);
  const defaultBrandColor = getCSSVariableValue("--color-primary-500");
  const defaultContrastColor = getCSSVariableValue("--color-tertiary-500");
  const brandColor = tinycolor(theme?.brandColor ?? defaultBrandColor);
  const contrastColor = tinycolor(theme?.contrastingColor ?? defaultContrastColor);

  // Picked from: client/src/app.less#L15
  const css = useMemo(() => {
    const colors = {
      brand800: getColor(brandColor, 800), //       #8b5c04
      brand600: getColor(brandColor, 600), //       #d3980d
      brand500: getColor(brandColor, 500), //       #eaab00
      brand400: getColor(brandColor, 400), //       #ffcb05
      brand200: getColor(brandColor, 200), //       #ffe582
      brand100: getColor(brandColor, 100), //       #fff4c2
      brand50: getColor(brandColor, 50), //         #fffae0
      contrast900: getColor(contrastColor, 900), // #004570
      contrast800: getColor(contrastColor, 800), // #00588b
      contrast700: getColor(contrastColor, 700), // #0068a1
      contrast600: getColor(contrastColor, 600),
      contrast500: getColor(contrastColor, 500), // #0093cf
      contrast400: getColor(contrastColor, 400), // #7fabc4
      contrast300: getColor(contrastColor, 300), // #c0d3d8
    };

    return `
    :root {
      --color-primary-800: ${colors.brand800};
      --color-primary-600: ${colors.brand600};
      --color-primary-500: ${colors.brand500};
      --color-primary-400: ${
        colors.brand500 /* This is the predominant brand color in benefits explorer. When the user select a custom brand color this is the one that appears predominantly. */
      };
      --color-primary-200: ${colors.brand200};
      --color-primary-100: ${colors.brand100};
      --color-primary-50:  ${colors.brand50};

      --color-tertiary-900: ${colors.contrast900};
      --color-tertiary-800: ${colors.contrast800};
      --color-tertiary-700: ${
        colors.contrast500 /* This is the predominant contrast color in benefits explorer. */
      };
      --color-tertiary-500: ${colors.contrast500};
      --color-tertiary-400: ${colors.contrast400};
      --color-tertiary-300: ${colors.contrast300};

      --color-text-primary: ${getTextColor(colors.brand500)};
      --color-text-primary-50: ${getTextColor(colors.brand50)};
      --color-text-secondary: ${getTextColor(colors.contrast500)};
    }

    .${clientPageStyles.menuLink} .${clientPageStyles.linkText} {
      --color-secondary-900: ${getTextColor(colors.brand600)};
    }

    .${buttonStyles.primary} {
      --color-secondary-900: ${getTextColor(colors.brand600)};
    }

    .${buttonStyles.secondary} {
      --color-tertiary-900: ${colors.contrast600};
      --color-tertiary-800: ${colors.contrast500};
      --color-tertiary-400: ${colors.contrast400};
      color: ${getTextColor(colors.contrast500)} !important;
    }

    .${clientPageBenefitStyles.planSummaryLink} a {
      color: var(--color-gray-900);
    }

    .${contactUsStyles.heading} span {
      color: var(--color-text-primary);
    }
  `;
  }, [brandColor, contrastColor]);

  const style = useRef<HTMLStyleElement | null>(null);

  const removeStyle = useCallback(() => {
    if (style.current) {
      document.head.removeChild(style.current);
      style.current = null;
    }
  }, [style]);

  const updateStyle = useCallback(
    (content: string) => {
      removeStyle();
      style.current = document.createElement("style");
      style.current.appendChild(document.createTextNode(content));
      document.head.appendChild(style.current);
    },
    [removeStyle],
  );

  useLayoutEffect(() => {
    if (enabled) updateStyle(css);
    else removeStyle();
  }, [enabled, css, updateStyle, removeStyle]);
}
