import { faExclamationCircle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { handleSize, handleType } from "client/src/components/Button/ButtonOld";
import { getExplorerPageURL } from "client/src/domain/ExplorerPages/utils";
import clsx from "clsx";
import { RouteData } from "shared/config/routeData";

import { getDaysFromDates } from "../../../../../shared/utils/date";
import { formatDate } from "../../../../../shared/utils/format";
import { InternalLinkButton } from "../../../components/Button/InternalLinkButton";
import { SlobTable } from "../../../components/SlobTable/SlobTable";

import { Tooltip } from "../../../components/Tooltip/Tooltip";
import * as styles from "./ExplorerPageTable.module.less";

import type { ExplorerPage } from "../../../../../shared/types/ExplorerPage";
import type { SlobColumnsType, SlobTableProps } from "../../../components/SlobTable/SlobTable";

export type ExplorerPageTableProps = Omit<SlobTableProps<ExplorerPage>, "columns">;

export const DataTestId = {
  FontAwesomeIconInfoAlert: "font-awesome-icon-info-alert",
  SiteNotYetAvailableIcon: "site-not-yet-available-icon",
  renewIcon: "renew-info-icon",
};

const DAYS_CLOSE_TO_EXPIRATION = 90;

const DAYS_EXPIRED = 0;

export const ExplorerPageTable = ({
  isLoading,
  currentPage,
  onChange,
  totalItems,
  pageSize,
  clientId,
  data: explorerPages,
  featureToggles,
}: ExplorerPageTableProps) => {
  return (
    <>
      <SlobTable
        columns={makeTableColumns(clientId || "")}
        isLoading={isLoading}
        currentPage={currentPage}
        onChange={onChange}
        totalItems={totalItems}
        pageSize={pageSize}
        data={explorerPages}
        getIsRowInErrorState={getIsRowInErrorState}
        featureToggles={featureToggles}
      />
    </>
  );
};

const makeTableColumns = (clientId: string): SlobColumnsType<ExplorerPage> => {
  return [
    {
      title: "Site Name",
      dataIndex: "name",
      sorter: true,
      render: (value: string, record) => {
        const isSiteNotYetAvailable = record.startsAt
          ? record.startsAt.getTime() > Date.now()
          : false;
        return (
          <>
            {value}
            {isSiteNotYetAvailable && record.startsAt && (
              <Tooltip
                title={`This new site is scheduled to begin on ${formatDate(
                  record.startsAt,
                )}. Click edit to see your past site.`}
                placement="bottom"
              >
                <FontAwesomeIcon
                  className={styles.siteNotYetAvailableIcon}
                  data-testid={DataTestId.SiteNotYetAvailableIcon}
                  flip="vertical"
                  icon={faExclamationCircle}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      sorter: true,
      render: (createdAt: Date) => formatDate(createdAt),
    },

    {
      title: "Site Availability Date Range",
      dataIndex: "startsAt",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_: unknown, record) => {
        const { startsAt, expiresAt } = record;
        return startsAt && expiresAt ? (
          getDaysFromDates(expiresAt, new Date()) <= DAYS_CLOSE_TO_EXPIRATION &&
          getDaysFromDates(expiresAt, new Date()) >= DAYS_EXPIRED ? (
            <>
              {formatDate(startsAt)} - {formatDate(expiresAt)}{" "}
              <Tooltip
                title={
                  "Warning  - your site is about to expire. Update the details of your Site or add a new Explorer Site."
                }
                placement="bottom"
              >
                <FontAwesomeIcon
                  data-testid={DataTestId.FontAwesomeIconInfoAlert}
                  icon={faTriangleExclamation}
                  className={styles.siteExpiredIcon}
                />
              </Tooltip>
            </>
          ) : getDaysFromDates(expiresAt, new Date()) < DAYS_EXPIRED ? (
            <>
              {formatDate(startsAt)} - {formatDate(expiresAt)}{" "}
              <Tooltip
                title={
                  "Warning - your Explorer site has been expired. Update the details of your site or add a new Sun Life Benefits Explorer Site."
                }
                placement="bottom"
              >
                <FontAwesomeIcon
                  data-testid={DataTestId.FontAwesomeIconInfoAlert}
                  icon={faTriangleExclamation}
                  className={styles.siteExpiredIcon}
                />
              </Tooltip>
            </>
          ) : (
            `${formatDate(startsAt)} - ${formatDate(expiresAt)}`
          )
        ) : null;
      },
    },

    {
      render: (_: unknown, record) => {
        const { id, vanitySlug, customBranding } = record;
        return (
          <div className={styles.actionButtons}>
            {customBranding ? (
              <a
                className={clsx(
                  "ant-btn",
                  handleType("link"),
                  handleSize("xtra-small"),
                  "link-inline-bold",
                )}
                href={getExplorerPageURL(vanitySlug ?? id, !!vanitySlug, customBranding)}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className={clsx("ant-btn-content")}>View</span>
                <span className="sr-only">(opens in a new tab)</span>
              </a>
            ) : (
              <InternalLinkButton
                type="link-inline-bold"
                size="xtra-small"
                to={RouteData.benefitsExplorerInternal.getPath(id)}
                target="_blank"
              >
                View
              </InternalLinkButton>
            )}
            <InternalLinkButton
              type="link-inline-bold"
              size="xtra-small"
              to={RouteData.editBenefitsExplorerSite.getPath(clientId, id)}
            >
              Edit
            </InternalLinkButton>
            <InternalLinkButton
              type="link-inline-bold"
              size="xtra-small"
              to={RouteData.renewBenefitsExplorerSite.getPath(clientId, id)}
            >
              Renew
              <Tooltip
                title={
                  "This will copy your site Configuration page and its details which can help save you time if you need to create a new Site. If you wish to start with an empty Configuration page, add an Explorer Site. "
                }
                placement="bottom"
              >
                <FontAwesomeIcon
                  className={styles.siteNotYetAvailableIcon}
                  data-testid={DataTestId.renewIcon}
                  flip="vertical"
                  icon={faExclamationCircle}
                />
              </Tooltip>
            </InternalLinkButton>
          </div>
        );
      },
    },
  ];
};

const getIsRowInErrorState = (explorerPage: ExplorerPage) => {
  return (
    (explorerPage.expiresAt &&
      getDaysFromDates(explorerPage.expiresAt, new Date()) <= DAYS_CLOSE_TO_EXPIRATION &&
      getDaysFromDates(explorerPage.expiresAt, new Date()) >= DAYS_EXPIRED) ??
    false
  );
};
