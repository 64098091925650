import { FilterByClientErrorTypePopover } from "client/src/domain/Client/ClientErrors/FilterByClientErrorTypePopover";
import { type FC, memo } from "react";
import { AlertBanner } from "../../../components/Banner/AlertBanner";
import { Body3 } from "../../../components/Typography/Typography";
import type { ClientErrorType } from "@prisma/client";
import type { Client } from "shared/types/Client";

type ClientErrorsBannerProps = {
  data?: {
    data: Client[];
    meta: {
      count: number;
      countByError?: Record<string, number>;
      countWithErrors?: number;
    };
  };
  filters: ClientErrorType[];
  onChange: (filters: ClientErrorType[]) => void;
};

export const ClientErrorsBanner: FC<ClientErrorsBannerProps> = memo(
  ({ data, filters, onChange }) => {
    if (!data) {
      return null;
    }

    const countByError = data.meta.countByError || {};
    const errorCount = Object.values(countByError).reduce((sum, item) => sum + item, 0);

    if (!errorCount) {
      return null;
    }

    return (
      <AlertBanner
        variant="error"
        message={
          <>
            <Body3>
              There are <strong>{errorCount}</strong> issues across{" "}
              <strong>{data.meta.countWithErrors}</strong> clients. See client specific
              troubleshooting help each client row.
            </Body3>
            <FilterByClientErrorTypePopover
              countByError={countByError}
              filters={filters}
              onChange={onChange}
            />
          </>
        }
      />
    );
  },
);
