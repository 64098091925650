import { Row, Col } from "client/src/components/Grid/Grid";
import { Link } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { Button } from "../../components/Button/Button";

import type { ClientId } from "shared/types/Client";

type ExplorerPageCreateProps = {
  clientId: ClientId;
};

export const ExplorerPageCreatePage = ({ clientId }: ExplorerPageCreateProps) => {
  return (
    <Row>
      <Col span={15}>
        <Link to={RouteData.createBenefitsExplorerSite.getPath(clientId)}>
          <Button type="default" size="middle">
            Add Explorer Site
          </Button>
        </Link>
      </Col>
    </Row>
  );
};
