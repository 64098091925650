import { getIsBenAdmin, getIsBroker } from "shared/rbac/rbac";
import { BEN_ADMIN, BROKER, SLF_ADMIN, SLF_OWNER } from "shared/rbac/roles";
import { getAreTherePendingChanges } from "shared/utils/EIF/changeLog";
import { getEIFEditState } from "shared/utils/EIF/getEIFEditState";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";

type Args = {
  client: Client;
  authUser: UserData | null;
  changesSnapshot: DEIFChangeSnapshot | null;
};

export function getIsAllowedToReviewEIF(args: Args) {
  const { authUser } = args;

  const role = authUser?.role || "none";

  const isAllowedToReviewEIF = (function () {
    switch (role) {
      case SLF_OWNER:
      case SLF_ADMIN:
        return true;
      case BROKER:
      case BEN_ADMIN: {
        const eifEditState = getEIFEditState(args);

        switch (eifEditState) {
          case "no-edits":
          case "ready-for-review":
          case "changes-declined-no-new-edits":
          case "changes-accepted":
            return true;
          case "edits-in-progress":
          case "changes-declined-then-edited":
            return false;

          default:
            exhaustiveCheck(eifEditState);
            return false;
        }
      }
      case "none":
        return false;
      default:
        exhaustiveCheck(role);
        return false;
    }
  })();

  return isAllowedToReviewEIF;
}

export function getIsAllowedToAcceptOrDeclineEIF(args: Args) {
  const { client, authUser, changesSnapshot } = args;

  if (authUser === null) {
    return false;
  }

  // outside signer auth returns an authUser with a role of BEN_ADMIN so this checks works OK for outside signer
  const isBA = getIsBenAdmin(authUser);
  const isBroker = getIsBroker(authUser);
  const isCorrectUserRole = client.brokerAsAuthorizedSigner ? isBroker : isBA;

  const hasPendingChanges = changesSnapshot ? getAreTherePendingChanges(changesSnapshot) : false;

  const { authorizedSignerUser, outsideSigner } = client;

  const isAuthorizedSignerUser = authorizedSignerUser
    ? authorizedSignerUser.id === authUser.id
    : outsideSigner
    ? outsideSigner.id === authUser.id
    : false;

  const isAllowedToAcceptOrDeclineEIF =
    isCorrectUserRole &&
    isAuthorizedSignerUser &&
    hasPendingChanges &&
    !!client.deifChangesReadyForReviewAt;
  return isAllowedToAcceptOrDeclineEIF;
}
