import { faChevronDown, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Popover from "@radix-ui/react-popover";
import { Button } from "client/src/components/Button/Button";
import { Checkbox, type CheckboxChangeEvent } from "client/src/components/Form/Checkbox";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body2 } from "client/src/components/Typography/Typography";
import { clientErrorLabel, clientErrorWarningTypes } from "client/src/hooks/clientError";
import { uniq } from "lodash";
import { useCallback } from "react";
import { getKeys } from "shared/utils/utils";
import { ReactComponent as WarningInfo } from "../../../components/Icons/WarningInfo.svg";
import * as style from "./filterByClientErrorTypePopover.module.less";
import type { ClientErrorType } from "@prisma/client";
import type { FC } from "react";

export type FilterByClientErrorTypePopoverProps = {
  countByError: Record<ClientErrorType, number>;
  filters: ClientErrorType[];
  onChange: (filters: ClientErrorType[]) => void;
};

export const FilterByClientErrorTypePopover = ({
  countByError,
  filters,
  onChange,
}: FilterByClientErrorTypePopoverProps) => {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button
          type="text"
          size="xtra-small"
          aria-label="Filter by issue type"
          className={style.viewMoreBtn}
        >
          <StackX dist={8}>
            <span>Filter by issue type</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </StackX>
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className={style.popoverContent} sideOffset={10}>
          <ErrorFilter data={countByError} filters={filters} onChange={onChange} />
          <Popover.Arrow className={style.popoverArrow} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

const warnings = getKeys(clientErrorLabel).filter((clientError) =>
  clientErrorWarningTypes.includes(clientError),
);
const errors = getKeys(clientErrorLabel).filter(
  (clientError) => !clientErrorWarningTypes.includes(clientError),
);

type ErrorFilterProps = {
  data: Record<ClientErrorType, number>;
  filters: ClientErrorType[];
  onChange: (value: ClientErrorType[]) => void;
};
const ErrorFilter: FC<ErrorFilterProps> = ({ data, filters, onChange }) => {
  const handleChange = useCallback(
    (e: CheckboxChangeEvent) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- help Checkbox remember the value type
      const { checked, value } = e.target as { checked: boolean; value: ClientErrorType };
      const newSelected = checked ? [...filters, value] : filters.filter((s) => s !== value);
      onChange(newSelected);
    },
    [onChange, filters],
  );

  return (
    <div>
      <div className={style.filterHeader}>
        <span className={style.iconError}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        <Body2>Errors</Body2>
        {errors.some((error) => !filters.includes(error)) ? (
          <Button
            type="text"
            className={style.toggleAll}
            onClick={() => onChange(uniq([...errors, ...filters]))}
          >
            Select all
          </Button>
        ) : (
          <Button
            type="text"
            className={style.toggleAll}
            onClick={() => onChange(filters.filter((filter) => !errors.includes(filter)))}
          >
            Unselect all
          </Button>
        )}
      </div>
      <StackY dist={8} className={style.filterContent}>
        {errors.map((error) => (
          <Checkbox
            key={error}
            name="clientErrorFilter"
            label={`${clientErrorLabel[error]} (${data[error]})`}
            value={error}
            checked={filters.includes(error)}
            onChange={handleChange}
          />
        ))}
      </StackY>
      <div className={style.filterHeader}>
        <span className={style.iconWarning}>
          <WarningInfo width={16} height={16} />
        </span>
        <Body2>Warnings</Body2>
        {warnings.some((warning) => !filters.includes(warning)) ? (
          <Button
            type="text"
            size="small"
            className={style.toggleAll}
            onClick={() => onChange(uniq([...warnings, ...filters]))}
          >
            Select all
          </Button>
        ) : (
          <Button
            type="text"
            size="small"
            className={style.toggleAll}
            onClick={() => onChange(filters.filter((filter) => !warnings.includes(filter)))}
          >
            Unselect all
          </Button>
        )}
      </div>
      <StackY dist={8} className={style.filterContent}>
        {warnings.map((warning) => (
          <Checkbox
            key={warning}
            name="clientErrorFilter"
            label={`${clientErrorLabel[warning]} (${data[warning]})`}
            value={warning}
            checked={filters.includes(warning)}
            onChange={handleChange}
          />
        ))}
      </StackY>
    </div>
  );
};
