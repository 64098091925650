import { SwapOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { ConfirmDialogDeleteClient } from "client/src/components/ConfirmDialog/ConfirmDialogDeleteClient";
import { Row, Col } from "client/src/components/Grid/Grid";
import { SlobDropdown } from "client/src/components/SlobDropdown/SlobDropdown";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3, Body5 } from "client/src/components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "client/src/components/UnorderedList/UnorderedList";
import { ClientInternalErrorBanner } from "client/src/domain/Client/ClientErrors/ClientInternalErrorBanner";
import { NotificationSettings } from "client/src/pages/Clients/NotificationSettings";
import { BrokerAsAuthorizedSignerCheckBox } from "client/src/pages/Clients/SetupCards/CardBenAdmins";
import { useTrackElementClicked } from "client/src/utils/analytics";
import { useCallback, useMemo, useState } from "react";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { formatShortDateTimeLocal, formatDateTimeLocal, formatFullName } from "shared/utils/format";

import { getIsInternalUser } from "../../../../shared/rbac/rbac";
import { isRiskRejected } from "../../../../shared/utils/riskRejected";
import { Button } from "../../components/Button/Button";
import { DropdownButton } from "../../components/Button/DropdownButton";
import { ClientDraftBadge } from "../../components/Client/ClientDraftBadge";
import { ClientTestBadge } from "../../components/Client/ClientTestBadge";
import { DetailsList } from "../../components/DetailsList/DetailsList";
import { genericErrorCopy2 } from "../../components/Error/ErrorMessage";
import { GoBackLink } from "../../components/Link/GoBackLink";
import { Loading } from "../../components/Loading/Loading";
import { ActionMenu } from "../../components/Menu/ActionMenu";
import { PageContent } from "../../components/PageContent/PageContent";
import { Tooltip } from "../../components/Tooltip/Tooltip";
import { slobMessage } from "../../components/slobMessage/slobMessage";
import { BrokerList } from "../../domain/Brokers/BrokerList";
import { PolicyList } from "../../domain/Client/PolicyList";
import { SubmitCompanyInformationSettings } from "../../domain/Client/SubmitCompanyInformationSettings";
import { ExplorerPageCreatedModal } from "../../domain/ExplorerPages/Modals/ExplorerPageCreatedModal";
import { useSlobAuth } from "../../hooks/auth";
import {
  useClientSetup,
  useDeleteClient,
  useGetClientByID,
  usePutClientInDraftMode,
  useSyncClient,
  useUpdateClient,
} from "../../hooks/client";
import { triggerError } from "../../hooks/generalError";
import { errorToMessage } from "../../hooks/query";
import { useGetServiceStatus } from "../../hooks/serviceCheck";
import { useClientHubParams } from "../../hooks/useClientHubParams";
import { useHubConfiguration } from "../../hooks/useConfig";

import { useGetFeatureToggles } from "../../hooks/useFeatureToggles";
import { BenAdminCreatePage } from "./BenAdminCreatePage";
import { BenAdminList } from "./BenAdminList";
import { BrokerCreatePage } from "./BrokerCreatePage";
import { ClientSetup } from "./ClientSetup";
import { ExplorerPageCreatePage } from "./ExplorerPageCreatePage";
import { ExplorerPageList } from "./ExplorerPages/ExplorerPageList";
import { ReplaceClient } from "./ReplaceClient";
import * as styles from "./clientDetail.module.less";

import type { ExplorerPage } from "../../../../shared/types/ExplorerPage";
import type { MenuProps } from "antd";
import type { Location } from "react-router-dom";

interface ClientDetailPageLocationState extends Location {
  state: { newExplorerPage: ExplorerPage | undefined } | undefined;
}

const { Title } = Typography;

export const ClientDetailPage = () => {
  const { clientId } = useClientHubParams(["clientId"]);
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  const location = useLocation() as ClientDetailPageLocationState;

  const config = useHubConfiguration();

  const { data: serviceStatus } = useGetServiceStatus();
  const isClientSyncDisabled = serviceStatus?.jira === false;

  const { data: client, isLoading: isClientLoading, error } = useGetClientByID(clientId);

  const { mutateAsync: deleteClient } = useDeleteClient();
  const { mutateAsync: updateClient } = useUpdateClient();
  const {
    mutateAsync: syncClient,
    isPending: isUpdateClientLoading,
    error: syncError,
  } = useSyncClient();
  const { mutateAsync: putClientInDraftMode } = usePutClientInDraftMode();

  const {
    data: featureToggles,
    isPending: isPendingFeatureToggles,
    error: errorFeatureToggles,
  } = useGetFeatureToggles(clientId);

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const { authUser } = useSlobAuth();
  const isInternalUser = getIsInternalUser(authUser);

  const { data: clientSetupData } = useClientSetup(clientId);

  const trackElementClicked = useTrackElementClicked(client);
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "Client Setup - Step 2",
        buttonLabel,
        moduleState: clientSetupData?.benAdmin.status,
      });
    },
    [trackElementClicked, clientSetupData?.benAdmin.status],
  );

  const detailsItems = useMemo(() => {
    if (!client) {
      return null;
    }
    const recentlyUpdatePolicy = client.policies.reduce((previous, current) => {
      return current.jiraUpdatedAt.getTime() > previous.jiraUpdatedAt.getTime()
        ? current
        : previous;
    });
    const policyLastUpdateDate = recentlyUpdatePolicy?.jiraUpdatedAt;
    return [
      { label: "Created Date", value: formatShortDateTimeLocal(client.createdAt) },
      {
        label: "Implementation Consultant",
        value: client.slfImplementationConsultant ? (
          formatFullName(client.slfImplementationConsultant)
        ) : (
          <div>
            <Body3>
              <b>No IC assigned</b>
            </Body3>
            <UnorderedList markerColor="gray">
              <UnorderedListItem>
                <Body5>
                  Check that the Jira ticket has an IC assigned in the SLF Implementation Consultant
                  field, under Internal Contacts.
                </Body5>
              </UnorderedListItem>
              <UnorderedListItem>
                <Body5>
                  Ask the assigned IC to make sure their email address visibility is set to "Anyone"
                  in the Profile and Visibility section of their Jira account.
                </Body5>
              </UnorderedListItem>
            </UnorderedList>
          </div>
        ),
      },
      {
        label: "Effective Dates & Policy Numbers",
        value: (
          <>
            <PolicyList client={client} jiraUrl={config.REACT_APP_JIRA_URL} />
          </>
        ),
      },
      { label: "Case ID", value: client.caseId },
      {
        label: "Last updated",
        value: policyLastUpdateDate ? formatDateTimeLocal(policyLastUpdateDate) : "-",
      },
    ];
  }, [client, config]);

  const handleSyncClient = async () => {
    const hide = slobMessage.loading("Update in progess", 0);

    try {
      await syncClient({ params: { clientId }, data: {} });
      void slobMessage.success("Client Updated");
    } catch (error) {
      console.error(error);
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleDelete = async () => {
    setIsDeleteDialogVisible(false);
    const hide = slobMessage.loading("Delete in progress", 0);

    try {
      const { isError, isSuccess } = await deleteClient({ params: { clientId } });

      if (isSuccess) {
        void slobMessage.success("Client Deleted");
        navigate(RouteData.homeInternal.getPath());
      } else if (isError) {
        void slobMessage.error(genericErrorCopy2);
      }
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handlePutInDraftMode = async () => {
    const hide = slobMessage.loading("Putting in Draft mode…", 0);

    try {
      const { isError, isSuccess } = await putClientInDraftMode({
        params: { clientId },
        data: { isActive: false },
      });

      hide();
      if (isSuccess) {
        void slobMessage.success("Client Updated");
      } else if (isError) {
        void slobMessage.error(genericErrorCopy2);
      }
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleMakeLive = async () => {
    const hide = slobMessage.loading("Making Live…", 0);

    try {
      const { isError, isSuccess } = await putClientInDraftMode({
        params: { clientId },
        data: { isActive: true },
      });

      hide();
      if (isSuccess) {
        void slobMessage.success("Client Updated");
      } else if (isError) {
        void slobMessage.error(genericErrorCopy2);
      }
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  if (error) {
    return triggerError(error);
  }

  if (!clientId) {
    return triggerError("Client not found");
  }

  if (syncError) {
    if (errorToMessage(syncError) === "TicketId not found") {
      if (isInternalUser) {
        return (
          <ReplaceClient
            clientId={clientId}
            clientName={client?.name}
            handleDelete={handleDelete}
          />
        );
      }
      return triggerError(syncError);
    }
  }

  if (errorFeatureToggles) {
    return triggerError(errorFeatureToggles);
  }

  if (isClientLoading || !client || !detailsItems || isPendingFeatureToggles) {
    return <Loading />;
  }

  if (client?.isArchived) {
    return <Navigate to={RouteData.notFound.getPath()} replace />;
  }

  const primaryPolicy = client.policies.find((p) => p.primaryPolicy);
  const riskRejected = primaryPolicy ? isRiskRejected(primaryPolicy) : false;

  const menuItems: MenuProps["items"] = [
    ...(isInternalUser
      ? [
          {
            key: "email-log",
            label: <Link to={RouteData.emails.getPath(clientId)}>Email Log</Link>,
          },
        ]
      : []),

    ...(client.isActive
      ? [{ key: "put-into-draft", label: "Put in Draft mode" }]
      : [
          {
            key: "make-live",
            disabled: riskRejected,
            label: (
              <Tooltip
                title={
                  riskRejected
                    ? "This Client is in Risk Rejected status and can't be promoted to live."
                    : null
                }
                placement="left"
              >
                <div>Make Live</div>
              </Tooltip>
            ),
          },
        ]),

    { key: "delete-client", label: "Delete Client" },
  ];

  const showSetupPage = client.isOnboard && !client.completedSetup;
  const usingDEIF = client.digitalEIF;

  return (
    <PageContent>
      <div className="mb-12">
        <GoBackLink to={RouteData.homeInternal.getPath()}>&lt; Back to all Clients</GoBackLink>
      </div>
      <Row>
        <Col span={16}>
          <Title>
            {client.name} {!client.isActive && <ClientDraftBadge />}{" "}
            {client.isTest && <ClientTestBadge />}
          </Title>
        </Col>
        <Col flex="auto">
          <Row justify="end" gutter={8}>
            <Col>
              <Button type="primary" to={RouteData.homeHub.getPath(clientId)}>
                View Portal
              </Button>
            </Col>
            <Col>
              <Tooltip
                title={
                  isClientSyncDisabled ? "This functionality is unavailable at this time" : null
                }
              >
                <Button
                  disabled={isClientSyncDisabled}
                  loading={isUpdateClientLoading}
                  onClick={handleSyncClient}
                >
                  Sync with Jira <SwapOutlined />
                </Button>
              </Tooltip>
            </Col>
            <Col>
              <SlobDropdown
                dropdownRender={() => (
                  <>
                    <ActionMenu
                      data-testid="dropdown-menu"
                      items={menuItems}
                      onClick={({ key }) => {
                        switch (key) {
                          case "delete-client":
                            setIsDeleteDialogVisible(true);
                            break;
                          case "put-into-draft":
                            void handlePutInDraftMode();
                            break;
                          case "make-live":
                            void handleMakeLive();
                            break;
                        }
                      }}
                    />
                    <ConfirmDialogDeleteClient
                      clientName={client.name ?? ""}
                      handleDelete={handleDelete}
                      onCancel={() => setIsDeleteDialogVisible(false)}
                      isVisible={isDeleteDialogVisible}
                    />
                  </>
                )}
              >
                <DropdownButton />
              </SlobDropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="w-full mb-24">
        <ClientInternalErrorBanner client={client} />
      </div>
      <Row gutter={30}>
        <Col span={6}>
          <DetailsList items={detailsItems} className={styles.clientDetailsList} />
        </Col>
        <Col span={18}>
          {showSetupPage ? (
            <StackY dist={40} wrap={false}>
              <ClientSetup featureToggles={featureToggles} client={client} />
              <NotificationSettings client={client} />

              <div>
                <ExplorerPageList
                  clientId={clientId}
                  featureToggles={featureToggles}
                  namespace="benex"
                />
                <ExplorerPageCreatePage clientId={clientId} />
              </div>
            </StackY>
          ) : (
            <StackY dist={40} wrap={false}>
              <div>
                <BrokerList featureToggles={featureToggles} namespace="broker" />
                <BrokerCreatePage client={client} sendWelcomeEmail={true} />
              </div>

              {!client.brokerAsAuthorizedSigner && (
                <div>
                  <BenAdminList client={client} featureToggles={featureToggles} namespace="ba" />
                  <BenAdminCreatePage clientId={clientId} />
                </div>
              )}

              {featureToggles.ONBOARD_BROKER_SIGN_DEIF && (
                <div className={styles.summaryContainer}>
                  <BrokerAsAuthorizedSignerCheckBox
                    client={client}
                    updateClient={updateClient}
                    track={track}
                    clearAuthorizedSigner={true}
                  />
                </div>
              )}

              {usingDEIF && <SubmitCompanyInformationSettings featureToggles={featureToggles} />}

              <NotificationSettings client={client} />

              <div>
                <ExplorerPageList
                  clientId={clientId}
                  featureToggles={featureToggles}
                  namespace="benex"
                />
                <ExplorerPageCreatePage clientId={clientId} />
              </div>
            </StackY>
          )}
        </Col>
      </Row>
      <ExplorerPageCreatedModal
        visible={location.state?.newExplorerPage != null}
        pageId={location.state?.newExplorerPage?.id ?? ""}
        pageVanitySlug={location.state?.newExplorerPage?.vanitySlug}
        pageName={location.state?.newExplorerPage?.name ?? ""}
        pageCustomBranding={location.state?.newExplorerPage?.customBranding ?? false}
        onCancel={() => {
          if (location.state?.newExplorerPage) {
            const state = { ...location.state };
            delete state.newExplorerPage;
            navigate(location, { replace: true, state });
          }
        }}
      />
    </PageContent>
  );
};
