import {
  checkIfShowPlanComparisonFields,
  isPageNewOrValidDate,
} from "client/src/domain/ExplorerPages/utils";
import { useSlobAuth } from "client/src/hooks/auth";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useCallback, useEffect, useState } from "react";

import { BenefitLevelData, DentalNetworkData, PlanTypeData } from "shared/data/ExplorerBenefit";
import { LocationStateData } from "shared/data/LocationState";
import { CARRIER } from "shared/rbac/roles";
import { BenefitTypeBenExData, BenefitTypesBenEx } from "shared/types/BenefitTypes";
import {
  BenefitCarrierBlocklist,
  BenefitContentFlags,
  BenefitLevelsByBenefit,
  BenefitPlanTypes,
  BenefitPremiumAndCosts,
  DentalNetworkByPlan,
  DentalNetworkByState,
  DentalStateByPlanType,
  getDefaultPlanName,
  isSunlifeCarrier,
} from "shared/types/ExplorerPageBenefit";
import { explorerPageBenefitInputValidation } from "shared/validation/explorerPageBenefit";
import type { ExplorerBenefitModalProps } from "./ExplorerBenefitModal";
import type { PlanType } from "@prisma/client";
import type { LabeledValue } from "antd/lib/select";
import type { CheckboxChangeEvent } from "client/src/components/Form/Checkbox";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { Carrier } from "shared/types/Carrier";
import type { Document } from "shared/types/Document";
import type { ContentFlag } from "shared/types/ExplorerPageBenefit";
import type { LocationStateCode } from "shared/types/Location";
import type { ShowPlanComparisonFields } from "shared/types/Plan";

export const useExplorerBenefitModalState = ({
  visible,
  planSummaryDocuments,
  spanishPlanSummaryDocuments,
  planSummaryDocumentsByBenefitType,
  spanishPlanSummaryDocumentsByBenefitType,
  carriers,
  benefit,
  clientId,
  employerState,
  featureToggle,
  defaultLinkedHDHPPlanName,
  onSave,
  onDelete,
  onCancel,
  pageCreationDate,
  isRenewal,
  hideNewVisionPlanFields,
  hideNewDentalPlanFields,
}: ExplorerBenefitModalProps) => {
  const [showPlanComparisonFields, setShowPlanComparisonFields] =
    useState<ShowPlanComparisonFields>(false);
  const [showFSARolloverFields, setShowFSARolloverFields] = useState<boolean>(false);

  const formik = useSlobFormik({
    enableReinitialize: true,
    validateOnMount: !!benefit,
    initialValues: {
      id: benefit ? benefit.id : undefined,
      benefitType: benefit ? benefit.benefitType : "",
      planType: benefit ? benefit.planType : "EPO",
      isNew: benefit ? benefit.isNew : false,
      legalEntity: benefit ? benefit.legalEntity : null,
      carrierId: benefit ? benefit.carrierId : 0,
      planName: benefit ? benefit.planName : "",
      planSummaryDocumentId: benefit ? benefit.planSummaryDocumentId : null,
      contentFlags: benefit ? benefit.contentFlags : null,
      benefitLevel: benefit ? benefit.benefitLevel : null,
      wellnessAmount: benefit ? benefit.wellnessAmount : null,
      prepaidDentalNetwork: benefit ? benefit.prepaidDentalNetwork : null,
      prepaidSitusState: benefit ? benefit.prepaidSitusState : null,
      premium: benefit ? benefit.premium : null,
      outOfPocketCosts: benefit ? benefit.outOfPocketCosts : null,
      hidePremiumOutOfPocket: featureToggle?.BENEX_MEDICAL_PLAN_COMPARISON,
      spanishPlanSummaryDocumentId: benefit ? benefit.spanishPlanSummaryDocumentId : null,
      linkedHDHPPlanName: benefit ? benefit.linkedHDHPPlanName : defaultLinkedHDHPPlanName,
      planYearDeductibleIndividualInNetwork: benefit?.planYearDeductibleIndividualInNetwork ?? 0,
      planYearDeductibleIndividualOutOfNetwork:
        benefit?.planYearDeductibleIndividualOutOfNetwork ?? 0,
      planYearDeductibleFamilyInNetwork: benefit?.planYearDeductibleFamilyInNetwork ?? 0,
      planYearDeductibleFamilyOutOfNetwork: benefit?.planYearDeductibleFamilyOutOfNetwork ?? 0,
      planYearDeductibleOutOfNetworkNA: benefit?.planYearDeductibleOutOfNetworkNA ?? false,
      planYearMaximumIndividualInNetwork: benefit?.planYearMaximumIndividualInNetwork ?? 0,
      planYearMaximumIndividualOutOfNetwork: benefit?.planYearMaximumIndividualOutOfNetwork ?? 0,
      planYearMaximumFamilyInNetwork: benefit?.planYearMaximumFamilyInNetwork ?? 0,
      planYearMaximumFamilyOutOfNetwork: benefit?.planYearMaximumFamilyOutOfNetwork ?? 0,
      planYearMaximumOutOfNetworkNA: benefit?.planYearMaximumOutOfNetworkNA ?? false,
      medicalHealthSavingsAccountEmployerContribution:
        benefit?.medicalHealthSavingsAccountEmployerContribution ?? null,
      medicalOutOfNetworkBenefits: benefit?.medicalOutOfNetworkBenefits ?? null,
      medicalPcpCoordinationRequired: benefit?.medicalPcpCoordinationRequired ?? null,
      medicalPcpSpecialtyCareReferralRequired:
        benefit?.medicalPcpSpecialtyCareReferralRequired ?? null,
      dentalOrthodonticServices: benefit?.dentalOrthodonticServices ?? null,
      dentalOrthodonticMaximumAdult: benefit?.dentalOrthodonticMaximumAdult ?? 0,
      dentalOrthodonticMaximumChild: benefit?.dentalOrthodonticMaximumChild ?? 0,
      dentalTypeOneInNetwork: benefit?.dentalTypeOneInNetwork ?? 0,
      dentalTypeOneOutOfNetwork: benefit?.dentalTypeOneOutOfNetwork ?? 0,
      dentalTypeTwoInNetwork: benefit?.dentalTypeTwoInNetwork ?? 0,
      dentalTypeTwoOutOfNetwork: benefit?.dentalTypeTwoOutOfNetwork ?? 0,
      dentalTypeThreeInNetwork: benefit?.dentalTypeThreeInNetwork ?? 0,
      dentalTypeThreeOutOfNetwork: benefit?.dentalTypeThreeOutOfNetwork ?? 0,
      visionExamServicesBiYearly: benefit?.visionExamServicesBiYearly ?? null,
      visionExamServicesInNetwork: benefit?.visionExamServicesInNetwork ?? 0,
      visionExamServicesOutOfNetwork: benefit?.visionExamServicesOutOfNetwork ?? 0,
      visionLensesBiYearly: benefit?.visionLensesBiYearly ?? null,
      visionLensesInNetwork: benefit?.visionLensesInNetwork ?? 0,
      visionLensesOutOfNetwork: benefit?.visionLensesOutOfNetwork ?? 0,
      visionFramesBiYearly: benefit?.visionFramesBiYearly ?? null,
      visionFramesInNetwork: benefit?.visionFramesInNetwork ?? 0,
      visionFramesOutOfNetwork: benefit?.visionFramesOutOfNetwork ?? 0,
      visionFramesOverInNetwork: benefit?.visionFramesOverInNetwork ?? 0,
      visionFramesOverOutOfNetwork: benefit?.visionFramesOverOutOfNetwork ?? 0,
      visionContactLensesBiYearly: benefit?.visionContactLensesBiYearly ?? null,
      visionContactLensesInNetwork: benefit?.visionContactLensesInNetwork ?? 0,
      visionContactLensesOutOfNetwork: benefit?.visionContactLensesOutOfNetwork ?? 0,
      visionContactLensesExamInNetwork: benefit?.visionContactLensesExamInNetwork ?? 0,
      visionContactLensesExamOutOfNetwork: benefit?.visionContactLensesExamOutOfNetwork ?? 0,
      financialFSAMoneyRollover: benefit?.financialFSAMoneyRollover ?? null,
      financialFSAMoneyRolloverAmount: benefit?.financialFSAMoneyRolloverAmount ?? 0,
      financialFSAMoneyRolloverDate: benefit?.financialFSAMoneyRolloverDate ?? null,
    },
    initialTouched: {
      benefitType: !!benefit,
      planType: !!benefit,
      isNew: !!benefit,
      legalEntity: !!benefit,
      carrierId: !!benefit,
      planName: !!benefit,
      planSummaryDocumentId: !!benefit,
      contentFlags: !!benefit,
      benefitLevel: !!benefit,
      wellnessAmount: !!benefit,
      prepaidDentalNetwork: !!benefit,
      prepaidSitusState: !!benefit,
      premium: !!benefit,
      outOfPocketCosts: !!benefit,
      spanishPlanSummaryDocumentId: !!benefit,
    },
    validationSchema: explorerPageBenefitInputValidation,
    validationContext: {
      showPlanComparisonFields,
      showFSARolloverFields,
    },
    onSubmit: (values) => {
      onSave(values);
      formik.resetForm();
    },
  });

  useEffect(() => {
    setShowPlanComparisonFields(
      checkIfShowPlanComparisonFields(
        pageCreationDate,
        formik.values.benefitType ?? "",
        formik.values.planType,
        featureToggle,
        isRenewal,
        hideNewVisionPlanFields,
        hideNewDentalPlanFields,
      ),
    );

    setShowFSARolloverFields(
      !!featureToggle?.BENEX_FSA_ROLLOVER &&
        formik.values.benefitType === "FINANCIAL" &&
        (formik.values.planType === "FSA" || formik.values.planType === "DCA") &&
        (isPageNewOrValidDate(pageCreationDate, formik.values.benefitType) || !!isRenewal),
    );
  }, [
    pageCreationDate,
    formik.values.benefitType,
    formik.values.planType,
    featureToggle,
    isRenewal,
    hideNewVisionPlanFields,
    hideNewDentalPlanFields,
  ]);

  const { accessCheck } = useSlobAuth();
  const canCreateCarrier = accessCheck("create", CARRIER).granted;

  const [confirmingBenefitDeletion, setConfirmingDelete] = useState(false);
  const [uploadingDocument, setUploadingDocument] = useState(false);
  const [uploadSpanishDocument, setUploadSpanishDocument] = useState(false);

  const title = benefit
    ? confirmingBenefitDeletion
      ? "Remove Benefit"
      : uploadingDocument
      ? uploadSpanishDocument
        ? "Upload Spanish Document"
        : "Upload Document"
      : "Edit Benefit"
    : "Add Benefit";

  const carrierOptions = carriers.filter((carrier) =>
    formik.values.benefitType
      ? !BenefitCarrierBlocklist.get(formik.values.benefitType)?.includes(carrier.carrierName)
      : false,
  );

  const getAvailablePlanTypes = useCallback(() => {
    if (formik.values.benefitType) {
      const availablePlanTypes = BenefitPlanTypes.get(formik.values.benefitType);
      return availablePlanTypes?.filter((type) => {
        switch (type) {
          case "STANDALONE_ADD":
            return !!featureToggle?.BENEX_STANDALONE_ADD;
          default:
            return true;
        }
      });
    }
  }, [featureToggle?.BENEX_STANDALONE_ADD, formik.values.benefitType]);

  const getContentFlagsPerBenefit = (
    benefitType: BenefitTypeBenEx,
    planType: PlanType | null | undefined,
    empState: LocationStateCode | null,
  ) => {
    const omittedFlags: ContentFlag[] = [];

    if (benefitType === "LIFE" && (empState === "NY" || empState === "VT")) {
      omittedFlags.push("Self_Care+");
    }

    if (planType === "STANDALONE_ADD") {
      omittedFlags.push("AD&D");
    }

    return BenefitContentFlags.get(benefitType)?.filter((bt) => !omittedFlags.includes(bt));
  };

  const availableContentFlags = formik.values.benefitType
    ? getContentFlagsPerBenefit(formik.values.benefitType, formik.values.planType, employerState)
    : undefined;

  const prepaidNetworkRequired =
    isSunlifeCarrier(formik.values.carrierId) && formik.values.benefitType === "DENTAL";

  const prepaidSitusStateRequired = prepaidNetworkRequired && formik.values.planType === "DHMO";

  const isBenefitTypeNotGap = formik.values.benefitType !== "GAP";
  const isBenefitTypeMedical = formik.values.benefitType === "MEDICAL";
  const isBenefitTypeDental = formik.values.benefitType === "DENTAL";
  const isBenefitTypeVision = formik.values.benefitType === "VISION";
  const isBenefitTypeFinancial = formik.values.benefitType === "FINANCIAL";

  const situsState =
    formik.values.planType === "DHMO" ? formik.values.prepaidSitusState : employerState;

  const availableNetworksByState =
    situsState && situsState !== "MT"
      ? formik.values.planType &&
        DentalStateByPlanType.get(formik.values.planType)?.includes(situsState)
        ? DentalNetworkByState.get(situsState) ?? null
        : null
      : null;
  const availableNetworksByPlan = formik.values.planType
    ? DentalNetworkByPlan.get(formik.values.planType) ?? null
    : null;
  const availableNetworkByStateAndPlan =
    availableNetworksByState?.filter((o) => availableNetworksByPlan?.some((val) => o === val)) ??
    [];

  const onCarrierChange = useCallback(
    async (carrierId: number) => {
      const newValues = { ...formik.values };
      newValues.carrierId = carrierId;
      newValues.planName = getDefaultPlanName(newValues, carriers, employerState);
      newValues.prepaidDentalNetwork = null;
      newValues.prepaidSitusState = null;
      await formik.setValues(newValues);
    },
    [formik, carriers, employerState],
  );

  const onCancelModal = () => {
    if (uploadingDocument) {
      setUploadingDocument(false);
      setUploadSpanishDocument(false);
      return;
    }
    formik.resetForm();
    setConfirmingDelete(false);
    setUploadingDocument(false);
    setUploadSpanishDocument(false);
    onCancel();
  };

  const onConfirmDialogContent = () => {
    formik.resetForm();
    onDelete();
    setConfirmingDelete(false);
  };

  const onUploadDocumentUploadContent = (documents: Array<Document>) =>
    formik.setValues((oldVals) => ({
      ...oldVals,
      ...(!uploadSpanishDocument && {
        planSummaryDocumentId: documents.length && documents[0] ? documents[0].id : null,
      }),
      ...(uploadSpanishDocument && {
        spanishPlanSummaryDocumentId: documents.length && documents[0] ? documents[0].id : null,
      }),
    }));

  const onBenefitChange = async (option: { label: string; value: "" | BenefitTypeBenEx }) => {
    const newValues = { ...formik.values };
    newValues.benefitType = option.value;
    const planTypes = option.value ? BenefitPlanTypes.get(option.value) : null;
    newValues.planType = planTypes?.length === 1 ? planTypes[0] : null;
    newValues.carrierId = 0;
    newValues.contentFlags = null;
    newValues.benefitLevel = null;
    newValues.planName = getDefaultPlanName(newValues, carriers, employerState);
    await formik.setValues(newValues);
  };

  const benefitOption = formik.values.benefitType
    ? {
        label: BenefitTypeBenExData[formik.values.benefitType].displayName,
        value: formik.values.benefitType,
      }
    : null;

  const benefitOptions: {
    label: string;
    value: BenefitTypeBenEx;
  }[] = BenefitTypesBenEx.filter((b) => b !== "GAP").map((type) => ({
    label: BenefitTypeBenExData[type].displayName,
    value: type,
  }));

  const onBenefitPlanTypeChange = async (option: { label: string; value: "" | PlanType }) => {
    const newValues = { ...formik.values };
    option.value === "" ? (newValues.planType = null) : (newValues.planType = option.value);
    newValues.planName = getDefaultPlanName(newValues, carriers, employerState);
    newValues.prepaidDentalNetwork = null;
    newValues.prepaidSitusState = null;
    await formik.setValues(newValues);
  };

  const benefitPlanTypeOption = formik.values.planType
    ? {
        label: PlanTypeData[formik.values.planType].displayName,
        value: formik.values.planType,
      }
    : null;

  const availablePlanTypes = getAvailablePlanTypes();

  const benefitPlanTypeOptions = availablePlanTypes?.map((type) => ({
    label: PlanTypeData[type].displayName,
    value: type,
  }));

  const onBenefitCarrierChange = (option: { label: string; value: number }) =>
    onCarrierChange(option.value);

  const benefitCarrierOption = formik.values.carrierId
    ? {
        label: carriers.filter((c) => c.id === formik.values.carrierId)[0]?.carrierName ?? "",
        value: formik.values.carrierId,
      }
    : null;

  const benefitCarrierOptions = carrierOptions.map((c) => ({
    label: c.carrierName,
    value: c.id,
  }));

  const onBenefitCarrierCreate = (carrier: Carrier) => onCarrierChange(carrier.id);

  const onBenefitPrepaidSitusStateChange = async (option: {
    label: string;
    value: "" | LocationStateCode;
  }) => {
    const newValues = { ...formik.values };
    newValues.prepaidSitusState = option.value ? option.value : null;
    newValues.planName = getDefaultPlanName(newValues, carriers, employerState);
    newValues.prepaidDentalNetwork = null;
    await formik.setValues(newValues);
  };

  const availableSitusStates = formik.values.planType
    ? DentalStateByPlanType.get(formik.values.planType) ?? null
    : null;

  const benefitPrepaidSitusStateOption = formik.values.prepaidSitusState
    ? {
        label: LocationStateData[formik.values.prepaidSitusState].displayName,
        value: formik.values.prepaidSitusState,
      }
    : null;

  const benefitPrepaidSitusStateOptions = availableSitusStates?.map((state) => ({
    label: LocationStateData[state].displayName,
    value: state,
  }));

  const benefitNetworkOption = formik.values.prepaidDentalNetwork
    ? {
        label: DentalNetworkData[formik.values.prepaidDentalNetwork].displayName,
        value: formik.values.prepaidDentalNetwork,
      }
    : null;

  const benefitNetworkOptions = availableNetworkByStateAndPlan.map((network) => ({
    label: DentalNetworkData[network].displayName,
    value: network,
  }));

  const onBenefitNetworkChange = async (option: typeof benefitNetworkOption) => {
    await formik.setFieldValue("prepaidDentalNetwork", option?.value);
  };

  const benefitLevelOption = formik.values.benefitLevel
    ? {
        label: BenefitLevelData[formik.values.benefitLevel].displayName,
        value: formik.values.benefitLevel ?? "",
      }
    : null;

  const availableBenefitLevels = formik.values.benefitType
    ? BenefitLevelsByBenefit.get(formik.values.benefitType) ?? null
    : null;

  const benefitLevelOptions = availableBenefitLevels?.map((type) => ({
    label: BenefitLevelData[type].displayName,
    value: type,
  }));

  const onBenefitLevelChange = async (val: typeof benefitLevelOption) => {
    const newValues = { ...formik.values };
    newValues.benefitLevel = val?.value ? val.value : null;
    newValues.planName = getDefaultPlanName(newValues, carriers, employerState);
    await formik.setValues(newValues);
  };

  const onBenefitLevelClear = async () => {
    const newValues = { ...formik.values };
    newValues.benefitLevel = null;
    newValues.planName = getDefaultPlanName(newValues, carriers, employerState);
    await formik.setValues(newValues);
  };

  const createBenefitContentFlagChangeHandler =
    (flag: ContentFlag) =>
    async (event: CheckboxChangeEvent): Promise<void> => {
      const newValues = { ...formik.values };
      if (event.target.checked && !formik.values.contentFlags?.includes(flag)) {
        const newFlags = formik.values.contentFlags ?? [];
        newFlags.push(flag);
        newValues.contentFlags = newFlags;
      } else if (!event.target.checked && formik.values.contentFlags?.includes(flag)) {
        newValues.contentFlags = formik.values.contentFlags.filter((f) => f !== flag);
      }
      newValues.planName =
        formik.values.benefitType !== "MEDICAL"
          ? getDefaultPlanName(newValues, carriers, employerState)
          : formik.values.planName;
      await formik.setValues(newValues);
    };

  const availablePremiumAndCosts = formik.values.benefitType
    ? BenefitPremiumAndCosts.get(formik.values.benefitType)
    : undefined;

  const benefitPremiumOptions = availablePremiumAndCosts?.map((cost) => ({
    label: cost,
    value: cost,
  }));

  const onPlanSummaryDocumentChange = async (option?: LabeledValue) => {
    const planSummaryDocumentId = !option || option.value === "" ? null : option.value;
    await formik.setFieldValue("planSummaryDocumentId", planSummaryDocumentId);
  };

  const onPlanSummaryDocumentUploadClick = () => {
    setUploadSpanishDocument(false);
    setUploadingDocument(true);
  };

  const onSpanishPlanSummaryDocumentChange = async (option?: LabeledValue) => {
    const spanishPlanSummaryDocumentId = !option || option.value === "" ? null : option.value;
    await formik.setFieldValue("spanishPlanSummaryDocumentId", spanishPlanSummaryDocumentId);
  };

  const onSpanishPlanSummaryDocumentUploadClick = () => {
    setUploadingDocument(true);
    setUploadSpanishDocument(true);
  };

  const onRemoveBenefitClick = () => {
    setConfirmingDelete(true);
  };

  const showLegalEntity = !isSunlifeCarrier(formik.values.carrierId);

  const shouldShowBenefitLevels = (): boolean => {
    if (formik.values.benefitType === "ACCIDENT" || formik.values.benefitType === "CANCER")
      return isSunlifeCarrier(formik.values.carrierId);
    if (
      formik.values.benefitType === "LONG_TERM_DISABILITY" ||
      formik.values.benefitType === "SHORT_TERM_DISABILITY"
    )
      return formik.values.planType === "EMPLOYER_PAID";
    if (formik.values.benefitType === "LIFE") return formik.values.planType === "BASIC_LIFE";
    return true;
  };

  const shouldShowWellnessAmount = (): boolean => {
    return (
      isSunlifeCarrier(formik.values.carrierId) &&
      (formik.values.benefitType === "ACCIDENT" ||
        formik.values.benefitType === "HOSPITAL_INDEMNITY" ||
        formik.values.benefitType === "CANCER" ||
        formik.values.benefitType === "CRITICAL_ILLNESS")
    );
  };

  const shouldShowAdditionalContent = (flag: ContentFlag): boolean => {
    if (
      formik.values.benefitType === "LIFE" &&
      (flag === "VAS_1" || flag === "VAS_2" || flag === "Self_Care+")
    )
      return isSunlifeCarrier(formik.values.carrierId);
    else if (formik.values.benefitType === "ACCIDENT" && flag === "VAS_1")
      return isSunlifeCarrier(formik.values.carrierId);
    return true;
  };

  return {
    formik,
    data: {
      title,
      clientId,
      featureToggle,
      visible,
      canCreateCarrier,
      benefit,
      benefitOption,
      benefitOptions,
      benefitPlanTypeOption,
      benefitPlanTypeOptions,
      benefitCarrierOption,
      benefitCarrierOptions,
      prepaidSitusStateRequired,
      benefitPrepaidSitusStateOption,
      benefitPrepaidSitusStateOptions,
      benefitNetworkOption,
      benefitNetworkOptions,
      situsState,
      prepaidNetworkRequired,
      benefitLevelOption,
      benefitLevelOptions,
      availableContentFlags,
      isBenefitTypeNotGap,
      isBenefitTypeMedical,
      isBenefitTypeDental,
      isBenefitTypeVision,
      isBenefitTypeFinancial,
      benefitPremiumOptions,
      planSummaryDocuments,
      spanishPlanSummaryDocuments,
      planSummaryDocumentsByBenefitType,
      spanishPlanSummaryDocumentsByBenefitType,
      showLegalEntity,
      showBenefitLevels: shouldShowBenefitLevels(),
      showWellnessAmount: shouldShowWellnessAmount(),
      showAdditionalContentByContentFlag: availableContentFlags?.reduce((map, contentFlag) => {
        map.set(contentFlag, shouldShowAdditionalContent(contentFlag));
        return map;
      }, new Map<ContentFlag, boolean>()),
      showLinkedHDHPPlanName:
        formik.values.benefitType === "FINANCIAL" && formik.values.planType === "HSA",

      showPlanComparisonFields,
      showFSARolloverFields,
    },
    state: {
      confirmingBenefitDeletion,
      uploadingDocument,
      uploadSpanishDocument,
    },
    modalActions: {
      onCancelModal,
      onConfirmDialogContent,
      onCancelDialogContent: () => setConfirmingDelete(false),
    },
    documentUploadContentActions: {
      onTrackDocumentUploadContent: () => null,
      onDoneDocumentUploadContent: () => setUploadingDocument(false),
      onUploadDocumentUploadContent,
    },
    benefitActions: {
      onBenefitChange,
      onBenefitPlanTypeChange,
      onBenefitCarrierChange,
      onBenefitCarrierCreate,
      onBenefitPrepaidSitusStateChange,
      onBenefitNetworkChange,
      onBenefitLevelChange,
      onBenefitLevelClear,
      onRemoveBenefitClick,
      createBenefitContentFlagChangeHandler,
    },
    planSummaryDocumentActions: {
      onPlanSummaryDocumentChange,
      onPlanSummaryDocumentUploadClick,
      onSpanishPlanSummaryDocumentChange,
      onSpanishPlanSummaryDocumentUploadClick,
    },
  };
};
