import { getIsStatutoryBenefitType } from "shared/types/BenefitTypes";
import { disabilityStateCodes } from "shared/types/Location";
import { assertIsDefined } from "shared/utils/utils";
import { taxIdSchema } from "shared/validation/client";
import { locationInputValidation } from "shared/validation/location";
import { nullNotAllowedTestConfig } from "shared/validation/validation";
import * as Yup from "yup";
import type { BenefitTypeEIF } from "@prisma/client";
import type { YesNo } from "shared/types/Client";
import type { DisabilityStateCode } from "shared/types/Location";

export const subsidiaryLocationInputValidation = locationInputValidation.pick([
  "name",
  "address1",
  "address2",
  "city",
  "state",
  "zipCode",
  "locationType",
]);

export const subsidiaryValidationSchema = Yup.object({
  companyName: Yup.string()
    .nullable()
    .defined()
    .when(["$prefill", "$benefitTypes", "$hasSubsidiary", "$subsidiaryEEsInNYNJOrHI"], {
      is: (
        prefill: boolean | undefined,
        benefitTypes: BenefitTypeEIF[] | undefined,
        hasSubsidiary: YesNo | undefined,
        subsidiaryEEsInNYNJOrHI: YesNo | undefined,
      ) => {
        assertIsDefined(benefitTypes, "benefitTypes");
        assertIsDefined(hasSubsidiary, "hasSubsidiary");
        assertIsDefined(subsidiaryEEsInNYNJOrHI, "subsidiaryEEsInNYNJOrHI");

        const hasStatutoryBenefits = benefitTypes.some((benefitType) =>
          getIsStatutoryBenefitType(benefitType),
        );

        const isRequired =
          !prefill &&
          ((hasStatutoryBenefits && subsidiaryEEsInNYNJOrHI === "YES") ||
            (benefitTypes.includes("DENTAL_ASO") && hasSubsidiary === "YES"));

        return isRequired;
      },
      then: (schema) => schema.required("Please provide a company name"),
      otherwise: (schema) => schema,
    }),
  taxId: taxIdSchema
    .nullable()
    .defined()
    .when(["$prefill", "$benefitTypes", "$subsidiaryEEsInNYNJOrHI"], {
      is: (
        prefill: boolean | undefined,
        benefitTypes: BenefitTypeEIF[] | undefined,
        subsidiaryEEsInNYNJOrHI: YesNo | undefined,
      ) => {
        assertIsDefined(benefitTypes, "benefitTypes");
        assertIsDefined(subsidiaryEEsInNYNJOrHI, "subsidiaryEEsInNYNJOrHI");

        const hasStatutoryBenefits = benefitTypes.some((benefitType) =>
          getIsStatutoryBenefitType(benefitType),
        );

        const isNullable = !!prefill || !hasStatutoryBenefits || subsidiaryEEsInNYNJOrHI === "NO";
        return isNullable;
      },
      then: (schema) => schema.transform((value: string | undefined) => (value ? value : null)),
      otherwise: (schema) => {
        return schema.test(nullNotAllowedTestConfig());
      },
    }),
  employeeStates: Yup.array()
    .of(Yup.mixed<DisabilityStateCode>().oneOf(disabilityStateCodes.slice()).required())
    .nullable()
    .defined()
    .when(["$prefill", "$benefitTypes", "$subsidiaryEEsInNYNJOrHI"], {
      is: (
        prefill: boolean | undefined,
        benefitTypes: BenefitTypeEIF[] | undefined,
        subsidiaryEEsInNYNJOrHI: YesNo | undefined,
      ) => {
        assertIsDefined(benefitTypes, "benefitTypes");
        assertIsDefined(subsidiaryEEsInNYNJOrHI, "subsidiaryEEsInNYNJOrHI");

        const hasStatutoryBenefits = benefitTypes.some((benefitType) =>
          getIsStatutoryBenefitType(benefitType),
        );

        const isRequired = !prefill && hasStatutoryBenefits && subsidiaryEEsInNYNJOrHI === "YES";
        return isRequired;
      },
      then: (schema) =>
        schema
          .min(1, "Please select at least one state")
          .required("Please select at least one state"),
    }),
  location: subsidiaryLocationInputValidation
    .nullable()
    .when(["$prefill", "$benefitTypes", "$hasSubsidiary"], {
      is: (
        prefill: boolean | undefined,
        benefitTypes: BenefitTypeEIF[] | undefined,
        hasSubsidiary: YesNo | undefined,
      ) => {
        assertIsDefined(benefitTypes, "benefitTypes");
        assertIsDefined(hasSubsidiary, "hasSubsidiary");
        const isRequired =
          !prefill && benefitTypes.includes("DENTAL_ASO") && hasSubsidiary === "YES";
        return isRequired;
      },
      then: (schema) => schema.required("Please enter address information"),
    }),
});
