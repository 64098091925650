import clsx from "clsx";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- disable
export type ElementOrReactComponent = keyof JSX.IntrinsicElements | React.ComponentType<any>;

type WrapperProps<As extends ElementOrReactComponent> = Omit<
  React.ComponentPropsWithRef<As>,
  "className"
> & {
  colorPrimary?: boolean;
  colorSecondary?: boolean;
  blueMedium?: boolean;
  blue?: boolean;
  redMedium?: boolean;
  greenMedium?: boolean;
  white?: boolean;
  yellow?: boolean;
  darkYellow?: boolean;
  greyMedium?: boolean;
  greyDark?: boolean;
  as?: As;
};

const typographyFactory = (
  displayName: string,
  className: string,
  tagName: keyof JSX.IntrinsicElements = "span",
) => {
  const Wrapper = <As extends ElementOrReactComponent = typeof tagName>({
    as,
    colorPrimary,
    colorSecondary,
    blue,
    blueMedium,
    yellow,
    white,
    darkYellow,
    redMedium,
    greenMedium,
    greyMedium,
    greyDark,
    ...props
  }: WrapperProps<As>) => {
    const Component = as || tagName;
    return (
      <Component
        {...props}
        className={clsx(className, {
          colorPrimary,
          colorSecondary,
          blue,
          blueMedium,
          yellow,
          white,
          darkYellow,
          redMedium,
          greenMedium,
          greyMedium,
          greyDark,
        })}
      />
    );
  };

  Wrapper.displayName = displayName;

  return Wrapper;
};

export const H1 = typographyFactory("H1", "h1", "h1");
export const H1Large = typographyFactory("H1Large", "h1-large", "h1");
export const H1ExtraLarge = typographyFactory("H1ExtraLarge", "h1-extra-large", "h1");
export const H2 = typographyFactory("H2", "h2", "h2");
export const H3 = typographyFactory("H3", "h3", "h3");
export const H4 = typographyFactory("H4", "h4", "h4");
export const H5 = typographyFactory("H5", "h5", "h5");

export const Body1 = typographyFactory("Body1", "body1");
export const Body2 = typographyFactory("Body2", "body2");
export const Body3 = typographyFactory("Body3", "body3");
export const Body4 = typographyFactory("Body4", "body4");
export const Body5 = typographyFactory("Body5", "body5");
export const Body6 = typographyFactory("Body6", "body6");
export const Body7 = typographyFactory("Body7", "body7");
export const Body8 = typographyFactory("Body8", "body8");
export const Eyebrow = typographyFactory("EyeBrow", "eyebrow");
